import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

const Title = ({ children, className, ...props }) => (
  <div className={classnames(styles.title, className)} {...props}>
    <h2>{children}</h2>
  </div>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Title.defaultProps = {
  className: undefined,
};

export default Title;
