import I18n from 'utils/i18n';
import PasswordValidator from './PasswordValidator';

export default class ForbiddenWordsValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { forbiddenWords } = passwordPolicy;

    const { words } = forbiddenWords;

    let translation = null;

    if (words.length !== 0) {
      translation = <I18n props={{ words }} path="organization.password-rules.forbidden-words" />;
    }

    super(forbiddenWords, 'forbidden_words', false, translation);
  }

  validate(password) {
    if (this.policy) {
      const { words } = this.policy;
      const isCaseSensitive = this.policy.is_case_sensitive;

      const foundForbiddenPasswords = [];

      words.forEach((word) => {
        const pattern = `${word}`;
        const regex = isCaseSensitive ? new RegExp(pattern) : new RegExp(pattern, 'i');

        if (regex.test(password)) {
          foundForbiddenPasswords.push(word);
        }
      });

      if (foundForbiddenPasswords.length > 0) {
        return false;
      }
    }

    return true;
  }
}
