import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as connectRedux } from 'react-redux';
import classNames from 'classnames';
import ClickOutside from 'react-outside-click-handler';
import { toast } from 'actions/toasts';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Input from 'components/atoms/input';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import ProfileTag from 'components/molecules/profile-tag';
import Dropdown from 'components/molecules/dropdown';
import DropdownItem from 'components/molecules/account-item';
import LabeledInput from 'components/molecules/labeled-input';
import MonitorizationList from 'components/organisms/monitorization-list';
import I18n from 'utils/i18n';
import humanizeDate from 'utils/humanize-date';
import { analyticsApi, portalApi } from 'api/http';
import App from './template';
import styles from './styles.module.css';

const HOME = <I18n path="physio.breadcrumbs.home" />;
const PHYSIO = <I18n path="physio.breadcrumbs.title" />;
const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const LOAD_FAILURE = <I18n path="messages.load-failure" />;

const ACTIVATE_PROTOCOL_SUCCESS = <I18n path="physio.physio-personal.activate-protocol-success" />;
const ACTIVATE_PROTOCOL_FAILURE = <I18n path="physio.physio-personal.activate-protocol-failure" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/physio',
    title: PHYSIO,
    isActive: true,
  },
];

const mapStateToProps = (state) => ({
  user: state.user.profile,
});

class Reports extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
  };

  state = {
    blocked: false,
    blockedUntil: null,
    remainingAttempts: 3,
    showRemainingAttempts: false,
    physioProtocol: '',
    date: {
      startDate: new Date(),
      endDate: new Date(),
    },
    dataVisibility: 'FULL',
    acceptSharingTerms: true,
    shareLink: '',
    showReportDialog: false,
    showActivationDialog: false,
    doctors: [],
    selectedDoctor: undefined,
    searchDoctor: '',
    showDropdownDoctors: false,
    acquisitions: null,
    keys: null,
    isActivating: false,
    isLoading: false,
    protocolError: '',
    doctorError: '',
    shouldRefresh: false,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.handleFetchProtocolBlocked();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleFetchProtocolBlocked = () => {
    this.setState({ isLoading: true });
    analyticsApi()
      .get('/protocol-blocked')
      .then((response) => {
        const { data } = response;

        this.setState({
          isLoading: false,
          blocked: data.blocked,
          blockedUntil: data.blocked_until,
        });
      })
      .catch(() => {
        toast('error', LOAD_FAILURE);
      });
  };

  handleInputChange = (field, event) => {
    this.setState({
      [field]: event.target.value,
    });
  };

  handleDateRange = (ranges) => {
    const dateRange = {
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate,
    };
    this.setState({
      date: dateRange,
    });
  };

  handleInputToggle = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  showActivationDialog = () => {
    this.handleInputToggle('showActivationDialog');
  };

  closeActivationDialog = () => {
    this.handleInputToggle('showActivationDialog');
    this.setState({
      physioProtocol: '',
      protocolError: '',
      searchDoctor: '',
      selectedDoctor: undefined,
    });
  };

  onShowActivateProtocol = (isShow) => {
    if (isShow) {
      this.setState({ showActivationDialog: true });
    }
  };

  activateReports = () => {
    const { physioProtocol, selectedDoctor } = this.state;
    if (!this.mounted) return;

    if (!physioProtocol) {
      this.setState({ protocolError: FILL_REQUIRED });
    }

    if (!selectedDoctor) {
      this.setState({ doctorError: FILL_REQUIRED });
    }

    if (!physioProtocol || !selectedDoctor) {
      return;
    }

    this.setState({ isActivating: true });

    portalApi()
      .post('/protocol', { protocol: physioProtocol })
      .then((response) => {
        const { data } = response;
        if (!this.mounted) return;
        this.setState({ isActivating: false, protocolError: '' });
        this.postReportsActivateToAnalytics(data.organization);
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState({ isActivating: false, protocolError: ACTIVATE_PROTOCOL_FAILURE });
      });
  };

  postReportsActivateToAnalytics = () => {
    const { physioProtocol, selectedDoctor } = this.state;
    const { user } = this.props;
    if (!this.mounted) return;

    this.setState({ isActivating: true });
    analyticsApi()
      .post('/protocol', {
        protocol_id: physioProtocol,
        doctor_id: selectedDoctor.id,
        doctor_org_id: selectedDoctor.orgId,
        date_of_birth: user.birth_date,
      })
      .then((response) => {
        if (!this.mounted) return;

        const { data, error } = response.data;

        this.setState({ isActivating: false });

        if (!error) {
          this.closeActivationDialog();
          this.handleInputToggle('shouldRefresh');
          this.setState({ showRemainingAttempts: false });
          toast('success', ACTIVATE_PROTOCOL_SUCCESS);
          return;
        }

        if (error && data) {
          this.setState({
            blocked: Boolean(data.blocked_until),
            blockedUntil: data.blocked_until,
            remainingAttempts: data.remaining_attempts,
            showRemainingAttempts: true,
          });
        }

        this.setState({ protocolError: ACTIVATE_PROTOCOL_FAILURE });
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState({ isActivating: false, protocolError: ACTIVATE_PROTOCOL_FAILURE });
      });
  };

  removeSelectedDoctor = () => this.setState({ selectedDoctor: undefined });

  handleDoctorSelected = (id, name, image, orgId) => {
    this.inputRef.current.focus();
    this.setState({
      selectedDoctor: {
        id,
        name,
        image,
        orgId,
      },
      searchDoctor: '',
    });

    this.setState({ showDropdownDoctors: false });
  };

  handleSearchDoctors = (keyword) => {
    if (keyword.trim() === '') {
      this.setState({ showDropdownDoctors: false });
      return;
    }

    this.setState({ showDropdownDoctors: true });
    portalApi()
      .get(`/get-doctors?search_name=${keyword}`)
      .then((response) => {
        if (!this.mounted) return;

        const { data } = response;

        const doctors = [...data.results];
        this.setState({ doctors });
        if (!doctors.length) this.setState({ showDropdownDoctors: false });
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState({ showDropdownDoctors: false, doctorError: LOAD_FAILURE });
      });
  };

  handleSearchChange = (event) => {
    const keyword = event.target.value;
    this.setState({ searchDoctor: keyword });

    // TODO fix setTimeout
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.handleSearchDoctors(keyword);
    }, 400);
  };

  renderDropdownDoctors = () => {
    const { doctors, showDropdownDoctors } = this.state;

    return (
      <ClickOutside onOutsideClick={() => this.setState({ showDropdownDoctors: false })}>
        <Dropdown
          className={classNames(styles.searchDropdown, {
            [styles.dropdownOpen]: showDropdownDoctors,
          })}
        >
          {doctors.map((doctor) => (
            <DropdownItem
              key={`doctor-${doctor.user_id}${doctor.organization_id}`}
              image={doctor.photo}
              title={doctor.name}
              subtitle={doctor.organization_name || ''}
              className={styles.dropdownItem}
              onClick={() =>
                this.handleDoctorSelected(
                  String(doctor.user_id),
                  doctor.name,
                  doctor.photo,
                  Number(doctor.organization_physio_org_id),
                )
              }
            />
          ))}
        </Dropdown>
      </ClickOutside>
    );
  };

  renderActivationBlocked = () => (
    <Body>
      <Text>
        <I18n path="physio.physio-personal-blocked.text" />
      </Text>
      <div className={styles.blockedWarning}>
        <div>
          <I18n path="physio.physio-personal-blocked.label-blocked-until" />
        </div>
        {humanizeDate(this.state.blockedUntil)}
      </div>
    </Body>
  );

  renderActivationForms = () => {
    const {
      physioProtocol,
      isActivating,
      protocolError,
      selectedDoctor,
      searchDoctor,
      doctorError,
      showRemainingAttempts,
      remainingAttempts,
    } = this.state;

    return (
      <>
        <Body>
          <Text>
            <I18n path="physio.physio-personal.text-activate-listing" />
          </Text>
          {showRemainingAttempts && (
            <div className={classNames(styles.blockedWarning, styles.remainingAttempts)}>
              <div>
                <I18n path="physio.physio-personal-blocked.label-remaining-attempts" />
              </div>
              {remainingAttempts}
            </div>
          )}

          <LabeledInput label={<I18n path="physio.physio-personal.input-protocol" />} htmlFor="physio_protocol">
            <Input
              id="physio_protocol"
              onChange={(event) => this.handleInputChange('physioProtocol', event)}
              hasError={Boolean(protocolError)}
              errorMessage={protocolError}
              value={physioProtocol}
            />
          </LabeledInput>

          <div className={styles.searchWrapper}>
            <LabeledInput label={<I18n path="physio.physio-personal.input-doctor" />} htmlFor="doctor">
              <div
                className={classNames(styles.searchInput, {
                  [styles.hasError]: Boolean(doctorError),
                })}
              >
                {selectedDoctor && (
                  <ProfileTag
                    key="selected-doctor"
                    name={selectedDoctor.name}
                    avatar={selectedDoctor.image}
                    iconName="cross"
                    iconClick={() => this.removeSelectedDoctor()}
                    className={styles.profileTag}
                  />
                )}
                <Input
                  id="doctor"
                  onChange={this.handleSearchChange}
                  inputRef={this.inputRef}
                  wrapperClassName={styles.inputSearch}
                  className={styles.inputSearchBorder}
                  value={searchDoctor}
                />
              </div>
            </LabeledInput>
            {this.renderDropdownDoctors()}
            {true && <p className={styles.errorMessage}>{doctorError}</p>}
          </div>
        </Body>
        <Footer>
          <Button onClick={this.closeActivationDialog}>
            <I18n path="physio.physio-personal.button-cancel-protocol" />
          </Button>
          <Button type="primary" onClick={this.activateReports} isLoading={isActivating}>
            <I18n path="physio.physio-personal.button-activate-protocol" />
          </Button>
        </Footer>
      </>
    );
  };

  renderActivationDialog = () => {
    const { blocked, showActivationDialog } = this.state;
    const type = blocked ? 'error' : undefined;

    return (
      <Dialog isOpen={showActivationDialog}>
        <Header onClose={() => this.handleInputToggle('showActivationDialog')} type={type}>
          {blocked ? (
            <I18n path="physio.physio-personal-blocked.title" />
          ) : (
            <I18n path="physio.physio-personal.title-activate-listing" />
          )}
        </Header>
        {blocked ? this.renderActivationBlocked() : this.renderActivationForms()}
      </Dialog>
    );
  };

  render() {
    const { shouldRefresh, isLoading } = this.state;
    return (
      <App
        breadcrumbs={breadcrumbs}
        title={<I18n path="physio.physio-personal.title-main-physio" />}
        buttonTitle={<I18n path="physio.physio-personal.button-activate" />}
        buttonIsLoading={isLoading}
        onButtonClick={this.showActivationDialog}
      >
        <Text className={styles.amplifyListing}>
          <I18n path="physio.physio-personal.text-amplify-listing" />
        </Text>
        <MonitorizationList
          showActivateProtocolHandler={this.onShowActivateProtocol}
          shouldRefresh={shouldRefresh}
          onRefreshSuccess={() => this.handleInputToggle('shouldRefresh')}
        />
        {this.renderActivationDialog()}
      </App>
    );
  }
}

const ReportsConnected = connectRedux(mapStateToProps, null)(Reports);

export default ReportsConnected;
