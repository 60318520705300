import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

const TableRow = ({ children, className, ...props }) => (
  <tr {...props} className={classnames(styles.row, className)}>
    {children}
  </tr>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TableRow.defaultProps = {
  className: undefined,
};

export default TableRow;
