import { useEffect, useRef, useState } from 'react';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Text from 'components/atoms/text';
import Avatar from 'components/atoms/avatar';
import Input from 'components/atoms/input';
import Radio from 'components/atoms/input-radio';
import Label from 'components/atoms/input-label';
import Button from 'components/atoms/button';
import InputImage from 'components/atoms/input-image';
import ButtonIcon from 'components/molecules/button-icon';
import LabeledInput from 'components/molecules/labeled-input';
import I18n from 'utils/i18n';
import validateEmail from 'utils/validate-email';
import validatePhone from 'utils/validate-phone';
import App from 'pages/account/me/template';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_LOADING, setProfileData } from 'features/userSlice';
import { fetchProfessions, uploadPersonalInformation } from 'api/portal';
import { SlideRightFadeContainer } from 'utils/transitions';
import Loader from 'components/molecules/section-loader';
import styles from '../styles.module.css';

const MAP_FIELDS = {
  photo: 'photo',
  first_name: 'firstName',
  last_name: 'lastName',
  birth_date: 'birthYear',
  email: 'email',
  phone_number: 'phoneNumber',
  notification_language: 'notificationLanguage',
};

const SAVE_SUCCESS_MESSAGE = <I18n path="messages.save-success" />;
const SAVE_FAILURE_MESSAGE = <I18n path="messages.save-failure" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const INVALID_EMAIL_ERROR = <I18n path="messages.invalid-email" />;
const INVALID_PHONE_ERROR = <I18n path="messages.invalid-phone" />;
const INVALID_IMAGE_EXTENSION = <I18n path="messages.image-extension-error" />;
const ERROR_LOADING_PROFESSIONS = <I18n path="messages.error-professions" />;

function PersonalProfileInfo() {
  const user = useSelector((state) => state.user);
  const ref = useRef();

  const { profile } = user;

  const birthDate = profile.birth_date || '';

  const [photo, setPhoto] = useState(profile.photo || '');
  const [photoFile, setPhotoFile] = useState(undefined);
  const [firstName, setFirstName] = useState(profile.first_name);
  const [lastName, setLastName] = useState(profile.last_name);
  const [birthYear, setBirthYear] = useState(birthDate.split('-')[0]);
  const [email, setEmail] = useState(profile.email);
  const [phoneNumber, setPhoneNumber] = useState(profile.phone_number || '');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [errors, setErrors] = useState(undefined);
  const [notificationLanguage, setNotificationLanguage] = useState(profile.notification_language);
  const [professions, setProfessions] = useState([]);
  const [profession, setProfession] = useState(profile.profession);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProfessions()
      .then((response) => {
        const { data } = response;

        const professions = [];

        const returnedProfessions = data.professions;

        returnedProfessions.forEach((returnedProfession) => {
          professions.push(
            <Option key={returnedProfession} value={returnedProfession}>
              <I18n path={'professions.text-' + returnedProfession} />
            </Option>,
          );
        });

        setProfessions(professions);
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_LOADING_PROFESSIONS));
      });
  }, []);

  useEffect(() => {
    if (isLoading && ref.current === STATUS_LOADING && user.status !== STATUS_LOADING) {
      setIsLoading(false);

      const { profile } = user;

      const birthDate = profile.birth_date || '';

      setPhoto(profile.photo || '');
      setFirstName(profile.first_name);
      setLastName(profile.last_name);
      setBirthYear(birthDate.split('-')[0]);
      setEmail(profile.email);
      setPhoneNumber(profile.phone_number || '');
      setNotificationLanguage(profile.notification_language);
      setProfession(profile.profession);
    }

    ref.current = user.status;
  }, [user.status]);

  const handleOnSaveChanges = () => {
    setIsSaving(true);

    let updatedInfo = {
      first_name: firstName,
      last_name: lastName,
      birth_date: `${birthYear}-01-01`,
      email,
      phone_number: phoneNumber,
      notification_language: notificationLanguage,
      profession,
    };

    if (photoFile) {
      updatedInfo = { ...updatedInfo, photo: photoFile };
    }

    const formData = new FormData();
    Object.entries(updatedInfo).forEach(([key, value]) => {
      formData.append(key, value);
    });

    uploadPersonalInformation(formData)
      .then((response) => {
        const { data } = response;

        setIsSaving(false);

        dispatch(
          setProfileData({
            photo: data.photo,
            first_name: data.first_name,
            last_name: data.last_name,
            birth_date: data.birth_date,
            gender: data.gender,
            email: data.email,
            phone_number: data.phone_number,
            notification_language: data.notification_language,
            profession: data.profession,
          }),
        );

        setErrors(undefined);
        dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        setIsSaving(false);

        const errors = new Map();

        Object.entries(data).forEach(([key, value]) => {
          errors.set(MAP_FIELDS[key], value[0]);
        });

        setErrors(errors);
        dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
      });
  };

  const showImagePreview = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => callback(reader.result);
  };

  const handleOnImageChange = (event) => {
    const file = event.target.files[0];
    const re = /(\.jpg|\.jpeg|\.png)$/;

    if (!file) {
      return;
    }

    if (!re.exec(file.name)) {
      dispatch(addToast('error', INVALID_IMAGE_EXTENSION));
      return;
    }

    showImagePreview(file, (previewUrl) => {
      setPhoto(previewUrl);
      setPhotoFile(file);
    });
  };

  const validateRequiredFields = () => {
    const requiredFields = {
      firstName,
      lastName,
      email,
      birthYear,
      notificationLanguage,
    };

    const errors = new Map();
    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    if (phoneNumber && !validatePhone(phoneNumber)) {
      errors.set('phoneNumber', INVALID_PHONE_ERROR);
    }

    if (!errors.size && isValidEmail && isValidPhone) {
      handleOnSaveChanges();
    } else {
      setErrors(errors);
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  const handleValidateEmail = (value) => {
    const isValidEmail = value === '' || validateEmail(value);
    setIsValidEmail(isValidEmail);

    if (!isValidEmail) {
      dispatch(addToast('error', INVALID_EMAIL_ERROR));
    }
  };

  const handleValidatePhone = (value) => {
    const isValidPhone = value === '' || validatePhone(value);
    setIsValidPhone(isValidPhone);

    if (!isValidPhone) {
      dispatch(addToast('error', INVALID_PHONE_ERROR));
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <App>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="account.me-edit-info.title-sub-me-infor" />
        </Subtitle>
        <Text isItalic>
          <I18n path="account.me-edit-info.text-account-page-main" />
        </Text>

        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <Section>
              <div className={styles.imageWrapper}>
                <Avatar image={photo} description="profile image" />
                <InputImage alt="profile image" onChange={(event) => handleOnImageChange(event)}>
                  <ButtonIcon icon="camera" className={styles.uploadPhoto}>
                    <I18n path="account.me-edit-info.button-foto-add" />
                  </ButtonIcon>
                </InputImage>
                <Button type="danger" onClick={() => setPhoto(null)} className={styles.removeButton}>
                  <I18n path="account.me-edit-info.button-foto-remove" />
                </Button>
              </div>

              <LabeledInput
                label={<I18n path="account.me-edit-info.input-name" />}
                htmlFor="name"
                data-testid="first-name-labeled-input"
              >
                <Input
                  id="name"
                  name="Nome"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  hasError={Boolean(errors && errors.get('firstName'))}
                  errorMessage={errors && errors.get('firstName') ? errors.get('firstName') : ''}
                  data-testid="first-name-input"
                />
              </LabeledInput>

              <LabeledInput
                label={<I18n path="account.me-edit-info.input-surname" />}
                htmlFor="last_name"
                data-testid="last-name-labeled-input"
              >
                <Input
                  id="last_name"
                  name="Sobrenome"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  hasError={Boolean(errors && errors.get('lastName'))}
                  errorMessage={errors && errors.get('lastName') ? errors.get('lastName') : ''}
                  data-testid="last-name-input"
                />
              </LabeledInput>

              <LabeledInput
                label={<I18n path="account.me-edit-info.input-birth-year" />}
                htmlFor="birth_year"
                data-testid="birth-year-labeled-input"
              >
                <Input
                  id="birth_year"
                  name="Birth year"
                  maxLength={4}
                  value={birthYear}
                  onChange={(event) => setBirthYear(event.target.value.replace(/\D/g, ''))}
                  hasError={Boolean(errors && errors.get('birthYear'))}
                  errorMessage={errors && errors.get('birthYear') ? errors.get('birthYear') : ''}
                  data-testid="birth-year-input"
                />
              </LabeledInput>

              <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
                <LabeledInput
                  label={<I18n path="account.me-edit-info.input-email" />}
                  htmlFor="email"
                  data-testid="email-labeled-input"
                >
                  <Input
                    id="email"
                    placeholder="theresa@stanfordmedicalcenter.com"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    hasError={Boolean(errors && errors.get('email')) || !isValidEmail}
                    errorMessage={
                      (!isValidEmail && INVALID_EMAIL_ERROR) ||
                      (errors && errors.get('email') ? errors.get('email') : '')
                    }
                    onBlur={() => handleValidateEmail(email)}
                    data-testid="email-input"
                  />
                </LabeledInput>

                <LabeledInput
                  label={<I18n path="account.me-edit-info.input-phone" />}
                  htmlFor="phone"
                  data-testid="phone-labeled-input"
                >
                  <Input
                    id="phone"
                    placeholder="+ 1 650-742-4750"
                    name="phone"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    hasError={Boolean(errors && errors.get('phoneNumber')) && !isValidPhone}
                    errorMessage={
                      (!isValidPhone && INVALID_PHONE_ERROR) ||
                      (errors && errors.get('phoneNumber') ? errors.get('phoneNumber') : '')
                    }
                    onBlur={() => handleValidatePhone(phoneNumber)}
                    data-testid="phone-input"
                  />
                </LabeledInput>
              </form>

              <div className={styles.language}>
                <Label>
                  <I18n path="auth.signup.text-doc-choice-language" />
                </Label>
                <div className={styles.languageOptions}>
                  <Radio
                    id="pt-br"
                    name="notificationLanguage"
                    value="pt-br"
                    label={<I18n path="auth.signup.input-language-pt-br" />}
                    isChecked={notificationLanguage === 'pt-br'}
                    onChange={(event) => setNotificationLanguage(event.target.value)}
                    data-testid="pt-br-radio-item"
                  />
                  <Radio
                    id="en-us"
                    name="notificationLanguage"
                    value="en-us"
                    label={<I18n path="auth.signup.input-language-en-us" />}
                    isChecked={notificationLanguage === 'en-us'}
                    onChange={(event) => setNotificationLanguage(event.target.value)}
                    data-testid="en-us-radio-item"
                  />
                </div>
              </div>

              <LabeledInput label={<I18n path="auth.signup.input-profession" />}>
                <Select
                  value={profession}
                  onChange={(event) => setProfession(event.target.value)}
                  data-testid="select-profession"
                >
                  {professions}
                </Select>
              </LabeledInput>
            </Section>

            <div>
              <Button
                type="primary"
                isDisabled={isLoading}
                isLoading={isSaving}
                onClick={validateRequiredFields}
                data-testid="save-button"
              >
                <I18n path="account.me-edit-info.button-save" />
              </Button>
              <Button onClick={() => navigate('/')} data-testid="cancel-button">
                <I18n path="account.me-edit-info.button-cancel" />
              </Button>
            </div>
          </SlideRightFadeContainer>
        )}
      </Section>
    </App>
  );
}

export default PersonalProfileInfo;
