import classNames from 'classnames';
import Button from 'components/atoms/button';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'features/userSlice';
import styles from './styles.module.css';

const Translator = () => {
  const language = useSelector((state) => state.user.language);
  const dispatch = useDispatch();

  return (
    <div className={styles.buttons}>
      <Button
        className={classNames(styles.button, { [styles.selected]: language === 'en-us' })}
        onClick={() => dispatch(setLanguage('en-us'))}
      >
        English (US)
      </Button>
      <Button
        className={classNames(styles.button, { [styles.selected]: language === 'pt-br' })}
        onClick={() => dispatch(setLanguage('pt-br'))}
      >
        Português (BR)
      </Button>
    </div>
  );
};

export default Translator;
