import I18n, { translate } from 'utils/i18n';
import dongleIcon from 'assets/images/ic_dongle.svg';
import batteryIcon from 'assets/images/ic_battery.svg';
import sensorIcon from 'assets/images/ic_sensor.svg';

const CARD_SESSIONS = <I18n path="utils.aggregates.sessions" />;
const CARD_AVG_SIGNAL = <I18n path="utils.aggregates.avg-signal" />;
const CARD_TEMPERATURE = <I18n path="utils.aggregates.temperature" />;
const CARD_AUTONOMY_TIME = <I18n path="utils.aggregates.autonomy-time" />;

const CARD_SENSOR_BATTERY_STATUS = <I18n path="utils.aggregates.sensor-battery-status" />;
const CARD_DONGLE_MONITOR_MV = <I18n path="utils.aggregates.dongle-monitor-mv" />;
const CARD_BATTERY_CYCLES = <I18n path="utils.aggregates.battery-cycles" />;
const CARD_BATTERY_TOTAL_TIME = <I18n path="utils.aggregates.battery-total-time" />;

export const STATUS_PENDING = translate('utils.status.pending');
export const STATUS_ACTIVE = translate('utils.status.active');
export const STATUS_BLOCKED = translate('utils.status.blocked');
export const STATUS_SUSPENDED = translate('utils.status.suspended');
export const STATUS_IN_USE = translate('utils.status.in-use');
export const STATUS_RETURNED = translate('utils.status.returned');

export const DEVICE_BATTERY = translate('utils.devices.battery');
export const DEVICE_DONGLE = translate('utils.devices.dongle');
export const DEVICE_SENSOR = translate('utils.devices.sensor');
export const DEVICE_TRUSTED = translate('utils.devices.device');

export const AGGREGATES_TEXTS = {
  monitorizations: { text: CARD_SESSIONS, icon: 'stats-bars2', unit: '' },
  battery_status: { text: CARD_SENSOR_BATTERY_STATUS, icon: 'power' },
  signal_strength: { text: CARD_AVG_SIGNAL, icon: 'connection' },
  battery_cycles: { text: CARD_BATTERY_CYCLES, icon: 'power-cord' },
  on_monitorizations: { text: CARD_BATTERY_TOTAL_TIME, icon: 'clock' },
  monitor_voltage: { text: CARD_DONGLE_MONITOR_MV, icon: 'meter' },
  avg_signal: { text: CARD_AVG_SIGNAL, icon: 'connection' },
  temperature: { text: CARD_TEMPERATURE, icon: 'sun' },
  autonomy_time: { text: CARD_AUTONOMY_TIME, icon: 'clock' },
};

export const DEVICE_STATUS = [
  { id: 'PENDING', text: STATUS_PENDING },
  { id: 'ACTIVE', text: STATUS_ACTIVE },
  { id: 'BLOCKED', text: STATUS_BLOCKED },
  { id: 'SUSPENDED', text: STATUS_SUSPENDED },
  { id: 'IN USE', text: STATUS_IN_USE },
  { id: 'RETURNED', text: STATUS_RETURNED },
];

export const DEVICES = [
  { id: 'BATTERY', text: DEVICE_BATTERY },
  { id: 'DONGLE', text: DEVICE_DONGLE },
  { id: 'SENSOR', text: DEVICE_SENSOR },
];

export const DEVICE_ICON = {
  BATTERY: batteryIcon,
  DONGLE: dongleIcon,
  SENSOR: sensorIcon,
};

export const getStatus = (status) => ({ ...DEVICE_STATUS.find((device) => device.id === status) }).text;
