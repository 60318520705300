/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { useState } from 'react';
import { addToast } from 'actions/toasts';
import Button from 'components/atoms/button';
import ButtonIcon from 'components/molecules/button-icon';
import Dialog from 'components/atoms/dialog';
import DialogBody from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Header from 'components/atoms/dialog-header';
import InputFile from 'components/atoms/input-file';
import Checkbox from 'components/atoms/input-checkbox';
import Loader from 'components/atoms/loader';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Table, { Head, Body, Row, Cell } from 'components/atoms/table';
import I18n from 'utils/i18n';
import { bulkUpload } from 'api/portal';
import BulkUploadModelCSV from 'assets/files/bulk_upload_model.csv';
import { useDispatch } from 'react-redux';
import styles from './styles.module.css';

const EMPTY_FILE_ERROR = <I18n path="messages.file-is-empty" />;
const FILE_NOT_CSV_ERROR = <I18n path="messages.file-not-csv" />;
const FILE_NOT_SELECTED_ERROR = <I18n path="messages.file-not-selected" />;
const FILE_TOO_LARGE_ERROR = <I18n path="messages.file-too-large" />;
const ACTIVATION_LABEL = <I18n path="organization.organization-members-bulk-upload.automatic-activation-label" />;
const ACTIVATION_TEXT = <I18n path="organization.organization-members-bulk-upload.automatic-activation-text" />;

const RowsWhitErrors = ({ list }) =>
  list.map((item) => {
    if (Object.keys(item).length <= 0) {
      return '';
    }

    const key = Object.keys(item)[0];
    const idKey = key + Math.random();

    return (
      <div key={idKey} className={styles.errorList}>
        <Text isWithoutMargin>Linha{key}:</Text>
        <ShowErrorsRows arrayErrorsMessages={item[key]} />
      </div>
    );
  });

const ShowErrorsRows = ({ arrayErrorsMessages }) =>
  arrayErrorsMessages.map((message) => (
    <div key={`keyid:${Math.random()}`} className={styles.errorsList}>
      <Text isWithoutMargin size="medium">
        <div>
          <I18n path={message} />,
        </div>
      </Text>
    </div>
  ));

const ImportCSV = () => {
  const [selectedFile, setselectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dataResponse, setDataResponse] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [activateUsers, setActivateUsers] = useState(true);

  const dispatch = useDispatch();

  const handleSendFile = (event) => {
    const re = /(?:\.([^.]+))?$/;

    setselectedFile({
      file: event.target.files[0],
      name: event.target.files[0].name,
      extension: re.exec(event.target.files[0].name)[1],
    });
  };

  const arrNumberToString = (arr) => {
    let stringNumber = '';
    for (let i = 0; i < arr.length; i++) {
      if (i === arr.length - 1) {
        stringNumber += `${arr[i]}.`;
      } else {
        stringNumber += `${arr[i]}, `;
      }
    }
    return stringNumber;
  };

  const getErrorMessage = (error) => {
    const { status, data } = error.response;

    if (status === 400) {
      if (data.message === 'Incorrect CSV headers') {
        return (
          <I18n path="organization.organization-members-bulk-upload.responses.error.error-400-incorrect-headers" />
        );
      }

      return <I18n path="organization.organization-members-bulk-upload.responses.error.error-400-no-data" />;
    }

    if (status === 415) {
      return <I18n path="organization.organization-members-bulk-upload.responses.error.error-415" />;
    }

    if (status === 422) {
      return <I18n path="organization.organization-members-bulk-upload.responses.error.error-422" />;
    }

    return <I18n path="organization.organization-members-bulk-upload.responses.error.error-500" />;
  };

  const submitFile = () => {
    if (selectedFile === null) {
      dispatch(addToast('error', FILE_NOT_SELECTED_ERROR));
      return;
    }

    if (selectedFile.file.size === 0) {
      dispatch(addToast('error', EMPTY_FILE_ERROR));
      return;
    }

    if (selectedFile.file.size >= 1048576) {
      dispatch(addToast('error', FILE_TOO_LARGE_ERROR));
      return;
    }

    if (selectedFile.extension !== 'csv') {
      dispatch(addToast('error', FILE_NOT_CSV_ERROR));
      return;
    }

    setIsLoading(true);
    setShowDialog(true);

    const formData = new FormData();
    formData.append('file', selectedFile.file);

    if (activateUsers) {
      formData.append('activate', true);
    }

    bulkUpload(formData)
      .then((response) => {
        const importedRows = arrNumberToString(response.data.data.summary.rows_imported.rows);
        const skippedRows = arrNumberToString(response.data.data.summary.rows_skipped.rows);
        const errorsRows = response.data.data.summary.rows_not_imported.rows;

        setDataResponse({
          errorsList: response.data.data.errors_list,
          importedList: response.data.data.imported_list,
          skippedList: response.data.data.skipped_list,
          totalSentRows: response.data.data.summary.csv_rows,
          totalImportedRows: response.data.data.summary.rows_imported.count,
          importedRows,
          totalNotImportedRows: response.data.data.summary.rows_not_imported.count,
          errorsRows,
          totalSkippedRows: response.data.data.summary.rows_skipped.count,
          skippedRows,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setDataError({
          errorMessage: getErrorMessage(error),
        });
        setIsLoading(false);
      });
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setselectedFile(null);
    setDataResponse(null);
    document.location.reload();
  };

  const convertArrToCSV = (arr) => {
    const csvRows = [];

    if (arr && arr.length) {
      const headers = Object.keys(arr[0]);

      for (const row of arr) {
        const values = headers.map((header) => row[header]);
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    }

    return csvRows;
  };

  const handlerDownloadCSVList = (arr, nameFile) => {
    const CSVdata = convertArrToCSV(arr);
    const blob = new Blob([CSVdata], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', nameFile);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <Section maxWidth="100%">
        <Subtitle>
          <I18n path="organization.organization-members-bulk-upload.title-bulk-upload" />
        </Subtitle>

        <div className={styles.bulkUploadTemplate}>
          <div>
            <Text>
              <I18n path="organization.organization-members-bulk-upload.text-bulk-upload" />
            </Text>
            <Text isError>
              <I18n path="organization.organization-members-bulk-upload.warning.text-bulk" />
            </Text>
          </div>

          <a href={BulkUploadModelCSV} download aria-label="download-model-csv">
            <Button data-testid="download-button">
              <I18n path="organization.organization-members-bulk-upload.button-download-model-csv" />
            </Button>
          </a>
        </div>

        <Section>
          <Table>
            <Head>
              <Row>
                <Cell isHead align="left">
                  <I18n path="organization.organization-members-bulk-upload.table.head-name.field" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="organization.organization-members-bulk-upload.table.head-name.description" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="organization.organization-members-bulk-upload.table.head-name.formats" />
                </Cell>
              </Row>
            </Head>
            <Body>
              <Row data-testid="first-name-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.first-name" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.first-name-description" />
                  <div className={styles.cautionDescription}>
                    <I18n path="organization.organization-members-bulk-upload.table.second-column.caution-description" />
                  </div>
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.first-name-format" />
                </Cell>
              </Row>
              <Row data-testid="last-name-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.last-name" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.last-name-description" />
                  <div className={styles.cautionDescription}>
                    <I18n path="organization.organization-members-bulk-upload.table.second-column.caution-description" />
                  </div>
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.last-name-format" />
                </Cell>
              </Row>
              <Row data-testid="birth-year-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.birthday" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.birthday-description" />
                  <div className={styles.cautionDescription}>
                    <I18n path="organization.organization-members-bulk-upload.table.second-column.caution-description" />
                  </div>
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.birthday-format" />
                </Cell>
              </Row>
              <Row data-testid="personal-email-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.personal-email" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.personal-email-description" />
                  <div className={styles.cautionDescription}>
                    <I18n path="organization.organization-members-bulk-upload.table.second-column.caution-description" />
                  </div>
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.personal-email-format" />
                </Cell>
              </Row>
              <Row data-testid="personal-phone-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.personal-phone" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.personal-phone-description" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.personal-phone-format" />
                </Cell>
              </Row>
              <Row data-testid="id-type-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.id-type" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.id-type-description-cpf" />
                  <br />
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.id-type-description-id" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.id-type-format" />
                </Cell>
              </Row>
              <Row data-testid="id-number-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.id-number" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.id-number-description" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.id-number-cpf-format" />
                  <br />
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.id-number-id-format" />
                </Cell>
              </Row>
              <Row data-testid="work-email-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.work-email" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.work-email-description" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.work-email-format" />
                </Cell>
              </Row>
              <Row data-testid="work-phone-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.work-phone" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.work-phone-description" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.work-phone-format" />
                </Cell>
              </Row>
              <Row data-testid="user-profile-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.user-profile" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.user-profile-description" />
                  <br />
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.user-profile-3" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.user-example" />
                </Cell>
              </Row>
              <Row data-testid="notifications-language-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.notifications-language" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.notifications-language-description" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.notifications-language-format" />
                </Cell>
              </Row>
              <Row data-testid="organization-units-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.organizational-units" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.organizational-units-description" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.organizational-units-format" />
                </Cell>
              </Row>
              <Row data-testid="password-row">
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.first-column.password" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.second-column.password-description" />
                </Cell>
                <Cell>
                  <I18n path="organization.organization-members-bulk-upload.table.third-column.password-format" />
                </Cell>
              </Row>
            </Body>
          </Table>
        </Section>

        <div className={styles.checkBoxActivation}>
          <Checkbox
            id="userActivation"
            name="userActivation"
            value={activateUsers}
            label={ACTIVATION_LABEL}
            onChange={() => setActivateUsers(!activateUsers)}
            isChecked={activateUsers}
            data-testid="user-activation-checkbox"
          />
        </div>
        <Text isError>{ACTIVATION_TEXT}</Text>

        <div className={styles.buttonsSendCSVContainer}>
          <div>
            <Text isWithoutMargin>
              <I18n path="organization.organization-members-bulk-upload.text-chose-file-csv-to-send" />
            </Text>
            <div className={styles.chooseFileGroup}>
              <InputFile
                alt="send bulk upload csv"
                onChange={(event) => handleSendFile(event)}
                data-testid="input-file"
              >
                <Button className={styles.uploadFileCSV} data-testid="choose-csv-button">
                  <I18n path="organization.organization-members-bulk-upload.button-chose-csv" />
                </Button>
              </InputFile>
              <Text isWithoutMargin>
                {selectedFile ? (
                  selectedFile.name
                ) : (
                  <I18n path="organization.organization-members-bulk-upload.text-no-file-selected" />
                )}
              </Text>
            </div>
          </div>
          <ButtonIcon icon="folder-upload" type="success" onClick={submitFile} data-testid="upload-button">
            <I18n path="organization.organization-members-bulk-upload.button-bulk-upload" />
          </ButtonIcon>
        </div>
      </Section>

      <Section className={styles.hiddenContinueButton}>
        <Subtitle>
          <I18n path="organization.organization-members-bulk-upload.title-continue-review" />
        </Subtitle>
        <Text>
          <I18n path="organization.organization-members-bulk-upload.text-continue-review" />
        </Text>
        <div className={styles.buttonsContainer}>
          <Button isDisabled type="primary">
            <I18n path="organization.organization-members-bulk-upload.button-continue-importCSV" />
          </Button>
        </div>
      </Section>

      <Dialog size="large" isOpen={showDialog} data-testid="dialog-header">
        <Header>
          {isLoading ? (
            <I18n path="organization.organization-members-bulk-upload.text-processing-file" />
          ) : (
            <I18n path="organization.organization-members-bulk-upload.text-process-result" />
          )}
        </Header>

        <DialogBody>
          {dataError ? (
            <div className={styles.modalBody}>
              {dataError.errorMessage && (
                <Text size="large" isBold isError data-testid="error-message-text">
                  {dataError.errorMessage}
                </Text>
              )}
            </div>
          ) : (
            ''
          )}

          {dataResponse ? (
            <div className={styles.modalBody}>
              {dataResponse.totalSentRows && (
                <div className={styles.summary} data-testid="total-rows">
                  <Text size="large" isBold isPrimary>
                    <I18n path="organization.organization-members-bulk-upload.dialog.total-rows" />
                  </Text>
                  <Text size="large">{dataResponse.totalSentRows}</Text>
                </div>
              )}

              {dataResponse.importedList && (
                <>
                  <div className={styles.totalImportedLines} data-testid="total-imported-lines">
                    <Text size="large" isBold isPrimary isWithoutMargin>
                      <I18n path="organization.organization-members-bulk-upload.dialog.total-imported" />
                    </Text>
                    <Text size="large" isWithoutMargin>
                      {dataResponse.totalImportedRows}
                    </Text>
                  </div>

                  <div className={styles.resultLists} data-testid="imported-rows">
                    <Text size="medium" isPrimary isWithoutMargin>
                      <I18n path="organization.organization-members-bulk-upload.dialog.imported-rows" />
                    </Text>
                    <Text size="medium" isWithoutMargin>
                      {dataResponse.importedRows}
                    </Text>
                  </div>

                  <Button
                    type="ghost"
                    className={styles.resultListsButton}
                    onClick={() => handlerDownloadCSVList(dataResponse.importedList, 'resultList.csv')}
                    data-testid="imported-list-button"
                  >
                    [
                    <I18n path="organization.organization-members-bulk-upload.responses.click" />]
                  </Button>
                </>
              )}

              {dataResponse.errorsList && (
                <>
                  <div className={styles.totalImportedLines} data-testid="total-not-imported-lines">
                    <Text size="large" isWithoutMargin isBold isPrimary>
                      <I18n path="organization.organization-members-bulk-upload.dialog.total-not-imported" />
                    </Text>
                    <Text size="large" isWithoutMargin>
                      {dataResponse.totalNotImportedRows}
                    </Text>
                  </div>

                  <div className={styles.resultLists} data-testid="errors-rows">
                    <Text size="medium" isPrimary isWithoutMargin>
                      <I18n path="organization.organization-members-bulk-upload.dialog.errors-rows" />
                    </Text>
                  </div>
                  <div>
                    <RowsWhitErrors list={dataResponse.errorsRows} />
                  </div>

                  <Button
                    type="ghost"
                    className={styles.resultListsButton}
                    onClick={() => handlerDownloadCSVList(dataResponse.errorsList, 'errorList.csv')}
                    data-testid="errors-list-button"
                  >
                    [
                    <I18n path="organization.organization-members-bulk-upload.responses.click" />]
                  </Button>
                </>
              )}

              {dataResponse.skippedList && (
                <>
                  <div className={styles.totalImportedLines} data-testid="total-skipped-lines">
                    <Text size="large" isWithoutMargin isBold isPrimary>
                      <I18n path="organization.organization-members-bulk-upload.dialog.total-skipped" />
                    </Text>
                    <Text size="large" isWithoutMargin>
                      {dataResponse.totalSkippedRows}
                    </Text>
                    <Text size="large" isWithoutMargin>
                      <I18n path="organization.organization-members-bulk-upload.dialog.skipped-warning" />
                    </Text>
                  </div>

                  <div className={styles.resultLists} data-testid="skipped-rows">
                    <Text size="medium" isWithoutMargin isPrimary>
                      <I18n path="organization.organization-members-bulk-upload.dialog.skipped-rows" />
                    </Text>
                    <Text size="medium" isWithoutMargin>
                      {dataResponse.skippedRows}
                    </Text>
                  </div>

                  <Button
                    type="ghost"
                    className={styles.resultListsButton}
                    onClick={() => handlerDownloadCSVList(dataResponse.skippedList, 'skippedList.csv')}
                    data-testid="skipped-list-button"
                  >
                    [
                    <I18n path="organization.organization-members-bulk-upload.responses.click" />]
                  </Button>
                </>
              )}
            </div>
          ) : (
            ''
          )}
        </DialogBody>

        <Footer>
          <Button onClick={handleCloseDialog} type="primary" data-testid="exit-dialog-button">
            {isLoading ? <Loader /> : <I18n path="organization.organization-members-bulk-upload.exit-dialog-button" />}
          </Button>
        </Footer>
      </Dialog>
    </div>
  );
};

export default ImportCSV;
