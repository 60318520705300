import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text from 'components/atoms/text';
import Spinner from 'components/atoms/spinner';
import styles from './styles.module.css';

const NotificationItem = ({ text, date, disabled = false, hasLink, isRead, onClick, ...props }) => (
  <div
    className={classNames(styles.notifications, {
      [styles.read]: isRead,
      [styles.disabled]: disabled,
    })}
    onClick={(event) => {
      if (!disabled) {
        onClick(event);
      }
    }}
    role="presentation"
    {...props}
  >
    {isRead === undefined ? (
      <div className={styles.spinnerContainer}>
        <Spinner className={styles.spinner} />
      </div>
    ) : (
      <>
        <Text className={styles.text} data-testid="main-text-notification">
          {text}
        </Text>
        <Text className={styles.date}>{date}</Text>
      </>
    )}
  </div>
);

NotificationItem.propTypes = {
  text: PropTypes.any.isRequired, // para tirar erro no console log na home
  date: PropTypes.string.isRequired,
  hasLink: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotificationItem;
