export const formatFromString = (value) => {
  let val = value.replace(/\D/g, '');
  let newVal = '';

  if (val.length > 4 && val.length < 7) {
    newVal += `${val.substr(0, 4)}-`;
    val = val.substr(4);
  }
  if (val.length > 6) {
    newVal += `${val.substr(0, 4)}-`;
    newVal += `${val.substr(4, 2)}-`;
    val = val.substr(6);
  }
  newVal += val;
  const formattedDocument = newVal.substring(0, 10);
  return formattedDocument;
};

export const dateFromString = (value) => {
  if (!value) return new Date();
  const parsed = value.split('-');
  return new Date(parsed[0], parsed[1] - 1, parsed[2]);
};

export const stringFromDate = (value) => {
  const year = value.getFullYear();
  const month = value.getMonth() + 1;
  const day = value.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const getValidDate = (value) => {
  const date = new Date(value);
  if (date instanceof Date && !Number.isNaN(date.getTime())) {
    date.setDate(date.getDate() + 1);
    return date;
  }
  return new Date();
};
