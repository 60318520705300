import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from 'components/atoms/loader';
import styles from './styles.module.css';

const SectionLoader = ({ className }) => (
  <div className={classNames(className, styles.sectionLoader)}>
    <Loader className={styles.loader} />
  </div>
);

SectionLoader.propTypes = {
  className: PropTypes.string,
};

SectionLoader.defaultProps = {
  className: '',
};

export default SectionLoader;
