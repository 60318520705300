import { MenuItem as MuiMenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  fontSize: 13,
  background: 'white',
  color: theme.palette.colorGreyDark.light,
  '&:hover': { background: theme.palette.colorGreyLight.light },
  '&.Mui-selected': {
    background: 'white',
    color: theme.palette.primary.light,
  },
}));

export default MenuItem;
