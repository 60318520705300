import { useEffect, useState } from 'react';
import braincare from 'components/routes/braincare';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Input from 'components/atoms/input';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Button from 'components/atoms/button';
import Loader from 'components/molecules/section-loader';
import LabeledInput from 'components/molecules/labeled-input';
import I18n from 'utils/i18n';
import validateEmail from 'utils/validate-email';
import validatePhone from 'utils/validate-phone';
import { formatToCPFDocument, formatToSSNDocument, unformatDocument } from 'utils/format-documents';
import { validateCpf, validateSsn } from 'utils/validate-documents';
import { SlideRightFadeContainer } from 'utils/transitions';
import App from 'pages/braincare/AdminUsers/edit/template';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchBuUser, updateBuUser } from 'api/portal';
import { useDispatch } from 'react-redux';

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const SAVE_SUCCESS_MESSAGE = <I18n path="messages.save-success" />;
const SAVE_FAILURE_MESSAGE = <I18n path="messages.save-failure" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const INVALID_EMAIL_ERROR = <I18n path="messages.invalid-email" />;
const INVALID_PHONE_ERROR = <I18n path="messages.invalid-phone" />;
const INVALID_DOCUMENT_ERROR = <I18n path="messages.invalid-document" />;

function Profile() {
  const [id, setId] = useState(-1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [document, setDocument] = useState('');
  const [documentType, setDocumentType] = useState('CPF');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidDocument, setIsValidDocument] = useState(true);
  const [errors, setErrors] = useState(undefined);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const handleFetchUser = () => {
    const { userId } = params;

    setIsLoading(true);

    fetchBuUser(userId)
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setId(data.id);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
        setPhoneNumber(data.phone_number || '');
        setDocument(data.document);
        setDocumentType(data.document_type);
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(addToast('error', LOAD_FAILURE));
        navigate('/braincare/users');
      });
  };

  useEffect(() => {
    handleFetchUser();
  }, []);

  const handleValidateEmail = (value) => {
    setIsValidEmail(value === '' || validateEmail(value));
  };

  const handleValidatePhone = (value) => {
    setIsValidPhone(value === '' || validatePhone(value));
  };

  const handleValidateDocument = (value) => {
    setIsValidDocument(value === '' || (documentType === 'CPF' ? validateCpf(value) : validateSsn(value)));
  };

  const handleUpdateUser = () => {
    const updatedInfo = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      document: unformatDocument(document),
      document_type: documentType,
    };

    const formData = new FormData();
    Object.entries(updatedInfo).forEach(([key, value]) => {
      formData.append(key, value);
    });

    setIsSaving(true);

    updateBuUser(id, formData)
      .then(() => {
        setIsSaving(false);
        dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
      });
  };

  const validateRequiredFields = () => {
    const requiredFields = {
      firstName,
      lastName,
      email,
      document,
    };

    const errors = new Map();
    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    if (phoneNumber && !validatePhone(phoneNumber)) errors.set('phoneNumber', INVALID_PHONE_ERROR);

    if (!errors.size && isValidEmail && isValidPhone && isValidDocument) {
      handleUpdateUser();
    } else {
      setErrors(errors);
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  const setFormatDocument = (value) => {
    setDocument(documentType === 'CPF' ? formatToCPFDocument(value) : formatToSSNDocument(value));
  };

  const changeDocumentType = (value) => {
    setDocumentType(value);
    setDocument('');
  };

  return (
    <App user={{ id, name: `${firstName} ${lastName}` }}>
      {isLoading ? (
        <Loader />
      ) : (
        <SlideRightFadeContainer>
          <Section maxWidth="428px">
            <Subtitle>
              <I18n path="braincare.users-edit.title-user-info" />
            </Subtitle>

            <LabeledInput
              label={<I18n path="braincare.users-edit.input-name" />}
              htmlFor="name"
              data-testid="first-name-labeled-input"
            >
              <Input
                id="name"
                name="Nome"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                hasError={Boolean(errors && errors.get('firstName'))}
                errorMessage={errors && errors.get('firstName') ? errors.get('firstName') : ''}
                data-testid="first-name-input"
              />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="braincare.users-edit.input-surname" />}
              htmlFor="last_name"
              data-testid="last-name-labeled-input"
            >
              <Input
                id="last_name"
                name="Sobrenome"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                hasError={Boolean(errors && errors.get('lastName'))}
                errorMessage={errors && errors.get('lastName') ? errors.get('lastName') : ''}
                data-testid="last-name-input"
              />
            </LabeledInput>
            <br />
            <br />
            <Subtitle>
              <I18n path="braincare.users-edit.title-user-identification" />
            </Subtitle>
            <LabeledInput label={<I18n path="braincare.users-edit.input-doc-type-choice" />}>
              <Select
                value={documentType}
                onChange={(event) => changeDocumentType(event.target.value)}
                hasError={Boolean(errors && errors.get('documentType'))}
                errorMessage={errors && errors.get('documentType') ? errors.get('documentType') : ''}
                data-testid="document-type-select"
              >
                <Option value="CPF" data-testid="cpf-option">
                  CPF
                </Option>
                <Option value="ID" data-testid="id-option">
                  ID
                </Option>
              </Select>
            </LabeledInput>
            <LabeledInput
              label={<I18n path="braincare.users-edit.input-document" />}
              data-testid="document-labeled-input"
            >
              <Input
                placeholder={documentType === 'CPF' ? '000.000.000-00' : '000-00-0000'}
                value={document}
                onChange={(event) => setFormatDocument(event.target.value)}
                hasError={!isValidDocument || Boolean(errors && errors.get('document'))}
                errorMessage={
                  (!isValidDocument && INVALID_DOCUMENT_ERROR) ||
                  (errors && errors.get('document') ? errors.get('document') : '')
                }
                onBlur={() => handleValidateDocument(document)}
                maxLength={documentType === 'CPF' ? '14' : '11'}
                data-testid="document-input"
              />
            </LabeledInput>
            <br />
            <br />
            <Subtitle>
              <I18n path="braincare.users-edit.title-user-contact-info" />
            </Subtitle>
            <LabeledInput
              label={<I18n path="braincare.users-edit.input-email" />}
              htmlFor="email"
              data-testid="email-labeled-input"
            >
              <Input
                id="email"
                placeholder="theresa@stanfordmedicalcenter.com"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                hasError={Boolean(errors && errors.get('email')) || !isValidEmail}
                errorMessage={
                  (!isValidEmail && INVALID_EMAIL_ERROR) || (errors && errors.get('email') ? errors.get('email') : '')
                }
                onBlur={() => handleValidateEmail(email)}
                data-testid="email-input"
              />
            </LabeledInput>
            <LabeledInput
              label={<I18n path="braincare.users-edit.input-phone" />}
              htmlFor="phone"
              data-testid="phone-labeled-input"
            >
              <Input
                id="phone"
                placeholder="+ 1 650-742-4750"
                name="phone"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                hasError={Boolean(errors && errors.get('phoneNumber')) && !isValidPhone}
                errorMessage={
                  (!isValidPhone && INVALID_PHONE_ERROR) ||
                  (errors && errors.get('phoneNumber') ? errors.get('phoneNumber') : '')
                }
                onBlur={() => handleValidatePhone(phoneNumber)}
                data-testid="phone-input"
              />
            </LabeledInput>
          </Section>
          <div>
            <Button type="primary" isLoading={isSaving} onClick={validateRequiredFields} data-testid="save-button">
              <I18n path="braincare.users-edit.button-save" />
            </Button>
            <Button onClick={() => navigate('/braincare/users')} data-testid="cancel-button">
              <I18n path="braincare.users-edit.button-cancel" />
            </Button>
          </div>
        </SlideRightFadeContainer>
      )}
    </App>
  );
}

export default braincare(Profile);
