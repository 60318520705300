import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import localeEnUs from 'locales/en-us.json';
import localePtBr from 'locales/pt-br.json';

import getLanguage from 'utils/get-language';

const locales = {
  'en-us': localeEnUs,
  'pt-br': localePtBr,
};

const mapStateToProps = (state) => ({
  language: state.user.language,
});

const parsePath = (language, path) => {
  const parsed = path.split('.');
  return parsed.reduce((directory, next) => (directory ? directory[next] : ''), locales[language]);
};

const parseProps = (string, props) => {
  let parsed = string;
  if (!parsed) return parsed;
  if (props) {
    Object.keys(props).forEach((variable) => {
      parsed = parsed.replace(new RegExp(`<${variable}>`, 'g'), props[variable]);
    });
  }
  return parsed;
};

export const translate = (path, props = {}) => {
  if (!path) return '';

  const language = getLanguage();

  const string = parsePath(language, path);

  return parseProps(string, props) || null;
};

function T({ language, path, props }) {
  const string = parsePath(language, path);
  return parseProps(string, props) || null;
}

T.propTypes = {
  language: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  props: PropTypes.instanceOf(Object),
};

T.defaultProps = {
  props: {},
};

export default connect(mapStateToProps, null)(T);
