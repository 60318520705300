import Tag from 'components/atoms/tag-button';
import Text from 'components/atoms/text';
import Loader from 'components/molecules/section-loader';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

function MemberUnits({ error, isLoading, onToggle, selectedUnits, units, ...props }) {
  const handleUnitsInputToggle = (value) => {
    if (selectedUnits.includes(value)) {
      onToggle(selectedUnits.filter((item) => item !== value));
    } else {
      onToggle([...selectedUnits, value]);
    }
  };

  return (
    <div {...props}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.organizationTags}>
          {units.map((unit) => (
            <Tag
              key={unit.id}
              isSelected={selectedUnits.includes(unit.id)}
              onToggle={() => handleUnitsInputToggle(unit.id)}
              data-testid={`unit-tag-${unit.id}`}
            >
              {unit.name}
            </Tag>
          ))}
          {error && (
            <Text isError>
              <I18n path="organization.organization-units.error-units" />
            </Text>
          )}
        </div>
      )}
    </div>
  );
}

export default MemberUnits;
