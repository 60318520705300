import { useEffect } from 'react';
import Auth from 'components/templates/auth';
import App from 'components/templates/app';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'features/userSlice';
import { getToken } from 'utils/tokens';
import TermsAndServicesPortuguese from './pt';
import TermsAndServicesEnglish from './en';

function TermsAndServices() {
  const user = useSelector((state) => state.user);

  const accessToken = getToken('access_token');
  const refreshToken = getToken('refresh_token');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const defaultLanguage = searchParams.get('language');

  useEffect(() => {
    if (defaultLanguage) {
      if (defaultLanguage === 'pt-br' || defaultLanguage === 'en-us') {
        dispatch(setLanguage(defaultLanguage));
        navigate('/public/terms-and-service');
      }
    }
  }, []);

  window.scrollTo(0, 0);

  const renderText = () => {
    if (user.language === 'pt-br') {
      return <TermsAndServicesPortuguese />;
    }

    return <TermsAndServicesEnglish />;
  };

  const signed = accessToken !== undefined && refreshToken !== undefined;

  if (signed) {
    return <App>{renderText()}</App>;
  }

  return <Auth maxWidth="90vw">{renderText()}</Auth>;
}

export default TermsAndServices;
