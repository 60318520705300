import { InputLabel as MuiInputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
  transform: 'translate(14px, -9px) scale(0.75)',
  backgroundColor: theme.palette.primary.contrastText,
}));

export default InputLabel;
