import Section from 'components/atoms/section';
import AuthTitle from 'components/atoms/auth-title';
import Text from 'components/atoms/text';
import Subtitle from 'components/atoms/subtitle';
import I18n from 'utils/i18n';
import styles from '../styles.module.css';

export default function PrivacyPolicyPortuguese() {
  return (
    <Section className={styles.section}>
      <AuthTitle>
        <I18n path="auth.privacy-policy.title00" />
      </AuthTitle>

      <Text size="medium">
        <I18n path="auth.privacy-policy.text00" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text01" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text02" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text03" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.privacy-policy.title01" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.privacy-policy.text04" />
        <br />
        <br />
        <span className={styles.warning}>
          <I18n path="auth.privacy-policy.text05" />
        </span>
        <br />
        <br />
        <I18n path="auth.privacy-policy.text06" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text07" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.privacy-policy.title03" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.privacy-policy.text08" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text09" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text10" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text11" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.privacy-policy.title04" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.privacy-policy.text12" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text13" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text14" />
        <br />
        <br />
        <I18n path="auth.privacy-policy.text15" />
        <br />
        <br />
      </Text>
    </Section>
  );
}
