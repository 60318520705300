import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import MobileDeviceDetails from '../../MobileDeviceDetails';

function OperationConcluded({ device, subtitle, text }) {
  const navigate = useNavigate();

  return (
    <>
      <Section maxWidth="428px">
        <Subtitle>{subtitle}</Subtitle>
        <Text isItalic>{text}</Text>
        <MobileDeviceDetails device={device} />
      </Section>
      <div className={styles.buttonsContainer}>
        <Button type="primary" onClick={() => navigate('/org-mobile-devices/devices')} data-testid="back-button">
          <I18n path="organization.organization-mobile-devices-create.button-back" />
        </Button>
      </div>
    </>
  );
}

export default OperationConcluded;
