import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import appPlaceholder from 'assets/images/app-placeholder.svg';
import Text from 'components/atoms/text';
import styles from './styles.module.css';

const AppItem = ({ onClick, text, image, href, ...props }) => {
  const navigate = useNavigate();

  return (
    <button className={styles.appItem} onClick={() => navigate(href)} {...props}>
      <img className={styles.image} src={image || appPlaceholder} alt="" />
      <Text isBold className={styles.text}>
        {text}
      </Text>
    </button>
  );
};

AppItem.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.any.isRequired,
  image: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

AppItem.defaultProps = {
  onClick: () => {},
};

export default AppItem;
