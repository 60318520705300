import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';
import { fetchOrganizationProfile, fetchProfile, fetchProfiles } from 'api/portal';
import { CORPORATE_TOKEN, PERSONAL_TOKEN, getTokenType } from 'utils/tokens';

export const STATUS_IDLE = 'idle';
export const STATUS_LOADING = 'loading';
export const STATUS_SUCCEEDED = 'succeeded';
export const STATUS_FAILED = 'failed';

const initialState = {
  // User data returned from the API
  profile: {
    birth_date: '',
    doctor_document: '',
    document: '',
    document_type: '',
    email: '',
    first_name: '',
    gender: '',
    id: -1,
    last_name: '',
    phone_number: '',
    photo: '',
    notification_language: '',
    braincare_permissions: [],
  },
  // Organization data from the API
  active_organization: {
    id: -1,
    name: '',
    photo: '',
    password_policy: {},
  },
  // Member data from the API
  organization_profile: {
    id: -1,
    email: '',
    phone_number: '',
    roles: [],
    units: [],
    status: '',
    member_permissions: [],
  },
  organizations: [],
  isBraincareUser: Cookie.get('isBraincareUser') === 'true',
  language: Cookie.get('language') ? Cookie.get('language') : 'pt-br',
  status: STATUS_IDLE,
};

export const updateProfile = createAsyncThunk('me/fetchProfile', async () => {
  const token = Cookie.get('access_token');

  const tokenType = getTokenType(token);

  if (tokenType === CORPORATE_TOKEN) {
    const response = await fetchOrganizationProfile();

    const { data } = response;

    const profile = data.user;
    const activeOrganization = data.organization;
    const memberId = data.id;
    const memberEmail = data.email;
    const memberPhoneNumber = data.phone_number;
    const memberRoles = data.roles;
    const memberUnits = data.units;
    const memberStatus = data.status;
    const memberPermissions = data.member_permissions;

    return {
      profile,
      active_organization: activeOrganization,
      organization_profile: {
        id: memberId,
        email: memberEmail,
        phone_number: memberPhoneNumber,
        roles: memberRoles,
        units: memberUnits,
        status: memberStatus,
        member_permissions: memberPermissions,
      },
    };
  }

  if (tokenType === PERSONAL_TOKEN) {
    const response = await fetchProfile();

    const profile = response.data;

    return {
      profile,
      active_organization: initialState.active_organization,
      organization_profile: initialState.organization_profile,
    };
  }

  return {};
});

export const updateOrganizations = createAsyncThunk('me/fetchOrganizations', async () => {
  const token = Cookie.get('access_token');

  const tokenType = getTokenType(token);

  if (tokenType !== null) {
    const response = await fetchProfiles();

    const { data } = response;

    return { organizations: data };
  }

  return {};
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserData() {
      return initialState;
    },
    clearProfileData(state) {
      return {
        ...state,
        profile: initialState.profile,
      };
    },
    clearActiveOrganizationData(state) {
      return {
        ...state,
        active_organization: initialState.active_organization,
      };
    },
    clearOrganizationProfileData(state) {
      return {
        ...state,
        organization_profile: initialState.organization_profile,
      };
    },
    clearOrganizations(state) {
      return {
        ...state,
        organizations: initialState.organizations,
      };
    },
    setProfileData(state, action) {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    },
    setIsBraincareUser(state, action) {
      Cookie.set('isBraincareUser', action.payload, { sameSite: 'strict', expires: 365 });
      return {
        ...state,
        isBraincareUser: action.payload,
      };
    },
    setActiveOrganizationData(state, action) {
      return {
        ...state,
        active_organization: {
          ...state.active_organization,
          ...action.payload,
        },
      };
    },
    setOrganizationProfileData(state, action) {
      return {
        ...state,
        organization_profile: {
          ...state.organization_profile,
          ...action.payload,
        },
      };
    },
    setOrganizations(state, action) {
      return {
        ...state,
        organizations: action.payload,
      };
    },
    setLanguage(state, action) {
      Cookie.set('language', action.payload, { sameSite: 'strict', expires: 365 });
      return {
        ...state,
        language: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateProfile.pending, (state) => ({
        ...state,
        status: STATUS_LOADING,
      }))
      .addCase(updateProfile.fulfilled, (state, action) => {
        const { isBraincareUser } = state;

        if (isBraincareUser) {
          return {
            ...state,
            profile: action.payload.profile,
            status: STATUS_SUCCEEDED,
          };
        }

        return {
          ...state,
          ...action.payload,
          status: STATUS_SUCCEEDED,
        };
      })
      .addCase(updateProfile.rejected, (state) => ({
        ...state,
        status: STATUS_FAILED,
      }))
      .addCase(updateOrganizations.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }));
  },
});

export const {
  clearUserData,
  clearProfileData,
  clearActiveOrganizationData,
  clearOrganizationProfileData,
  clearOrganizations,
  setProfileData,
  setIsBraincareUser,
  setActiveOrganizationData,
  setOrganizationProfileData,
  setOrganizations,
  setLanguage,
} = userSlice.actions;

export default userSlice.reducer;
