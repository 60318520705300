import { useEffect, useState } from 'react';
import { addToast } from 'actions/toasts';
import Badge from 'components/atoms/badge';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import Radio from 'components/atoms/input-radio';
import Label from 'components/atoms/input-label';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Text from 'components/atoms/text';
import Avatar from 'components/atoms/avatar';
import Loader from 'components/molecules/section-loader';
import LabeledInput from 'components/molecules/labeled-input';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import { MEMBER_STATUS } from 'utils/members';
import App from 'pages/organization/members/edit/template';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMember, setOrganizationSelectedMember } from 'features/organizationSlice';
import { fetchMember, updateMember } from 'api/portal';
import styles from './styles.module.css';

const BADGE_STATUS = {
  ACTIVE: 'success',
  PENDING: 'pending',
  SUSPENDED: 'danger',
  DECLINED: 'alert',
  BLOCKED: 'blocked',
};

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const REQUEST_SUCCESS = <I18n path="messages.request-success" />;
const REQUEST_FAILURE = <I18n path="messages.request-failure" />;

function CorporateInfo() {
  const member = useSelector((state) => state.organization.selectedMember);

  const { user } = member;

  const [photo, setPhoto] = useState(user ? user.photo : '');
  const status = member.status || 'ACTIVE';
  const reason = member.reason || '';
  const [firstName, setFirstName] = useState(user ? user.first_name : '');
  const [lastName, setLastName] = useState(user ? user.last_name : '');
  const [birthDate, setBirthDate] = useState(user ? user.birth_date : '');
  const [notificationLanguage, setNotificationLanguage] = useState(user ? user.notification_language : '');
  const [documentType, setDocumentType] = useState(user ? user.document_type : '');
  const [document, setDocument] = useState(user ? user.document : '');

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { memberId } = params;

  const handleGetMemberRequest = (id) => {
    setIsLoading(true);

    fetchMember(id)
      .then((response) => {
        const { data } = response;

        dispatch(setOrganizationSelectedMember(data));

        const { user } = data;

        setPhoto(user.photo);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setBirthDate(user.birth_date);
        setDocumentType(user.document_type);
        setDocument(user.document);
        setNotificationLanguage(user.notification_language);
        setIsLoading(false);
      })
      .catch(() => {
        dispatch(addToast('error', LOAD_FAILURE));
        navigate('/organization/members');
      });
  };

  useEffect(() => {
    if (member.id !== Number(memberId)) {
      dispatch(clearOrganizationSelectedMember());
      handleGetMemberRequest(memberId);
    }
  }, []);

  const handleResendInvite = () => {
    setIsUpdating(true);

    updateMember(memberId, { mail_again: true })
      .then(() => {
        setIsUpdating(false);
        dispatch(addToast('success', REQUEST_SUCCESS));
      })
      .catch(() => {
        setIsUpdating(false);
        dispatch(addToast('error', REQUEST_FAILURE));
      });
  };

  return (
    <App>
      <Section className={styles.section} maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-members-edit.title-sub-member-edit" />
        </Subtitle>
        <Text isItalic>
          <I18n path="organization.organization-members-edit.text-organization-members-main" />
        </Text>

        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <Avatar image={photo} description="profile image" className={styles.profileImage} />

            <div className={styles.statusContainer}>
              <div>
                <b className={styles.label}>
                  <I18n path="organization.organization-members-edit.label-status" />
                </b>
                <Badge className={styles.statusBadge} type={BADGE_STATUS[status]} data-testid="status-badge">
                  {MEMBER_STATUS.find((item) => item.id === status).text}
                </Badge>
              </div>

              {status === 'DECLINED' && (
                <div data-testid="declining-reason">
                  <b className={styles.label}>
                    <I18n path="organization.organization-members-edit.label-reason" />
                  </b>
                  <span>{reason}</span>
                </div>
              )}

              {status === 'DECLINED' && (
                <Button
                  type="danger"
                  onClick={handleResendInvite}
                  isLoading={isUpdating}
                  data-testid="resend-invitation-button"
                >
                  <I18n path="organization.organization-members-edit.button-resend-invite" />
                </Button>
              )}
            </div>

            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-name" />}
              htmlFor="first_name"
              isDisabled
            >
              <Input
                id="first_name"
                name="first-name"
                value={firstName || ''}
                isDisabled
                data-testid="first-name-input"
              />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-surname" />}
              htmlFor="last_name"
              isDisabled
            >
              <Input id="last_name" name="last-name" value={lastName || ''} isDisabled data-testid="last-name-input" />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-birthday" />}
              htmlFor="birth_date"
              isDisabled
            >
              <Input
                id="birth_date"
                name="birth-date"
                value={birthDate || ''}
                isDisabled
                data-testid="birth-date-input"
              />
            </LabeledInput>
            <div>
              <div className={styles.language}>
                <Label isDisabled>
                  <I18n path="auth.signup.text-doc-choice-language" />
                </Label>
                <div className={styles.languageOptions}>
                  <Radio
                    id="pt-br"
                    name="notificationLanguage"
                    value="pt-br"
                    label={<I18n path="auth.signup.input-language-pt-br" />}
                    isChecked={notificationLanguage === 'pt-br'}
                    isDisabled
                    data-testid="pt-br-radio"
                  />
                  <Radio
                    id="en-us"
                    name="notificationLanguage"
                    value="en-us"
                    label={<I18n path="auth.signup.input-language-en-us" />}
                    isChecked={notificationLanguage === 'en-us'}
                    isDisabled
                    data-testid="en-us-radio"
                  />
                </div>
              </div>
            </div>
          </SlideRightFadeContainer>
        )}
      </Section>

      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-members-edit.title-sub-member-identification" />
        </Subtitle>
        <Text isItalic>
          <I18n path="organization.organization-members-edit.text-organization-members-username" />
        </Text>

        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-doc-type-choice" />}
              htmlFor="document_type"
              isDisabled
            >
              <Select
                id="document_type"
                name="document-type"
                value={documentType || ''}
                isDisabled
                data-testid="document-type-select"
              >
                <Option value="CPF">CPF</Option>
                <Option value="ID">ID</Option>
              </Select>
            </LabeledInput>

            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-document-number" />}
              htmlFor="document"
              isDisabled
            >
              <Input id="document" name="document" value={document || ''} isDisabled data-testid="document-input" />
            </LabeledInput>
          </SlideRightFadeContainer>
        )}
      </Section>
      <Button
        onClick={() => {
          navigate('/organization/members');
        }}
        data-testid="cancel-button"
      >
        <I18n path="organization.organization-members-edit.button-cancel" />
      </Button>
    </App>
  );
}

export default CorporateInfo;
