import { Checkbox } from '@mui/material';
import { addToast } from 'actions/toasts';
import { fetchOrganization, updateOrganization } from 'api/portal';
import Dialog from 'components/atoms/dialog';
import DialogBody from 'components/atoms/dialog-body';
import DialogFooter from 'components/atoms/dialog-footer';
import DialogHeader from 'components/atoms/dialog-header';
import Section from 'components/atoms/section';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import SectionLoader from 'components/molecules/section-loader';
import Button from 'components/mui/Button';
import FormControlLabel from 'components/mui/FormControlLabel';
import TextField from 'components/mui/TextField';
import braincare from 'components/routes/braincare';
import App from 'pages/braincare/AdminOrganizations/template';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { formatNumber } from 'utils/format-number';
import I18n from 'utils/i18n';
import { SlideRightFadeContainer } from 'utils/transitions';

const AUTO_PURGE_PERIOD_EMPTY = <I18n path="organization.organization-settings.reports.auto-purge-period-empty" />;
const SAVE_SUCCESS_MESSAGE = <I18n path="organization.organization-settings.save-success-message" />;
const SAVE_FAILURE_MESSAGE = <I18n path="organization.organization-settings.save-failure-message" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const LOAD_FAILURE = <I18n path="messages.load-failure" />;

function AdminOrganizationReportsPolicies() {
  const [organizationId, setOrganizationId] = useState();
  const [name, setName] = useState('');

  const [globalAutoShare, setGlobalAutoShare] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [autoPurge, setAutoPurge] = useState(false);
  const [autoPurgePeriod, setAutoPurgePeriod] = useState('');

  const [errors, setErrors] = useState(new Map());

  const [isLoading, setIsLoading] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    setIsLoading(true);

    const { orgId } = params;

    setOrganizationId(orgId);

    fetchOrganization(orgId)
      .then((response) => {
        setIsLoading(false);

        const { data } = response;

        setName(data.name);
        setGlobalAutoShare(data.global_auto_share);
        setAutoPurge(data.auto_purge);
        setAutoPurgePeriod(data.expiration_days || '');
      })
      .catch(() => {
        setIsLoading(false);
        navigate('/braincare/organizations');
        dispatch(addToast('error', LOAD_FAILURE));
      });
  }, []);

  const validateForm = () => {
    const errors = new Map();

    if (autoPurge) {
      if (autoPurgePeriod === '') {
        errors.set('autoPurgePeriod', AUTO_PURGE_PERIOD_EMPTY);
      }
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateOrganization(organizationId, {
        global_auto_share: globalAutoShare,
        auto_purge: autoPurge,
        expiration_days: autoPurgePeriod === '' ? null : autoPurgePeriod,
      })
        .then(() => {
          setIsSubmitting(false);
          dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
        });
    } else {
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  return (
    <App title={name}>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-settings.reports.subtitle" />
        </Subtitle>

        <Dialog isOpen={isConfirmDialogOpen} data-testid="confirm-auto-purge-dialog">
          <DialogHeader type="warning">
            <I18n path="organization.organization-settings.reports.auto-purge-confirmation-title" />
          </DialogHeader>
          <DialogBody>
            <Text>
              <I18n path="organization.organization-settings.reports.auto-purge-confirmation-body" />
            </Text>
          </DialogBody>
          <DialogFooter>
            <Button
              fullWidth
              onClick={() => {
                setIsConfirmDialogOpen(false);
                setAutoPurge(true);
              }}
              loading={false}
              data-testid="confirm-auto-purge-button"
            >
              <I18n path="organization.organization-settings.reports.auto-purge-confirmation-confirm-button" />
            </Button>
            <Button fullWidth type="primary" onClick={() => setIsConfirmDialogOpen(false)}>
              <I18n path="organization.organization-settings.reports.auto-purge-confirmation-cancel-button" />
            </Button>
          </DialogFooter>
        </Dialog>

        {isLoading ? (
          <SectionLoader />
        ) : (
          <SlideRightFadeContainer>
            <Section>
              <br />
              <SubSubtitle>
                <I18n path="organization.organization-settings.reports.global-auto-share-subsubtitle" />
              </SubSubtitle>

              <Text isItalic>
                <I18n path="organization.organization-settings.reports.global-auto-share-text" />
              </Text>

              <FormControlLabel
                label={<I18n path="organization.organization-settings.reports.global-auto-share-checkbox" />}
                control={
                  <Checkbox
                    checked={globalAutoShare}
                    inputProps={{ 'data-testid': 'auto-share-checkbox-input' }}
                    onClick={() => setGlobalAutoShare(!globalAutoShare)}
                    data-testid="auto-share-checkbox"
                  />
                }
              />
            </Section>

            <Section>
              <SubSubtitle>
                <I18n path="organization.organization-settings.reports.auto-purge-subsubtitle" />
              </SubSubtitle>

              <Text isItalic>
                <I18n path="organization.organization-settings.reports.auto-purge-text" />
              </Text>

              <FormControlLabel
                label={<I18n path="organization.organization-settings.reports.auto-purge-checkbox" />}
                control={
                  <Checkbox
                    checked={autoPurge}
                    inputProps={{ 'data-testid': 'auto-purge-checkbox-input' }}
                    onClick={() => {
                      if (autoPurge) {
                        setAutoPurge(false);
                      } else {
                        setIsConfirmDialogOpen(true);
                      }
                    }}
                    data-testid="auto-purge-checkbox"
                  />
                }
              />

              <TextField
                id="autoPurgePeriod"
                sx={{ marginTop: 2 }}
                value={autoPurgePeriod}
                onChange={(event) => setAutoPurgePeriod(formatNumber(event.target.value))}
                label={<I18n path="organization.organization-settings.reports.auto-purge-input" />}
                variant="outlined"
                error={errors.has('autoPurgePeriod')}
                helperText={errors.get('autoPurgePeriod')}
                data-testid="auto-purge-text-field"
                inputProps={{ 'data-testid': 'auto-purge-input' }}
                FormHelperTextProps={{ 'data-testid': 'auto-purge-error' }}
                fullWidth
              />
            </Section>

            <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
              <I18n path="organization.organization-settings.reports.submit-button-text" />
            </Button>
            <Button
              sx={{ marginLeft: 2 }}
              variant="outlined"
              onClick={() => navigate('/braincare/organizations')}
              data-testid="cancel-button"
            >
              <I18n path="organization.organization-settings.reports.cancel-button-text" />
            </Button>
          </SlideRightFadeContainer>
        )}
      </Section>
    </App>
  );
}

export default braincare(AdminOrganizationReportsPolicies);
