import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

const Subtitle = ({ children, className, isError, ...props }) => (
  <h3 className={classnames(styles.subtitle, className, { [styles.error]: isError })} {...props}>
    {children}
  </h3>
);

Subtitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isError: PropTypes.bool,
};

Subtitle.defaultProps = {
  className: undefined,
  isError: false,
};

export default Subtitle;
