import { useState } from 'react';
import AppLogged from 'components/templates/app';
import AppPublic from 'components/templates/app-public';
import { addToast } from 'actions/toasts';
import AuthTitle from 'components/atoms/auth-title';
import Button from 'components/atoms/button';
import Container from 'components/atoms/container';
import Input from 'components/atoms/input';
import TextArea from 'components/atoms/input-textarea';
import Text from 'components/atoms/text';
import LabeledInput from 'components/molecules/labeled-input';
import I18n from 'utils/i18n';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessageToSupport } from 'api/portal';
import { getToken } from 'utils/tokens';
import { Form, FormButtons } from './styles';
import styles from './styles.module.css';

const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const MESSAGE_SUCCESS = <I18n path="messages.message-success" />;
const MESSAGE_FAILURE = <I18n path="messages.message-failure" />;

function Support() {
  const user = useSelector((state) => state.user);

  const { profile } = user;
  const activeOrganization = user.active_organization;
  const organizationProfile = user.organization_profile;

  const accessToken = getToken('access_token');
  const refreshToken = getToken('refresh_token');

  const [name, setName] = useState(`${profile.first_name} ${profile.last_name}`);
  const [email, setEmail] = useState(organizationProfile.email || profile.email);
  const [organization, setOrganization] = useState(activeOrganization.name);
  const [role, setRole] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateForms = () => {
    const requiredFields = {
      name,
      email,
      organization,
      role,
      subject,
      message,
    };

    const errors = new Map();
    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    setErrors(errors);

    return errors.size;
  };

  const handleSubmitForm = () => {
    const errors = validateForms();

    if (errors) {
      dispatch(addToast('error', FIX_FIELDS));
    } else {
      setIsPosting(true);

      sendMessageToSupport({
        name,
        email,
        organization,
        role,
        subject,
        message,
      })
        .then(() => {
          setIsPosting(false);
          dispatch(addToast('success', MESSAGE_SUCCESS));
          navigate('/');
        })
        .catch(() => {
          setIsPosting(false);
          dispatch(addToast('error', MESSAGE_FAILURE));
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const signed = accessToken !== undefined && refreshToken !== undefined;
  const Wrapper = signed ? AppLogged : AppPublic;

  return (
    <Wrapper>
      <SlideFadeInContainer>
        <Container>
          <AuthTitle className={styles.title}>
            <I18n path="support.title" />
          </AuthTitle>
          <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
            <Form>
              <Text>
                <I18n path="support.text" />
                <div className={styles.contacts}>
                  <div className={styles.contactRow}>
                    <i className="fas fa-phone" /> (11) 4324-5305
                  </div>
                  <div className={styles.contactRow}>
                    <i className="fab fa-whatsapp" /> (11) 99740-9598
                  </div>
                  <div className={styles.contactRow}>
                    <i className="fas fa-envelope" />
                    <a href="mailto:suporte@brain4.care">suporte@brain4.care</a>
                  </div>
                </div>
              </Text>
              <LabeledInput label={<I18n path="support.label-input-name" />} data-testid="name-labeled-input">
                <Input
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  hasError={Boolean(errors && errors.get('name'))}
                  errorMessage={errors && errors.get('name') ? errors.get('name') : ''}
                  data-testid="name-input"
                />
              </LabeledInput>
              <LabeledInput label={<I18n path="support.label-input-email" />} data-testid="email-labeled-input">
                <Input
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  hasError={Boolean(errors && errors.get('email'))}
                  errorMessage={errors && errors.get('email') ? errors.get('email') : ''}
                  data-testid="email-input"
                />
              </LabeledInput>
              <LabeledInput
                label={<I18n path="support.label-input-organization" />}
                data-testid="organization-labeled-input"
              >
                <Input
                  value={organization}
                  onChange={(event) => setOrganization(event.target.value)}
                  hasError={Boolean(errors && errors.get('organization'))}
                  errorMessage={errors && errors.get('organization') ? errors.get('organization') : ''}
                  data-testid="organization-input"
                />
              </LabeledInput>
              <LabeledInput label={<I18n path="support.label-input-role" />} data-testid="role-labeled-input">
                <Input
                  value={role}
                  onChange={(event) => setRole(event.target.value)}
                  hasError={Boolean(errors && errors.get('role'))}
                  errorMessage={errors && errors.get('role') ? errors.get('role') : ''}
                  data-testid="role-input"
                />
              </LabeledInput>
              <LabeledInput label={<I18n path="support.label-input-subject" />} data-testid="subject-labeled-input">
                <Input
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                  hasError={Boolean(errors && errors.get('subject'))}
                  errorMessage={errors && errors.get('subject') ? errors.get('subject') : ''}
                  data-testid="subject-input"
                />
              </LabeledInput>
              <LabeledInput label={<I18n path="support.label-input-message" />} data-testid="message-labeled-input">
                <TextArea
                  lines={5}
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  hasError={Boolean(errors && errors.get('message'))}
                  errorMessage={errors && errors.get('message') ? errors.get('message') : ''}
                  data-testid="message-textarea"
                />
              </LabeledInput>
              <FormButtons>
                <Button onClick={() => navigate('/')} data-testid="back-button">
                  <I18n path="support.button-back" />
                </Button>
                <Button type="primary" isLoading={isPosting} onClick={handleSubmitForm} data-testid="send-button">
                  <I18n path="support.button-send" />
                </Button>
              </FormButtons>
            </Form>
          </form>
        </Container>
      </SlideFadeInContainer>
    </Wrapper>
  );
}

export default Support;
