import { useEffect, useState } from 'react';
import Card from 'components/atoms/card';
import CardTitle from 'components/atoms/card-title';
import Checkbox from 'components/atoms/input-checkbox';
import Text from 'components/atoms/text';
import Loader from 'components/molecules/section-loader';
import I18n from 'utils/i18n';
import { fetchRoles } from 'api/portal';
import styles from './styles.module.css';

const ERROR_LOADING_ROLES = <I18n path="messages.error-roles" />;

function MemberPermissions({ onToggle, selected = [], ...props }) {
  const [allPermissions, setAllPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (allPermissions.length === 0) {
      setIsLoading(true);

      fetchRoles()
        .then((response) => {
          const { data } = response;
          setIsLoading(false);
          // Remove the roles: "Gestor de Dispositivos" and "Gestor de Contrato"
          setAllPermissions(data.filter((el) => el.id === 1 || el.id === 3));
          setError(false);
        })
        .catch(() => {
          setIsLoading(false);
          setError(true);
        });
    }
  }, []);

  const handleInputToggle = (value) => {
    let permissions = [];

    if (selected.includes(value)) {
      permissions = selected.filter((item) => item !== value);
    } else {
      permissions = [...selected, value];
    }

    onToggle(permissions);
  };

  const renderResults = () => {
    if (error) {
      return <Text isError>{ERROR_LOADING_ROLES}</Text>;
    }

    return (
      <div className={styles.cards}>
        {allPermissions.map((permission) => (
          <Card key={permission.id.toString()}>
            <Checkbox
              id={permission.id.toString()}
              value="large"
              isChecked={selected.includes(permission.id)}
              onClick={() => handleInputToggle(permission.id)}
              data-testid={`permission-${permission.id}`}
            />
            <CardTitle>
              <I18n path={permission.name} />
            </CardTitle>
            <Text size="medium" isItalic>
              {permission.description || ''}
            </Text>
          </Card>
        ))}
      </div>
    );
  };

  return <div {...props}>{isLoading ? <Loader /> : renderResults()}</div>;
}

export default MemberPermissions;
