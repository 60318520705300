import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const InputLabel = ({ htmlFor, className, isDisabled, children, ...props }) => (
  <label
    htmlFor={htmlFor}
    className={classNames(styles.inputLabel, { [styles.disabled]: isDisabled }, className)}
    {...props}
  >
    {children}
  </label>
);

InputLabel.propTypes = {
  htmlFor: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

InputLabel.defaultProps = {
  htmlFor: undefined,
  className: undefined,
  isDisabled: false,
};

export default InputLabel;
