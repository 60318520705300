import ActiveAccountItem from 'components/molecules/active-account-item';
import AccountItem from 'components/molecules/account-item';
import Apps from 'components/organisms/Apps';
import BCAvatar from 'assets/images/braincare-user/organization-avatar.png';
import BraincareLogo from 'assets/images/logo-braincare.png';
import ClickOutside from 'react-outside-click-handler';
import Dropdown from 'components/molecules/dropdown';
import DropdownMenu from 'components/molecules/dropdown-menu';
import DropdownToggle from 'components/molecules/dropdown-toggle';
import Navbar from 'components/organisms/navbar';
import NavbarProfile from 'components/molecules/navbar-profile';
import Notifications from 'components/organisms/notifications';
import LoginDialog from 'pages/auth/common/LoginDialog';
import Text from 'components/atoms/text';
import I18n, { translate } from 'utils/i18n';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'components/atoms/button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from 'features/notificationsSlice';
import {
  STATUS_FAILED,
  clearActiveOrganizationData,
  clearOrganizationProfileData,
  setActiveOrganizationData,
  setIsBraincareUser,
  setOrganizationProfileData,
  setProfileData,
  updateOrganizations,
  updateProfile,
} from 'features/userSlice';
import { braincareApps } from 'utils/braincare-admins';
import { getToken, setToken } from 'utils/tokens';
import { fetchApplications, fetchOrganizationProfile, login, memberLogin } from 'api/portal';
import { addToast } from 'actions/toasts';
import styles from './styles.module.css';
import { Separator } from './styles';

const USER_BLOCKED = <I18n path="auth.login.user-blocked" />;
const INVALID_CREDENTIAL = <I18n path="auth.login.auth-error" />;
const GENERIC_ERROR = <I18n path="messages.generic-error" />;
const CORPORATE_LOGIN_PASSWORD_ERROR = <I18n path="auth.accounts-corp-login.text-corp-login-pwd-error" />;
const OUT_OF_DATE_WARNING = <I18n path="messages.error-update-data" />;

function Menu() {
  const [account, setAccount] = useState(undefined);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const [applications, setApplications] = useState([]);
  const [isLoadingApplications, setIsLoadingApplication] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const { isBraincareUser, organizations, profile } = user;
  const activeOrganization = user.active_organization;
  const braincarePermissions = profile.braincare_permissions ? profile.braincare_permissions : [];

  const setBraincareApplications = () => {
    const permittedApps = braincareApps.filter((app) => braincarePermissions.indexOf(app.permission) !== -1);
    setApplications(permittedApps);
  };

  const fetchApplicationsHandler = () => {
    setIsLoadingApplication(true);

    fetchApplications()
      .then((response) => {
        const { data } = response;
        setApplications(data);
        setIsLoadingApplication(false);
      })
      .catch(() => setIsLoadingApplication(false));
  };

  useEffect(() => {
    if (isBraincareUser) {
      setBraincareApplications();
    } else {
      fetchApplicationsHandler();
    }

    dispatch(updateProfile());
    dispatch(updateOrganizations());
  }, []);

  useEffect(() => {
    if (isBraincareUser && braincarePermissions.length > 0) {
      setBraincareApplications();
    }
  }, [braincarePermissions.length]);

  useEffect(() => {
    if (user.status === STATUS_FAILED) {
      dispatch(addToast('warning', OUT_OF_DATE_WARNING));
    }
  }, [user.status]);

  const redirectToPersonalAccount = () => {
    dispatch(clearNotifications());
    dispatch(setIsBraincareUser(false));

    fetchApplicationsHandler();

    dispatch(clearActiveOrganizationData());
    dispatch(clearOrganizationProfileData());
    setIsOpenDropdown(false);

    navigate('/');
  };

  const onClickPersonalAccount = (profile) => {
    const personalAccessToken = getToken('personal_access_token');
    const personalRefreshToken = getToken('personal_refresh_token');

    if (personalAccessToken !== undefined && personalRefreshToken !== undefined) {
      setToken('access_token', personalAccessToken);
      setToken('refresh_token', personalRefreshToken);
      redirectToPersonalAccount();
    } else {
      setAccount(profile);
    }
  };

  const onClickBraincareUser = () => {
    dispatch(clearNotifications());
    dispatch(setIsBraincareUser(true));

    setBraincareApplications();

    dispatch(clearActiveOrganizationData());
    dispatch(clearOrganizationProfileData());
    setIsOpenDropdown(false);

    navigate('/');
  };

  const getSelectedOrganization = () => {
    if (account) {
      if (account.physio_org_id) {
        return account;
      }
    }

    return undefined;
  };

  const personalLogin = (formData) => {
    login({
      document: user.profile.document,
      password: formData.password,
    })
      .then((response) => {
        const { data } = response;

        if (data.hasOwnProperty('error')) {
          setErrors(new Map([['password', INVALID_CREDENTIAL]]));
          setIsSubmitting(false);
          return;
        }

        dispatch(setProfileData(data.user));

        setToken('personal_access_token', data.access_token);
        setToken('personal_refresh_token', data.refresh_token);
        setToken('access_token', data.access_token);
        setToken('refresh_token', data.refresh_token);

        setAccount(undefined);
        setIsSubmitting(false);

        if (data.user.accept_terms === false) {
          navigate('/auth/accept-terms');
        } else {
          redirectToPersonalAccount();
        }
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        if (data.hasOwnProperty('blocked')) {
          setIsSubmitting(false);
          setErrors(new Map([['password', USER_BLOCKED]]));
          return;
        }

        if (data) {
          setIsSubmitting(false);
          setErrors(new Map([['password', GENERIC_ERROR]]));
        }
      });
  };

  const handleCorporateLoginError = () => {
    setIsSubmitting(false);
    setErrors(new Map([['password', CORPORATE_LOGIN_PASSWORD_ERROR]]));
  };

  const corporateLogin = (formData) => {
    memberLogin({ org_id: account.id, password: formData.password })
      .then((response) => {
        const { data } = response;

        setToken('access_token', data.access_token);
        setToken('refresh_token', data.refresh_token);

        fetchOrganizationProfile()
          .then((response) => {
            const { data } = response;
            dispatch(setOrganizationProfileData(data));

            const activeOrganization = user.organizations.find((item) => item.id === account.id);

            dispatch(setActiveOrganizationData(activeOrganization));
            dispatch(clearNotifications());
            dispatch(setIsBraincareUser(false));

            fetchApplicationsHandler();

            setIsOpenDropdown(false);

            setAccount(undefined);
            setIsSubmitting(false);

            navigate('/');
          })
          .catch(handleCorporateLoginError);
      })
      .catch(handleCorporateLoginError);
  };

  const onSubmitLoginDialog = (formData) => {
    setIsSubmitting(true);

    if (account.physio_org_id) {
      corporateLogin(formData);
    } else {
      personalLogin(formData);
    }
  };

  const renderDropdownActiveAccountAvatar = () => {
    if (isBraincareUser) {
      return (
        <ActiveAccountItem
          photo={BCAvatar}
          title={`${profile.first_name} ${profile.last_name}`}
          subtitle={translate('auth.accounts-dropdown.label-sub-dropdown-braincare')}
          buttonText={<I18n path="auth.accounts-dropdown.button-account-config" />}
          onClick={() => {
            setIsOpenDropdown(false);
            navigate('/account/me/edit/info');
          }}
          data-testid="active-account-item"
        />
      );
    }

    return activeOrganization.id !== -1 ? (
      <ActiveAccountItem
        photo={activeOrganization.photo}
        title={activeOrganization.name}
        subtitle={profile.email}
        buttonText={<I18n path="auth.accounts-dropdown.button-account-config" />}
        onClick={() => {
          setIsOpenDropdown(false);
          navigate('/account/member/edit/info');
        }}
        data-testid="active-account-item"
      />
    ) : (
      <ActiveAccountItem
        photo={profile.photo}
        title={`${profile.first_name} ${profile.last_name}`}
        subtitle={profile.email}
        buttonText={<I18n path="auth.accounts-dropdown.button-account-config" />}
        onClick={() => {
          setIsOpenDropdown(false);
          navigate('/account/me/edit/info');
        }}
        data-testid="active-account-item"
      />
    );
  };

  const renderDropdownItems = () => (
    <DropdownMenu className={styles.dropdownMenu} isOpen={isOpenDropdown}>
      {renderDropdownActiveAccountAvatar()}

      {(activeOrganization.id !== -1 || isBraincareUser) && (
        <>
          <Text className={styles.accountSubtitle} isPrimary>
            <I18n path="auth.accounts-dropdown.title-sub-dropdown-personal" />
          </Text>
          <AccountItem
            image={profile.photo}
            title={`${profile.first_name} ${profile.last_name}`}
            subtitle={<I18n path="auth.accounts-dropdown.title-sub-personal" />}
            onClick={() => {
              setIsOpenDropdown(false);
              onClickPersonalAccount(profile);
            }}
            data-testid="personal-account-item"
          />
        </>
      )}

      {!isBraincareUser && Boolean(braincarePermissions.length) && (
        <>
          <Text className={styles.accountSubtitle} isPrimary>
            <I18n path="auth.accounts-dropdown.title-sub-dropdown-braincare" />
          </Text>
          <AccountItem
            image={BCAvatar}
            title={`${profile.first_name} ${profile.last_name}`}
            subtitle={<I18n path="auth.accounts-dropdown.label-sub-dropdown-braincare" />}
            onClick={() => {
              setIsOpenDropdown(false);
              onClickBraincareUser();
            }}
            data-testid="braincare-account-item"
          />
        </>
      )}

      {((organizations.length > 1 && activeOrganization.id !== -1) ||
        (organizations.length > 0 && activeOrganization.id === -1)) && (
        // eslint-disable-next-line react/jsx-indent
        <Text isPrimary>
          <I18n path="auth.accounts-dropdown.title-sub-dropdown-accounts" />
        </Text>
      )}

      {organizations
        .filter((item) => item.id !== activeOrganization.id)
        .map((item) => (
          <AccountItem
            key={item.id}
            image={item.photo}
            title={item.name}
            subtitle={item.address}
            onClick={() => {
              setIsOpenDropdown(false);
              setAccount(item);
            }}
            data-testid={`organization-account-item-${item.id}`}
          />
        ))}

      {organizations.length > 0 && <Separator />}

      <Link to="/auth/logout">
        <Button className={styles.buttonLogout} type="danger" isCentralized>
          <I18n path="auth.accounts-dropdown.button-logout" />
        </Button>
      </Link>
    </DropdownMenu>
  );

  return (
    <Navbar companyLogo={BraincareLogo} companyLogoText="Braincare logo" brandHref="/">
      <Notifications data-testid="notifications-item" />
      <Apps applications={applications} isLoading={isLoadingApplications} data-testid="apps-item" />
      <div className={styles.separatorVertical} />
      <ClickOutside onOutsideClick={() => setIsOpenDropdown(false)}>
        <Dropdown>
          <DropdownToggle onClick={() => setIsOpenDropdown(!isOpenDropdown)} data-testid="menu-toggle">
            <NavbarProfile
              hasOrganization={activeOrganization.id !== -1 || isBraincareUser}
              profileImage={profile.photo}
              profileAlt="Member profile photo"
              userName={`${profile.first_name} ${profile.last_name}`}
              universityName={
                !isBraincareUser ? activeOrganization.name : translate('auth.accounts-dropdown.navbar-org-braincare')
              }
              universityAlt="Organization image"
              universityFlag={!isBraincareUser ? activeOrganization.photo : BCAvatar}
              data-testid="authenticated-profile"
            />
          </DropdownToggle>
          {renderDropdownItems()}
          <LoginDialog
            organization={getSelectedOrganization()}
            isOpen={account !== undefined}
            onClose={() => {
              setAccount(undefined);
              setErrors(new Map());
            }}
            isSubmitting={isSubmitting}
            onSubmit={(data) => onSubmitLoginDialog(data)}
            errors={errors}
          />
        </Dropdown>
      </ClickOutside>
    </Navbar>
  );
}

export default Menu;
