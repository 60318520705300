import PropTypes from 'prop-types';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Text from 'components/atoms/text';
import Checkbox from 'components/atoms/checkbox';
import Avatar from 'components/atoms/avatar';
import Button from 'components/atoms/button';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

const MembersSelectDialog = ({
  title,
  allMembers,
  selectedIds,
  isSavingMembers,
  show,
  onCheckboxClick,
  onDialogToggle,
  onAddClick,
}) => {
  const memberList = allMembers.map((member) => (
    <label
      key={`member-${member.id}`}
      htmlFor={member.id}
      className={styles.toggleMember}
      data-testid={`member-${member.id}`}
    >
      <div className={styles.profileCheckbox}>
        <Avatar
          size="small"
          image={member.user.photo}
          description="Member profile image"
          className={styles.profileCheckboxAvatar}
        />
        <Text className={styles.profileCheckboxText}>{`${member.user.first_name} ${member.user.last_name}`}</Text>
      </div>
      <Checkbox
        id={`member-${member.id}`}
        isChecked={selectedIds.includes(member.id)}
        onChange={() => {}}
        onClick={() => onCheckboxClick(member.id)}
      />
    </label>
  ));

  return (
    <Dialog isOpen={show}>
      <Header onClose={onDialogToggle}>{title}</Header>
      <Body>
        <div className={styles.profileCheckboxWrapper}>{memberList}</div>
      </Body>
      <Footer className={styles.dialogFooter}>
        <Text className={styles.memberCountText} data-testid="dialog-member-counter">
          {selectedIds.length}&nbsp;
          <I18n
            path="organization.organization-units-members.box-counter"
            props={{ s: selectedIds.length > 1 ? 's' : '' }}
          />
        </Text>
        <div className={styles.dialogButtons}>
          <Button
            isBlock
            onClick={onDialogToggle}
            type="ghost"
            className={styles.cancel}
            data-testid="dialog-button-cancel"
          >
            <I18n path="organization.organization-units-members.button-cancel-box" />
          </Button>
          <Button
            isBlock
            type="primary"
            onClick={onAddClick}
            isLoading={isSavingMembers}
            className={styles.addMemberButton}
            data-testid="dialog-button-save"
          >
            <I18n path="organization.organization-units-members.button-save-box" />
          </Button>
        </div>
      </Footer>
    </Dialog>
  );
};

MembersSelectDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  allMembers: PropTypes.instanceOf(Array),
  selectedIds: PropTypes.instanceOf(Array),
  isSavingMembers: PropTypes.bool,
  show: PropTypes.bool,
  onCheckboxClick: PropTypes.func.isRequired,
  onDialogToggle: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

MembersSelectDialog.defaultProps = {
  title: undefined,
  allMembers: [],
  selectedIds: [],
  isSavingMembers: false,
  show: false,
};

export default MembersSelectDialog;
