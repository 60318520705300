import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const ListItem = ({ className, children }) => <li className={classNames(styles.item, className)}>{children}</li>;

ListItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ListItem.defaultProps = {
  className: undefined,
};

export default ListItem;
