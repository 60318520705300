import { getPasswordValidators } from 'utils/password-validators';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

function PasswordRequirements({ nonFulfilledRules, passwordPolicy }) {
  const validators = getPasswordValidators(passwordPolicy);

  const nonFulfilledRulesMap = new Map();

  if (nonFulfilledRules) {
    nonFulfilledRules.forEach((field) => nonFulfilledRulesMap.set(field, true));
  }

  return (
    <>
      <p data-testid="base-text">
        <I18n path="organization.password-rules.password-requirements-base-text" />
      </p>
      <ul>
        {validators.map((validator) => {
          let variant = '';

          if (nonFulfilledRules) {
            variant = nonFulfilledRulesMap.has(validator.policyCode) ? 'error' : 'success';
          }

          if (validator.message) {
            return (
              <li key={validator.policyCode} className={styles[variant]} data-testid={`rule-${validator.policyCode}`}>
                {validator.message}
              </li>
            );
          }

          return null;
        })}
      </ul>
    </>
  );
}

export default PasswordRequirements;
