import App from 'components/templates/app';
import LogList from 'components/organisms/LogList';
import I18n from 'utils/i18n';

const HOME = <I18n path="logs.breadcrumbs.home" />;
const TITLE = <I18n path="logs.breadcrumbs.activities-report" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/logs',
    title: TITLE,
    isActive: true,
  },
];

const Reports = () => (
  <App title={TITLE} breadcrumbs={breadcrumbs}>
    <LogList />
  </App>
);

export default Reports;
