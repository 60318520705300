import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Title from 'components/atoms/auth-title';
import Section from 'components/atoms/section';
import Label from 'components/atoms/input-label';
import InputGroup from 'components/atoms/input-group';
import Input from 'components/atoms/input';
import Button from 'components/atoms/button';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Dialog from 'components/atoms/dialog';
import DialogHeader from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import DialogFooter from 'components/atoms/dialog-footer';
import Text from 'components/atoms/text';
import InputIcon from 'components/molecules/input-icon';
import LabeledInput from 'components/molecules/labeled-input';
import Loader from 'components/molecules/section-loader';
import Card, { Header, Footer } from 'components/molecules/device-card';
import Pagination from 'components/organisms/pagination';
import { SlideFadeInContainer } from 'utils/transitions';
import I18n, { translate } from 'utils/i18n';
import formatDate from 'utils/format-full-date';
import formatTime from 'utils/format-time';
import { DEVICES, DEVICE_ICON, DEVICE_STATUS } from 'utils/devices';
import { confirmDevice, fetchBraincareDevices } from 'api/portal';
import { useDispatch } from 'react-redux';
import { addToast } from 'actions/toasts';
import styles from './styles.module.css';

const HOME = <I18n path="devices.breadcrumbs.home" />;
const MANAGE_DEVICES = <I18n path="devices.breadcrumbs.manage-devices" />;
const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const DEVICE_CONFIRM_ERROR = (
  <I18n path="organization.organization-devices-confirm-box.text-box-device-confirm-error" />
);
const LOAD_DEVICES_ERROR = <I18n path="organization.organization-devices.load-devices-error" />;
const LOAD_PENDING_DEVICES_ERROR = <I18n path="organization.organization-devices.load-pending-devices-error" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/organization/devices',
    title: MANAGE_DEVICES,
    isActive: true,
  },
];

const PAGE_SIZE = 8;

function BraincareDevices() {
  const [filterValue, setFilterValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [devices, setDevices] = useState([]);
  const [pendingDevices, setPendingDevices] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDeviceDialog, setShowConfirmDeviceDialog] = useState(false);
  const [showConfirmedDeviceDialog, setShowConfirmedDeviceDialog] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [loadDevicesError, setLoadDevicesError] = useState(false);
  const [confirmDeviceError, setConfirmDeviceError] = useState(false);
  const [confirmDeviceErrorMessage, setConfirmDeviceErrorMessage] = useState('');
  const [udi, setUdi] = useState('');
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const fetchDevicesHandler = (searchParams) => {
    setIsLoading(true);
    setLoadDevicesError(false);

    fetchBraincareDevices(searchParams)
      .then((response) => {
        const { data } = response;

        const devices = data.results;
        setDevices(devices);
        setRows(data.count);
        setCurrentPage(Number(searchParams.get('page')));
        setIsLoading(false);
      })
      .catch(() => {
        dispatch(addToast('error', LOAD_DEVICES_ERROR));
        setLoadDevicesError(true);
        setIsLoading(false);
      });

    const searchParamsPending = new URLSearchParams();

    searchParamsPending.set('current_status', 'PENDING');

    fetchBraincareDevices(searchParamsPending)
      .then((response) => {
        const { data } = response;
        setPendingDevices(data.count);
      })
      .catch(() => {
        dispatch(addToast('error', LOAD_PENDING_DEVICES_ERROR));
      });
  };

  const fetchDevicesWithFilter = (page) => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', PAGE_SIZE);
    searchParams.set('exclude_current_status', 'PENDING');
    searchParams.set('search_udi', filterValue);
    searchParams.set('device_type', selectedDevice);
    searchParams.set('current_status', selectedStatus);

    fetchDevicesHandler(searchParams);
  };

  const fetchDevicesWithoutFilter = () => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', PAGE_SIZE);
    searchParams.set('exclude_current_status', 'PENDING');
    searchParams.set('search_udi', '');
    searchParams.set('device_type', '');
    searchParams.set('current_status', '');

    fetchDevicesHandler(searchParams);
  };

  useEffect(() => {
    fetchDevicesWithFilter(1);
  }, []);

  const handleClearFilterClick = () => {
    setFilterValue('');
    setSelectedStatus('');
    setSelectedDevice('');
    fetchDevicesWithoutFilter();
  };

  const handleConfirmUDIClick = () => {
    if (!udi) {
      setConfirmDeviceErrorMessage(FILL_REQUIRED);
      return;
    }

    setIsConfirming(true);

    confirmDevice({ udi })
      .then(() => {
        fetchDevicesWithFilter(1);

        setIsConfirming(false);
        setShowConfirmDeviceDialog(false);

        const newPendingDevices = pendingDevices - 1;
        setPendingDevices(newPendingDevices);

        if (newPendingDevices > 0) {
          setShowConfirmedDeviceDialog(true);
        }

        setUdi('');
        setConfirmDeviceError(false);
      })
      .catch(() => {
        setIsConfirming(false);
        setConfirmDeviceError(true);
        setConfirmDeviceErrorMessage(DEVICE_CONFIRM_ERROR);
      });
  };

  // eslint-disable-next-line class-methods-use-this
  const formPreventDefault = (e) => {
    e.preventDefault();
  };

  // eslint-disable-next-line class-methods-use-this
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const renderConfirmDeviceDialog = () => (
    <Dialog isOpen={showConfirmDeviceDialog} data-testid="confirm-device-dialog">
      <DialogHeader onClose={() => setShowConfirmDeviceDialog(false)}>
        <I18n path="organization.organization-devices-confirm-box.title-box-confirm-devices" />
      </DialogHeader>
      <Body>
        <Text>
          <I18n path="organization.organization-devices-confirm-box.text-box-confirm-devices" />
        </Text>
        <LabeledInput
          label={<I18n path="organization.organization-devices-confirm-box.input-device-uid-box" />}
          htmlFor="udi"
          data-testid="udi-labeled-input"
        >
          <Input
            id="udi"
            name="udi"
            value={udi}
            onChange={(event) => setUdi(event.target.value)}
            hasError={Boolean(confirmDeviceErrorMessage)}
            errorMessage={confirmDeviceErrorMessage}
            data-testid="udi-input"
          />
        </LabeledInput>
        {confirmDeviceError && (
          <Text data-testid="help-text-error">
            <I18n path="organization.organization-devices-confirm-box.text-box-device-confirm-error-persists" />
          </Text>
        )}
      </Body>
      <DialogFooter>
        <Button isBlock onClick={() => setShowConfirmDeviceDialog(false)} type="ghost">
          <I18n path="organization.organization-devices-confirm-box.button-cancel" />
        </Button>
        <Button
          isBlock
          type="primary"
          onClick={handleConfirmUDIClick}
          isLoading={isConfirming}
          data-testid="confirm-udi-button"
        >
          <I18n path="organization.organization-devices-confirm-box.button-device-confirm-box" />
        </Button>
      </DialogFooter>
    </Dialog>
  );

  const renderDeviceConfirmed = () => (
    <Dialog isOpen={showConfirmedDeviceDialog} data-testid="confirmed-device-dialog">
      <DialogHeader type="success" onClose={() => setShowConfirmedDeviceDialog(false)}>
        <I18n path="organization.organization-devices-confirm-box.title-box-confirm-devices-success" />
      </DialogHeader>
      <Body>
        <Text>
          <I18n path="organization.organization-devices-confirm-box.text-box-confirm-devices-success" />
        </Text>
      </Body>
      <DialogFooter>
        {pendingDevices > 0 && (
          <Button
            isBlock
            onClick={() => {
              setShowConfirmDeviceDialog(true);
              setShowConfirmedDeviceDialog(false);
            }}
            isLoading={false}
            data-testid="next-device-button"
          >
            <I18n path="organization.organization-devices-confirm-box.button-device-confirm-another-box" />
          </Button>
        )}
        <Button isBlock type="primary" onClick={() => setShowConfirmedDeviceDialog(false)}>
          <I18n path="organization.organization-devices-confirm-box.button-device-confirm-close" />
        </Button>
      </DialogFooter>
    </Dialog>
  );

  const renderDevices = () => {
    if (loadDevicesError) {
      return (
        <Text isError data-testid="load-devices-error">
          {LOAD_DEVICES_ERROR}
        </Text>
      );
    }

    if (devices.length === 0) {
      return (
        <div className={styles.noActiveDevices} data-testid="no-devices-message">
          <I18n path="organization.organization-devices.no-active-devices" />
        </div>
      );
    }

    return devices.map((device) => (
      <Card
        key={`device-card-${device.udi}`}
        isBlocked={device.status === 'BLOCKED' || device.status === 'SUSPENDED'}
        data-testid={`device-card-${device.id}`}
      >
        <Header
          title={device.udi}
          type={device.device_type}
          inUse={device.current_status === 'IN USE'}
          status={device.current_status}
          icon={DEVICE_ICON[device.device_type]}
        />
        <Footer
          date={
            <I18n
              path="organization.organization-devices.card-device-last-update"
              props={{
                last_update: `${formatDate(device.modified)} ${formatTime(device.modified)}`,
              }}
            />
          }
        />
      </Card>
    ));
  };

  return (
    <App breadcrumbs={breadcrumbs}>
      <Section className={styles.title}>
        <Title>
          <I18n path="organization.organization-devices.title-main-devices" />
        </Title>
        {pendingDevices > 0 && (
          <Button onClick={() => setShowConfirmDeviceDialog(true)} data-testid="confirm-device-button">
            <I18n path="organization.organization-devices.button-device-confirm" props={{ nr: pendingDevices }} />
          </Button>
        )}
      </Section>

      {renderConfirmDeviceDialog()}
      {renderDeviceConfirmed()}
      <Section className={styles.container}>
        <form autoComplete="off" onSubmit={formPreventDefault} onKeyDown={handleKeyDown}>
          <div className={styles.filters}>
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="filter">
                <I18n path="organization.organization-devices.input-filter-uid" />
              </Label>
              <InputIcon
                id="filter"
                placeholder={translate('organization.organization-devices.text-devices-uid')}
                onChange={(event) => setFilterValue(event.target.value)}
                onIconClick={() => fetchDevicesWithFilter(1)}
                value={filterValue}
                icon="search"
                data-testid="udi-input-icon"
              />
            </InputGroup>
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="status">
                <I18n path="organization.organization-devices.input-filter-status" />
              </Label>
              <Select
                id="status"
                onChange={(event) => setSelectedStatus(event.target.value)}
                value={selectedStatus}
                data-testid="status-select"
              >
                <Option value="">{translate('organization.organization-devices.dropdown-filter-status')}</Option>
                {DEVICE_STATUS.map((status) => (
                  <Option key={`filter-${status.id}`} value={status.id}>
                    {status.text}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="device">
                <I18n path="organization.organization-devices.input-filter-type" />
              </Label>
              <Select
                id="device"
                onChange={(event) => setSelectedDevice(event.target.value)}
                value={selectedDevice}
                data-testid="device-select"
              >
                <Option value="">{translate('organization.organization-devices.dropdown-filter-type')}</Option>
                {DEVICES.map((device) => (
                  <Option key={`device-${device.id}`} value={device.id}>
                    {device.text}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <Button className={styles.applyFilter} onClick={handleClearFilterClick} data-testid="reset-filters-button">
              <I18n path="organization.organization-devices.button-filter-reset" />
            </Button>
            <Button
              className={styles.applyFilter}
              onClick={() => fetchDevicesWithFilter(1)}
              type="primary"
              data-testid="apply-filters-button"
            >
              <I18n path="organization.organization-devices.button-filter-apply" />
            </Button>
          </div>
        </form>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <SlideFadeInContainer>
              <Section className={styles.devicesContainer}>{renderDevices()}</Section>
            </SlideFadeInContainer>
            <Pagination
              rows={rows}
              rowsPerPage={PAGE_SIZE}
              currentPage={currentPage}
              onPageSelect={fetchDevicesWithFilter}
              maxPagesDisplay={3}
            />
          </>
        )}
      </Section>
    </App>
  );
}

export default BraincareDevices;
