import PropTypes from 'prop-types';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import Dialog from 'components/atoms/dialog';
import DialogBody from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Header from 'components/atoms/dialog-header';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

function LogDialog({ isOpen, onClose, log }) {
  return (
    <Dialog size="large" isOpen={isOpen} data-testid="log-dialog">
      <Header onClose={onClose}>
        <I18n path="logs.activities-report.dialog.title" />
      </Header>
      <DialogBody>
        <SubSubtitle>
          <I18n path="logs.activities-report.dialog.title-id" />
        </SubSubtitle>
        {log && log['@ptr'] ? <Text className={styles.idText}>{log['@ptr']}</Text> : ''}
        <SubSubtitle>
          <I18n path="logs.activities-report.dialog.title-user-agent" />
        </SubSubtitle>
        {log && log.user_agent ? <Text>{log.user_agent}</Text> : ''}
        <SubSubtitle>
          <I18n path="logs.activities-report.dialog.title-data" />
        </SubSubtitle>
        {log && log.additional_data ? <Text>{log.additional_data}</Text> : ''}
      </DialogBody>
      <Footer>
        <Button onClick={onClose} type="primary">
          <I18n path="logs.activities-report.dialog.close-button-text" />
        </Button>
      </Footer>
    </Dialog>
  );
}

LogDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  log: PropTypes.object,
};

export default LogDialog;
