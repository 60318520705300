import { useEffect } from 'react';
import classNames from 'classnames';

import Icon from 'components/atoms/icon';
import IconButton from 'components/atoms/icon-button';
import Text from 'components/atoms/text';

import styles from './styles.module.css';

let timer;

const TYPE = {
  error: styles.error,
  warning: styles.warning,
  success: styles.success,
};

const ICON = {
  error: 'warning',
  warning: 'info',
  success: 'checkmark',
};

function Snackbar({ onClose = () => {}, type = 'error', children, ...props }) {
  useEffect(() => {
    clearTimeout(timer);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={classNames(styles.snackbar)} {...props}>
      <div className={classNames(styles.iconWrapper, TYPE[type])}>
        <Icon name={ICON[type]} className={styles.icon} />
      </div>
      <Text className={styles.text}>{children}</Text>
      <IconButton name="cross" onClick={onClose} iconClassName={classNames(styles.closeIcon)} />
    </div>
  );
}

export default Snackbar;
