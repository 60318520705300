export default (value) => {
  const date = new Date(value);
  if (date instanceof Date && !Number.isNaN(date.getTime())) {
    return true;
  }
  return false;
};

export const validateISOCompleteDate = (value) => {
  // check if value is a String having the form: YYYY-MM-DD
  if (typeof value === 'string') {
    let arr = value.split('-');

    if (arr.length !== 3) return false;

    arr = arr.filter((el) => Number.isInteger(Number(el)));
    if (arr.length !== 3) return false;

    return !Number.isNaN(new Date(value));
  }
  return false;
};
