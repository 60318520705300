import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  roles: [],
  selectedMember: {
    id: -1,
  },
  selectedUnit: {
    id: -1,
  },
  selectedMobileDevice: {
    org_device_id: '-1',
    name: '',
  },
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    clearOrganizationData() {
      return initialState;
    },
    clearOrganizationSelectedMember(state) {
      return {
        ...state,
        selectedMember: initialState.selectedMember,
      };
    },
    clearOrganizationSelectedUnit(state) {
      return {
        ...state,
        selectedUnit: initialState.selectedUnit,
      };
    },
    clearOrganizationSelectedMobileDevice(state) {
      return {
        ...state,
        selectedMobileDevice: initialState.selectedMobileDevice,
      };
    },
    setOrganizationRoles(state, action) {
      return {
        ...state,
        roles: action.payload,
      };
    },
    setOrganizationSelectedMember(state, action) {
      return {
        ...state,
        selectedMember: {
          ...state.selectedMember,
          ...action.payload,
        },
      };
    },
    setOrganizationSelectedUnit(state, action) {
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          ...action.payload,
        },
      };
    },
    setOrganizationSelectedMobileDevice(state, action) {
      return {
        ...state,
        selectedMobileDevice: {
          ...state.selectedMobileDevice,
          ...action.payload,
        },
      };
    },
  },
});

export const {
  clearOrganizationData,
  clearOrganizationSelectedMember,
  clearOrganizationSelectedUnit,
  clearOrganizationSelectedMobileDevice,
  setOrganizationRoles,
  setOrganizationSelectedMember,
  setOrganizationSelectedUnit,
  setOrganizationSelectedMobileDevice,
} = organizationSlice.actions;

export default organizationSlice.reducer;
