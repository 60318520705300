import PropTypes from 'prop-types';
import AppMenu from 'components/templates/app-menu';
import I18n from 'utils/i18n';
import { useParams } from 'react-router-dom';

const HOME = <I18n path="braincare.breadcrumbs.home" />;
const USERS = <I18n path="braincare.breadcrumbs.users" />;
const TITLE = <I18n path="braincare.breadcrumbs.users-edit" />;

const INFO = <I18n path="braincare.users-edit.menu-item-user-info" />;
const SUSPEND = <I18n path="braincare.users-edit.menu-item-suspend" />;

const App = ({ user, children }) => {
  const params = useParams();

  const { pathname } = window.location;

  const { userId } = params;

  const INFO_URL = `/braincare/users/${userId}/info`;
  const SUSPEND_URL = `/braincare/users/${userId}/suspend`;

  const SIDE_MENU = [
    {
      href: INFO_URL,
      text: INFO,
      isActive: pathname === INFO_URL,
    },
    {
      href: SUSPEND_URL,
      text: SUSPEND,
      isActive: pathname === SUSPEND_URL,
    },
  ];

  const getBreadcrumb = (path) => {
    switch (path) {
      case INFO_URL:
        return INFO;
      case SUSPEND_URL:
        return SUSPEND;
      default:
        return '';
    }
  };

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/users',
      title: USERS,
    },
    {
      path: pathname,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];

  const title = user.id !== -1 ? user.name : TITLE;

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={title} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.instanceOf(Object),
};

App.defaultProps = {
  user: {},
};

export default App;
