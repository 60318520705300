import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiInputLabel-formControl': {
    color: theme.palette.primary.main,
    transform: 'translate(14px, -9px) scale(0.75)',
    '&.Mui-error': {
      color: theme.palette.error.light,
    },
    backgroundColor: theme.palette.primary.contrastText,
  },
  '& .MuiInputBase-formControl': {
    fontSize: 13,
    color: theme.palette.colorGreyDark.light,
    borderRadius: 40,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.colorGrey.light}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.colorGreyDark.light}`,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.error.light}`,
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.colorGreyLight.light}`,
    },
    '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.colorGreyLight.light}`,
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.primary.light}`,
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px',
    },
  },
}));

export default TextField;
