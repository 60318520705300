import { Portal } from 'react-portal';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Dialog = ({ isOpen, children, className, size, shouldOverflow, ...props }) => (
  <Portal>
    <div className={classNames(styles.dialog, { [styles.open]: isOpen })} {...props}>
      <div className={classNames(styles.box, styles[size], !shouldOverflow ? styles.overflowHidden : '', className)}>
        <div className={!shouldOverflow ? styles.overflowWrapper : ''}>{children}</div>
      </div>
    </div>
  </Portal>
);

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  shouldOverflow: PropTypes.bool,
};

Dialog.defaultProps = {
  shouldOverflow: false,
  isOpen: false,
  className: undefined,
  size: 'small',
};

export default Dialog;
