import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Option = ({ value, children, onClick, selected, ...props }) => (
  <option
    className={classNames(styles.option, { [styles.selected]: selected })}
    onClick={onClick}
    value={value}
    {...props}
  >
    {children}
  </option>
);

Option.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

Option.defaultProps = {
  onClick: () => {},
  selected: false,
};

export default Option;
