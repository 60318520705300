import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input from 'components/atoms/input';
import Dropdown from 'components/molecules/dropdown';
import LabeledInput from 'components/molecules/labeled-input';
import DropdownItem from 'components/molecules/account-item';
import Spinner from 'components/atoms/spinner';
import styles from './styles.module.css';
import ProfileTag from '../profile-tag';

function InputMulti({
  className,
  label,
  selected,
  hasError,
  errorMessage,
  onSearch,
  onSelect,
  onRemoveMember,
  ...props
}) {
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);

  const handleSelectItem = (item) => {
    setShowDropdown(false);
    setSearch('');
    onSelect(item);
  };

  const handleSearch = (keyword) => {
    setIsInputDisabled(true);

    onSearch(keyword)
      .then((response) => {
        setIsInputDisabled(false);
        setIsLoadingResults(false);

        const { data } = response;
        const results = data.results ? [...data.results] : [...data];

        setResults(results);

        if (!results.length) {
          setShowDropdown(false);
        }
      })
      .catch(() => {
        setIsInputDisabled(false);
        setIsLoadingResults(false);
        setShowDropdown(false);
      });
  };

  const handleSearchChange = (event) => {
    const keyword = event.target.value;

    setSearch(keyword);

    clearTimeout(searchTimeout);

    setSearchTimeout(
      setTimeout(() => {
        handleSearch(keyword);
      }, 1000),
    );

    if (keyword.trim() === '') {
      setShowDropdown(false);
      setIsLoadingResults(false);
      return;
    }

    setIsLoadingResults(true);
    setShowDropdown(true);
  };

  const renderDropdown = () => (
    <Dropdown className={classNames(styles.searchDropdown, { [styles.dropdownOpen]: showDropdown })}>
      {isLoadingResults ? (
        <Spinner data-testid="spinner" />
      ) : (
        results.map((item) => (
          <DropdownItem
            key={`member-${item.user_id}${item.organization_id}`}
            image={item.photo}
            title={item.full_name}
            subtitle={item.organization_name || ''}
            className={styles.dropdownItem}
            onClick={() => handleSelectItem(item)}
            data-testid={`member-${item.user_id}${item.organization_id}`}
          />
        ))
      )}
    </Dropdown>
  );

  return (
    <div className={classNames(styles.searchWrapper, className)} {...props}>
      <LabeledInput className={styles.inputGroup} isDisabled={isInputDisabled} label={label} htmlFor="input-multi">
        <div className={classNames(styles.searchInput, { [styles.hasError]: hasError })}>
          {selected.map((item) => (
            <ProfileTag
              key={`selected-member-${item.id}${item.orgId}`}
              name={item.name}
              avatar={item.image}
              iconName="cross"
              iconClick={() => onRemoveMember(item.id)}
              className={styles.profileTag}
              data-testid={`selected-member-${item.id}${item.orgId}`}
            />
          ))}
          <Input
            id="input-multi"
            isDisabled={isInputDisabled}
            onChange={handleSearchChange}
            wrapperClassName={styles.inputSearch}
            className={styles.inputSearchBorder}
            value={search}
            data-testid="input-multi"
          />
        </div>
      </LabeledInput>
      {renderDropdown()}
      {hasError && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
}

InputMulti.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  selected: PropTypes.instanceOf(Array),
  hasError: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemoveMember: PropTypes.func.isRequired,
};

InputMulti.defaultProps = {
  className: undefined,
  label: '',
  selected: [],
  hasError: false,
  errorMessage: '',
};

export default InputMulti;
