import { useState } from 'react';
import AppWizard from 'components/templates/app-wizard';
import Section from 'components/atoms/section';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import { useSearchParams } from 'react-router-dom';
import ConfirmationStatusUpdate from '../steps/ConfirmationStatusUpdate';
import OperationConcluded from '../steps/MobileDeviceSummary';

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_DEVICES = <I18n path="organization.breadcrumbs.manage-devices" />;
const CURRENT_NAV = <I18n path="organization.breadcrumbs.edit-organization-mobile-device" />;

const SUBTITLE_STATUS_UPDATED = <I18n path="organization.organization-mobile-devices-edit.title-sub-device-updated" />;
const TEXT_STATUS_UPDATED = <I18n path="organization.organization-mobile-devices-edit.text-device-updated" />;

const STEP_1 = <I18n path="organization.organization-mobile-devices-create.wizard-confirmation" />;
const STEP_2 = <I18n path="organization.organization-mobile-devices-create.wizard-end" />;

const STEPS = [STEP_1, STEP_2];

export default function MobileDeviceStatusUpdate({ device }) {
  const { pathname, search } = window.location;

  const [step, setStep] = useState(0);
  const [updatedDevice, setUpdatedDevice] = useState({});

  const [searchParams] = useSearchParams();

  const status = searchParams.get('status');

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <ConfirmationStatusUpdate
            device={device}
            status={status}
            onClickNextStep={(device) => {
              setStep(1);
              setUpdatedDevice(device);
            }}
          />
        );
      case 1:
        return (
          <OperationConcluded subtitle={SUBTITLE_STATUS_UPDATED} text={TEXT_STATUS_UPDATED} device={updatedDevice} />
        );
      default:
        return null;
    }
  };

  const TITLE = (
    <I18n props={{ name: device.name }} path="organization.organization-mobile-devices-edit.title-edit-device" />
  );

  const BREADCRUMBS = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/org-mobile-devices/devices',
      title: MANAGE_DEVICES,
    },
    {
      path: `${pathname}${search}`,
      title: CURRENT_NAV,
      isActive: true,
    },
  ];

  return (
    <AppWizard title={TITLE} breadcrumbs={BREADCRUMBS} steps={STEPS} currentStep={step}>
      <SlideRightFadeContainer>
        <Section>{renderStep()}</Section>
      </SlideRightFadeContainer>
    </AppWizard>
  );
}
