import { useEffect, useState } from 'react';
import { addToast } from 'actions/toasts';
import Auth from 'components/templates/auth';
import Section from 'components/atoms/section';
import AuthTitle from 'components/atoms/auth-title';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import LinkItem from 'components/atoms/link-item';
import LabeledInput from 'components/molecules/labeled-input';
import InputIcon from 'components/molecules/input-icon';
import I18n from 'utils/i18n';
import { SlideFadeInContainer, SlideRightFadeContainer } from 'utils/transitions';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from 'components/molecules/section-loader';
import { validatePassword as applyPolicyValidation } from 'utils/password-validators';
import { useDispatch } from 'react-redux';
import { confirmPassword, fetchPasswordPolicy } from 'api/portal';
import styles from '../styles.module.css';
import RuledPasswordInput from '../../../components/organisms/RuledPasswordInput';

const MISMATCH_PASSWORD_ERROR = <I18n path="messages.mismatch-error" />;
const REQUIRED_FIELD_ERROR = <I18n path="account.edit-password.fill-required" />;
const DEFINE_SUCCESS_MESSAGE = <I18n path="auth.define-password.define-success-message" />;
const DEFINE_INVALID_TOKEN_MESSAGE = <I18n path="auth.define-password.define-invalid-token-message" />;
const DEFINE_FAILURE_MESSAGE = <I18n path="auth.define-password.define-failure-message" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

function DefinePassword() {
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isConfirmedPasswordVisible, setIsConfirmedPasswordVisible] = useState(false);
  const [errors, setErrors] = useState(new Map());
  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);
  const [hasReadTermsAndConditions, setHasReadTermsAndConditions] = useState(false);
  const [hasReadPrivacyPolicy, setHasReadPrivacyPolicy] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [passwordPolicy, setPasswordPolicy] = useState(undefined);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    const newUser = searchParams.get('new');

    const { uid1, uid2, token } = params;

    fetchPasswordPolicy({ uid_1: uid1, uid_2: uid2, token })
      .then((response) => {
        const { data } = response;

        const currentStep = newUser ? 1 : 2;

        setPasswordPolicy(data.password_policy);
        setCurrentStep(currentStep);
      })
      .catch(() => {
        dispatch(addToast('error', DEFINE_INVALID_TOKEN_MESSAGE));
        navigate('/auth/direct-login');
      });
  }, []);

  const validatePassword = (password) => {
    const policyErrors = applyPolicyValidation(passwordPolicy, password);
    setPasswordPolicyErrors(policyErrors);

    if (policyErrors.length === 0) {
      errors.delete('password');
    } else {
      errors.set('password', policyErrors);
    }

    if (password) {
      errors.delete('password');
    } else {
      errors.set('password', REQUIRED_FIELD_ERROR);
    }

    if (password === confirmedPassword) {
      errors.delete('confirmedPassword');
    } else {
      errors.set('confirmedPassword', MISMATCH_PASSWORD_ERROR);
    }

    setPassword(password);
    setErrors(errors);
  };

  const validateConfirmedPassword = (confirmedPassword) => {
    if (password === confirmedPassword) {
      errors.delete('confirmedPassword');
    } else {
      errors.set('confirmedPassword', MISMATCH_PASSWORD_ERROR);
    }

    setConfirmedPassword(confirmedPassword);
    setErrors(errors);
  };

  const handleOnSubmit = () => {
    validatePassword(password);
    validateConfirmedPassword(confirmedPassword);

    if (errors.size > 0) {
      dispatch(addToast('error', FIX_FIELDS));
      return;
    }

    const { uid1, uid2, token } = params;

    confirmPassword({ uid_1: uid1, uid_2: uid2, token, password })
      .then(() => {
        dispatch(addToast('success', DEFINE_SUCCESS_MESSAGE));
        navigate('/auth/direct-login');
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        const nonFieldErrors = data.non_field_errors;

        if (Array.isArray(nonFieldErrors)) {
          const policyErrors = nonFieldErrors.map((passwordError) => passwordError.split(']')[0].split('[')[1]);
          setPasswordPolicyErrors(policyErrors);
        }

        dispatch(addToast('error', DEFINE_FAILURE_MESSAGE));
      });
  };

  const renderIsNew = () => (
    <SlideFadeInContainer>
      <Section maxWidth="428px">
        <AuthTitle>
          <I18n path="auth.define-password-new.title" />
        </AuthTitle>
        <Text size="medium">
          <I18n path="auth.define-password-new.text-terms-conditions" />
          &nbsp;
          <LinkItem href="/public/terms-and-service" onClick={() => setHasReadTermsAndConditions(true)} target="_blank">
            <I18n path="auth.define-password-new.text-terms" />
          </LinkItem>
          <I18n path="auth.define-password-new.text-terms-conditions-between" />
          <LinkItem href="/public/privacy-policy" onClick={() => setHasReadPrivacyPolicy(true)} target="_blank">
            <I18n path="auth.define-password-new.text-conditions" />
          </LinkItem>
          <I18n path="auth.define-password-new.text-terms-accept" />.
        </Text>

        <Button
          type="primary"
          isBlock
          isLarge
          onClick={() => setCurrentStep(2)}
          isDisabled={!(hasReadPrivacyPolicy && hasReadTermsAndConditions)}
        >
          <I18n path="auth.define-password-new.button-accept" />
        </Button>
      </Section>
    </SlideFadeInContainer>
  );

  const renderDefinePassword = () => (
    <SlideRightFadeContainer>
      <Section maxWidth="428px">
        <AuthTitle data-testid="title-main-password">
          <I18n path="auth.define-password.title-main-password" />
        </AuthTitle>
        <Text size="small">
          <I18n path="auth.define-password.text-password-format" />
        </Text>

        <RuledPasswordInput
          errors={passwordPolicyErrors}
          hasError={errors.has('password')}
          passwordPolicy={passwordPolicy}
          onChange={(event) => validatePassword(event.target.value)}
          value={password}
        />

        <LabeledInput
          label={<I18n path="auth.define-password.input-confirmed-password" />}
          data-testid="confirm-password-labeled-input"
        >
          <InputIcon
            id="confirmedPassword"
            value={confirmedPassword}
            onChange={(event) => validateConfirmedPassword(event.target.value)}
            onBlur={(event) => validateConfirmedPassword(event.target.value)}
            type={isConfirmedPasswordVisible ? 'text' : 'password'}
            icon={isConfirmedPasswordVisible ? 'eye' : 'eye-blocked'}
            onIconClick={() => setIsConfirmedPasswordVisible(!isConfirmedPasswordVisible)}
            hasError={Boolean(errors && errors.get('confirmedPassword'))}
            errorMessage={errors && errors.get('confirmedPassword')}
            data-testid="confirm-password-input"
          />
        </LabeledInput>

        <Button
          type="primary"
          isBlock
          className={styles.nextButton}
          onClick={handleOnSubmit}
          isLoading={false}
          data-testid="save-button"
        >
          <I18n path="auth.define-password.button-save" />
        </Button>
        <Button isBlock onClick={() => navigate('/auth/direct-login')} data-testid="cancel-button">
          <I18n path="auth.define-password.button-cancel" />
        </Button>
      </Section>
    </SlideRightFadeContainer>
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return renderIsNew();
      case 2:
        return renderDefinePassword();
      default:
        return null;
    }
  };

  return <Auth>{passwordPolicy ? renderCurrentStep() : <Loader />}</Auth>;
}

export default DefinePassword;
