import PropTypes from 'prop-types';
import Icon from 'components/atoms/icon';
import styles from './styles.module.css';

const Item = ({ icon, children }) => (
  <div className={styles.item}>
    {icon && <Icon name={icon} className={styles.itemIcon} />}
    <div className={styles.itemText}>{children}</div>
  </div>
);

Item.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Item.defaultProps = {
  icon: undefined,
};

export default Item;
