import PropTypes from 'prop-types';
import { Container, Icon } from './styles';

const DeviceAggregate = ({ className, icon, value, label }) => (
  <Container className={className}>
    <Icon name={icon} />
    <span>{value}&nbsp;</span>
    <div>{label}</div>
  </Container>
);

DeviceAggregate.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

DeviceAggregate.defaultProps = {
  className: '',
  value: '',
  label: '',
};

export default DeviceAggregate;
