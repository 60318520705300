import { useEffect, useState } from 'react';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import LabeledInput from 'components/molecules/labeled-input';
import Input from 'components/atoms/input';
import AppMenu from 'components/templates/app-menu';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearOrganizationSelectedUnit, setOrganizationSelectedUnit } from 'features/organizationSlice';
import { createUnit } from 'api/portal';
import { addToast } from 'actions/toasts';

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_ORGANIZATION = <I18n path="organization.breadcrumbs.manage-organization" />;
const ADD_UNIT = <I18n path="organization.breadcrumbs.add-unit" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/organization/units',
    title: MANAGE_ORGANIZATION,
  },
  {
    path: 'organization/units/create',
    title: ADD_UNIT,
    isActive: true,
  },
];

const SIDE_MENU = [
  {
    href: '/organization/units/create',
    text: <I18n path="organization.organization-units-edit.menu-unit-info" />,
    isActive: true,
  },
];

const REQUIRED_FIELD = <I18n path="messages.fill-required" />;

function CreateUnit() {
  const [unitName, setUnitName] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [errors, setErrors] = useState(new Map());
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearOrganizationSelectedUnit());
  }, []);

  const handleOnSaveChanges = () => {
    const formErrors = new Map();

    if (!unitName) {
      formErrors.set('name', REQUIRED_FIELD);
    }

    if (!additionalInformation) {
      formErrors.set('additionalInformation', REQUIRED_FIELD);
    }

    if (formErrors.size > 0) {
      setErrors(formErrors);
      return;
    }

    setIsSaving(true);
    setErrors(formErrors);

    createUnit({
      name: unitName,
      additional_information: additionalInformation,
    })
      .then((response) => {
        const { data } = response;

        setIsSaving(false);
        dispatch(setOrganizationSelectedUnit(data));
        navigate(`/organization/units/${data.id}/members`);
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(addToast('error', SAVE_FAILURE));
      });
  };

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={ADD_UNIT} menuItems={SIDE_MENU}>
      <Section>
        <Subtitle>
          <I18n path="organization.organization-units-create.title-sub-unit-create" />
        </Subtitle>
        <Text>
          <I18n path="organization.organization-units-create.text-unit-create-page" />
        </Text>

        <LabeledInput
          label={<I18n path="organization.organization-units-create.input-unit-name" />}
          htmlFor="unit_name"
          data-testid="labeled-input-unit-name"
        >
          <Input
            id="unit_name"
            name="unit-name"
            value={unitName}
            onChange={(event) => setUnitName(event.target.value)}
            hasError={errors.has('name')}
            errorMessage={errors.has('name') ? errors.get('name') : ''}
            data-testid="input-unit-name"
          />
        </LabeledInput>

        <LabeledInput
          label={<I18n path="organization.organization-units-create.input-unit-info" />}
          htmlFor="info"
          data-testid="labeled-input-unit-info"
        >
          <Input
            id="info"
            name="info"
            value={additionalInformation}
            onChange={(event) => setAdditionalInformation(event.target.value)}
            hasError={errors.has('additionalInformation')}
            errorMessage={errors.has('additionalInformation') ? errors.get('additionalInformation') : ''}
            data-testid="input-info"
          />
        </LabeledInput>
      </Section>
      <Button type="primary" isLoading={isSaving} onClick={handleOnSaveChanges} data-testid="button-save">
        <I18n path="organization.organization-units-create.button-save" />
      </Button>
      <Button onClick={() => navigate('/organization/units')} data-testid="button-cancel">
        <I18n path="organization.organization-units-create.button-cancel" />
      </Button>
    </AppMenu>
  );
}

export default CreateUnit;
