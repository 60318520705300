import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text from 'components/atoms/text';
import Icon from 'components/atoms/icon';
import { getStatus, DEVICE_SENSOR, DEVICE_DONGLE, DEVICE_BATTERY } from 'utils/devices';
import styles from './styles.module.css';

const TYPE = {
  SENSOR: DEVICE_SENSOR,
  DONGLE: DEVICE_DONGLE,
  BATTERY: DEVICE_BATTERY,
};

const STATUS_FLAGS = ['trusted', 'normal', 'blocked'];

const Header = ({ icon, type, title, hasNotification, inUse, notificationCount, status, ...props }) => (
  <div className={styles.header} {...props}>
    {STATUS_FLAGS.includes(status) && <div className={classNames(styles.statusFlag, styles[status.toLowerCase()])} />}

    {icon && (
      <div className={styles.deviceIconContainer}>
        {type ? (
          <img src={icon} className={styles.deviceIcon} alt="Device icon" />
        ) : (
          <Icon name={icon} className={styles.deviceIcon} />
        )}
        {inUse && <div className={styles.active} />}
      </div>
    )}

    <div className={styles.headerInfo}>
      <Text className={styles.headerTitle}>
        {title}

        {hasNotification && (
          <div className={styles.warning}>
            <Text className={styles.warningCount}>{notificationCount}</Text>
          </div>
        )}
      </Text>
      {type && (
        <Text className={styles.deviceType}>
          {TYPE[type]} {status && `• ${getStatus(status)}`}
        </Text>
      )}
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  type: PropTypes.string,
  hasNotification: PropTypes.bool,
  inUse: PropTypes.bool,
  notificationCount: PropTypes.number,
  icon: PropTypes.string,
  status: PropTypes.string,
};

Header.defaultProps = {
  hasNotification: false,
  type: undefined,
  inUse: false,
  icon: undefined,
  notificationCount: 0,
  status: undefined,
};

export default Header;
