import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Checkbox from 'components/atoms/input-checkbox';
import Button from 'components/atoms/button';
import Hint from 'components/atoms/input-hint';
import Spinner from 'components/atoms/spinner';
import LabeledInput from 'components/molecules/labeled-input';
import DropdownItem from 'components/molecules/account-item';
import { addToast } from 'actions/toasts';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { fetchMembersEligibleToPinPolicy } from 'api/portal';
import styles from './styles.module.css';

const NO_PIN = <I18n path="organization.organization-mobile-devices-create.pin-policy-options.no-pin" />;
const YEAR_BIRTH_DATE = (
  <I18n path="organization.organization-mobile-devices-create.pin-policy-options.year-birth-date" />
);
const MONTH_DAY_BIRTH_DATE = (
  <I18n path="organization.organization-mobile-devices-create.pin-policy-options.month-day-birth-date" />
);
const PHONE_LAST_4_DIGITS = (
  <I18n path="organization.organization-mobile-devices-create.pin-policy-options.phone-last-4-digits" />
);

const PIN_POLICY_CHOICES = [
  {
    value: 'NO_PIN',
    description: NO_PIN,
  },
  {
    value: 'YEAR_BIRTH_DATE',
    description: YEAR_BIRTH_DATE,
  },
  {
    value: 'MONTH_DAY_BIRTH_DATE',
    description: MONTH_DAY_BIRTH_DATE,
  },
  {
    value: 'PHONE_LAST_4_DIGITS',
    description: PHONE_LAST_4_DIGITS,
  },
];

const NON_ELIGIBLE_MEMBER_SEARCH = (
  <I18n path="organization.organization-mobile-devices-create.loading-non-eligible-members" />
);
const ERROR_FETCH_NON_ELIGIBLE_MEMBERS = (
  <I18n path="organization.organization-mobile-devices-create.load-non-eligible-members-failure" />
);
const REQUIRE_PIN_CHANGE_CHECKBOX = (
  <I18n path="organization.organization-mobile-devices-create.input-require-pin-change-check-box" />
);
const SUBTITLE_NON_ELIGIBLE_MEMBERS = (
  <I18n path="organization.organization-mobile-devices-create.link-non-eligible-members" />
);

function ChoosePinPolicy({ isLoading, onClickNextStep }) {
  const [pinPolicy, setPinPolicy] = useState(PIN_POLICY_CHOICES[0].value);
  const [requirePinChange, setRequirePinChange] = useState(false);
  const [nonEligibleMembers, setNonEligibleMembers] = useState([]);
  const [isFetchingMembers, setIsFetchingMembers] = useState(false);
  const [errorFetchingMembers, setErrorFetchingMembers] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSelectChange = (value) => {
    setPinPolicy(value);
    setIsFetchingMembers(true);
    setErrorFetchingMembers(false);

    fetchMembersEligibleToPinPolicy(value)
      .then((response) => {
        const { data } = response;
        setNonEligibleMembers(data.non_eligible);
        setIsFetchingMembers(false);
        setErrorFetchingMembers(false);
      })
      .catch(() => {
        setNonEligibleMembers([]);
        setIsFetchingMembers(false);
        setErrorFetchingMembers(true);
        dispatch(addToast('error', ERROR_FETCH_NON_ELIGIBLE_MEMBERS));
      });
  };

  const renderHint = () => {
    if (errorFetchingMembers) {
      return (
        <Hint>
          <Text isError data-testid="hint-error">
            {ERROR_FETCH_NON_ELIGIBLE_MEMBERS}
          </Text>
        </Hint>
      );
    }

    if (isFetchingMembers) {
      return (
        <Hint>
          <Spinner />
          {NON_ELIGIBLE_MEMBER_SEARCH}
        </Hint>
      );
    }

    return null;
  };

  const numberNonEligibleMembers = nonEligibleMembers.length;

  return (
    <>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-mobile-devices-create.title-sub-pin-policy-setup" />
        </Subtitle>
        <Text isItalic>
          <I18n path="organization.organization-mobile-devices-create.text-pin-policy-setup" />
        </Text>
        <LabeledInput
          label={<I18n path="organization.organization-mobile-devices-create.input-pin-policy-choice" />}
          htmlFor="pin_policy"
        >
          <Select
            id="pin_policy"
            name="pin-policy"
            value={pinPolicy}
            onChange={(event) => onSelectChange(event.target.value)}
            hasError={numberNonEligibleMembers !== 0}
            errorMessage={
              numberNonEligibleMembers !== 0 ? (
                <I18n
                  path="organization.organization-mobile-devices-create.error-message-non-eligible-members"
                  props={{ number_members: numberNonEligibleMembers }}
                />
              ) : (
                ''
              )
            }
            data-testid="pin-policy-select"
          >
            {PIN_POLICY_CHOICES.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.description}
              </Option>
            ))}
          </Select>
          {renderHint()}
        </LabeledInput>
        <Checkbox
          id="require_pin_change"
          name="require-pin-change"
          value={requirePinChange}
          label={REQUIRE_PIN_CHANGE_CHECKBOX}
          isChecked={requirePinChange}
          onClick={() => setRequirePinChange(!requirePinChange)}
          data-testid="require-pin-change-checkbox"
        />
      </Section>

      {numberNonEligibleMembers !== 0 ? (
        <Section maxWidth="428px">
          <Subtitle isError>
            <I18n path="organization.organization-mobile-devices-create.title-sub-non-eligible-members" />
          </Subtitle>
          <Text isItalic>
            <I18n path="organization.organization-mobile-devices-create.text-non-eligible-members" />
          </Text>
          {nonEligibleMembers.map((member) => (
            <DropdownItem
              key={`member-${member.id}`}
              image={member.user ? member.user.photo : null}
              title={`${member.user.first_name} ${member.user.last_name}`}
              subtitle={SUBTITLE_NON_ELIGIBLE_MEMBERS}
              onClick={() => navigate(`/organization/members/${member.id}/edit/info`)}
            />
          ))}
        </Section>
      ) : null}

      <div className={styles.buttonsContainer}>
        <Button
          type="primary"
          isLoading={isLoading}
          onClick={() => onClickNextStep({ pinPolicy, requirePinChange })}
          data-testid="confirm-button"
        >
          <I18n path="organization.organization-mobile-devices-create.button-confirm-pin-policy-setup" />
        </Button>
        <Button onClick={() => navigate('/org-mobile-devices/devices')} data-testid="back-button">
          <I18n path="organization.organization-mobile-devices-create.button-back" />
        </Button>
      </div>
    </>
  );
}

export default ChoosePinPolicy;
