import { useEffect, useState } from 'react';
import Tag from 'components/atoms/tag-button';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Card from 'components/molecules/member-card';
import Loader from 'components/molecules/section-loader';
import Pagination from 'components/organisms/pagination';
import MembersSelectDialog from 'components/organisms/members-select-dialog';
import I18n from 'utils/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedUnit, setOrganizationSelectedUnit } from 'features/organizationSlice';
import { fetchMembers, fetchUnit, updateUnit } from 'api/portal';
import { addToast } from 'actions/toasts';
import App from '../template';
import styles from '../styles.module.css';

const LOAD_MEMBERS_FAILURE = <I18n path="organization.organization-units-members.load-members-failure" />;
const LOAD_UNIT_MEMBERS_FAILURE = <I18n path="organization.organization-units-members.load-unit-members-failure" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;

function UnitMembers() {
  const [isLoading, setIsLoading] = useState(false);
  const [unitMembers, setUnitMembers] = useState([]);
  const [isSavingMembers, setIsSavingMembers] = useState(false);
  const [isToShowMembersDialog, setIsToShowMembersDialog] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingError, setLoadingError] = useState(false);

  const organization = useSelector((state) => state.organization);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { unitId } = params;

  const fetchAllMembersHandler = () => {
    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchMembers(searchParams)
      .then((response) => {
        const { data } = response;

        if (data && data.results.length > 0) {
          setAllMembers(data.results);
        }
      })
      .catch(() => {
        dispatch(addToast('error', LOAD_MEMBERS_FAILURE));
      });
  };

  const fetchUnitMembersHandler = (page) => {
    setIsLoading(true);

    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('unit', unitId);

    setLoadingError(false);
    setCurrentPage(page);

    fetchMembers(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setLoadingError(false);

        const unitMembers = data.results;

        if (data && unitMembers.length > 0) {
          const selectedIds = unitMembers.map((member) => member.id);

          setUnitMembers(unitMembers);
          setSelectedIds(selectedIds);
          setRows(data.count);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setLoadingError(true);
        dispatch(addToast('error', LOAD_UNIT_MEMBERS_FAILURE));
      });
  };

  useEffect(() => {
    const unit = organization ? organization.selectedUnit : organization;

    if (unit.id !== Number(unitId)) {
      dispatch(clearOrganizationSelectedUnit());

      setIsLoading(true);

      fetchUnit(unitId)
        .then((response) => {
          const { data } = response;

          dispatch(setOrganizationSelectedUnit(data));
          fetchAllMembersHandler();
          fetchUnitMembersHandler(1);
        })
        .catch(() => {
          navigate('/organization/units');
        });
    } else {
      fetchAllMembersHandler();
      fetchUnitMembersHandler(1);
    }
  }, []);

  const handleCheckboxToggle = (id) => {
    let selectedMembersId = [];

    if (selectedIds.includes(id)) {
      selectedMembersId = selectedIds.filter((item) => item !== id);
    } else {
      selectedMembersId = [...selectedIds, id];
    }

    setSelectedIds(selectedMembersId);
  };

  const handleAddingMembers = () => {
    setIsSavingMembers(true);

    updateUnit(unitId, { members: selectedIds })
      .then(() => {
        setIsSavingMembers(false);
        setIsToShowMembersDialog(false);
        fetchUnitMembersHandler(1);
      })
      .catch(() => {
        setIsSavingMembers(false);
        setIsToShowMembersDialog(false);
        dispatch(addToast('error', SAVE_FAILURE));
      });
  };

  const renderSelectMembersDialog = () => (
    <MembersSelectDialog
      title={<I18n path="organization.organization-units-members.title-box-unit-member" />}
      allMembers={allMembers}
      selectedIds={selectedIds}
      isSavingMembers={isSavingMembers}
      show={isToShowMembersDialog}
      onCheckboxClick={handleCheckboxToggle}
      onDialogToggle={() => setIsToShowMembersDialog(!isToShowMembersDialog)}
      onAddClick={handleAddingMembers}
    />
  );

  const renderMemberList = () => {
    if (loadingError) {
      return (
        <div className={styles.emptyList} data-testid="error-member-list">
          <Text>{LOAD_UNIT_MEMBERS_FAILURE}</Text>
          <Button onClick={() => fetchUnitMembersHandler(currentPage)}>
            <I18n path="organization.organization-units-members.text-try-again" />
          </Button>
        </div>
      );
    }

    if (unitMembers.length > 0) {
      return (
        <div className={styles.memberList}>
          {unitMembers.map((member) => (
            <Card
              key={`member-card-${member.id}`}
              status={member.status}
              onClick={() => navigate(`/organization/members/${member.id}/edit/info`)}
              data-testid={`member-card-${member.id}`}
            >
              <div className={styles.cardHeader}>
                <Card.Header name={`${member.user.first_name} ${member.user.last_name}`} image={member.user.photo} />
              </div>
              <Card.Body>
                <div>
                  <p className={styles.cardSubtitle}>
                    <I18n path="organization.organization-units-members.member-card-role" />
                  </p>
                  <p className={styles.cardContent}>{member.roles.map((role) => role.name).join(', ')}</p>
                </div>
                <div>
                  <p className={styles.cardSubtitle}>
                    <I18n path="organization.organization-units-members.member-card-units" />
                  </p>
                  <div>
                    {member.units.slice(0, 4).map((unit) => (
                      <Tag key={`unit-tag-${unit.name}`}>{unit.name}</Tag>
                    ))}
                    {member.units.length > 4 && <Tag>+{member.units.length - 4}</Tag>}
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
      );
    }

    return (
      <div className={styles.emptyList}>
        <Text>
          <I18n path="organization.organization-units-members.text-unit-member-empty" />
        </Text>
        <Button onClick={() => setIsToShowMembersDialog(true)}>
          <I18n path="organization.organization-units-members.button-add-remove-member" />
        </Button>
      </div>
    );
  };

  return (
    <App>
      <Section>
        <div className={styles.memberHeader}>
          <div>
            <Subtitle>
              <I18n path="organization.organization-units-members.menu-member-info" />
            </Subtitle>
            <Text>
              <I18n path="organization.organization-units-members.text-unit-member-list" />
            </Text>
          </div>
          {unitMembers.length > 0 || loadingError ? (
            <Button onClick={() => setIsToShowMembersDialog(true)} data-testid="button-add-remove-member">
              <I18n path="organization.organization-units-members.button-add-remove-member" />
            </Button>
          ) : null}
        </div>
        {renderSelectMembersDialog()}
        {isLoading ? <Loader /> : renderMemberList()}
        <div className={styles.footer}>
          <Button onClick={() => navigate('/organization/units')} data-testid="button-cancel">
            <I18n path="organization.organization-units-members.button-cancel" />
          </Button>
          {rows > 0 && (
            <Pagination
              rows={rows}
              rowsPerPage={10}
              currentPage={currentPage}
              onPageSelect={fetchUnitMembersHandler}
              maxPagesDisplay={3}
            />
          )}
        </div>
      </Section>
    </App>
  );
}

export default UnitMembers;
