import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Avatar from 'components/atoms/avatar';
import Section from 'components/atoms/section';
import LinkItem from 'components/atoms/link-item';
import AuthTitle from 'components/atoms/auth-title';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import Slider, { Slide } from 'components/atoms/slider';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Input from 'components/atoms/input';
import InputIcon from 'components/molecules/input-icon';
import LabeledInput from 'components/molecules/labeled-input';
import I18n from 'utils/i18n';
import { SlideFadeInContainer } from 'utils/transitions';
import homeImage from 'assets/images/dashboard-home-image.png';
import IcpCurve from 'assets/images/icp-curve.png';
import { addToast } from 'actions/toasts';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganizations } from 'features/userSlice';
import { validatePassword as applyPolicyValidation } from 'utils/password-validators';
import { fetchPendingMembers, updatePendingMember } from 'api/portal';
import styles from './styles.module.css';
import RuledPasswordInput from '../../components/organisms/RuledPasswordInput';

const REQUIRED_FIELD_ERROR = <I18n path="account.edit-password.fill-required" />;
const MISMATCH_ERROR = <I18n path="messages.mismatch-error" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const REQUEST_ERROR = <I18n path="messages.request-error" />;
const LOADING_ERROR = <I18n path="home.pending-members.loading-error" />;

function Home() {
  const [notificationIndex, setNotificationIndex] = useState(0);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [reason, setReason] = useState('');
  const [pendingMember, setPendingMember] = useState({});
  const [isToShowDialog, setIsToShowDialog] = useState(false);
  const [isToShowAcceptDialog, setIsToShowAcceptDialog] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState(new Map());
  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);

  const user = useSelector((state) => state.user);

  const profileLink = user.active_organization.id !== -1 ? '/account/member/edit/info' : '/account/me/edit/info';

  const dispatch = useDispatch();

  const getPendingMembers = () => {
    fetchPendingMembers()
      .then((response) => {
        const { data } = response;
        setPendingMembers(data);
      })
      .catch(() => {
        dispatch(addToast('error', LOADING_ERROR));
      });
  };

  useEffect(() => {
    getPendingMembers();
    const interval = setInterval(getPendingMembers, 180000);
    return () => clearInterval(interval);
  }, []);

  const handleDeclineConfirmation = () => {
    const errors = new Map();

    if (reason === '') {
      errors.set('reason', REQUIRED_FIELD_ERROR);
      setErrors(errors);
      return;
    }

    setIsDeclining(true);
    setErrors(errors);

    updatePendingMember(pendingMember.id, { status: 'DECLINED', reason })
      .then(() => {
        const pendingList = pendingMembers.filter((member) => member.id !== pendingMember.id);
        const index = pendingMembers.length === notificationIndex + 1 ? notificationIndex - 1 : notificationIndex;
        setPendingMembers(pendingList);
        setNotificationIndex(index);
        setIsDeclining(false);
        setReason('');
        setIsToShowDialog(false);
      })
      .catch(() => {
        setIsDeclining(false);
        setIsToShowDialog(false);
        dispatch(addToast('error', REQUEST_ERROR));
      });
  };

  const getPasswordPolicy = () => {
    const { organization } = pendingMember;
    return organization ? organization.password_policy : {};
  };

  const validatePassword = (password) => {
    const passwordPolicy = getPasswordPolicy();
    const policyErrors = applyPolicyValidation(passwordPolicy, password);
    setPasswordPolicyErrors(policyErrors);

    if (policyErrors.length === 0) {
      errors.delete('password');
    } else {
      errors.set('password', policyErrors);
    }

    if (password) {
      errors.delete('password');
    } else {
      errors.set('password', REQUIRED_FIELD_ERROR);
    }

    if (password === confirmPassword) {
      errors.delete('confirmPassword');
    } else {
      errors.set('confirmPassword', MISMATCH_ERROR);
    }

    setPassword(password);
    setErrors(errors);
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (password === confirmPassword) {
      errors.delete('confirmPassword');
    } else {
      errors.set('confirmPassword', MISMATCH_ERROR);
    }

    setConfirmPassword(confirmPassword);
    setErrors(errors);
  };

  const handleAcceptInvitation = () => {
    validatePassword(password);
    validateConfirmPassword(confirmPassword);

    if (errors.size > 0) {
      dispatch(addToast('error', FIX_FIELDS));
      return;
    }

    setIsAccepting(true);
    setErrors(new Map());

    updatePendingMember(pendingMember.id, { status: 'ACTIVE', password })
      .then((response) => {
        const { data } = response;

        const pendingList = pendingMembers.filter((member) => member.id !== pendingMember.id);
        const index = pendingMembers.length === notificationIndex + 1 ? notificationIndex - 1 : notificationIndex;

        dispatch(setOrganizations([data.organization, ...user.organizations]));
        setPendingMembers(pendingList);
        setNotificationIndex(index);
        setIsAccepting(false);
        setPassword('');
        setIsToShowAcceptDialog(false);
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        const passwordErrors = data.password;

        setIsAccepting(false);

        if (Array.isArray(passwordErrors)) {
          const policyErrors = passwordErrors.map((passwordError) => passwordError.split(']')[0].split('[')[1]);
          setPasswordPolicyErrors(policyErrors);
          return;
        }

        setIsToShowAcceptDialog(false);
        dispatch(addToast('error', REQUEST_ERROR));
      });
  };

  const closeAcceptInviteDialog = () => {
    setIsToShowAcceptDialog(false);
    setErrors(new Map());
  };

  const closeDeclineInviteDialog = () => {
    setIsToShowDialog(false);
    setErrors(new Map());
  };

  const renderDeclineInviteDialog = () => (
    <Dialog isOpen={isToShowDialog} data-testid="decline-invitation-dialog">
      <Header onClose={closeDeclineInviteDialog}>
        <I18n path="auth.account-invite-reject.text-account-invite-decline" />
      </Header>
      <Body>
        <LabeledInput
          label={<I18n path="auth.account-invite-reject.input-invite-reject-reason" />}
          htmlFor="unit_name"
          data-testid="reason-labeled-input"
        >
          <Input
            id="reason"
            name="reason"
            value={reason}
            onChange={(event) => setReason(event.target.value)}
            placeholder="Reason to decline"
            hasError={errors.has('reason')}
            errorMessage={errors.has('reason') ? errors.get('reason') : ''}
            data-testid="reason-input"
          />
        </LabeledInput>
      </Body>
      <Footer>
        <Button isBlock onClick={closeDeclineInviteDialog} type="ghost" data-testid="cancel-button">
          <I18n path="auth.account-invite-reject.button-cancel" />
        </Button>
        <Button
          isBlock
          type="primary"
          onClick={handleDeclineConfirmation}
          isLoading={isDeclining}
          data-testid="decline-button"
        >
          <I18n path="auth.account-invite-reject.button-decline" />
        </Button>
      </Footer>
    </Dialog>
  );

  const renderAcceptInviteDialog = () => (
    <Dialog isOpen={isToShowAcceptDialog} data-testid="accept-invitation-dialog">
      <Header onClose={closeAcceptInviteDialog}>
        <I18n path="auth.account-invite-accept.text-account-invite-accept" />
      </Header>
      <Body>
        <RuledPasswordInput
          errors={passwordPolicyErrors}
          hasError={errors.has('password')}
          passwordPolicy={getPasswordPolicy()}
          onChange={(event) => validatePassword(event.target.value)}
          value={password}
        />
        <LabeledInput
          label={<I18n path="auth.account-invite-accept.input-confirmed-password" />}
          className={styles.passwordInput}
          data-testid="confirm-password-labeled-input"
        >
          <InputIcon
            name="confirm-password"
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            icon={isConfirmPasswordVisible ? 'eye' : 'eye-blocked'}
            onIconClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
            value={confirmPassword}
            hasError={errors.has('confirmPassword')}
            errorMessage={errors.has('confirmPassword') ? errors.get('confirmPassword') : ''}
            onChange={(event) => validateConfirmPassword(event.target.value)}
            onBlur={(event) => validateConfirmPassword(event.target.value)}
            data-testid="confirm-password-input"
          />
        </LabeledInput>
      </Body>
      <Footer>
        <Button isBlock onClick={closeAcceptInviteDialog} type="ghost" data-testid="cancel-button">
          <I18n path="auth.account-invite-accept.button-cancel" />
        </Button>
        <Button
          isBlock
          type="primary"
          onClick={handleAcceptInvitation}
          isLoading={isAccepting}
          data-testid="accept-button"
        >
          <I18n path="auth.account-invite-accept.button-accept-invite" />
        </Button>
      </Footer>
    </Dialog>
  );

  return (
    <App>
      <Section className={styles.dashboard}>
        {pendingMembers.length > 0 && (
          <Slider
            title={<I18n path="auth.account-invite.text-account-invite" />}
            onChange={() => setNotificationIndex(notificationIndex)}
            currentIndex={notificationIndex}
          >
            {pendingMembers.map((member) => (
              <Slide
                key={member.id}
                title={member.organization.name}
                subtile={member.organization.address}
                image={member.organization.photo}
                onDeclineClick={() => {
                  setPendingMember(member);
                  setIsToShowDialog(true);
                }}
                onAcceptClick={() => {
                  setPendingMember(member);
                  setIsToShowAcceptDialog(true);
                }}
                data-testid={`pending-member-card-${member.id}`}
              />
            ))}
          </Slider>
        )}
        {renderAcceptInviteDialog()}
        {renderDeclineInviteDialog()}
        <SlideFadeInContainer className={styles.dashboardContent}>
          <div className={styles.mainImage}>
            <img alt="icp-curve" className={styles.curve} src={IcpCurve} />
            <Avatar size="big" image={homeImage} description="profile image" className={styles.homeImage} />
          </div>
          <AuthTitle>
            <I18n path="auth.account-personal.title-main-account-center" />
          </AuthTitle>
          <Text className={styles.dashboardDescription} size="medium">
            <I18n path="auth.account-personal.text-account-center" />
          </Text>
          <LinkItem href={profileLink} isBlock className={styles.updateProfileLink}>
            <I18n path="auth.account-personal.text-account-profile" />
          </LinkItem>
        </SlideFadeInContainer>
      </Section>
    </App>
  );
}

export default Home;
