import { useEffect, useState } from 'react';
import { addToast } from 'actions/toasts';
import Subtitle from 'components/atoms/subtitle';
import Button from 'components/atoms/button';
import Loader from 'components/molecules/section-loader';
import { SlideRightFadeContainer } from 'utils/transitions';
import Text from 'components/atoms/text';
import Section from 'components/atoms/section';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import I18n from 'utils/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMobileDevice, setOrganizationSelectedMobileDevice } from 'features/organizationSlice';
import { deleteMobileDevice, fetchMobileDevices } from 'api/portal';
import App from '../../template';
import styles from './styles.module.css';
import MobileDeviceDetails from '../../common/MobileDeviceDetails';
import StatusSetupDialog from '../../common/StatusSetupDialog';

const ERROR_FETCH_DEVICE = <I18n path="organization.organization-mobile-devices-edit.error-fetch-device" />;
const ERROR_DELETE_DEVICE = <I18n path="organization.organization-mobile-devices-edit.error-delete-device" />;

function OrganizationMobileDeviceInfo() {
  const mobileDevice = useSelector((state) => state.organization.selectedMobileDevice);

  const [isLoadingDevice, setIsLoadingDevice] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [isToShowDeleteDialog, setIsToShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const { deviceId } = params;

  const onErrorFetchingDevice = () => {
    dispatch(addToast('error', ERROR_FETCH_DEVICE));
    navigate('/org-mobile-devices/devices');
  };

  useEffect(() => {
    if (mobileDevice.org_device_id !== deviceId) {
      dispatch(clearOrganizationSelectedMobileDevice());
    }

    fetchMobileDevices(new URLSearchParams(), {
      org_device_id: deviceId,
    })
      .then((response) => {
        const { data } = response;

        if (!data.results) {
          onErrorFetchingDevice();
        } else if (data.results.length === 1) {
          const device = data.results[0];

          dispatch(clearOrganizationSelectedMobileDevice());
          dispatch(setOrganizationSelectedMobileDevice(device));
          setIsLoadingDevice(false);
        } else {
          onErrorFetchingDevice();
        }
      })
      .catch(() => {
        onErrorFetchingDevice();
      });
  }, []);

  const deleteDevice = () => {
    setIsDeleting(true);
    setIsToShowDeleteDialog(false);

    deleteMobileDevice(deviceId)
      .then(() => navigate('/org-mobile-devices/devices'))
      .catch(() => {
        setIsDeleting(false);
        dispatch(addToast('error', ERROR_DELETE_DEVICE));
      });
  };

  const renderDeleteDialog = () => (
    <Dialog isOpen={isToShowDeleteDialog} data-testid="confirm-deletion-dialog">
      <Header>
        <I18n path="organization.organization-mobile-devices-info.dialog-remove-device.title-remove-device" />
      </Header>
      <Body>
        <Text>
          <I18n path="organization.organization-mobile-devices-info.dialog-remove-device.text-remove-device" />
        </Text>
      </Body>
      <Footer>
        <Button isBlock onClick={() => setIsToShowDeleteDialog(false)} type="ghost" data-testid="dismiss-button">
          <I18n path="organization.organization-mobile-devices-info.dialog-remove-device.cancel" />
        </Button>
        <Button isBlock type="primary" onClick={deleteDevice} data-testid="confirm-button">
          <I18n path="organization.organization-mobile-devices-info.dialog-remove-device.confirm-removal" />
        </Button>
      </Footer>
    </Dialog>
  );

  return (
    <App device={mobileDevice}>
      <Subtitle>
        <I18n path="organization.organization-mobile-devices-info.title-info-device" />
      </Subtitle>
      <Section className={styles.section} maxWidth="500px">
        {isLoadingDevice ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <MobileDeviceDetails device={mobileDevice} />
            <Button type="primary" onClick={() => setShowDialog(true)} data-testid="change-status-button">
              <I18n path="organization.organization-mobile-devices-info.logdialog.change-trust-status-button" />
            </Button>
          </SlideRightFadeContainer>
        )}
      </Section>
      {renderDeleteDialog()}
      <div className={styles.buttonContainer}>
        <Button
          type="danger"
          isLoading={isDeleting}
          onClick={() => setIsToShowDeleteDialog(true)}
          data-testid="delete-button"
        >
          <I18n path="organization.organization-mobile-devices-info.remove-device" />
        </Button>
        <Button
          onClick={() => {
            navigate('/org-mobile-devices/devices');
          }}
          data-testid="cancel-button"
        >
          <I18n path="organization.organization-members-edit.button-cancel" />
        </Button>
      </div>
      <StatusSetupDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        excludeStatus={mobileDevice.status}
        onConfirm={(status) => {
          navigate(`/org-mobile-devices/devices/${mobileDevice.org_device_id}/edit-status?status=${status}`);
        }}
        data-testid="choose-status-dialog"
      />
    </App>
  );
}

export default OrganizationMobileDeviceInfo;
