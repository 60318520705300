import PropTypes from 'prop-types';
import Avatar from 'components/atoms/avatar';
import IconMore from 'components/atoms/icon-more';
import AvatarPlaceholder from 'assets/images/avatar-placeholder.png';
import styles from './styles.module.css';

const Hydra = ({ avatarList, count }) => {
  const avatarSubList = avatarList.slice(0, 7);
  const listRest = avatarList.length - 7;

  const renderCount = () => {
    // This count property is specific for pagination components
    if (count && count > 7) {
      return <IconMore number={count - 7} />;
    }

    return listRest > 0 && <IconMore number={listRest} />;
  };

  return (
    <div className={styles.hydra}>
      {avatarSubList.map((avatarURL, index) => (
        <Avatar
          key={index.toString()}
          image={avatarURL || AvatarPlaceholder}
          size="small"
          className={styles.hydraImg}
        />
      ))}
      {renderCount()}
    </div>
  );
};

Hydra.propTypes = {
  avatarList: PropTypes.instanceOf(Array),
  count: PropTypes.number,
};

Hydra.defaultProps = {
  avatarList: [],
  count: undefined,
};

export default Hydra;
