import { portalApi } from './http';

export const login = (payload) => portalApi().post('/login', payload);
export const logout = (refreshToken) => portalApi().post('/logout', { refresh_token: refreshToken });
export const directLogin = (payload) => portalApi().post('/direct-login', payload);
export const memberLogin = (payload) => portalApi().post('/member/login', payload);

export const acceptTerms = () => portalApi().patch('/accept-terms');

export const fetchProfile = () => portalApi().get('/me');
export const fetchProfiles = () => portalApi().get('/me/organization');
export const fetchOrganizationProfile = () => portalApi().get('/me/member');

export const fetchRoles = () => portalApi().get('/role');

export const fetchProfessions = () => portalApi().get('/professions');

export const fetchOrganization = (organizationId) => portalApi().get(`/organization/${organizationId}`);

export const resetPersonalPassword = (payload) => portalApi().post('/user/reset-password', payload);
export const resetCorporatePassword = (payload) => portalApi().post('/member/reset-password', payload);
export const changePassword = (payload) => portalApi().post('/change-password', payload);

export const fetchMembersByOrganization = (organizationId, searchParams) =>
  portalApi().get(`/${organizationId}/member?${searchParams}`);
export const fetchUnitsByOrganization = (organizationId) => portalApi().get(`/${organizationId}/unit?page_size=9999`);
export const fetchMemberByOrganization = (organizationId, memberId) =>
  portalApi().get(`/${organizationId}/member/${memberId}`);
export const updateMemberByOrganization = (organizationId, memberId, payload) =>
  portalApi().patch(`/${organizationId}/member/${memberId}`, payload);
export const deleteMemberByOrganization = (organizationId, memberId) =>
  portalApi().delete(`/${organizationId}/member/${memberId}`);

export const uploadPersonalInformation = (formData) =>
  portalApi().patch('me', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const signup = (payload) => portalApi().post('/signup', payload);

export const signupMember = (payload) => portalApi().post('/member/signup', payload);
export const createMember = (payload) => portalApi().post('member', payload);

export const searchUser = (document) => portalApi().post('/user/search', { document });

export const fetchMembers = (searchParams) => portalApi().get(`/member?${searchParams}`);
export const fetchMember = (memberId) => portalApi().get(`/member/${memberId}`);
export const updateMember = (memberId, payload) => portalApi().patch(`/member/${memberId}`, payload);
export const deleteMember = (memberId) => portalApi().delete(`/member/${memberId}`);

export const fetchUnits = (searchParams) => portalApi().get(`unit?${searchParams}`);
export const fetchUnit = (id) => portalApi().get(`unit/${id}`);
export const createUnit = (payload) => portalApi().post('/unit', payload);
export const updateUnit = (unitId, payload) => portalApi().patch(`/unit/${unitId}`, payload);
export const deleteUnit = (id) => portalApi().delete(`/unit/${id}`);

export const fetchNotifications = (page = 1) => portalApi().get(`/me/notifications?page=${page}`);
export const readNotification = (id = null) => {
  if (id) {
    return portalApi().patch(`me/notifications/${id}/read`);
  }

  return portalApi().patch('me/notifications/read');
};

export const fetchPendingMembers = () => portalApi().get('/me/pending-members');
export const updatePendingMember = (memberId, payload) => portalApi().put(`me/pending-members/${memberId}`, payload);

export const fetchApplications = () => portalApi().get('/my-apps');

export const fetchPasswordPolicy = (payload) => portalApi().post('/password-policy', payload);
export const confirmPassword = (payload) => portalApi().post('/confirm-password', payload);

export const bulkUpload = (formData) => portalApi().post('/bulk-upload', formData);

export const requestRemoval = () => portalApi().post('/remove-request');
export const updateCorporateAccount = (payload) => portalApi().patch('/me/member', payload);
export const sendMessageToSupport = (payload) => portalApi().post('/support', payload);

export const fetchMobileDevices = (searchParams, payload) =>
  portalApi().post(`/organization-mobile-devices/devices?${searchParams}`, payload);
export const updateMobileDevice = (payload) => portalApi().patch('/organization-mobile-devices/devices', payload);
export const deleteMobileDevice = (orgDeviceId) =>
  portalApi().delete('/organization-mobile-devices/devices', { data: { org_device_id: orgDeviceId } });

export const issueDeviceQrCode = (payload) => portalApi().post('/organization-mobile-devices/qr-code', payload);
export const refreshDeviceQrCode = (qrCode) =>
  portalApi().patch('/organization-mobile-devices/qr-code', { qr_code: qrCode });

export const fetchMembersEligibleToPinPolicy = (pinPolicy, orgDeviceId = undefined) => {
  const payload = { pin_policy: pinPolicy };

  if (orgDeviceId) {
    payload.org_device_id = orgDeviceId;
  }

  return portalApi().post('/organization-mobile-devices/eligible-members', payload);
};

export const redefineMemberPin = (payload) => portalApi().patch('organization-mobile-devices/member-pin', payload);
export const fetchActiveMembers = (orgDeviceId) =>
  portalApi().post('organization-mobile-devices/active-members', { org_device_id: orgDeviceId });

export const fetchOrganizations = (searchParams) => portalApi().get(`/organization?${searchParams}`);
export const updateOrganization = (id, formData) => portalApi().patch(`/organization/${id}`, formData);
export const deleteOrganization = (id) => portalApi().delete(`/organization/${id}`);

export const fetchAllOrganizations = () => portalApi().get('/list-organization');

export const fetchBuUsers = (searchParams) => portalApi().get(`bu-user?${searchParams}`);
export const fetchBuUser = (id) => portalApi().get(`/bu-user/${id}`);
export const createBuUser = (payload) => portalApi().post('/bu-user', payload);
export const updateBuUser = (id, formData) =>
  portalApi().patch(`/bu-user/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const deleteBuUser = (id) => portalApi().delete(`/bu-user/${id}`);

export const fetchBraincareDevices = (searchParams) => portalApi().get(`/organization-devices?${searchParams}`);
export const confirmDevice = (payload) => portalApi().post('/confirm-device', payload);

export const fetchAllBraincareDevices = (searchParams) => portalApi().get(`/braincare-device?${searchParams}`);

export const fetchMemberHistory = (searchParams) => portalApi().get(`/org-members-history?${searchParams}`);
export const fetchLogs = (searchParams) => portalApi().get(`/get-logs?${searchParams}`);

export const eduLogin = (payload) => portalApi().post('/edu/login', payload);
export const acceptEduTerms = () => portalApi().patch('/edu/accept-terms/accept');
export const resetEduPassword = (payload) => portalApi().post('/edu/reset-password', payload);

export const updateMemberOrganization = (payload) => portalApi().patch('/me/member-organization', payload);
