import { Store } from 'store';
import _ from 'lodash';

const defaultTimeout = 5000;

export const toggleToastVisibility = (key) => ({
  type: 'TOASTS/TOGGLE_TOAST_VISIBILITY',
  payload: key,
});

export const closeToast = (key) => (dispatch) => {
  dispatch(toggleToastVisibility(key));
  setTimeout(
    () =>
      dispatch({
        type: 'TOASTS/CLOSE_TOAST',
        payload: key,
      }),
    200,
  );
};

export const addToast = (type, message, timeout) => (dispatch) => {
  const key = _.uniqueId('toast');

  if (typeof timeout !== 'number') {
    setTimeout(() => dispatch(closeToast(key)), defaultTimeout);
  } else if (timeout !== 0) {
    setTimeout(() => dispatch(closeToast(key)), timeout);
  }

  dispatch({
    type: 'TOASTS/ADD_TOAST',
    payload: {
      type,
      message,
      timeout,
      key,
      visible: true,
    },
  });
};

export const toast = (type, message, timeout) => Store.dispatch(addToast(type, message, timeout));
