import I18n from 'utils/i18n';
import MobileDeviceRegistration from '../common/MobileDeviceRegistration';

const ADD_DEVICE = <I18n path="organization.breadcrumbs.add-organization-mobile-device" />;

function CreateMobileDevice() {
  return <MobileDeviceRegistration title={ADD_DEVICE} currentNav={ADD_DEVICE} />;
}

export default CreateMobileDevice;
