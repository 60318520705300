import { toast } from 'actions/toasts';
import Auth from 'components/templates/auth';
import AuthTitle from 'components/atoms/auth-title';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import Section from 'components/atoms/section';
import I18n from 'utils/i18n';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate } from 'react-router-dom';
import styles from '../styles.module.css';

const CREATE_SUCCESS = <I18n path="auth.signup.create-success" />;

export default function SignupSuccess() {
  const navigate = useNavigate();

  const handleClickSuccess = () => {
    toast('warning', CREATE_SUCCESS, 0);
    navigate('/auth/direct-login');
  };

  return (
    <Auth>
      <SlideFadeInContainer>
        <Section>
          <AuthTitle>
            <I18n path="auth.signup-success.title" />
          </AuthTitle>
          <Text>
            <I18n path="auth.signup-success.text" />
          </Text>
          <Button isBlock isLarge type="primary" className={styles.buttonSuccess} onClick={handleClickSuccess}>
            <I18n path="auth.signup-success.button-ok" />
          </Button>
        </Section>
      </SlideFadeInContainer>
    </Auth>
  );
}
