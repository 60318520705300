import { useState } from 'react';
import { addToast } from 'actions/toasts';
import Auth from 'components/templates/auth';
import Section from 'components/atoms/section';
import AuthTitle from 'components/atoms/auth-title';
import Text from 'components/atoms/text';
import Icon from 'components/atoms/icon';
import Input from 'components/atoms/input';
import Button from 'components/atoms/button';
import LabeledInput from 'components/molecules/labeled-input';
import I18n from 'utils/i18n';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetCorporatePassword, resetPersonalPassword } from 'api/portal';
import styles from '../styles.module.css';

const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const CHECK_EMAIL = <I18n path="auth.forgot-password.check-email" />;
const REDEFINE_FAILURE = <I18n path="auth.forgot-password.redefine-failure" />;

function ForgotPassword() {
  const [document, setDocument] = useState('');
  const [documentError, setDocumentError] = useState('');

  const [email, setEmail] = useState('');
  const [accountManagers, setAccountManagers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { orgId } = params;

  const handleMemberRequest = () => {
    resetCorporatePassword({
      organization: orgId,
    })
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setIsSuccess(true);
        setDocumentError('');
        setEmail(data.email);
        setAccountManagers(data.account_managers);
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        if (!data.detail) {
          setIsLoading(false);
          setDocumentError(REDEFINE_FAILURE);
          dispatch(addToast('error', REDEFINE_FAILURE));
        }
      });
  };

  const handlePatientRequest = () => {
    resetPersonalPassword({ document })
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setIsSuccess(true);
        setDocumentError('');
        setEmail(data.email);
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        if (!data.detail) {
          setIsLoading(false);
          setDocumentError(REDEFINE_FAILURE);
          dispatch(addToast('error', REDEFINE_FAILURE));
        }
      });
  };

  const handleOnNextClick = () => {
    if (document === '') {
      setDocumentError(FILL_REQUIRED);
      return;
    }

    setDocumentError('');
    setIsLoading(true);

    if (orgId) {
      handleMemberRequest();
    } else {
      handlePatientRequest();
    }
  };

  const handleClickSuccess = () => {
    dispatch(addToast('warning', CHECK_EMAIL));
    navigate('/auth/direct-login');
  };

  const renderSuccessMessage = () => (
    <SlideFadeInContainer>
      <Section>
        <AuthTitle data-testid="success-message-title">
          <I18n path="auth.forgot-password-success.title" />
        </AuthTitle>
        <Text data-testid="success-message-text">
          <I18n props={{ email }} path="auth.forgot-password-success.text" />
        </Text>
        {accountManagers.length === 0 ? null : (
          <Text data-testid="success-message-account-managers">
            <I18n path="auth.forgot-password-success.text-account-managers-list" />
            <ul>
              {accountManagers.map((accountManager, index) => (
                <li key={index} data-testid={`account-manager-${index}`}>
                  {accountManager}
                </li>
              ))}
            </ul>
          </Text>
        )}
        <Button
          isBlock
          type="primary"
          className={styles.buttonSuccess}
          onClick={handleClickSuccess}
          data-testid="success-message-close-button"
        >
          <I18n path="auth.forgot-password-success.button-close" />
        </Button>
      </Section>
    </SlideFadeInContainer>
  );

  const renderForm = () => (
    <SlideFadeInContainer>
      <Section>
        <AuthTitle>
          <I18n path="auth.forgot-password.title-main-password" />
        </AuthTitle>
        <Text size="medium" className={styles.descriptions}>
          <I18n path="auth.forgot-password.text-password-forgot" />
        </Text>

        <Text size="medium">
          <b className={styles.warning}>
            <Icon className={styles.warningIcon} name="warning" />
            <I18n path="auth.forgot-password.warning-brand" />
          </b>
          <I18n path="auth.forgot-password.warning-password-forgot" />
        </Text>

        <LabeledInput
          label={<I18n path="auth.forgot-password.input-password-forgot" />}
          data-testid="document-labeled-input"
        >
          <Input
            onChange={(event) => setDocument(event.target.value)}
            value={document}
            hasError={!!documentError}
            errorMessage={documentError}
            maxLength="32"
            data-testid="document-input"
          />
        </LabeledInput>

        <Button
          type="primary"
          isBlock
          className={styles.nextButton}
          onClick={handleOnNextClick}
          isLoading={isLoading}
          data-testid="submit-button"
        >
          <I18n path="auth.forgot-password.button-reset-password" />
        </Button>

        <Button isBlock onClick={() => navigate('/auth/direct-login')} data-testid="cancel-button">
          <I18n path="auth.forgot-password.button-cancel" />
        </Button>
      </Section>
    </SlideFadeInContainer>
  );

  return <Auth>{isSuccess ? renderSuccessMessage() : renderForm()}</Auth>;
}

export default ForgotPassword;
