import React from 'react';
import { useDispatch } from 'react-redux';
import Auth from 'components/templates/auth';
import App from 'components/templates/app';
import Section from 'components/atoms/section';
import AuthTitle from 'components/atoms/auth-title';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import I18n from 'utils/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setLanguage } from 'features/userSlice';
import { getToken } from 'utils/tokens';
import styles from './styles.module.css';

export default function TermsAndServices() {
  window.scrollTo(0, 0);

  const accessToken = getToken('access_token');
  const refreshToken = getToken('refresh_token');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const defaultLanguage = searchParams.get('language');

  if (defaultLanguage) {
    if (defaultLanguage === 'pt-br' || defaultLanguage === 'en-us') {
      dispatch(setLanguage(defaultLanguage));
      navigate('/public/security');
    }
  }

  const renderText = () => (
    <Section className={styles.section}>
      <AuthTitle>
        <I18n path="auth.security.title00" />
      </AuthTitle>
      <Text size="medium">
        <I18n path="auth.security.text00" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title01" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text01" />
        <ul>
          <li>
            <I18n path="auth.security.text02" />
          </li>
          <li>
            <I18n path="auth.security.text03" />
          </li>
          <li>
            <I18n path="auth.security.text04" />
          </li>
          <li>
            <I18n path="auth.security.text05" />
          </li>
        </ul>
      </Text>
      <Subtitle>
        <I18n path="auth.security.title02" />
      </Subtitle>
      <br />
      <Subtitle>
        <I18n path="auth.security.title03" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text06" />
        <br />
        <br />
        <I18n path="auth.security.text07" />
        <br />
        <br />
        <I18n path="auth.security.text08" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title04" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text09" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title05" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text10" />
        <br />
        <br />
        <I18n path="auth.security.text11" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title06" />
      </Subtitle>
      <br />
      <Subtitle>
        <I18n path="auth.security.title07" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text12" />
        <br />
        <br />
        <I18n path="auth.security.text13" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title08" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text14" />
        <ul>
          <li>
            <I18n path="auth.security.text15" />
          </li>
          <li>
            <I18n path="auth.security.text16" />
          </li>
          <li>
            <I18n path="auth.security.text17" />
          </li>
          <li>
            <I18n path="auth.security.text18" />
          </li>
          <li>
            <I18n path="auth.security.text19" />
          </li>
        </ul>
      </Text>
      <Subtitle>
        <I18n path="auth.security.title09" />
      </Subtitle>
      <br />
      <Subtitle>
        <I18n path="auth.security.title10" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text20" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title11" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text21" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title12" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text22" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title13" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text23" />
        <br />
        <br />
        <I18n path="auth.security.text24" />
      </Text>
      <Subtitle>
        <I18n path="auth.security.title14" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.security.text25" />
        <ul>
          <li>
            <I18n path="auth.security.text26" />
          </li>
          <li>
            <I18n path="auth.security.text27" />
          </li>
          <li>
            <I18n path="auth.security.text28" />
          </li>
        </ul>
        <I18n path="auth.security.text29" />
      </Text>
    </Section>
  );

  const signed = accessToken !== undefined && refreshToken !== undefined;

  if (signed) {
    return <App>{renderText()}</App>;
  }

  return <Auth maxWidth="90vw">{renderText()}</Auth>;
}
