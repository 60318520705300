import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToast } from 'actions/toasts';
import { formatFromString, stringFromDate, dateFromString, getValidDate } from 'utils/format-date';
import { formatToCPFDocument, unformatDocument } from 'utils/format-documents';
import { validateCpf } from 'utils/validate-documents';
import validateEmail from 'utils/validate-email';
import validateDate from 'utils/validate-date';
import I18n from 'utils/i18n';
import Text from 'components/atoms/text';
import Input from 'components/atoms/input';
import Option from 'components/atoms/option';
import Button from 'components/atoms/button';
import Subtitle from 'components/atoms/subtitle';
import Radio from 'components/atoms/input-radio';
import Label from 'components/atoms/input-label';
import Select from 'components/atoms/input-select';
import AuthTitle from 'components/atoms/auth-title';
import LabeledInput from 'components/molecules/labeled-input';
import InputDatePicker from 'components/molecules/input-date-picker';
import { useNavigate } from 'react-router-dom';
import { fetchProfessions, signup } from 'api/portal';
import styles from '../styles.module.css';

const SAVE_FAILURE = <I18n path="messages.save-failure" />;
const SIGNUP_ERROR = <I18n path="auth.accept-terms.title" />;
const ACCOUNT_EXISTS = <I18n path="messages.account-exists" />;
const SIGNUP_SUCCESS_TITLE = <I18n path="auth.signup-success.title" />;
const SIGNUP_SUCCESS_MESSAGE = <I18n path="auth.signup-success.text" />;
const INVALID_DOCUMENT_ERROR = <I18n path="messages.invalid-document" />;
const INVALID_EMAIL_ERROR = <I18n path="messages.invalid-email" />;
const INVALID_DATE_ERROR = <I18n path="messages.invalid-date" />;
const ERROR_LOADING_PROFESSIONS = <I18n path="messages.error-professions" />;
const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

function EduSignup() {
  const [stage, setStage] = useState('Form');
  const [document, setDocument] = useState('');
  const [documentType, setDocumentType] = useState('ID');
  const [profession, setProfession] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [date, setDate] = useState(dateFromString(''));
  const [notificationLanguage, setNotificationLanguage] = useState('en-us');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidDocument, setIsValidDocument] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidDate, setIsValidDate] = useState(true);
  const [result, setResult] = useState(null);
  const [professions, setProfessions] = useState([]);

  const [errors, setErrors] = useState(new Map());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfessions()
      .then((response) => {
        const { data } = response;

        const professions = [];

        const returnedProfessions = data.professions;

        returnedProfessions.forEach((returnedProfession) => {
          professions.push(
            <Option key={returnedProfession} value={returnedProfession}>
              <I18n path={'professions.text-' + returnedProfession} />
            </Option>,
          );
        });

        setProfessions(professions);
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_LOADING_PROFESSIONS));
      });
  }, []);

  const handleChangeBirthDate = (date) => {
    if (date instanceof Date) {
      setIsValidDate(birthDate === '' || validateDate(birthDate));
      setBirthDate(stringFromDate(date));
      setDate(date);
    } else {
      setBirthDate(formatFromString(date.target.value));
      setDate(getValidDate(date.target.value));
    }
  };

  const cancel = () => {
    setStage('Form');
    setDocument('');
    setDocumentType('ID');
    setProfession(null);
    setEmail('');
    setFirstName('');
    setLastName('');
    setBirthDate('');
    setDate(dateFromString(''));
    setNotificationLanguage('en-us');
    setIsLoading(false);
    setIsValidDocument(true);
    setIsValidEmail(true);
    setIsValidDate(true);
    setResult(null);
    navigate('/auth/edu/login');
  };

  const handleSubmit = () => {
    setIsLoading(true);

    signup({
      accept_terms: true,
      document: documentType === 'CPF' ? unformatDocument(document) : document,
      document_type: documentType,
      doctor_document: null,
      profession,
      email,
      first_name: firstName,
      last_name: lastName,
      birth_date: birthDate,
      has_read_privacy_policy: true,
      has_read_terms_and_conditions: true,
      notification_language: notificationLanguage,
    })
      .then(() => {
        setIsLoading(false);
        setStage('Result');
        setResult([SIGNUP_SUCCESS_TITLE, SIGNUP_SUCCESS_MESSAGE]);
      })
      .catch((error) => {
        setIsLoading(false);

        const { response } = error;
        const { data } = response;

        if (data.document || data.email) {
          setStage('Result');
          setResult([SIGNUP_ERROR, ACCOUNT_EXISTS]);
        } else {
          dispatch(addToast('error', SAVE_FAILURE));
          cancel();
        }
      });
  };

  const validateFields = () => {
    const requiredFields = {
      firstName,
      lastName,
      documentType,
      document,
      email,
      birthDate,
      notificationLanguage,
    };

    const errors = new Map();

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    setErrors(errors);

    if (!errors.size && isValidDocument && isValidEmail && isValidDate) {
      setStage('Terms');
      return;
    }

    dispatch(addToast('error', FIX_FIELDS));
  };

  const renderForm = () => (
    <div className={styles.webview_container_form}>
      <AuthTitle className={styles.signupTitle}>
        <I18n path="auth.signup.title-main-signup" />
      </AuthTitle>

      <LabeledInput label={<I18n path="auth.signup.input-name" />} data-testid="first-name-labeled-input">
        <Input
          placeholder="e.g.: John"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          hasError={Boolean(errors && errors.get('firstName'))}
          errorMessage={errors && errors.get('firstName') ? errors.get('firstName') : ''}
          data-testid="first-name-input"
        />
      </LabeledInput>

      <LabeledInput label={<I18n path="auth.signup.input-surname" />} data-testid="last-name-labeled-input">
        <Input
          placeholder="e.g.: Doe"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          hasError={Boolean(errors && errors.get('lastName'))}
          errorMessage={errors && errors.get('lastName') ? errors.get('lastName') : ''}
          data-testid="last-name-input"
        />
      </LabeledInput>

      <LabeledInput label={<I18n path="auth.signup.input-email" />} data-testid="email-labeled-input">
        <Input
          placeholder="eg.: johndoe@yourorganization.com"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          hasError={!isValidEmail || Boolean(errors && errors.get('email'))}
          errorMessage={
            (!isValidEmail && INVALID_EMAIL_ERROR) || (errors && !!errors.get('email') ? errors.get('email') : '')
          }
          onBlur={() => setIsValidEmail(email === '' || validateEmail(email))}
          data-testid="email-input"
        />
      </LabeledInput>

      <InputDatePicker
        label={<I18n path="auth.signup.input-birthdate" />}
        labelDate={birthDate}
        date={date}
        maxDate={new Date()}
        placeholder="1992-11-22"
        onChange={handleChangeBirthDate}
        onBlur={() => setIsValidDate(birthDate === '' || validateDate(birthDate))}
        hasError={!isValidDate || Boolean(errors && errors.get('birthDate'))}
        errorMessage={
          (!isValidDate && INVALID_DATE_ERROR) || (errors && errors.get('birthDate') ? errors.get('birthDate') : '')
        }
        data-testid="birth-date-input"
      />

      <LabeledInput label={<I18n path="auth.signup.input-profession" />}>
        <Select
          value={profession}
          onChange={(event) => setProfession(event.target.value)}
          data-testid="profession-select"
        >
          {professions}
        </Select>
      </LabeledInput>

      <LabeledInput label={<I18n path="auth.signup.input-doc-type-choice" />}>
        <Select
          value={documentType}
          onChange={(event) => {
            setDocumentType(event.target.value);
            setDocument('');
          }}
          hasError={Boolean(errors && errors.get('documentType'))}
          errorMessage={errors && errors.get('documentType') ? errors.get('documentType') : ''}
          data-testid="document-type-select"
        >
          <Option value="CPF" data-testid="cpf-option">
            CPF (BR)
          </Option>
          <Option value="ID" data-testid="id-option">
            ID (USA)
          </Option>
        </Select>
      </LabeledInput>

      <LabeledInput label={<I18n path="auth.signup.input-document" />} data-testid="document-labeled-input">
        <Input
          placeholder={documentType === 'CPF' ? '000.000.000-00' : ''}
          value={document}
          onChange={(event) => {
            setDocument(documentType === 'CPF' ? formatToCPFDocument(event.target.value) : event.target.value);
          }}
          hasError={!isValidDocument || Boolean(errors && errors.get('document'))}
          errorMessage={
            (!isValidDocument && INVALID_DOCUMENT_ERROR) ||
            (errors && errors.get('document') ? errors.get('document') : '')
          }
          onBlur={() => setIsValidDocument(document === '' || (documentType === 'CPF' ? validateCpf(document) : true))}
          maxLength={documentType === 'CPF' ? '14' : '32'}
          data-testid="document-input"
        />
      </LabeledInput>

      <div className={styles.language}>
        <Label>
          <I18n path="auth.signup.text-doc-choice-language" />
        </Label>
        <div className={styles.languageOptions}>
          <Radio
            id="1"
            name={notificationLanguage}
            value="pt-br"
            label={<I18n path="auth.signup.input-language-pt-br" />}
            isChecked={notificationLanguage === 'pt-br'}
            onChange={(event) => setNotificationLanguage(event.target.value)}
            data-testid="pt-br-radio"
          />
          <Radio
            id="2"
            name={notificationLanguage}
            value="en-us"
            label={<I18n path="auth.signup.input-language-en-us" />}
            isChecked={notificationLanguage === 'en-us'}
            onChange={(event) => setNotificationLanguage(event.target.value)}
            data-testid="en-us-radio"
          />
        </div>
      </div>

      <Text className={styles.signUpFormText} isBold isWithoutMargin>
        <I18n path="auth.accept-terms.text" />
      </Text>

      <Button
        type="success"
        isBlock
        isLarge
        className={styles.button}
        onClick={validateFields}
        data-testid="continue-button"
      >
        <I18n path="auth.accept-terms.button-continue" />
      </Button>

      <Button
        type="danger"
        isBlock
        isLarge
        className={styles.button}
        onClick={() => navigate('/auth/edu/login')}
        data-testid="cancel-button"
      >
        <I18n path="auth.accept-terms.button-cancel" />
      </Button>
    </div>
  );

  const renderTerms = () => (
    <div className={styles.termsMobile} data-testid="terms-and-services">
      <AuthTitle>
        <I18n path="auth.terms-and-service.title00" />
      </AuthTitle>

      <Subtitle>
        <I18n path="auth.terms-and-service.title01" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text00" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text01" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title02" />
      </Subtitle>
      <Text size="medium">
        <span className={styles.warning}>
          <I18n path="auth.terms-and-service.text02" />
        </span>
        <br />
        <br />
        <I18n path="auth.terms-and-service.text03" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text04" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text05" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text06" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text07" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text08" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text09" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text10" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title03" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text11" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text12" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text13" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text14" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title04" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text15" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text16" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text17" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title05" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text18" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title06" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text19" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text20" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text21" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text22" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text23" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text24" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title07" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text25" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text26" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text27" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text28" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text29" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text30" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title08" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text31" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title09" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text32" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title10" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text33" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text34" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text35" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text36" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text37" />
        <br />
        <br />
      </Text>

      <Button
        type="success"
        isLarge
        isBlock
        className={styles.button}
        onClick={() => {
          setStage('Privacy');
        }}
        data-testid="accept-terms-button"
      >
        <I18n path="auth.accept-terms.button-accept" />
      </Button>

      <Button
        type="danger"
        isBlock
        isLarge
        className={styles.button}
        onClick={cancel}
        data-testid="decline-terms-button"
      >
        <I18n path="auth.accept-terms.button-cancel" />
      </Button>
    </div>
  );

  const renderPrivacy = () => {
    window.scrollTo(0, 0);
    return (
      <div className={styles.termsMobile} data-testid="privacy-policy">
        <AuthTitle>
          <I18n path="auth.privacy-policy.title00" />
        </AuthTitle>

        <Text size="medium">
          <I18n path="auth.privacy-policy.text00" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text01" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text02" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text03" />
          <br />
          <br />
        </Text>

        <Subtitle>
          <I18n path="auth.privacy-policy.title01" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy.text04" />
          <br />
          <br />
          <span className={styles.warning}>
            <I18n path="auth.privacy-policy.text05" />
          </span>
          <br />
          <br />
          <I18n path="auth.privacy-policy.text06" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text07" />
          <br />
          <br />
        </Text>

        <Subtitle>
          <I18n path="auth.privacy-policy.title03" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy.text08" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text09" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text10" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text11" />
          <br />
          <br />
        </Text>

        <Subtitle>
          <I18n path="auth.privacy-policy.title04" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy.text12" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text13" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text14" />
          <br />
          <br />
          <I18n path="auth.privacy-policy.text15" />
          <br />
          <br />
        </Text>

        <Button
          type="success"
          isLarge
          isBlock
          isLoading={isLoading}
          className={styles.button}
          onClick={() => {
            handleSubmit();
          }}
          data-testid="accept-privacy-policy-button"
        >
          <I18n path="auth.accept-terms.button-accept" />
        </Button>

        <Button
          type="danger"
          isBlock
          isLarge
          className={styles.button}
          onClick={cancel}
          data-testid="decline-privacy-policy-button"
        >
          <I18n path="auth.accept-terms.button-cancel" />
        </Button>
      </div>
    );
  };

  const renderResult = () => (
    <div className={styles.webview_container}>
      <div className={styles.signUpResult}>
        <AuthTitle data-testid="result-title">{result[0]}</AuthTitle>
        <Text data-testid="result-text">{result[1]}</Text>
        <Button type="primary" isBlock isLarge className={styles.buttonSuccess} onClick={cancel}>
          <I18n path="auth.signup-success.button-ok" />
        </Button>
      </div>
    </div>
  );

  switch (stage) {
    case 'Terms':
      return <div>{renderTerms()}</div>;
    case 'Privacy':
      return <div>{renderPrivacy()}</div>;
    case 'Result':
      return <div>{renderResult()}</div>;
    default:
      return <div className={styles.webview_container}>{renderForm()}</div>;
  }
}

export default EduSignup;
