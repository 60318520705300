import PropTypes from 'prop-types';
import App from 'components/templates/app';
import Title from 'components/atoms/title';
import Button from 'components/atoms/button';
import styles from './styles.module.css';

function PhysioTemplate({ breadcrumbs, title, buttonTitle, buttonIsLoading, onButtonClick, children }) {
  return (
    <App breadcrumbs={breadcrumbs}>
      <div className={styles.header}>
        <Title className={styles.title}>{title}</Title>
        <Button onClick={onButtonClick} isLoading={buttonIsLoading}>
          {buttonTitle}
        </Button>
      </div>
      {children}
    </App>
  );
}

PhysioTemplate.propTypes = {
  breadcrumbs: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  buttonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  buttonIsLoading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default PhysioTemplate;
