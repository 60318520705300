import PropTypes from 'prop-types';
import AppMenu from 'components/templates/app-menu';
import I18n from 'utils/i18n';
import { useParams } from 'react-router-dom';

const HOME = <I18n path="braincare.breadcrumbs.home" />;
const ORGANIZATIONS = <I18n path="braincare.breadcrumbs.organizations" />;
const ORGANIZATIONS_EDIT = <I18n path="braincare.breadcrumbs.organizations-edit" />;

const INFO = <I18n path="braincare.organizations-edit.sidebar.step-basic-info" />;
const MEMBERS = <I18n path="braincare.organizations-edit.sidebar.step-members" />;
const INACTIVITY_POLICY = <I18n path="braincare.organizations-edit.sidebar.step-inactivity-policy" />;
const SHARE_AND_PURGE_POLICY = <I18n path="braincare.organizations-edit.sidebar.step-reports-policy" />;
const PASSWORD_POLICY = <I18n path="braincare.organizations-edit.sidebar.step-password-policy" />;

const App = ({ title, children }) => {
  const params = useParams();

  const { orgId } = params;

  const { pathname, search } = window.location;

  const INFO_LINK = `/braincare/organizations/${orgId}/edit/info`;
  const MEMBERS_LINK = `/braincare/organizations/${orgId}/members`;
  const PASSWORD_POLICY_LINK = `/braincare/organizations/${orgId}/edit/password-policy`;
  const AUTO_LOGOUT_LINK = `/braincare/organizations/${orgId}/edit/inactivity-policy`;
  const REPORTS_LINK = `/braincare/organizations/${orgId}/edit/reports`;

  const SIDE_MENU = [
    {
      href: INFO_LINK,
      text: INFO,
      isActive: pathname === INFO_LINK,
    },
    {
      href: PASSWORD_POLICY_LINK,
      text: PASSWORD_POLICY,
      isActive: pathname === PASSWORD_POLICY_LINK,
    },
    {
      href: AUTO_LOGOUT_LINK,
      text: INACTIVITY_POLICY,
      isActive: pathname === AUTO_LOGOUT_LINK,
    },
    {
      href: REPORTS_LINK,
      text: SHARE_AND_PURGE_POLICY,
      isActive: pathname === REPORTS_LINK,
    },
    {
      href: MEMBERS_LINK,
      text: MEMBERS,
      isActive: pathname === MEMBERS_LINK,
    },
  ];
  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/organizations',
      title: ORGANIZATIONS,
    },
    {
      path: `${pathname}${search}`,
      title: ORGANIZATIONS_EDIT,
      isActive: true,
    },
  ];
  const TITLE = title || ORGANIZATIONS_EDIT;

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={TITLE} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

App.defaultProps = {
  title: undefined,
};

export default App;
