import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const AuthTitle = ({ children, className, ...props }) => (
  <h2 className={classNames([styles.title], className)} {...props}>
    {children}
  </h2>
);

AuthTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

AuthTitle.defaultProps = {
  className: undefined,
};

export default AuthTitle;
