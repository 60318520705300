import PropTypes from 'prop-types';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Text from 'components/atoms/text';
import Checkbox from 'components/atoms/checkbox';
import Button from 'components/atoms/button';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

const TagsSelectDialog = ({ tags, selectedTags, show, onCheckboxClick, onDialogToggle }) => {
  const memberList = tags.map((tag) => (
    <label key={`member-${tag}`} htmlFor={tag} className={styles.toggleMember}>
      <div className={styles.profileCheckbox}>
        <Text className={styles.profileCheckboxText}>{tag}</Text>
      </div>
      <Checkbox
        id={`member-${tag}`}
        isChecked={selectedTags.includes(tag)}
        onChange={() => {}}
        onClick={() => onCheckboxClick(tag)}
      />
    </label>
  ));

  return (
    <Dialog isOpen={show}>
      <Header>
        <I18n path="physio.physio-personal.dialog-filter-by-tag" />
      </Header>
      <Body>
        <div className={styles.profileCheckboxWrapper}>{memberList}</div>
        <Text className={styles.counter}>
          {selectedTags.length}&nbsp;
          <I18n path="physio.dialog-filter-by-tag.box-counter" props={{ s: selectedTags.length > 1 ? 's' : '' }} />
        </Text>
      </Body>
      <Footer>
        <Button isBlock type="primary" onClick={onDialogToggle}>
          <I18n path="physio.dialog-filter-by-tag.button-save" />
        </Button>
      </Footer>
    </Dialog>
  );
};

TagsSelectDialog.propTypes = {
  tags: PropTypes.instanceOf(Array),
  selectedTags: PropTypes.instanceOf(Array),
  show: PropTypes.bool,
  onCheckboxClick: PropTypes.func.isRequired,
  onDialogToggle: PropTypes.func.isRequired,
};

TagsSelectDialog.defaultProps = {
  tags: [],
  selectedTags: [],
  show: false,
};

export default TagsSelectDialog;
