import I18n from 'utils/i18n';
import PasswordValidator from './PasswordValidator';

export default class RepeatedCharSequencesValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { repeatedCharSequences } = passwordPolicy;

    const { max } = repeatedCharSequences;

    let translation = null;

    if (max !== undefined) {
      translation = <I18n props={{ max }} path="organization.password-rules.repeated-char-sequences" />;
    }

    super(repeatedCharSequences, 'repeated_char_sequences', false, translation);
  }

  validate(password) {
    if (this.policy) {
      const max = this.policy.max ? this.policy.max : 128;
      const isCaseSensitive = this.policy.is_case_sensitive;

      const pattern = `(.)\\1{${max},}`;
      const regex = isCaseSensitive ? new RegExp(pattern) : new RegExp(pattern, 'i');

      const repeatedCharSequences = regex.test(password);

      return !repeatedCharSequences;
    }

    return true;
  }
}
