import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Section = ({ maxWidth, children, className, ...props }) => (
  <section className={classNames(styles.section, className)} style={{ width: maxWidth }} {...props}>
    {children}
  </section>
);

Section.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Section.defaultProps = {
  maxWidth: '100%',
  className: undefined,
};

export default Section;
