import Section from 'components/atoms/section';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import App from 'pages/organization/organization-settings/App';
import { useState } from 'react';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PasswordPolicy from 'utils/password-validators/PasswordPolicy';
import PasswordPolicyBuilder from 'utils/password-validators/PasswordPolicyBuilder';
import { formatNumber } from 'utils/format-number';
import { addToast } from 'actions/toasts';
import { setActiveOrganizationData, setOrganizations } from 'features/userSlice';
import { SlideRightFadeContainer } from 'utils/transitions';
import ChipInput from 'components/molecules/ChipInput';
import { updateMemberOrganization } from 'api/portal';
import FormControlLabel from 'components/mui/FormControlLabel';
import Button from 'components/mui/Button';
import Select from 'components/mui/Select';
import { Checkbox, Chip, FormControl, FormGroup, Grid, Paper } from '@mui/material';
import InputLabel from 'components/mui/InputLabel';
import MenuItem from 'components/mui/MenuItem';
import TextField from 'components/mui/TextField';
import DateFormatEditor from 'components/mui/DateFormatEditor';
import { B4C_POLICY, INTERMEDIATE_POLICY, RIGOROUS_POLICY } from 'utils/password-validators';

const corporateEmailAttr = 'email';
const corporatePhoneAttr = 'phone_number';
const documentAttr = 'user__document';
const firstNameAttr = 'user__first_name';
const lastNameAttr = 'user__last_name';
const personalEmailAttr = 'user__email';
const personalPhoneAttr = 'user__phone_number';
const birthDateAttr = 'user__birth_date';
const organizationNameAttr = 'organization__name';

const CORPORATE_EMAIL_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.corporate-email-label" />
);

const CORPORATE_PHONE_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.corporate-phone-label" />
);

const PERSONAL_EMAIL_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.personal-email-label" />
);

const PERSONAL_PHONE_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.personal-phone-label" />
);

const REPEATED_LETTERS_LABEL = (
  <I18n path="organization.organization-settings.password-policy.sequential-strings.repeated-letters-label" />
);
const SIMILARITY_RATIO_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.similarity-ratio-label" />
);
const ORGANIZATION_NAME_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.organization-name-label" />
);
const LIST_FORBIDDEN_WORDS_LABEL = (
  <I18n path="organization.organization-settings.password-policy.forbidden-words.list-forbidden-words-label" />
);

const SEQUENTIAL_STR_CASE_SENSITIVE_LABEL = (
  <I18n path="organization.organization-settings.password-policy.sequential-strings.case-sensitive-label" />
);
const SEQUENTIAL_STR_REVERSE_ORDER_LABEL = (
  <I18n path="organization.organization-settings.password-policy.sequential-strings.reverse-order-label" />
);
const COMMON_PASSWORDS_LABEL = (
  <I18n path="organization.organization-settings.password-policy.common-passwords.common-passwords-label" />
);

const SIMILARITY_RATIO_EQUAL_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.similarity-ratio-equal" />
);
const SIMILARITY_RATIO_LOW_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.similarity-ratio-low" />
);
const SIMILARITY_RATIO_MEDIUM_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.similarity-ratio-medium" />
);
const SIMILARITY_RATIO_HIGH_LABEL = (
  <I18n path="organization.organization-settings.password-policy.personal-data.similarity-ratio-high" />
);

const SAVE_SUCCESS_MESSAGE = <I18n path="organization.organization-settings.save-success-message" />;
const SAVE_FAILURE_MESSAGE = <I18n path="organization.organization-settings.save-failure-message" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

const ERROR_RANGE = <I18n path="organization.organization-settings.password-policy.errors.range" />;
const ERROR_RANGE_2 = <I18n path="organization.organization-settings.password-policy.errors.range-2" />;
const ERROR_RANGE_3 = <I18n path="organization.organization-settings.password-policy.errors.range-3" />;
const ERROR_RANGE_4 = <I18n path="organization.organization-settings.password-policy.errors.range-4" />;
const ERROR_RANGE_5 = <I18n path="organization.organization-settings.password-policy.errors.range-5" />;
const ERROR_MAX_LENGTH = <I18n path="organization.organization-settings.password-policy.errors.max-length" />;
const ERROR_MAX_NON_ALPHANUMERIC = (
  <I18n path="organization.organization-settings.password-policy.errors.max-non-alphanumeric-chars" />
);
const ERROR_MAX_DIGITS = <I18n path="organization.organization-settings.password-policy.errors.max-digits" />;
const ERROR_MAX_UPPERCASE = <I18n path="organization.organization-settings.password-policy.errors.max-uppercase" />;
const ERROR_MAX_LOWERCASE = <I18n path="organization.organization-settings.password-policy.errors.max-lowercase" />;
const ERROR_DATE_FORMATS = <I18n path="organization.organization-settings.password-policy.errors.date-formats" />;
const ERROR_EXISTING_FORBIDDEN_WORDS = (
  <I18n path="organization.organization-settings.password-policy.errors.existing-forbidden-words" />
);
const ERROR_EXISTING_DATE_FORMAT = (
  <I18n path="organization.organization-settings.password-policy.errors.existing-date-format" />
);

function OrganizationPasswordPolicy() {
  const organization = useSelector((state) => state.user.active_organization);
  const passwordPolicy = new PasswordPolicy(organization.password_policy);

  const organizations = useSelector((state) => state.user.organizations);

  const {
    sizeRange,
    nonAlphanumericRange,
    digitsRange,
    uppercaseRange,
    lowercaseRange,
    sequentialStrings,
    excludePersonalInformation,
    forbiddenWords,
    repeatedCharSequences,
  } = passwordPolicy;

  const [maxLength, setMaxLength] = useState(sizeRange.max);
  const [minLength, setMinLength] = useState(sizeRange.min);

  const [maxNonAlphanumeric, setMaxNonAlphanumeric] = useState(nonAlphanumericRange.max);
  const [minNonAlphanumeric, setMinNonAlphanumeric] = useState(nonAlphanumericRange.min);

  const [maxDigits, setMaxDigits] = useState(digitsRange.max);
  const [minDigits, setMinDigits] = useState(digitsRange.min);

  const [maxUppercase, setMaxUppercase] = useState(uppercaseRange.max);
  const [minUppercase, setMinUppercase] = useState(uppercaseRange.min);

  const [maxLowercase, setMaxLowercase] = useState(lowercaseRange.max);
  const [minLowercase, setMinLowercase] = useState(lowercaseRange.min);

  const [maxLengthSeqStrings, setMaxLengthSeqStrings] = useState(sequentialStrings.max);
  const [caseSensitiveSeqStrings, setCaseSensitiveSeqStrings] = useState(sequentialStrings.is_case_sensitive);
  const [reverseOrderSeqStrings, setReverseOrderSeqStrings] = useState(passwordPolicy.sequentialStrings.reverse_order);
  const [repeatedLettersSeqStrings, setRepeatedLettersSeqStrings] = useState(sequentialStrings.repeated_letters);
  const [loopingSeqStrings, setLoopingSeqStrings] = useState(sequentialStrings.looping);

  const [similarityRatio, setSimilarityRatio] = useState(String(excludePersonalInformation.similarity_ratio));
  const [personalData, setPersonalData] = useState(excludePersonalInformation.fields);
  const [dateFormats, setDateFormats] = useState(excludePersonalInformation.date_formatters);

  const [forbiddenWord, setForbiddenWord] = useState('');
  const [listForbiddenWords, setListForbiddenWords] = useState(forbiddenWords.words);
  const [caseSensitiveForbiddenWords, setCaseSensitiveForbiddenWords] = useState(forbiddenWords.is_case_sensitive);

  const [excludeCommonPasswords, setExcludeCommonPasswords] = useState(passwordPolicy.excludeCommonPasswords);

  const [maxLengthRepeatedChars, setMaxLengthRepeatedChars] = useState(repeatedCharSequences.max);
  const [caseSensitiveRepeatedChars, setCaseSensitiveRepeatedChars] = useState(repeatedCharSequences.is_case_sensitive);

  const [maxRepetitions, setMaxRepetitions] = useState(passwordPolicy.passwordReuse);

  const [errors, setErrors] = useState(new Map());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasAttr = (attr) => personalData.some((value) => value === attr);

  const addPersonalAttribute = (attr) => {
    if (hasAttr(attr)) {
      setPersonalData(personalData.filter((value) => value !== attr));
    } else {
      setPersonalData([...personalData, attr]);
    }
  };

  const removeForbiddenWord = (word) => {
    setListForbiddenWords(listForbiddenWords.filter((value) => value !== word));
  };

  const addForbiddenWord = (event) => {
    if (event.code === 'Enter') {
      const word = event.target.value;

      const hasWord = listForbiddenWords.some((value) => value === word);

      if (hasWord) {
        dispatch(addToast('error', ERROR_EXISTING_FORBIDDEN_WORDS));
        return;
      }

      setListForbiddenWords([...listForbiddenWords, forbiddenWord]);
      setForbiddenWord('');
    }
  };

  const removeDateFormat = (dateFormat) => {
    setDateFormats(dateFormats.filter((value) => value !== dateFormat));
  };

  const selectDateFormat = (dateFormat) => {
    const hasDateFormat = dateFormats.some((value) => value === dateFormat);

    if (hasDateFormat) {
      dispatch(addToast('error', ERROR_EXISTING_DATE_FORMAT));
      return;
    }

    setDateFormats([...dateFormats, dateFormat]);
  };

  const pretiffyDateFormat = (dateFormat) => {
    const map = new Map([
      ['%d', '20'],
      ['%m', '03'],
      ['%b', 'Mar.'],
      ['%B', 'March'],
      ['%y', '99'],
      ['%Y', '1999'],
    ]);

    const format1 = dateFormat.slice(0, 2);
    const prettyFormat1 = map.get(format1);

    const separatorsAndFormat2 = dateFormat.slice(2, -2);

    const match = /%[A-Za-z]/.exec(separatorsAndFormat2);
    let prettyFormat2 = '';

    if (match !== null) {
      const format2 = match[0];
      prettyFormat2 = separatorsAndFormat2.replace(format2, map.get(format2));
    }

    const format3 = dateFormat.slice(-2);
    const prettyFormat3 = map.get(format3);

    return `${prettyFormat1}${prettyFormat2}${prettyFormat3}`;
  };

  const isInInterval = (value, min, max) => max >= value && value >= min;

  const castToNumber = (value) => {
    if (value === undefined) {
      return value;
    }

    if (value === '') {
      return undefined;
    }

    return Number(value);
  };

  const validateForm = () => {
    const minLengthNumber = castToNumber(minLength);
    const maxLengthNumber = castToNumber(maxLength);

    const minDigitsNumber = castToNumber(minDigits);
    const maxDigitsNumber = castToNumber(maxDigits);

    const minNonAlphanumericNumber = castToNumber(minNonAlphanumeric);
    const maxNonAlphanumericNumber = castToNumber(maxNonAlphanumeric);

    const minUppercaseNumber = castToNumber(minUppercase);
    const maxUppercaseNumber = castToNumber(maxUppercase);

    const minLowercaseNumber = castToNumber(minLowercase);
    const maxLowercaseNumber = castToNumber(maxLowercase);

    const maxLengthSeqStringsNumber = castToNumber(maxLengthSeqStrings);

    const maxLengthRepeatedCharsNumber = castToNumber(maxLengthRepeatedChars);

    const maxRepetitionsNumber = castToNumber(maxRepetitions);

    const errors = new Map();
    // Required fields
    if (maxLengthNumber === undefined) {
      errors.set('maxLength', ERROR_RANGE);
    }

    if (minLengthNumber === undefined) {
      errors.set('minLength', ERROR_RANGE);
    }
    // Range of the max and min length
    if (maxLengthNumber !== undefined && !isInInterval(maxLengthNumber, 6, 128)) {
      errors.set('maxLength', ERROR_RANGE);
    }

    if (minLengthNumber !== undefined && !isInInterval(minLengthNumber, 6, 128)) {
      errors.set('minLength', ERROR_RANGE);
    }
    // Checking that the min lengh < max length
    if (!errors.has('maxLength') && !errors.has('minLength')) {
      if (maxLengthNumber !== undefined && minLengthNumber !== undefined && maxLengthNumber < minLengthNumber) {
        errors.set('maxLength', ERROR_MAX_LENGTH);
      }
    }
    // Range of the max and min number of digits
    if (maxDigitsNumber !== undefined && !isInInterval(maxDigitsNumber, 0, 128)) {
      errors.set('maxDigits', ERROR_RANGE_2);
    }

    if (minDigitsNumber !== undefined && !isInInterval(minDigitsNumber, 0, 128)) {
      errors.set('minDigits', ERROR_RANGE_2);
    }
    // Checking that the min number of digits < max number of digits
    if (!errors.has('maxDigits') && !errors.has('minDigits')) {
      if (maxDigitsNumber !== undefined && minDigitsNumber !== undefined && maxDigitsNumber < minDigitsNumber) {
        errors.set('maxDigits', ERROR_MAX_DIGITS);
      }
    }
    // Range of the max and min number of non-alphanumeric chars
    if (maxNonAlphanumericNumber !== undefined && !isInInterval(maxNonAlphanumericNumber, 0, 128)) {
      errors.set('maxNonAlphanumeric', ERROR_RANGE_2);
    }

    if (minNonAlphanumericNumber !== undefined && !isInInterval(minNonAlphanumericNumber, 0, 128)) {
      errors.set('minNonAlphanumeric', ERROR_RANGE_2);
    }
    // Checking that the min number of non-alphanumeric chars < max number of non-alphanumeric chars
    if (!errors.has('maxNonAlphanumeric') && !errors.has('minNonAlphanumeric')) {
      if (
        maxNonAlphanumericNumber !== undefined &&
        minNonAlphanumericNumber !== undefined &&
        maxNonAlphanumericNumber < minNonAlphanumericNumber
      ) {
        errors.set('maxNonAlphanumeric', ERROR_MAX_NON_ALPHANUMERIC);
      }
    }
    // Range of the max and min number of uppercase letters
    if (maxUppercaseNumber !== undefined && !isInInterval(maxUppercaseNumber, 0, 128)) {
      errors.set('maxUppercase', ERROR_RANGE_2);
    }

    if (minUppercaseNumber !== undefined && !isInInterval(minUppercaseNumber, 0, 128)) {
      errors.set('minUppercase', ERROR_RANGE_2);
    }
    // Checking that the min number of uppercase letters < max number of uppercase letters
    if (!errors.has('maxUppercase') && !errors.has('minUppercase')) {
      if (
        maxUppercaseNumber !== undefined &&
        minUppercaseNumber !== undefined &&
        maxUppercaseNumber < minUppercaseNumber
      ) {
        errors.set('maxUppercase', ERROR_MAX_UPPERCASE);
      }
    }
    // Range of the max and min number of lowercase letters
    if (maxLowercaseNumber !== undefined && !isInInterval(maxLowercaseNumber, 0, 128)) {
      errors.set('maxLowercase', ERROR_RANGE_2);
    }

    if (minLowercaseNumber !== undefined && !isInInterval(minLowercaseNumber, 0, 128)) {
      errors.set('minLowercase', ERROR_RANGE_2);
    }
    // Checking that the min number of lowercase letters < max number of lowercase letters
    if (!errors.has('maxLowercase') && !errors.has('minLowercase')) {
      if (
        !maxLowercaseNumber !== undefined &&
        !minLowercaseNumber !== undefined &&
        maxLowercaseNumber < minLowercaseNumber
      ) {
        errors.set('maxLowercase', ERROR_MAX_LOWERCASE);
      }
    }
    // Checking that the min number of digits is enough to fulfill other minimal requirements
    if (
      !errors.has('minLength') &&
      !errors.has('minDigits') &&
      !errors.has('minNonAlphanumeric') &&
      !errors.has('minUppercase') &&
      !errors.has('minLowercase')
    ) {
      const minToFulfillMinChars = minDigitsNumber + minNonAlphanumericNumber + minUppercaseNumber + minLowercaseNumber;

      if (minLengthNumber < minToFulfillMinChars) {
        const ERROR_MIN_LENGTH = (
          <I18n
            props={{
              min_length: minLengthNumber,
              min_required: minToFulfillMinChars,
            }}
            path="organization.organization-settings.password-policy.errors.min-length"
          />
        );
        errors.set('minLength', ERROR_MIN_LENGTH);
      }
    }
    // Range of the max length of sequential strings
    if (maxLengthSeqStringsNumber !== undefined && !isInInterval(maxLengthSeqStringsNumber, 3, 128)) {
      errors.set('maxLengthSeqStrings', ERROR_RANGE_3);
    }
    // Checking that at least one date format was provided if the birth date must be excluded from the password
    const hasBirthDate = hasAttr(birthDateAttr);

    if (hasBirthDate && dateFormats.length === 0) {
      errors.set('dateFormats', ERROR_DATE_FORMATS);
    }
    // Range of the max length of repeated chars
    if (maxLengthRepeatedCharsNumber !== undefined && !isInInterval(maxLengthRepeatedCharsNumber, 2, 128)) {
      errors.set('maxLengthRepeatedChars', ERROR_RANGE_4);
    }
    // Range of the number of passwords whose reuse must be forbidden
    if (maxRepetitionsNumber !== undefined && !isInInterval(maxRepetitionsNumber, 0, 20)) {
      errors.set('maxRepetitions', ERROR_RANGE_5);
    }

    return errors;
  };

  const onSubmit = () => {
    const minLengthNumber = castToNumber(minLength);
    const maxLengthNumber = castToNumber(maxLength);

    const minDigitsNumber = castToNumber(minDigits);
    const maxDigitsNumber = castToNumber(maxDigits);

    const minNonAlphanumericNumber = castToNumber(minNonAlphanumeric);
    const maxNonAlphanumericNumber = castToNumber(maxNonAlphanumeric);

    const minUppercaseNumber = castToNumber(minUppercase);
    const maxUppercaseNumber = castToNumber(maxUppercase);

    const minLowercaseNumber = castToNumber(minLowercase);
    const maxLowercaseNumber = castToNumber(maxLowercase);

    const maxLengthSeqStringsNumber = castToNumber(maxLengthSeqStrings);

    const similarityRatioNumber = castToNumber(similarityRatio);

    const maxLengthRepeatedCharsNumber = castToNumber(maxLengthRepeatedChars);

    const maxRepetitionsNumber = castToNumber(maxRepetitions);

    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      const policy = new PasswordPolicyBuilder();

      policy.addLengthPolicy(minLengthNumber, maxLengthNumber);
      policy.addDigitsPolicy(minDigitsNumber, maxDigitsNumber);
      policy.addNonAlphanumericPolicy(minNonAlphanumericNumber, maxNonAlphanumericNumber);
      policy.addUppercasePolicy(minUppercaseNumber, maxUppercaseNumber);
      policy.addLowercasePolicy(minLowercaseNumber, maxLowercaseNumber);
      policy.addForbiddenWordsPolicy(listForbiddenWords, caseSensitiveForbiddenWords);
      policy.addSequentialStringPolicy(
        maxLengthSeqStringsNumber,
        loopingSeqStrings,
        reverseOrderSeqStrings,
        repeatedLettersSeqStrings,
        caseSensitiveSeqStrings,
      );
      policy.addCommonPasswordsPolicy(excludeCommonPasswords);
      policy.addExcludePersonalInformation(personalData, similarityRatioNumber, dateFormats);
      policy.addRepeatedCharSequencesPolicy(maxLengthRepeatedCharsNumber, caseSensitiveRepeatedChars);
      policy.addPasswordReusePolicy(maxRepetitionsNumber);

      updateMemberOrganization({
        password_policy: policy.passwordPolicy,
      })
        .then((response) => {
          const { data } = response;

          setIsSubmitting(false);

          dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
          dispatch(setActiveOrganizationData(data));
          dispatch(
            setOrganizations(
              organizations.map((item) => {
                if (item.id === data.id) {
                  return data;
                }

                return item;
              }),
            ),
          );
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
        });
    } else {
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  const fillFormWithTemplate = (template) => {
    const passwordPolicy = new PasswordPolicy(template);

    const {
      sizeRange,
      nonAlphanumericRange,
      digitsRange,
      uppercaseRange,
      lowercaseRange,
      sequentialStrings,
      excludePersonalInformation,
      forbiddenWords,
      repeatedCharSequences,
    } = passwordPolicy;

    setMaxLength(sizeRange.max);
    setMinLength(sizeRange.min);

    setMaxNonAlphanumeric(nonAlphanumericRange.max);
    setMinNonAlphanumeric(nonAlphanumericRange.min);

    setMaxDigits(digitsRange.max);
    setMinDigits(digitsRange.min);

    setMaxUppercase(uppercaseRange.max);
    setMinUppercase(uppercaseRange.min);

    setMaxLowercase(lowercaseRange.max);
    setMinLowercase(lowercaseRange.min);

    setMaxLengthSeqStrings(sequentialStrings.max ? sequentialStrings.max : '');
    setCaseSensitiveSeqStrings(sequentialStrings.is_case_sensitive);
    setReverseOrderSeqStrings(sequentialStrings.reverse_order);
    setRepeatedLettersSeqStrings(sequentialStrings.repeated_letters);
    setLoopingSeqStrings(sequentialStrings.looping);

    setSimilarityRatio(String(excludePersonalInformation.similarity_ratio));
    setPersonalData(excludePersonalInformation.fields);
    setDateFormats(excludePersonalInformation.date_formatters);

    setForbiddenWord('');
    setListForbiddenWords(forbiddenWords.words);
    setCaseSensitiveForbiddenWords(forbiddenWords.is_case_sensitive);

    setExcludeCommonPasswords(passwordPolicy.excludeCommonPasswords);

    setMaxLengthRepeatedChars(repeatedCharSequences.max ? repeatedCharSequences.max : '');
    setCaseSensitiveRepeatedChars(repeatedCharSequences.is_case_sensitive);

    setMaxRepetitions(passwordPolicy.passwordReuse);

    setErrors(new Map());
  };

  return (
    <App>
      <Section maxWidth="428px">
        <SlideRightFadeContainer>
          <Subtitle>
            <I18n path="organization.organization-settings.password-policy.subtitle" />
          </Subtitle>

          <Text isItalic>
            <I18n path="organization.organization-settings.password-policy.text" />
          </Text>

          <Text isItalic>
            <I18n path="organization.organization-settings.password-policy.text-2" />
          </Text>

          <Paper sx={{ padding: 2, marginBottom: 3 }}>
            <Grid container columnSpacing={1} sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => fillFormWithTemplate(B4C_POLICY)}
                  data-testid="basic-template-button"
                >
                  <I18n path="organization.organization-settings.password-policy.basic-template" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => fillFormWithTemplate(INTERMEDIATE_POLICY)}
                  data-testid="intermediate-template-button"
                >
                  <I18n path="organization.organization-settings.password-policy.intermediate-template" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => fillFormWithTemplate(RIGOROUS_POLICY)}
                  data-testid="rigorous-template-button"
                >
                  <I18n path="organization.organization-settings.password-policy.rigorous-template" />
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.subsubtitle-length" />
            </SubSubtitle>
            <Grid container direction="row" spacing={1} sx={{ marginTop: 4 }}>
              <Grid item xs={6}>
                <TextField
                  id="min-length"
                  value={minLength}
                  onChange={(event) => setMinLength(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.min-label" />}
                  variant="outlined"
                  error={errors.has('minLength')}
                  helperText={errors.get('minLength')}
                  data-testid="min-length-text-field"
                  inputProps={{ 'data-testid': 'min-length-input' }}
                  FormHelperTextProps={{ 'data-testid': 'min-length-error' }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="max-length"
                  value={maxLength}
                  onChange={(event) => setMaxLength(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.max-label" />}
                  variant="outlined"
                  error={errors.has('maxLength')}
                  helperText={errors.get('maxLength')}
                  data-testid="max-length-text-field"
                  inputProps={{ 'data-testid': 'max-length-input' }}
                  FormHelperTextProps={{ 'data-testid': 'max-length-error' }}
                />
              </Grid>
            </Grid>
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.subsubtitle-non-alphanumeric" />
            </SubSubtitle>
            <Grid container direction="row" spacing={1} sx={{ marginTop: 4 }}>
              <Grid item xs={6}>
                <TextField
                  id="min-non-alphanumeric"
                  value={minNonAlphanumeric}
                  onChange={(event) => setMinNonAlphanumeric(formatNumber(event.target.value))}
                  variant="outlined"
                  label={<I18n path="organization.organization-settings.password-policy.min-label" />}
                  error={errors.has('minNonAlphanumeric')}
                  helperText={errors.get('minNonAlphanumeric')}
                  data-testid="min-non-alphanumeric-text-field"
                  inputProps={{ 'data-testid': 'min-non-alphanumeric-input' }}
                  FormHelperTextProps={{ 'data-testid': 'min-non-alphanumeric-error' }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="max-non-alphanumeric"
                  value={maxNonAlphanumeric}
                  onChange={(event) => setMaxNonAlphanumeric(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.max-label" />}
                  variant="outlined"
                  error={errors.has('maxNonAlphanumeric')}
                  helperText={errors.get('maxNonAlphanumeric')}
                  data-testid="max-non-alphanumeric-text-field"
                  inputProps={{ 'data-testid': 'max-non-alphanumeric-input' }}
                  FormHelperTextProps={{ 'data-testid': 'max-non-alphanumeric-error' }}
                />
              </Grid>
            </Grid>
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.subsubtitle-digits" />
            </SubSubtitle>

            <Grid container direction="row" spacing={1} sx={{ marginTop: 4 }}>
              <Grid item xs={6}>
                <TextField
                  id="min-digits"
                  value={minDigits}
                  onChange={(event) => setMinDigits(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.min-label" />}
                  variant="outlined"
                  error={errors.has('minDigits')}
                  helperText={errors.get('minDigits')}
                  data-testid="min-digits-text-field"
                  inputProps={{ 'data-testid': 'min-digits-input' }}
                  FormHelperTextProps={{ 'data-testid': 'min-digits-error' }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="max-digits"
                  value={maxDigits}
                  onChange={(event) => setMaxDigits(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.max-label" />}
                  variant="outlined"
                  error={errors.has('maxDigits')}
                  helperText={errors.get('maxDigits')}
                  data-testid="max-digits-text-field"
                  inputProps={{ 'data-testid': 'max-digits-input' }}
                  FormHelperTextProps={{ 'data-testid': 'max-digits-error' }}
                />
              </Grid>
            </Grid>
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.subsubtitle-uppercase" />
            </SubSubtitle>

            <Grid container direction="row" spacing={1} sx={{ marginTop: 4 }}>
              <Grid item xs={6}>
                <TextField
                  id="min-uppercase"
                  value={minUppercase}
                  onChange={(event) => setMinUppercase(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.min-label" />}
                  variant="outlined"
                  error={errors.has('minUppercase')}
                  helperText={errors.get('minUppercase')}
                  data-testid="min-uppercase-text-field"
                  inputProps={{ 'data-testid': 'min-uppercase-input' }}
                  FormHelperTextProps={{ 'data-testid': 'min-uppercase-error' }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="max-uppercase"
                  value={maxUppercase}
                  onChange={(event) => setMaxUppercase(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.max-label" />}
                  variant="outlined"
                  error={errors.has('maxUppercase')}
                  helperText={errors.get('maxUppercase')}
                  data-testid="max-uppercase-text-field"
                  inputProps={{ 'data-testid': 'max-uppercase-input' }}
                  FormHelperTextProps={{ 'data-testid': 'max-uppercase-error' }}
                />
              </Grid>
            </Grid>
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.subsubtitle-lowercase" />
            </SubSubtitle>

            <Grid container direction="row" spacing={1} sx={{ marginTop: 4 }}>
              <Grid item xs={6}>
                <TextField
                  id="min-lowercase"
                  value={minLowercase}
                  onChange={(event) => setMinLowercase(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.min-label" />}
                  variant="outlined"
                  error={errors.has('minLowercase')}
                  helperText={errors.get('minLowercase')}
                  data-testid="min-lowercase-text-field"
                  inputProps={{ 'data-testid': 'min-lowercase-input' }}
                  FormHelperTextProps={{ 'data-testid': 'min-lowercase-error' }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="max-lowercase"
                  value={maxLowercase}
                  onChange={(event) => setMaxLowercase(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.password-policy.max-label" />}
                  variant="outlined"
                  error={errors.has('maxLowercase')}
                  helperText={errors.get('maxLowercase')}
                  data-testid="max-lowercase-text-field"
                  inputProps={{ 'data-testid': 'max-lowercase-input' }}
                  FormHelperTextProps={{ 'data-testid': 'max-lowercase-error' }}
                />
              </Grid>
            </Grid>
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.sequential-strings.subsubtitle" />
            </SubSubtitle>

            <Text isItalic>
              <I18n path="organization.organization-settings.password-policy.sequential-strings.text" />
            </Text>

            <TextField
              id="max-length-seq-strings"
              value={maxLengthSeqStrings}
              onChange={(event) => setMaxLengthSeqStrings(formatNumber(event.target.value))}
              label={
                <I18n path="organization.organization-settings.password-policy.sequential-strings.max-length-label" />
              }
              variant="outlined"
              error={errors.has('maxLengthSeqStrings')}
              helperText={errors.get('maxLengthSeqStrings')}
              data-testid="max-length-seq-strings-text-field"
              inputProps={{ 'data-testid': 'max-length-seq-strings-input' }}
              FormHelperTextProps={{ 'data-testid': 'max-length-seq-strings-error' }}
              fullWidth
            />

            <FormGroup>
              <FormControlLabel
                label={SEQUENTIAL_STR_CASE_SENSITIVE_LABEL}
                control={
                  <Checkbox
                    checked={caseSensitiveSeqStrings}
                    onChange={() => setCaseSensitiveSeqStrings(!caseSensitiveSeqStrings)}
                    inputProps={{ 'data-testid': 'case-sensitive-seq-strings-checkbox' }}
                  />
                }
                sx={{ marginTop: 1 }}
              />

              <FormControlLabel
                label={SEQUENTIAL_STR_REVERSE_ORDER_LABEL}
                control={
                  <Checkbox
                    checked={reverseOrderSeqStrings}
                    onChange={() => setReverseOrderSeqStrings(!reverseOrderSeqStrings)}
                    inputProps={{ 'data-testid': 'reverse-order-seq-strings-checkbox' }}
                  />
                }
                sx={{ marginTop: 1 }}
              />

              <FormControlLabel
                label={REPEATED_LETTERS_LABEL}
                control={
                  <Checkbox
                    checked={repeatedLettersSeqStrings}
                    onChange={() => setRepeatedLettersSeqStrings(!repeatedLettersSeqStrings)}
                    inputProps={{ 'data-testid': 'repeated-letters-seq-strings-checkbox' }}
                  />
                }
                sx={{ marginTop: 1 }}
              />

              <FormControlLabel
                label={
                  <I18n path="organization.organization-settings.password-policy.sequential-strings.looping-label" />
                }
                control={
                  <Checkbox
                    checked={loopingSeqStrings}
                    onChange={() => setLoopingSeqStrings(!loopingSeqStrings)}
                    inputProps={{ 'data-testid': 'looping-seq-strings-checkbox' }}
                  />
                }
                sx={{ marginTop: 1 }}
              />
            </FormGroup>
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.personal-data.subsubtitle" />
            </SubSubtitle>

            <Section>
              <Text isItalic>
                <I18n path="organization.organization-settings.password-policy.personal-data.text" />
              </Text>

              <FormGroup>
                <FormControlLabel
                  label={CORPORATE_EMAIL_LABEL}
                  control={
                    <Checkbox
                      checked={hasAttr(corporateEmailAttr)}
                      onClick={() => addPersonalAttribute(corporateEmailAttr)}
                      inputProps={{ 'data-testid': 'corporate-email-checkbox' }}
                    />
                  }
                />

                <FormControlLabel
                  label={CORPORATE_PHONE_LABEL}
                  control={
                    <Checkbox
                      checked={hasAttr(corporatePhoneAttr)}
                      onClick={() => addPersonalAttribute(corporatePhoneAttr)}
                      inputProps={{ 'data-testid': 'corporate-phone-checkbox' }}
                    />
                  }
                />

                <FormControlLabel
                  label={
                    <I18n path="organization.organization-settings.password-policy.personal-data.document-label" />
                  }
                  control={
                    <Checkbox
                      checked={hasAttr(documentAttr)}
                      onClick={() => addPersonalAttribute(documentAttr)}
                      inputProps={{ 'data-testid': 'document-checkbox' }}
                    />
                  }
                />

                <FormControlLabel
                  label={
                    <I18n path="organization.organization-settings.password-policy.personal-data.first-name-label" />
                  }
                  control={
                    <Checkbox
                      checked={hasAttr(firstNameAttr)}
                      onClick={() => addPersonalAttribute(firstNameAttr)}
                      inputProps={{ 'data-testid': 'first-name-checkbox' }}
                    />
                  }
                />

                <FormControlLabel
                  label={
                    <I18n path="organization.organization-settings.password-policy.personal-data.last-name-label" />
                  }
                  control={
                    <Checkbox
                      checked={hasAttr(lastNameAttr)}
                      onClick={() => addPersonalAttribute(lastNameAttr)}
                      inputProps={{ 'data-testid': 'last-name-checkbox' }}
                    />
                  }
                />

                <FormControlLabel
                  label={PERSONAL_EMAIL_LABEL}
                  control={
                    <Checkbox
                      checked={hasAttr(personalEmailAttr)}
                      onClick={() => addPersonalAttribute(personalEmailAttr)}
                      inputProps={{ 'data-testid': 'personal-email-checkbox' }}
                    />
                  }
                />

                <FormControlLabel
                  label={PERSONAL_PHONE_LABEL}
                  control={
                    <Checkbox
                      checked={hasAttr(personalPhoneAttr)}
                      onClick={() => addPersonalAttribute(personalPhoneAttr)}
                      inputProps={{ 'data-testid': 'personal-phone-checkbox' }}
                    />
                  }
                />

                <FormControlLabel
                  label={ORGANIZATION_NAME_LABEL}
                  control={
                    <Checkbox
                      checked={hasAttr(organizationNameAttr)}
                      onClick={() => addPersonalAttribute(organizationNameAttr)}
                      inputProps={{ 'data-testid': 'organization-name-checkbox' }}
                    />
                  }
                />

                <FormControlLabel
                  label={
                    <I18n path="organization.organization-settings.password-policy.personal-data.birth-date-label" />
                  }
                  control={
                    <Checkbox
                      checked={hasAttr(birthDateAttr)}
                      onClick={() => addPersonalAttribute(birthDateAttr)}
                      inputProps={{ 'data-testid': 'birth-date-checkbox' }}
                    />
                  }
                />
              </FormGroup>

              <Text isItalic>
                <I18n path="organization.organization-settings.password-policy.personal-data.date-formats-text" />
              </Text>

              <DateFormatEditor
                disabled={!hasAttr(birthDateAttr)}
                error={errors.has('dateFormats')}
                helperText={errors.get('dateFormats')}
                onClick={selectDateFormat}
                data-testid="date-format-editor"
              />

              {dateFormats.length > 0 && hasAttr(birthDateAttr) ? (
                <>
                  <br />
                  <Text isItalic>
                    <I18n path="organization.organization-settings.password-policy.personal-data.date-formats-label" />
                  </Text>
                  <Paper>
                    {dateFormats.map((dateFormat) => (
                      <Chip
                        key={dateFormat}
                        label={pretiffyDateFormat(dateFormat)}
                        onDelete={() => removeDateFormat(dateFormat)}
                        sx={{ margin: 1 }}
                      />
                    ))}
                  </Paper>
                </>
              ) : null}
            </Section>

            <Section>
              <Text isItalic>
                <I18n path="organization.organization-settings.password-policy.personal-data.similarity-ratio-text" />
              </Text>

              <FormControl fullWidth>
                <InputLabel id="similarity-ratio-label">{SIMILARITY_RATIO_LABEL}</InputLabel>
                <Select
                  labelId="similarity-ratio-label"
                  id="similarity-ratio"
                  value={similarityRatio}
                  onChange={(event) => setSimilarityRatio(event.target.value)}
                  data-testid="similarity-ratio-select"
                  inputProps={{ 'data-testid': 'similarity-ratio-input' }}
                >
                  <MenuItem value="1" data-testid="identical-option">
                    {SIMILARITY_RATIO_EQUAL_LABEL}
                  </MenuItem>
                  <MenuItem value="0.9" data-testid="high-option">
                    {SIMILARITY_RATIO_HIGH_LABEL}
                  </MenuItem>
                  <MenuItem value="0.7" data-testid="medium-option">
                    {SIMILARITY_RATIO_MEDIUM_LABEL}
                  </MenuItem>
                  <MenuItem value="0.3" data-testid="low-option">
                    {SIMILARITY_RATIO_LOW_LABEL}
                  </MenuItem>
                </Select>
              </FormControl>
            </Section>
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.forbidden-words.subsubtitle" />
            </SubSubtitle>

            <Text isItalic>
              <I18n path="organization.organization-settings.password-policy.forbidden-words.text" />
            </Text>

            <ChipInput
              items={listForbiddenWords}
              label={LIST_FORBIDDEN_WORDS_LABEL}
              onCloseItem={(item) => removeForbiddenWord(item)}
              onChange={(event) => setForbiddenWord(event.target.value)}
              onKeyPress={addForbiddenWord}
              value={forbiddenWord}
              data-testid="forbidden-words-input"
            />

            <FormControlLabel
              label={
                <I18n path="organization.organization-settings.password-policy.forbidden-words.case-sensitive-label" />
              }
              control={
                <Checkbox
                  checked={caseSensitiveForbiddenWords}
                  onClick={() => setCaseSensitiveForbiddenWords(!caseSensitiveForbiddenWords)}
                  inputProps={{ 'data-testid': 'case-sensitive-forbidden-words-checkbox' }}
                />
              }
            />
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.common-passwords.subsubtitle" />
            </SubSubtitle>

            <Text isItalic>
              <I18n path="organization.organization-settings.password-policy.common-passwords.text" />
            </Text>

            <FormControlLabel
              label={COMMON_PASSWORDS_LABEL}
              control={
                <Checkbox
                  checked={excludeCommonPasswords}
                  onClick={() => setExcludeCommonPasswords(!excludeCommonPasswords)}
                  inputProps={{ 'data-testid': 'common-passwords-checkbox' }}
                />
              }
            />
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.repeated-chars.subsubtitle" />
            </SubSubtitle>

            <Text isItalic>
              <I18n path="organization.organization-settings.password-policy.repeated-chars.text" />
            </Text>

            <TextField
              id="max-length-repeated-chars"
              sx={{ margin: '0px 0px 8px 0px' }}
              value={maxLengthRepeatedChars}
              onChange={(event) => setMaxLengthRepeatedChars(formatNumber(event.target.value))}
              label={<I18n path="organization.organization-settings.password-policy.repeated-chars.max-length-label" />}
              variant="outlined"
              error={errors.has('maxLengthRepeatedChars')}
              helperText={errors.get('maxLengthRepeatedChars')}
              data-testid="max-length-repeated-chars-text-field"
              inputProps={{ 'data-testid': 'max-length-repeated-chars-input' }}
              FormHelperTextProps={{ 'data-testid': 'max-length-repeated-chars-error' }}
              fullWidth
            />

            <FormControlLabel
              label={
                <I18n path="organization.organization-settings.password-policy.repeated-chars.case-sensitive-label" />
              }
              control={
                <Checkbox
                  checked={caseSensitiveRepeatedChars}
                  onClick={() => setCaseSensitiveRepeatedChars(!caseSensitiveRepeatedChars)}
                  inputProps={{ 'data-testid': 'case-sensitive-repeated-chars-checkbox' }}
                />
              }
            />
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.password-policy.password-reuse.subsubtitle" />
            </SubSubtitle>

            <Text isItalic>
              <I18n path="organization.organization-settings.password-policy.password-reuse.text" />
            </Text>

            <TextField
              id="max-repetitions"
              value={maxRepetitions}
              onChange={(event) => setMaxRepetitions(formatNumber(event.target.value))}
              label={
                <I18n path="organization.organization-settings.password-policy.password-reuse.max-repetitions-label" />
              }
              variant="outlined"
              error={errors.has('maxRepetitions')}
              helperText={errors.get('maxRepetitions')}
              data-testid="max-repetitions-text-field"
              inputProps={{ 'data-testid': 'max-repetitions-input' }}
              FormHelperTextProps={{ 'data-testid': 'max-repetitions-error' }}
              fullWidth
            />
          </Section>

          <Button loading={isSubmitting} variant="outlined" onClick={onSubmit} data-testid="save-button">
            <I18n path="organization.organization-settings.password-policy.submit-button-text" />
          </Button>
          <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate('/')} data-testid="cancel-button">
            <I18n path="organization.organization-settings.password-policy.cancel-button-text" />
          </Button>
        </SlideRightFadeContainer>
      </Section>
    </App>
  );
}

export default OrganizationPasswordPolicy;
