import { translate } from 'utils/i18n';

export const STATUS_PENDING = translate('utils.status.pending');
export const STATUS_ACTIVE = translate('utils.status.active');
export const STATUS_BLOCKED = translate('utils.status.blocked');
export const STATUS_SUSPENDED = translate('utils.status.suspended');
export const STATUS_DECLINED = translate('utils.status.declined');

export const MEMBER_STATUS = [
  { id: 'ACTIVE', text: STATUS_ACTIVE },
  { id: 'PENDING', text: STATUS_PENDING },
  { id: 'SUSPENDED', text: STATUS_SUSPENDED },
  { id: 'DECLINED', text: STATUS_DECLINED },
  { id: 'BLOCKED', text: STATUS_BLOCKED },
];

export const getStatus = (status) => ({ ...MEMBER_STATUS.find((device) => device.id === status) }).text;
