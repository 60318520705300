import { useEffect, useState } from 'react';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Alert from 'components/atoms/alert';
import Loader from 'components/molecules/section-loader';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import App from 'pages/braincare/AdminOrganizations/members/edit/template';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMember, setOrganizationSelectedMember } from 'features/organizationSlice';
import { deleteMemberByOrganization, fetchMemberByOrganization, updateMemberByOrganization } from 'api/portal';
import braincare from 'components/routes/braincare';
import { addToast } from 'actions/toasts';
import DeleteDialog from './dialogs/delete-dialog';
import SuspendDialog from './dialogs/suspend-dialog';

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const GENERIC_ERROR = <I18n path="messages.generic-error" />;

function SuspendAdmin() {
  const member = useSelector((state) => state.organization.selectedMember);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isToShowSuspendDialog, setIsToShowSuspendDialog] = useState(false);
  const [isToShowDeleteDialog, setIsToShowDeleteDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { orgId, memberId } = params;

  useEffect(() => {
    const { organization } = member;
    const persistedOrgId = organization ? organization.id : undefined;

    if (member.id !== Number(memberId) || persistedOrgId !== Number(orgId)) {
      dispatch(clearOrganizationSelectedMember());

      setIsLoading(true);

      fetchMemberByOrganization(orgId, memberId)
        .then((response) => {
          const { data } = response;
          setIsLoading(false);
          dispatch(setOrganizationSelectedMember(data));
        })
        .catch(() => {
          setIsLoading(false);
          dispatch(addToast('error', LOAD_FAILURE));
          navigate(`/braincare/organizations/${orgId}/members`);
        });
    }
  }, []);

  const changeAccountStatus = (newStatus) => {
    setIsLoading(true);
    setIsToShowSuspendDialog(false);

    updateMemberByOrganization(orgId, memberId, {
      status: newStatus,
    })
      .then((response) => {
        const { data } = response;
        setIsLoading(false);
        dispatch(setOrganizationSelectedMember(data));
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(addToast('error', GENERIC_ERROR));
      });
  };

  const deleteMemberAccount = () => {
    setIsDeleting(true);
    setIsToShowDeleteDialog(false);

    deleteMemberByOrganization(orgId, memberId)
      .then(() => {
        setIsDeleting(false);
        dispatch(clearOrganizationSelectedMember());
        navigate(`/braincare/organizations/${orgId}/members`);
      })
      .catch(() => {
        setIsDeleting(false);
        dispatch(addToast('error', GENERIC_ERROR));
      });
  };

  const renderSuspendDialog = () => (
    <SuspendDialog
      isOpen={isToShowSuspendDialog}
      onClose={() => setIsToShowSuspendDialog(false)}
      onSuspend={() => changeAccountStatus('SUSPENDED')}
      data-testid="suspend-dialog"
    />
  );

  const renderDeleteDialog = () => (
    <DeleteDialog
      isOpen={isToShowDeleteDialog}
      onClose={() => setIsToShowDeleteDialog(false)}
      onDelete={deleteMemberAccount}
      data-testid="delete-dialog"
    />
  );

  const renderSuspendAccount = () => (
    <div>
      <SubSubtitle data-testid="suspend-member-subsubtitle">
        <I18n path="organization.organization-members-edit-suspend.title-sub-member-suspend" />
      </SubSubtitle>
      <Text>
        <I18n path="organization.organization-members-edit-suspend.text-organization-members-suspend" />
      </Text>
      <Button isLoading={isLoading} onClick={() => setIsToShowSuspendDialog(true)} data-testid="suspend-button">
        <I18n path="organization.organization-members-edit-suspend.button-member-suspend" />
      </Button>
    </div>
  );

  const renderUnsuspendAccount = () => (
    <Alert>
      <Text>
        <I18n path="organization.organization-members-edit-suspend-revert.title-box-member-suspended" />
      </Text>
      <Text>
        <I18n path="organization.organization-members-edit-suspend-revert.text-box-organization-member-suspend" />
      </Text>
      <Button type="warning" onClick={() => changeAccountStatus('PENDING')} data-testid="unsuspend-button">
        <I18n path="organization.organization-members-edit-suspend-revert.button-suspend-revert" />
      </Button>
    </Alert>
  );

  const renderStatusSection = () => (member.status === 'SUSPENDED' ? renderUnsuspendAccount() : renderSuspendAccount());

  return (
    <App>
      <SlideRightFadeContainer>
        <Subtitle data-testid="suspend-and-delete-member-subtitle">
          <I18n path="organization.organization-members-edit-suspend.title-main-member-suspend" />
        </Subtitle>
        <Text>
          <I18n path="organization.organization-members-edit-suspend.text-organization-members-suspend-exclude" />
        </Text>
        <Section>{isLoading ? <Loader /> : renderStatusSection()}</Section>

        <Section>
          <SubSubtitle data-testid="delete-member-subsubtitle">
            <I18n path="organization.organization-members-edit-suspend.title-sub-member-exclude" />
          </SubSubtitle>
          <Text>
            <I18n path="organization.organization-members-edit-suspend.text-organization-members-exclude" />
          </Text>
          <Button
            type="danger"
            onClick={() => setIsToShowDeleteDialog(true)}
            isLoading={isDeleting}
            isDisabled={isLoading}
            data-testid="delete-button"
          >
            <I18n path="organization.organization-members-edit-suspend.button-member-exclude" />
          </Button>
        </Section>
        {renderSuspendDialog()}
        {renderDeleteDialog()}
        <Button onClick={() => navigate(`/braincare/organizations/${orgId}/members`)} data-testid="back-button">
          <I18n path="organization.organization-members-edit-suspend.button-cancel" />
        </Button>
      </SlideRightFadeContainer>
    </App>
  );
}

export default braincare(SuspendAdmin);
