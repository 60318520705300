import LinkItem from 'components/atoms/link-item';
import Brand from 'components/atoms/brand';
import LogoBraincare from 'assets/images/logo-braincare.png';
import Text from 'components/atoms/text';
import I18n from 'utils/i18n';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';

function Footer() {
  const language = useSelector((state) => state.user.language);

  const helpLink = language === 'pt-br' ? 'https://suporte.brain4.care' : 'https://help.brain4.care';

  return (
    <footer className={styles.footer}>
      <Text size="medium">
        <LinkItem href="/public/terms-and-service" target="_blank">
          <I18n path="auth.footer.terms-and-services" />
        </LinkItem>
        {' | '}
        <LinkItem href="/public/privacy-policy" target="_blank">
          <I18n path="auth.footer.privacy-policy" />
        </LinkItem>
        {' | '}
        <LinkItem href="/public/security" target="_blank">
          <I18n path="auth.footer.security" />
        </LinkItem>
        {' | '}
        <LinkItem href="https://status.brain4.care" target="_blank">
          <I18n path="auth.footer.system-status" />
        </LinkItem>
        {' | '}
        <LinkItem href={helpLink} target="_blank">
          <I18n path="auth.footer.help" />
        </LinkItem>
      </Text>
      <Brand image={LogoBraincare} title="logo braincare" />
    </footer>
  );
}

export default Footer;
