import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

export { default as Caption } from './caption';
export { default as Head } from './head';
export { default as Body } from './body';
export { default as Row } from './row';
export { default as Cell } from './cell';

const TYPES = {
  default: styles.default,
  borderless: styles.borderless,
};

const Table = ({ children, type, className }) => (
  <table className={classnames(styles.table, TYPES[type], className)}>{children}</table>
);

const TableContainer = (props) =>
  props.canScroll ? (
    <div className={styles.scroll}>
      <Table {...props} />
    </div>
  ) : (
    <Table {...props} />
  );

Table.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
};

Table.defaultProps = {
  type: 'default',
  className: undefined,
};

TableContainer.propTypes = {
  canScroll: PropTypes.bool,
};

TableContainer.defaultProps = {
  canScroll: true,
};

export default TableContainer;
