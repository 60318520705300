import PropTypes from 'prop-types';
import Avatar from 'components/atoms/avatar';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import { Separator } from './styles';
import styles from './styles.module.css';

const ActiveAccount = ({ photo, title, subtitle, buttonText, onClick, ...props }) => (
  <div {...props}>
    <div className={styles.activeAccount}>
      <Avatar image={photo} description="Active account profile image" size="medium" className={styles.profile} />
      <div>
        <Text className={styles.title} data-testid="active-account-title">
          {title}
        </Text>
        <Text data-testid="active-account-subtitle">{subtitle}</Text>
      </div>
    </div>
    <Button
      type="primary"
      onClick={onClick}
      className={styles.button}
      isCentralized
      data-testid="account-settings-button"
    >
      {buttonText}
    </Button>
    <Separator />
  </div>
);

ActiveAccount.propTypes = {
  photo: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  onClick: PropTypes.func.isRequired,
};

ActiveAccount.defaultProps = {
  photo: undefined,
};

export default ActiveAccount;
