import { useState } from 'react';
import Brand from 'components/atoms/brand';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import InputGroup from 'components/atoms/input-group';
import Label from 'components/atoms/input-label';
import LinkItem from 'components/atoms/link-item';
import Radio from 'components/atoms/input-radio';
import Section from 'components/atoms/section';
import Text from 'components/atoms/text';
import Title from 'components/atoms/auth-title';
import InputIcon from 'components/molecules/input-icon';
import Translator from 'components/molecules/translator';
import I18n from 'utils/i18n';
import { SlideRightFadeContainer } from 'utils/transitions';
import BraincareLogo from 'assets/images/logo-braincare.png';
import { Logo } from '../../login/styles';
import styles from './styles.module.css';
import { formatToCPFDocument, unformatDocument } from '../../../../utils/format-documents';
import Footer from '../../../../components/organisms/Footer';

const DOCUMENT_EMPTY_ERROR = <I18n path="auth.login.doc-empty-error" />;
const PASSWORD_EMPTY_ERROR = <I18n path="auth.login.pwd-empty-error" />;

function LoginForm({ isSubmitting, onSubmit }) {
  const [documentType, setDocumentType] = useState(undefined);
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(new Map());
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChangeDocumentType = (event) => {
    setDocumentType(event.target.value);
    setDocument('');
  };

  const handleChangeDocument = (event) => {
    if (documentType === 'ID') {
      setDocument(event.target.value);
    }

    if (documentType === 'CPF') {
      setDocument(unformatDocument(event.target.value));
    }
  };

  const handleSubmit = (data) => {
    const errors = new Map();

    if (!document) {
      errors.set('document', DOCUMENT_EMPTY_ERROR);
    }

    if (!password) {
      errors.set('password', PASSWORD_EMPTY_ERROR);
    }

    if (errors.size > 0) {
      setErrors(errors);
      return;
    }

    setErrors(new Map());

    onSubmit(data);
  };

  return (
    <div className={styles.container} data-testid="login-form">
      <Logo>
        <Brand image={BraincareLogo} title="Braincare logo" />
      </Logo>
      <Section className={styles.login}>
        <SlideRightFadeContainer className={styles.loginContent}>
          <Title className={styles.loginTitle} data-testid="form-title">
            <I18n path="auth.login.title-main-login" />
          </Title>

          <div>
            <Label data-testid="doc-type-label">
              <I18n path="auth.login.input-doc-type-choice" />
            </Label>
            <div className={styles.documentType}>
              <Radio
                id="login-radio-ssn"
                name="documentType"
                value="ID"
                label="ID"
                isChecked={documentType === 'ID'}
                onChange={(event) => handleChangeDocumentType(event)}
                data-testid="radio-ssn"
              />
              <Radio
                id="login-radio-cpf"
                name="documentType"
                value="CPF"
                label="CPF"
                isChecked={documentType === 'CPF'}
                onChange={(event) => handleChangeDocumentType(event)}
                data-testid="radio-cpf"
              />
            </div>
          </div>

          <InputGroup>
            <Label htmlFor="document" data-testid="document-label">
              <I18n path="auth.login.link-input-document" />
            </Label>
            <Input
              id="document"
              name="document"
              value={documentType === 'ID' ? document : formatToCPFDocument(document)}
              placeholder={documentType === 'ID' ? '' : '123.456.789-10'}
              hasError={errors.has('document')}
              errorMessage={errors.get('document')}
              onChange={(event) => handleChangeDocument(event)}
              isDisabled={!documentType}
              maxLength={documentType === 'CPF' ? '14' : '32'}
              autoComplete="new-password"
              data-testid="document-input"
            />
          </InputGroup>

          <InputGroup>
            <Label htmlFor="password" data-testid="password-label">
              <I18n path="auth.login.input-pwd" />
            </Label>
            <InputIcon
              id="password"
              name="password"
              value={password}
              hasError={errors.has('password')}
              errorMessage={errors.get('password')}
              onChange={(event) => setPassword(event.target.value)}
              type={isPasswordVisible ? 'text' : 'password'}
              icon={isPasswordVisible ? 'eye' : 'eye-blocked'}
              onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
              autoComplete="new-password"
              data-testid="password-input"
            />
          </InputGroup>

          <LinkItem href="/auth/forgot-password" data-testid="forgot-password-link">
            <I18n path="auth.login.link-password-reset" />
          </LinkItem>

          <Button
            type="primary"
            isLarge
            isBlock
            className={styles.button}
            onClick={() => handleSubmit({ document, password })}
            isLoading={isSubmitting}
            data-testid="submit-button"
          >
            <I18n path="auth.login.button-login" />
          </Button>

          <Text size="medium" className={styles.signUp}>
            <I18n path="auth.login.text-signup" />
            &nbsp;
            <LinkItem href="/auth/signup" data-testid="signup-link">
              <I18n path="auth.login.link-signup" />
            </LinkItem>
          </Text>

          <Footer />
        </SlideRightFadeContainer>
        <Translator />
      </Section>
    </div>
  );
}

export default LoginForm;
