import { useEffect, useState } from 'react';
import braincare from 'components/routes/braincare';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Alert from 'components/atoms/alert';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Loader from 'components/molecules/section-loader';
import I18n from 'utils/i18n';
import { SlideRightFadeContainer } from 'utils/transitions';
import App from 'pages/braincare/AdminUsers/edit/template';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteBuUser, fetchBuUser, updateBuUser } from 'api/portal';
import { useDispatch } from 'react-redux';

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const SAVE_SUCCESS = <I18n path="messages.save-success" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;
const DELETE_SUCCESS = <I18n path="messages.delete-success" />;
const DELETE_FAILURE = <I18n path="messages.delete-failure" />;

function Suspend() {
  const [id, setId] = useState(-1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuspending, setIsSuspending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSuspendDialog, setShowSuspendDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const handleFetchUser = () => {
    const { userId } = params;

    setIsLoading(true);

    fetchBuUser(userId)
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setId(data.id);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setIsActive(data.is_active);
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(addToast('error', LOAD_FAILURE));
        navigate('/braincare/users');
      });
  };

  useEffect(() => {
    handleFetchUser();
  }, []);

  const changeAccountStatus = (status) => {
    setShowSuspendDialog(false);
    setIsSuspending(true);

    const formData = new FormData();

    formData.append('is_active', status);

    updateBuUser(id, formData)
      .then(() => {
        setIsSuspending(false);
        dispatch(addToast('success', SAVE_SUCCESS));
        setIsActive(status);
      })
      .catch(() => {
        setIsSuspending(false);
        dispatch(addToast('error', SAVE_FAILURE));
      });
  };

  const deleteUserAccount = () => {
    setShowDeleteDialog(false);
    setIsDeleting(true);

    deleteBuUser(id)
      .then(() => {
        setIsDeleting(false);
        dispatch(addToast('success', DELETE_SUCCESS));
        navigate('/braincare/users');
      })
      .catch(() => {
        setIsDeleting(false);
        dispatch(addToast('error', DELETE_FAILURE));
      });
  };

  const renderSuspendDialog = () => (
    <Dialog isOpen={showSuspendDialog} data-testid="suspend-dialog">
      <Header onClose={() => setShowSuspendDialog(false)}>
        <I18n path="braincare.users-edit-suspend-box.title-box-member-suspend" />
      </Header>
      <Body>
        <Text>
          <I18n path="braincare.users-edit-suspend-box.box-text-organization-members-exclude" />
        </Text>
      </Body>
      <Footer>
        <Button isBlock onClick={() => setShowSuspendDialog(false)} type="ghost" data-testid="dismiss-button">
          <I18n path="braincare.users-edit-suspend-box.button-cancel" />
        </Button>
        <Button
          isBlock
          type="primary"
          onClick={() => changeAccountStatus(false)}
          data-testid="confirm-suspension-button"
        >
          <I18n path="braincare.users-edit-suspend-box.button-suspend" />
        </Button>
      </Footer>
    </Dialog>
  );

  const renderDeleteDialog = () => (
    <Dialog isOpen={showDeleteDialog} data-testid="delete-dialog">
      <Header onClose={() => setShowDeleteDialog(false)}>
        <I18n path="braincare.users-edit-suspend-exclude.title-box-member-exclude" />
      </Header>
      <Body>
        <Text>
          <I18n path="braincare.users-edit-suspend-exclude.text-box-organization-members-delete" />
        </Text>
      </Body>
      <Footer>
        <Button isBlock onClick={() => () => setShowDeleteDialog(false)} type="ghost" data-testid="dismiss-button">
          <I18n path="braincare.users-edit-suspend-exclude.box-button-cancel" />
        </Button>
        <Button isBlock type="primary" onClick={deleteUserAccount} data-testid="confirm-deletion-button">
          <I18n path="braincare.users-edit-suspend-exclude.box-button-suspend" />
        </Button>
      </Footer>
    </Dialog>
  );

  const renderSuspendAccount = () => (
    <div>
      <SubSubtitle data-testid="subsubtitle-suspend">
        <I18n path="braincare.users-edit-suspend.title-sub-member-suspend" />
      </SubSubtitle>
      <Text>
        <I18n path="braincare.users-edit-suspend.text-organization-members-suspend" />
      </Text>
      <Button isLoading={isSuspending} onClick={() => setShowSuspendDialog(true)} data-testid="suspend-button">
        <I18n path="braincare.users-edit-suspend.button-member-suspend" />
      </Button>
    </div>
  );

  const renderUnsuspendAccount = () => (
    <Alert>
      <Text>
        <I18n path="braincare.users-edit-suspend-revert.title-box-member-suspended" />
      </Text>
      <Text>
        <I18n path="braincare.users-edit-suspend-revert.text-box-organization-member-suspend" />
      </Text>
      <Button
        type="warning"
        isLoading={isSuspending}
        onClick={() => changeAccountStatus(true)}
        data-testid="unsuspend-button"
      >
        <I18n path="braincare.users-edit-suspend-revert.button-suspend-revert" />
      </Button>
    </Alert>
  );

  return (
    <App user={{ id, name: `${firstName} ${lastName}` }}>
      {isLoading ? (
        <Loader />
      ) : (
        <SlideRightFadeContainer>
          <Subtitle>
            <I18n path="braincare.users-edit-suspend.title-main-member-suspend" />
          </Subtitle>
          <Text>
            <I18n path="braincare.users-edit-suspend.text-organization-members-suspend-exclude" />
          </Text>
          <Section>{!isActive ? renderUnsuspendAccount() : renderSuspendAccount()}</Section>

          <Section>
            <SubSubtitle data-testid="subsubtitle-delete">
              <I18n path="braincare.users-edit-suspend.title-sub-member-exclude" />
            </SubSubtitle>
            <Text>
              <I18n path="braincare.users-edit-suspend.text-organization-members-exclude" />
            </Text>
            <Button
              type="danger"
              onClick={() => setShowDeleteDialog(true)}
              isLoading={isDeleting}
              isDisabled={isLoading}
              data-testid="delete-button"
            >
              <I18n path="braincare.users-edit-suspend.button-member-exclude" />
            </Button>
          </Section>
          {renderSuspendDialog()}
          {renderDeleteDialog()}
          <Button onClick={() => navigate('/braincare/users')} data-testid="cancel-button">
            <I18n path="braincare.users-edit-suspend.button-cancel" />
          </Button>
        </SlideRightFadeContainer>
      )}
    </App>
  );
}

export default braincare(Suspend);
