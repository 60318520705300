import styled, { keyframes } from 'styled-components';

import CardRaw from 'components/atoms/card/index';
import { Card as CardMixin } from 'utils/mixins';

const SlideFadeInCardAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px) scaleX(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scaleX(1);
  }
`;

export const AnimCardContainer = styled.div`
  opacity: 0;
  animation: ${SlideFadeInCardAnim} 500ms ease forwards;
  animation-delay: ${(props) => props.delay || 0}ms;
`;

export const Card = styled(CardRaw)`
  width: 230px;
  height: 240px;
  text-align: center;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  margin-bottom: 16px;

  ${CardMixin}
`;
