import styled from 'styled-components';
import { Container } from 'utils/mixins';

export const ContentWrapper = styled.div`
  padding: 88px 40px 0;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  ${Container}
`;
