import { Component } from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'react-outside-click-handler';
import { Container, Bars, Menu, Loader } from './styles';

class PopupMenu extends Component {
  state = {
    open: false,
  };

  render() {
    return (
      <Container>
        {!this.props.isLoading ? (
          <>
            <ClickOutside onOutsideClick={() => this.setState({ open: false })}>
              <Bars className="fas fa-bars" onClick={() => this.setState({ open: true })} />
            </ClickOutside>
            <Menu mount={this.props.mount} open={this.state.open}>
              {this.props.children}
            </Menu>
          </>
        ) : (
          <Loader />
        )}
      </Container>
    );
  }
}

PopupMenu.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  mount: PropTypes.string,
};

PopupMenu.defaultProps = {
  isLoading: false,
  mount: 'top right',
};

export default PopupMenu;
