import { combineReducers } from '@reduxjs/toolkit';
import notificationsSlice from 'features/notificationsSlice';
import organizationSlice from 'features/organizationSlice';
import userSlice from 'features/userSlice';
import toasts from './toasts';

export default combineReducers({
  notifications: notificationsSlice,
  organization: organizationSlice,
  user: userSlice,
  toasts,
});
