import { useState } from 'react';
import AppWizard from 'components/templates/app-wizard';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createMember, signupMember } from 'api/portal';
import PersonalInfo from './steps/personal-info';
import MemberInfo from './steps/member-info';

const STEPS = [
  // eslint-disable-next-line react/jsx-key
  <I18n path="organization.organization-members-create.wizard-personal-info" />,
  // eslint-disable-next-line react/jsx-key
  <I18n path="organization.organization-members-create.wizard-organization-info" />,
];

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_ORGANIZATION = <I18n path="organization.breadcrumbs.manage-organization" />;
const ADD_MEMBER = <I18n path="organization.breadcrumbs.add-member" />;

const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const FILL_ALL_REQUIRED = <I18n path="messages.fill-all-required" />;
const SELECT_AT_LEAST_ONE = <I18n path="messages.select-at-least-one" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

const MESSAGE_MEMBER_EXISTS = <I18n path="organization.organization-members-create.message-member-exists" />;
const SAVE_MEMBER_FAILURE = <I18n path="organization.organization-members-create.save-member-failure" />;
const SAVE_INFO_FAILURE = <I18n path="organization.organization-members-create.save-info-failure" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/organization/members',
    title: MANAGE_ORGANIZATION,
  },
  {
    path: '/organization/members/create',
    title: ADD_MEMBER,
    isActive: true,
  },
];

const MAP_FIELDS = {
  document: 'document',
  first_name: 'firstName',
  last_name: 'lastName',
  birth_date: 'birthYear',
  document_type: 'documentType',
  doctor_document: 'doctorDocument',
  email: 'email',
  phone_number: 'phoneNumber',
  roles: 'roles',
  units: 'units',
  notification_language: 'notificationLanguage',
};

// TODO - treat errors better
function Create() {
  const [personalData, setPersonalData] = useState({
    document: '',
    documentType: 'CPF',
    firstName: '',
    lastName: '',
    email: '',
    birthYear: '',
    notificationLanguage: 'pt-br',
  });
  const [corporateData, setCorporateData] = useState({
    email: '',
    phoneNumber: '',
    roles: [],
    units: [],
  });
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [errors, setErrors] = useState(new Map());
  const [errorsMember, setErrorsMember] = useState(new Map());
  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSaveUser = (data) => {
    const { userId, document, documentType, firstName, lastName, email, notificationLanguage } = data;

    const requiredFields = {
      firstName,
      lastName,
      email,
      documentType,
      document,
      notificationLanguage,
    };

    const errors = new Map();

    if (!userId) {
      Object.entries(requiredFields).forEach(([key, value]) => {
        if (!value) {
          errors.set(key, FILL_REQUIRED);
        }
      });
    }

    setErrors(errors);

    if (errors.size) {
      dispatch(addToast('error', FILL_ALL_REQUIRED));
    } else {
      setPersonalData(data);
      setStep(step + 1);
    }
  };

  const requestCreateMember = (userId, memberData) => {
    const { isNewUser } = personalData;

    const { email, phoneNumber, roles, units } = memberData;

    createMember({
      user: userId,
      email,
      phone_number: phoneNumber,
      roles,
      units,
      is_new_user: isNewUser,
    })
      .then(() => {
        setIsLoadingSave(false);
        navigate('/organization/members');
      })
      .catch((error) => {
        setIsLoadingSave(false);

        const { response } = error;
        const { data } = response;

        const errors = new Map();
        Object.entries(data).forEach(([key, value]) => {
          errors.set(MAP_FIELDS[key], value[0]);
        });

        setErrorsMember(errors);

        dispatch(addToast('error', SAVE_MEMBER_FAILURE));

        if (data.non_field_errors) {
          dispatch(addToast('error', MESSAGE_MEMBER_EXISTS));
        }
      });
  };

  const requestCreateUser = (personalData, memberData) => {
    const { document, documentType, firstName, lastName, email, birthYear, notificationLanguage } = personalData;

    signupMember({
      document,
      document_type: documentType,
      first_name: firstName,
      last_name: lastName,
      email,
      birth_date: `${birthYear}-01-01`,
      has_read_privacy_policy: false,
      has_read_terms_and_conditions: false,
      accept_terms: true,
      notification_language: notificationLanguage,
    })
      .then((response) => {
        setIsLoadingSave(false);

        const { data } = response;

        requestCreateMember(data.id, memberData);
      })
      .catch((error) => {
        setIsLoadingSave(false);

        const { response } = error;
        const { data } = response;

        const errors = new Map();

        Object.entries(data).forEach(([key, value]) => {
          errors.set(MAP_FIELDS[key], value[0]);
        });

        setErrors(errors);
        dispatch(addToast('error', SAVE_INFO_FAILURE));
      });
  };

  const onSaveMember = (data) => {
    const { userId } = personalData;

    const { email, phoneNumber, roles } = data;

    const requiredFields = {
      email,
      phoneNumber,
      roles,
    };

    const errors = new Map();

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (key === 'roles' && value.length <= 0) {
        errors.set(key, SELECT_AT_LEAST_ONE);
      } else if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    setErrorsMember(errors);

    if (errors.size) {
      dispatch(addToast('error', FIX_FIELDS));
      return;
    }

    setIsLoadingSave(true);
    setCorporateData(data);

    if (userId !== null) {
      requestCreateMember(userId, data);
    } else {
      requestCreateUser(personalData, data);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <PersonalInfo errors={errors} initialData={personalData} onSave={onSaveUser} />;
      case 1:
        return (
          <MemberInfo
            errors={errorsMember}
            initialData={corporateData}
            isLoadingSave={isLoadingSave}
            onPreviousStep={() => setStep(step - 1)}
            onSave={onSaveMember}
          />
        );
      default:
        return null;
    }
  };

  return (
    <AppWizard title={ADD_MEMBER} breadcrumbs={breadcrumbs} steps={STEPS} currentStep={step}>
      <SlideRightFadeContainer>
        <Section>{renderStep()}</Section>
      </SlideRightFadeContainer>
    </AppWizard>
  );
}

export default Create;
