import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Input = ({
  id,
  type,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  hasError,
  errorMessage,
  className,
  wrapperClassName,
  placeholder,
  isDisabled,
  inputRef,
  onKeyPress,
  ...props
}) => (
  <div className={wrapperClassName}>
    <input
      id={id}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={isDisabled}
      ref={inputRef}
      className={classNames(styles.input, { [styles.error]: hasError, [styles.disabled]: isDisabled }, className)}
      onKeyPress={onKeyPress}
      {...props}
    />
    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
  </div>
);

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.instanceOf(Object),
};

Input.defaultProps = {
  id: undefined,
  type: 'text',
  name: undefined,
  hasError: false,
  isDisabled: false,
  errorMessage: undefined,
  className: undefined,
  wrapperClassName: undefined,
  placeholder: undefined,
  inputRef: undefined,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onKeyPress: () => {},
};

export default Input;
