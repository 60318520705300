import styled from 'styled-components';

export const Form = styled.div`
  margin: 32px auto;
  width: 384px;
`;

export const FormButtons = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  & > * {
    flex: 1;
  }
`;
