import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AppMenu from 'components/templates/app-menu';
import I18n from 'utils/i18n';
import { useParams } from 'react-router-dom';

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_ORGANIZATION = <I18n path="organization.breadcrumbs.manage-organization" />;
const EDIT_MEMBER = <I18n path="organization.breadcrumbs.edit-member" />;

const INFO = <I18n path="organization.organization-members-edit.title-sub-member-edit" />;
const PERMISSIONS = <I18n path="organization.organization-members-edit-permissions.title-sub-member-roles" />;
const SUSPEND = <I18n path="organization.organization-members-edit-suspend.title-main-member-suspend" />;
const CHANGE_PASSWORD = (
  <I18n path="organization.organization-members-edit-change-password.title-main-member-change-password" />
);

const App = ({ children }) => {
  const { pathname, search } = window.location;

  const params = useParams();

  const member = useSelector((state) => state.organization.selectedMember);

  const { memberId } = params;

  const URL_INFO = `/organization/members/${memberId}/edit/info`;
  const URL_PERMISSIONS = `/organization/members/${memberId}/edit/permissions`;
  const URL_SUSPEND = `/organization/members/${memberId}/edit/suspend`;
  const URL_CHANGE_PASSWORD = `/organization/members/${memberId}/edit/password`;

  const SIDE_MENU = [
    {
      href: URL_INFO,
      text: INFO,
      isActive: pathname === URL_INFO,
    },
    {
      href: URL_PERMISSIONS,
      text: PERMISSIONS,
      isActive: pathname === URL_PERMISSIONS,
    },
    {
      href: URL_SUSPEND,
      text: SUSPEND,
      isActive: pathname === URL_SUSPEND,
    },
    {
      href: URL_CHANGE_PASSWORD,
      text: CHANGE_PASSWORD,
      isActive: pathname === URL_CHANGE_PASSWORD,
    },
  ];

  const getBreadcrumb = (path) => {
    switch (path) {
      case URL_INFO:
        return INFO;
      case URL_PERMISSIONS:
        return PERMISSIONS;
      case URL_SUSPEND:
        return SUSPEND;
      case URL_CHANGE_PASSWORD:
        return CHANGE_PASSWORD;
      default:
        return '';
    }
  };

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/members',
      title: MANAGE_ORGANIZATION,
    },
    {
      path: `${pathname}${search}`,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];
  const TITLE = member.user ? `${member.user.first_name} ${member.user.last_name}` : EDIT_MEMBER;

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={TITLE} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
