import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Title from 'components/atoms/auth-title';
import Section from 'components/atoms/section';
import Label from 'components/atoms/input-label';
import InputGroup from 'components/atoms/input-group';
import Button from 'components/atoms/button';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Loader from 'components/molecules/section-loader';
import Text from 'components/atoms/text';
import InputIcon from 'components/molecules/input-icon';
import { SlideFadeInContainer } from 'utils/transitions';
import Pagination from 'components/organisms/pagination';
import Card from 'components/molecules/mobile-device-card';
import I18n, { translate } from 'utils/i18n';
import { addToast } from 'actions/toasts';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchMobileDevices } from 'api/portal';
import StatusSetupDialog from '../common/StatusSetupDialog';
import styles from './styles.module.css';

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_DEVICES = <I18n path="organization.breadcrumbs.manage-devices" />;
const TITLE = <I18n path="organization.organization-mobile-devices-list.title-device-list" />;
const LOAD_FAILURE = <I18n path="organization.organization-mobile-devices-list.loading-failure" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/org-mobile-devices/devices',
    title: MANAGE_DEVICES,
    isActive: true,
  },
];

const PAGE_SIZE = 8;

function OrganizationMobileDeviceList() {
  const [devices, setDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [loadingError, setLoadingError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchDevices = (page, status = selectedStatus, text = filterValue) => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', PAGE_SIZE);

    const payload = {};

    if (status !== '') {
      payload.status = status;
    }

    if (text !== '') {
      payload.generic_text = text;
    }

    setLoadingError(false);
    setIsLoadingDevices(true);

    fetchMobileDevices(searchParams, payload)
      .then((response) => {
        const { data } = response;

        setIsLoadingDevices(false);
        setDevices(data.results);
        setRows(data.count);
        setCurrentPage(page);
        setLoadingError(false);
      })
      .catch(() => {
        setLoadingError(true);
        setIsLoadingDevices(false);
        dispatch(addToast('error', LOAD_FAILURE));
      });
  };

  useEffect(() => {
    fetchDevices(1);
  }, []);

  const handleClearFilterClick = () => {
    setFilterValue('');
    setSelectedStatus('');
    fetchDevices(1, '', '');
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const renderDevices = () => {
    if (loadingError) {
      return (
        <Text isError data-testid="loading-error">
          {LOAD_FAILURE}
        </Text>
      );
    }

    if (devices.length === 0) {
      return (
        <Text data-testid="no-results">
          <I18n path="organization.organization-devices.no-active-devices" />
        </Text>
      );
    }

    return (
      <Section className={styles.devicesContainer}>
        {devices.map((device) => (
          <Card
            key={`device-card-${device.org_device_id}`}
            device={device}
            onClick={() => navigate(`/org-mobile-devices/devices/${device.org_device_id}/info`)}
            data-testid={`device-card-${device.org_device_id}`}
          />
        ))}
      </Section>
    );
  };

  return (
    <App breadcrumbs={breadcrumbs}>
      <Section className={styles.title}>
        <Title>{TITLE}</Title>
        <Button
          className={styles.applyFilter}
          type="primary"
          onClick={() => setShowDialog(true)}
          data-testid="register-device-button"
        >
          <I18n path="organization.organization-mobile-devices-list.register-device" />
        </Button>
      </Section>

      <Section className={styles.container}>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
          <div className={styles.filters} data-testid="mobile-devices-filters">
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="Confiável">
                <I18n path="organization.organization-mobile-devices-list.filters.trust-level" />
              </Label>
              <Select
                id="status"
                onChange={(event) => setSelectedStatus(event.target.value)}
                value={selectedStatus}
                data-testid="select-status"
              >
                <Option value="">
                  <I18n path="organization.organization-mobile-devices-list.filters.all-statuses" />
                </Option>
                <Option value="TRUSTED" data-testid="trusted-status-option">
                  <I18n path="organization.organization-mobile-devices-info.trusted" />
                </Option>
                <Option value="NORMAL" data-testid="normal-status-option">
                  <I18n path="organization.organization-mobile-devices-info.normal" />
                </Option>
                <Option value="BLOCKED" data-testid="blocked-status-option">
                  <I18n path="organization.organization-mobile-devices-info.blocked" />
                </Option>
              </Select>
            </InputGroup>
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="filter">
                <I18n path="organization.organization-mobile-devices-list.filters.device" />
              </Label>
              <InputIcon
                id="filter"
                placeholder={translate(
                  'organization.organization-mobile-devices-list.filters.filter-device-placeholder',
                )}
                onChange={(event) => setFilterValue(event.target.value)}
                onIconClick={() => fetchDevices(1)}
                value={filterValue}
                icon="search"
                data-testid="search-input"
              />
            </InputGroup>
            <Button className={styles.applyFilter} onClick={handleClearFilterClick} data-testid="reset-filters-button">
              <I18n path="organization.organization-devices.button-filter-reset" />
            </Button>
            <Button
              className={styles.applyFilter}
              onClick={() => fetchDevices(1)}
              type="primary"
              data-testid="apply-filters-button"
            >
              <I18n path="organization.organization-devices.button-filter-apply" />
            </Button>
          </div>
        </form>
        {isLoadingDevices ? (
          <Loader />
        ) : (
          <>
            <SlideFadeInContainer>{renderDevices()}</SlideFadeInContainer>
            <Pagination
              rows={rows}
              rowsPerPage={PAGE_SIZE}
              currentPage={currentPage}
              onPageSelect={fetchDevices}
              maxPagesDisplay={3}
            />
          </>
        )}
      </Section>
      <StatusSetupDialog
        isOpen={showDialog}
        onConfirm={(status) => navigate(`/org-mobile-devices/devices/add?status=${status}`)}
        onClose={() => setShowDialog(false)}
        data-testid="choose-status-dialog"
      />
    </App>
  );
}

export default OrganizationMobileDeviceList;
