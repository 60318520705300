import { useEffect, useState } from 'react';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import Loader from 'components/molecules/section-loader';
import LabeledInput from 'components/molecules/labeled-input';
import Units from 'components/organisms/member-units';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import App from 'pages/braincare/AdminOrganizations/members/edit/template';
import { useNavigate, useParams } from 'react-router-dom';
import braincare from 'components/routes/braincare';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMember, setOrganizationSelectedMember } from 'features/organizationSlice';
import { fetchMemberByOrganization, fetchUnitsByOrganization, updateMemberByOrganization } from 'api/portal';
import Permissions from 'components/organisms/Permissions';
import validateEmail from 'utils/validate-email';
import validatePhone from 'utils/validate-phone';
import styles from '../styles.module.css';

const INVALID_EMAIL_ERROR = <I18n path="messages.invalid-email" />;
const INVALID_PHONE_ERROR = <I18n path="messages.invalid-phone" />;
const FILL_REQUIRED = <I18n path="logs.activities-report.messages.fill-required" />;

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const SAVE_SUCCESS = <I18n path="messages.save-success" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

function PermissionsAdmin() {
  const member = useSelector((state) => state.organization.selectedMember);

  const roles = (member.roles || []).map((role) => role.id);

  const [email, setEmail] = useState(member.email || '');
  const [phoneNumber, setPhoneNumber] = useState(member.phone_number || '');
  const [selectedPermissions, setSelectedPermissions] = useState(roles);

  const [units, setUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState((member.units || []).map((unit) => unit.id));
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [errorUnits, setErrorUnits] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [errors, setErrors] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { orgId, memberId } = params;

  useEffect(() => {
    const { organization } = member;
    const persistedOrgId = organization ? organization.id : undefined;

    if (member.id !== Number(memberId) || persistedOrgId !== Number(orgId)) {
      dispatch(clearOrganizationSelectedMember());

      setIsLoading(true);

      fetchMemberByOrganization(orgId, memberId)
        .then((response) => {
          const { data } = response;
          const { email, roles, units } = data;

          dispatch(setOrganizationSelectedMember(data));
          setEmail(email);
          setPhoneNumber(data.phone_number);
          setSelectedPermissions(roles.map((role) => role.id));
          setSelectedUnits(units.map((unit) => unit.id));
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(addToast('error', LOAD_FAILURE));
          navigate(`/braincare/organizations/${orgId}/members`);
        });
    }

    fetchUnitsByOrganization(orgId)
      .then((response) => {
        setIsLoadingUnits(false);

        const { data } = response;

        setUnits(data.results);
        setErrorUnits(false);
      })
      .catch(() => {
        setIsLoadingUnits(false);
        setErrorUnits(true);
      });
  }, []);

  const handleMemberSubmit = () => {
    const errors = new Map();

    if (email === '') {
      errors.set('email', FILL_REQUIRED);
    } else if (!validateEmail(email)) {
      errors.set('email', INVALID_EMAIL_ERROR);
    }

    if (phoneNumber === '') {
      errors.set('phoneNumber', FILL_REQUIRED);
    } else if (!validatePhone(phoneNumber)) {
      errors.set('phoneNumber', INVALID_PHONE_ERROR);
    }

    if (selectedPermissions.length === 0) {
      errors.set('permissions', FILL_REQUIRED);
    }

    setErrors(errors);

    if (errors.size !== 0) {
      dispatch(addToast('error', FIX_FIELDS));
      return;
    }

    setIsSaving(true);

    updateMemberByOrganization(orgId, memberId, {
      email,
      phone_number: phoneNumber,
      roles: selectedPermissions,
      units: selectedUnits,
    })
      .then(() => {
        setIsSaving(false);
        dispatch(addToast('success', SAVE_SUCCESS));
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(addToast('error', SAVE_FAILURE));
      });
  };

  return (
    <App>
      <Section>
        <Subtitle>
          <I18n path="organization.organization-members-edit-permissions.title-sub-member-contact" />
        </Subtitle>
        <Text isItalic>
          <I18n path="organization.organization-members-edit-permissions.text-organization-members-contact" />
        </Text>

        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer className={styles.section}>
            <LabeledInput
              label={<I18n path="organization.organization-members-edit-permissions.input-email" />}
              htmlFor="email"
              data-testid="labeled-input-email"
            >
              <Input
                id="email"
                placeholder="logan.chandler@organization.med"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                hasError={errors.has('email')}
                errorMessage={errors.get('email')}
                data-testid="email-input"
              />
            </LabeledInput>
            <LabeledInput
              label={<I18n path="organization.organization-members-edit-permissions.input-phone" />}
              htmlFor="phone"
              data-testid="labeled-input-phone"
            >
              <Input
                id="phone"
                placeholder="+1 512-312-344"
                name="phone"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                hasError={errors.has('phoneNumber')}
                errorMessage={errors.get('phoneNumber')}
                data-testid="phone-input"
              />
            </LabeledInput>
          </SlideRightFadeContainer>
        )}
      </Section>
      <Section>
        <Subtitle>
          <I18n path="organization.organization-members-edit-permissions.title-sub-member-roles" />
        </Subtitle>
        <Text>
          <I18n path="organization.organization-members-edit-permissions.text-organization-members-role" />
        </Text>
        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <Permissions
              selected={selectedPermissions}
              onToggle={(permissions) => setSelectedPermissions(permissions)}
              data-testid="permissions"
            />
            {errors.has('permissions') ? (
              <Text isError data-testid="permission-error">
                {errors.get('permissions')}
              </Text>
            ) : null}
          </SlideRightFadeContainer>
        )}
      </Section>
      <Section>
        <Subtitle>
          <I18n path="organization.organization-members-edit-permissions.title-sub-member-units" />
        </Subtitle>
        <Text>
          <I18n path="organization.organization-members-edit-permissions.text-organization-members-units" />
        </Text>
        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <Units
              error={errorUnits}
              isLoading={isLoadingUnits}
              onToggle={(units) => setSelectedUnits(units)}
              selectedUnits={selectedUnits}
              units={units}
              data-testid="units"
            />
          </SlideRightFadeContainer>
        )}
      </Section>
      <div className={styles.buttons}>
        <Button onClick={handleMemberSubmit} isLoading={isSaving} isDisabled={isLoading} data-testid="save-button">
          <I18n path="organization.organization-members-edit-permissions.button-save" />
        </Button>
        <Button onClick={() => navigate(`/braincare/organizations/${orgId}/members`)} data-testid="cancel-button">
          <I18n path="organization.organization-members-edit-permissions.button-cancel" />
        </Button>
      </div>
    </App>
  );
}

export default braincare(PermissionsAdmin);
