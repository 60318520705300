import PropTypes from 'prop-types';
import Avatar from 'components/atoms/avatar';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

const Slide = ({ title, subtile, image, onDeclineClick, onAcceptClick, isAccepting, ...props }) => (
  <div className={styles.slide} {...props}>
    <div className={styles.slideContent}>
      <Avatar image={image} description="Notification image" size="small" />
      <div className={styles.texts}>
        <Text className={styles.title}>{title}</Text>
        <Text className={styles.subtitle}>{subtile}</Text>
      </div>
    </div>
    <div>
      <Button type="danger" className={styles['slide-button']} onClick={onDeclineClick} data-testid="decline-button">
        <I18n path="auth.account-invite.button-decline" />
      </Button>
      <Button
        type="success"
        className={styles['slide-button']}
        onClick={onAcceptClick}
        isLoading={isAccepting}
        data-testid="accept-button"
      >
        <I18n path="auth.account-invite.button-accept" />
      </Button>
    </div>
  </div>
);

Slide.propTypes = {
  title: PropTypes.string.isRequired,
  subtile: PropTypes.string.isRequired,
  image: PropTypes.string,
  onDeclineClick: PropTypes.func.isRequired,
  onAcceptClick: PropTypes.func.isRequired,
  isAccepting: PropTypes.bool,
};

Slide.defaultProps = {
  image: '',
  isAccepting: false,
};

export default Slide;
