import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { toast } from 'actions/toasts';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Table, { Head, Body as TBody, Row, Cell } from 'components/atoms/table';
import I18n from 'utils/i18n';
import humanizeDate from 'utils/humanize-date';
import { portalApi } from 'api/http';
import styles from './styles.module.css';

const FETCH_DATA_FAILURE = <I18n path="messages.fetch-data-failure" />;
const WITHOUT_ORGANIZATION = <I18n path="messages.without-organization" />;
const NOT_FOUND = <I18n path="messages.not-found" />;
const LOADING = <I18n path="messages.loading" />;

const shareTranslation = {
  FULL: <I18n path="physio.physio-shared-with-dialog.share-type-full" />,
  HIDE: <I18n path="physio.physio-shared-with-dialog.share-type-anonymous" />,
};

const INITIAL_STATE = {
  users: [],
  organizations: [],
  isLoading: false,
  isLoadingOrg: false,
};

class SharedWithDialog extends Component {
  state = INITIAL_STATE;

  componentWillReceiveProps(nextProps) {
    if (nextProps.isVisible === true) {
      if (this.state.users === INITIAL_STATE.users) {
        nextProps.sharedWith.forEach((share) => this.handleFetchUser(share.user_id));
      }

      if (this.state.organizations === INITIAL_STATE.organizations) {
        nextProps.sharedWith.forEach((share) => this.handleFetchOrganization(share.org_id));
      }
    }
  }

  handleFetchUser = (id) => {
    const { onCloseDialog } = this.props;

    this.setState({ isLoading: true });

    portalApi()
      .get(`user/id?id=${id}`)
      .then((response) => {
        const { data } = response;
        this.setState({ isLoading: false });
        this.setState((prevState) => ({ users: [...prevState.users, data[0]] }));
      })
      .catch(() => {
        this.setState({ isLoading: false });
        toast('error', FETCH_DATA_FAILURE);
        onCloseDialog();
      });
  };

  handleFetchOrganization = (id) => {
    const { onCloseDialog } = this.props;
    const { organizations } = this.state;

    if (id === 'null') return;

    if (organizations[id] !== undefined) return;

    this.setState({ isLoadingOrg: true });

    portalApi()
      .get(`organization-by-id?physio_org_id=${id}`)
      .then((response) => {
        const { data } = response;
        this.setState({ isLoadingOrg: false });
        this.setState((prevState) => ({ organizations: [...prevState.organizations, data[0]] }));
      })
      .catch(() => {
        this.setState({ isLoadingOrg: false });
        toast('error', FETCH_DATA_FAILURE);
        onCloseDialog();
      });
  };

  getUserName = (id) => {
    const user = this.state.users.find((index) => index.id === Number(id));
    if (user) return `${user.first_name} ${user.last_name}`;
    return NOT_FOUND;
  };

  getOrganization = (id) => {
    const organization = this.state.organizations.find((index) => index.physio_org_id === id);
    if (id === 'null') {
      return WITHOUT_ORGANIZATION;
    }
    if (organization) return organization.name;
    return NOT_FOUND;
  };

  renderTable = () => {
    const { sharedWith } = this.props;
    const { isLoading, isLoadingOrg } = this.state;

    return (
      <Table className={styles.table} canScroll={false}>
        <Head>
          <Row>
            <Cell isHead align="left">
              <I18n path="physio.physio-shared-with-dialog.table-header-user" />
            </Cell>
            <Cell isHead align="left">
              <I18n path="physio.physio-shared-with-dialog.table-header-organization" />
            </Cell>
            <Cell isHead align="left">
              <I18n path="physio.physio-shared-with-dialog.table-header-share-type" />
            </Cell>
            <Cell isHead align="left">
              <I18n path="physio.physio-shared-with-dialog.table-header-share-exp" />
            </Cell>
          </Row>
        </Head>
        <TBody>
          {sharedWith.map((share) => (
            <Row key={_.uniqueId('share')}>
              <Cell align="left">{!isLoading ? this.getUserName(share.user_id) : LOADING}</Cell>
              <Cell align="left">{!isLoadingOrg ? this.getOrganization(share.org_id) : LOADING}</Cell>
              <Cell align="left">{shareTranslation[share.share_type]}</Cell>
              <Cell align="left">{humanizeDate(share.exp)}</Cell>
            </Row>
          ))}
        </TBody>
      </Table>
    );
  };

  render() {
    const { isVisible, onCloseDialog } = this.props;
    return (
      <Dialog isOpen={isVisible} size="medium">
        <Header onClose={onCloseDialog}>
          <I18n path="physio.physio-shared-with-dialog.title" />
        </Header>
        <Body>
          <I18n path="physio.physio-shared-with-dialog.text" />
          {this.renderTable()}
        </Body>
      </Dialog>
    );
  }
}

SharedWithDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  sharedWith: PropTypes.instanceOf(Array).isRequired,
};

export default SharedWithDialog;
