import Snackbar from 'components/atoms/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { closeToast } from 'actions/toasts';
import { Container, SlideIn, SlideOut } from './styles';

const ToastContainer = () => {
  const toasts = useSelector((state) => state.toasts.toasts);

  const dispatch = useDispatch();

  return (
    <Container>
      {toasts.map((toast) => {
        const Animator = toast.visible ? SlideIn : SlideOut;

        return (
          <Animator key={toast.key}>
            <Snackbar type={toast.type} onClose={() => dispatch(closeToast(toast.key))} data-testid="toast">
              {toast.message}
            </Snackbar>
          </Animator>
        );
      })}
    </Container>
  );
};

export default ToastContainer;
