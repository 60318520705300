import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const DialogFooter = ({ className, separator, children }) => (
  <div className={classNames(styles.footer, { className, separator })}>{children}</div>
);

DialogFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  separator: PropTypes.bool,
};

DialogFooter.defaultProps = {
  className: undefined,
  separator: false,
};

export default DialogFooter;
