import { useEffect, useRef, useState } from 'react';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Units from 'components/organisms/member-units';
import I18n from 'utils/i18n';
import App from 'pages/account/member/template';
import Permissions from 'components/organisms/Permissions';
import { fetchUnits } from 'api/portal';
import { useSelector } from 'react-redux';
import { SlideRightFadeContainer } from 'utils/transitions';
import { STATUS_LOADING } from 'features/userSlice';

function CorporatePermissions() {
  const user = useSelector((state) => state.user);
  const ref = useRef();

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [units, setUnits] = useState([]);
  const [isLoadingMemberData, setIsLoadingMemberData] = useState(true);
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [errorUnits, setErrorUnits] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchUnits(searchParams)
      .then((response) => {
        setIsLoadingUnits(false);

        const { data } = response;

        setUnits(data.results);
        setErrorUnits(false);
      })
      .catch(() => {
        setIsLoadingUnits(false);
        setErrorUnits(true);
      });
  }, []);

  useEffect(() => {
    if (isLoadingMemberData && ref.current === STATUS_LOADING && user.status !== STATUS_LOADING) {
      const { roles, units } = user.organization_profile;

      setIsLoadingMemberData(false);

      setSelectedPermissions(roles.map((role) => role.id));
      setSelectedUnits(units.map((unit) => unit.id));
    }

    ref.current = user.status;
  }, [user.status]);

  return (
    <App>
      <SlideRightFadeContainer>
        <Section>
          <Subtitle>
            <I18n path="account.member-edit-permissions.title-sub-me-permissions" />
          </Subtitle>
          <Text>
            <I18n path="account.member-edit-permissions.text-permissions" />
          </Text>
          <Permissions selected={selectedPermissions} onToggle={() => {}} data-testid="list-roles" />
        </Section>
        <Section>
          <Subtitle>
            <I18n path="account.member-edit-permissions.title-sub-me-units" />
          </Subtitle>
          <Text>
            <I18n path="account.member-edit-permissions.text-units" />
          </Text>
          <Units
            error={errorUnits}
            isLoading={isLoadingUnits}
            onToggle={() => {}}
            selectedUnits={selectedUnits}
            units={units}
            data-testid="list-units"
          />
        </Section>
      </SlideRightFadeContainer>
    </App>
  );
}

export default CorporatePermissions;
