import PropTypes from 'prop-types';
import { Card as CardDiv } from './styles';

const Card = ({ onClick, children, className, ...props }) => (
  <CardDiv onClick={onClick} className={className} role="presentation" {...props}>
    {children}
  </CardDiv>
);

Card.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  onClick: () => {},
  className: undefined,
};

export default Card;
