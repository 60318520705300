import ClickOutside from 'react-outside-click-handler';
import AppItem from 'components/molecules/app-item';
import Dropdown from 'components/molecules/dropdown';
import DropdownToggle from 'components/molecules/dropdown-toggle';
import Text from 'components/atoms/text';
import NavItem from 'components/molecules/nav-item';
import DropdownMenu from 'components/molecules/dropdown-menu';
import Spinner from 'components/atoms/spinner';
import I18n from 'utils/i18n';
import { useState } from 'react';
import styles from './styles.module.css';

function Apps({ applications, isLoading, ...props }) {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const getAppItems = () =>
    applications.map((app) => (
      <AppItem
        key={app.id}
        onClick={() => setIsOpenDropdown(false)}
        text={<I18n path={app.name} />}
        image={app.image}
        href={app.href}
        data-testid={`app-item-${app.id}`}
      />
    ));

  const renderAppItems = () => {
    const appItems = getAppItems();

    if (appItems.length > 0) {
      return appItems;
    }

    return (
      <Text data-testid="applications-empty-text">
        <I18n path="auth.account-personal.applications-empty" />
      </Text>
    );
  };

  return (
    <ClickOutside onOutsideClick={() => setIsOpenDropdown(false)}>
      <Dropdown className={styles.alignMenuItem} {...props}>
        <DropdownToggle onClick={() => setIsOpenDropdown(!isOpenDropdown)} data-testid="apps-toggle">
          <NavItem isActive={isOpenDropdown}>
            <I18n path="auth.account-personal.text-account-application" />
          </NavItem>
        </DropdownToggle>
        <DropdownMenu className={styles.applicationsMenu} isOpen={isOpenDropdown}>
          {isLoading ? <Spinner className={styles.applicationsLoader} data-testid="spinner" /> : renderAppItems()}
        </DropdownMenu>
      </Dropdown>
    </ClickOutside>
  );
}

export default Apps;
