import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/atoms/icon';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

const Warning = ({ children, className, ...props }) => (
  <div className={classnames(styles.warning, className)} {...props}>
    <b>
      <Icon className={styles.warningIcon} name="warning" />
      <I18n path="messages.warning" />
    </b>
    {children}
  </div>
);

Warning.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Warning.defaultProps = {
  className: undefined,
};

export default Warning;
