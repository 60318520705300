import ClickOutside from 'react-outside-click-handler';
import Dropdown from 'components/molecules/dropdown';
import DropdownToggle from 'components/molecules/dropdown-toggle';
import DropdownMenu from 'components/molecules/dropdown-menu';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'components/atoms/text';
import I18n from 'utils/i18n';
import NavItem from 'components/molecules/nav-item';
import Spinner from 'components/atoms/spinner';
import humanizeDuration from 'utils/humanize-date';
import NotificationItem from 'components/molecules/notification-item';
import { useEffect, useRef, useState } from 'react';
import { clearNotifications, setNotificationsData, setNotificationsProps } from 'features/notificationsSlice';
import { useNavigate } from 'react-router-dom';
import { fetchNotifications, readNotification } from 'api/portal';
import { addToast } from 'actions/toasts';
import Button from 'components/atoms/button';
import styles from './styles.module.css';

const READ_NOTIFICATION_FAILURE = <I18n path="auth.account-personal.read-notification-error" />;

function Notifications({ ...props }) {
  const [isReloadingNotifications, setIsReloadingNotifications] = useState();
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);

  const notifications = useSelector((state) => state.notifications);

  const savedCallback = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reloadNotifications = () => {
    setIsReloadingNotifications(true);
    setHasError(false);

    fetchNotifications()
      .then((response) => {
        const { data } = response;

        if (data && data.results.length > 0) {
          dispatch(
            setNotificationsProps({
              data: data.results,
              nextNotificationPage: data.next ? 2 : null,
              total: data.count,
              count: data.results.length,
              unreadCount: data.unread_count,
            }),
          );
        }

        // The case below happens when user is logged with patient account
        if (data && data.results.length === 0) {
          dispatch(clearNotifications());
        }

        setIsReloadingNotifications(false);
        setHasError(false);
      })
      .catch(() => {
        dispatch(clearNotifications());
        setHasError(true);
        setIsReloadingNotifications(false);
      });
  };

  const autoReloadNotifications = () => {
    if (!isOpenDropdown && !isDisabled) {
      reloadNotifications();
    }
  };

  const fetchNotificationsHandler = () => {
    if (!notifications.nextNotificationPage) return;

    setIsLoadingNotifications(true);
    setHasError(false);

    fetchNotifications(notifications.nextNotificationPage)
      .then((response) => {
        const { data } = response;

        setIsLoadingNotifications(false);
        setHasError(false);

        if (data && data.results.length > 0) {
          dispatch(
            setNotificationsProps({
              data: [...notifications.data, ...data.results],
              nextNotificationPage: data.next ? notifications.nextNotificationPage + 1 : null,
              total: data.count,
              count: notifications.count + data.results.length,
              unreadCount: data.unread_count,
            }),
          );
          return;
        }

        // The case below happens when user is logged with patient account
        if (data && data.results.length === 0) {
          dispatch(clearNotifications());
        }
      })
      .catch(() => {
        setHasError(true);
        setIsLoadingNotifications(false);
      });
  };

  useEffect(() => {
    savedCallback.current = autoReloadNotifications;
  });

  useEffect(() => {
    const notificationsPool = setInterval(() => savedCallback.current(), 180000);

    fetchNotificationsHandler();

    return () => clearInterval(notificationsPool);
  }, []);

  const shouldReadNotification = (notification) => {
    if (notification !== null) {
      return !notification.read;
    }

    return true;
  };

  const markAsRead = (notification = null) => {
    if (!shouldReadNotification(notification)) {
      if (notification.link) {
        navigate(notification.link);
      }
      return;
    }

    const notificationId = notification ? notification.id : null;

    if (notificationId) {
      setIsDisabled(true);
    } else {
      setIsReloadingNotifications(true);
    }

    const mappedNotifications = notifications.data.map((item) =>
      item.id === notificationId ? { ...item, read: undefined } : item,
    );
    dispatch(setNotificationsData(mappedNotifications));

    readNotification(notificationId)
      .then(() => {
        if (notificationId) {
          const mappedNotifications = notifications.data.map((item) =>
            item.id === notificationId ? { ...item, read: true } : item,
          );
          dispatch(setNotificationsProps({ data: mappedNotifications, unreadCount: notifications.unreadCount - 1 }));
        } else {
          const mappedNotifications = notifications.data.map((item) => ({ ...item, read: true }));
          dispatch(setNotificationsProps({ data: mappedNotifications, unreadCount: 0 }));
        }

        setIsDisabled(false);
        setIsReloadingNotifications(false);
      })
      .catch(() => {
        setIsDisabled(false);
        setIsReloadingNotifications(false);
        dispatch(addToast('error', READ_NOTIFICATION_FAILURE));
      });
  };

  const getNotifications = () =>
    notifications.data.map((notification) => {
      const responseMessage = JSON.parse(notification.message);
      return (
        <NotificationItem
          key={notification.id}
          text={<I18n path={responseMessage.key_message} props={responseMessage.values} />}
          date={humanizeDuration(notification.created)}
          disabled={isDisabled}
          hasLink={!!notification.link}
          isRead={notification.read}
          onClick={() => markAsRead(notification)}
          data-testid={`notification-${notification.id}`}
        />
      );
    });

  const onScrollNotifications = (event) => {
    const ref = event.target;
    const { total, count } = notifications;

    if (!hasError) {
      if (ref.clientHeight + ref.scrollTop >= ref.scrollHeight && count < total && !isLoadingNotifications) {
        fetchNotificationsHandler();
      }
    }
  };

  const renderNotifications = () => {
    const allNotifications = getNotifications();

    if (allNotifications.length > 0) {
      return allNotifications;
    }

    if (hasError) {
      return null;
    }

    return (
      <Text className={styles.centralizeMessage} data-testid="notifications-empty-message">
        <I18n path="auth.account-personal.notifications-empty" />
      </Text>
    );
  };

  const { unreadCount } = notifications;
  const count = unreadCount ? `(${unreadCount})` : '';

  return (
    <ClickOutside onOutsideClick={() => setIsOpenDropdown(false)}>
      <Dropdown className={styles.alignMenuItem} {...props}>
        <DropdownToggle
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
          hasNotification={!!unreadCount}
          data-testid="notifications-toggle"
        >
          <NavItem isActive={isOpenDropdown}>
            <I18n path="auth.account-personal.text-account-notifications" />
          </NavItem>
        </DropdownToggle>
        <DropdownMenu
          className={styles.notificationsWrapper}
          isOpen={isOpenDropdown}
          onScroll={onScrollNotifications}
          header={
            <>
              <span>
                <I18n path="auth.account-personal.text-account-notifications" /> {count}
              </span>
              <span className={styles.readAll}>
                <Button
                  type="ghost"
                  className={styles.dropdownHeaderText}
                  onClick={() => reloadNotifications()}
                  data-testid="force-reload-button"
                >
                  <I18n path="auth.account-personal.notifications-ask-reload" />
                </Button>
                {' | '}
                <Button
                  type="ghost"
                  className={styles.dropdownHeaderText}
                  onClick={() => markAsRead()}
                  data-testid="read-all-button"
                >
                  <I18n path="auth.account-personal.notifications-read-all" />
                </Button>
              </span>
            </>
          }
          data-testid="notification-dropdown-menu"
        >
          {isReloadingNotifications ? (
            <Text className={styles.centralizeMessage} data-testid="notifications-reload-message">
              <I18n path="auth.account-personal.notifications-reload" />
            </Text>
          ) : (
            renderNotifications()
          )}

          {hasError ? (
            <div className={styles.errorContainer}>
              <Text className={styles.centralizeMessage} data-testid="notifications-error-message">
                <I18n path="auth.account-personal.notifications-error" />
              </Text>
              <Button onClick={fetchNotificationsHandler} data-testid="button-try-again">
                <I18n path="auth.account-personal.notifications-try-again" />
              </Button>
            </div>
          ) : null}

          {(isLoadingNotifications || isReloadingNotifications) && <Spinner className={styles.spinner} />}
        </DropdownMenu>
      </Dropdown>
    </ClickOutside>
  );
}

export default Notifications;
