import PropTypes from 'prop-types';
import { Container, Icon } from './styles';
import styles from './styles.module.css';

const PopupMenuItem = ({ children, icon, type, ...props }) => (
  <Container className={styles[type]} {...props}>
    {icon ? <Icon className={icon} /> : null}
    {children}
  </Container>
);

PopupMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string,
};

PopupMenuItem.defaultProps = {
  icon: undefined,
  type: 'default',
};

export default PopupMenuItem;
