import PropTypes from 'prop-types';
import styles from './styles.module.css';

const IconMore = ({ number }) => <div className={styles.iconMore}>{number < 100 ? `+${number}` : '99+'}</div>;

IconMore.propTypes = {
  number: PropTypes.number,
};

IconMore.defaultProps = {
  number: 0,
};

export default IconMore;
