import { useState } from 'react';
import Subtitle from 'components/atoms/subtitle';
import Button from 'components/atoms/button';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Checkbox from 'components/atoms/input-checkbox';
import LabeledInput from 'components/molecules/labeled-input';
import InputIcon from 'components/molecules/input-icon';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

const REQUIRE_PIN_CHANGE_CHECKBOX = (
  <I18n path="organization.organization-mobile-devices-edit.input-require-pin-change-check-box" />
);
const NO_PIN_REQUIRED_CHECKBOX = (
  <I18n path="organization.organization-mobile-devices-edit.input-no-pin-required-check-box" />
);
const ERROR_TOO_SHORT_PIN = <I18n path="organization.organization-mobile-devices-edit.error-too-short-pin" />;
const ERROR_PIN_NOT_CONFIRMATED = <I18n path="organization.organization-mobile-devices-edit.error-pin-not-confirmed" />;

function DialogMemberPinUpdate({ isLoading, isOpen, memberName, onClose, onSubmit }) {
  const [pin, setPin] = useState('');
  const [isPinVisible, setIsPinVisible] = useState(false);
  const [pinConfirmation, setPinConfirmation] = useState('');
  const [isPinConfirmationVisible, setIsPinConfirmationVisible] = useState(false);
  const [requirePinChange, setRequirePinChange] = useState(false);
  const [noPinRequired, setNoPinRequired] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const validatePin = (pin) => {
    const cleanedPin = pin.replace(/\D/g, '');
    setPin(cleanedPin);

    if (cleanedPin.length < 4) {
      errors.set('pin', ERROR_TOO_SHORT_PIN);
    } else {
      errors.delete('pin');
    }

    if (cleanedPin !== pinConfirmation) {
      errors.set('pinConfirmation', ERROR_PIN_NOT_CONFIRMATED);
    } else {
      errors.delete('pinConfirmation');
    }

    setErrors(errors);
    return errors;
  };

  const validatePinConfirmation = (pinConfirmation) => {
    const cleanedPinConfirmation = pinConfirmation.replace(/\D/g, '');
    setPinConfirmation(cleanedPinConfirmation);

    if (pin !== cleanedPinConfirmation) {
      errors.set('pinConfirmation', ERROR_PIN_NOT_CONFIRMATED);
    } else {
      errors.delete('pinConfirmation');
    }

    setErrors(errors);
    return errors;
  };

  const onClickNoPinRequiredCheckbox = () => {
    setErrors(new Map());
    setNoPinRequired(!noPinRequired);
    setPin('');
    setPinConfirmation('');
  };

  const handleSubmit = () => {
    if (!noPinRequired) {
      validatePin(pin);
      validatePinConfirmation(pinConfirmation);

      if (errors.size !== 0) {
        // Force component interface by creating a new map instance (shallow copy of the current one)
        setErrors(new Map(errors));
        return;
      }
    }

    onSubmit({ pin, requirePinChange });
  };

  const handleClose = () => {
    setPin('');
    setIsPinVisible(false);
    setPinConfirmation('');
    setIsPinConfirmationVisible(false);
    setRequirePinChange(false);
    setNoPinRequired(false);
    setErrors(new Map());
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} data-testid="pin-redefinition-dialog">
      <Header>
        <I18n path="organization.organization-mobile-devices-edit.title-sub-member-pin-edit" />
      </Header>
      <Body>
        <Subtitle className={styles['text-center']}>{memberName}</Subtitle>
        <LabeledInput
          label={<I18n path="organization.organization-mobile-devices-edit.pin-label-input-member-pin-edit" />}
          htmlFor="pin"
          data-testid="pin-labeled-input"
        >
          <InputIcon
            id="pin"
            name="pin"
            value={pin}
            hasError={errors.has('pin')}
            errorMessage={errors.get('pin')}
            onChange={(event) => validatePin(event.target.value)}
            onBlur={(event) => validatePin(event.target.value)}
            icon={isPinVisible ? 'eye' : 'eye-blocked'}
            type={isPinVisible ? 'text' : 'password'}
            onIconClick={() => setIsPinVisible(!isPinVisible)}
            isDisabled={noPinRequired}
            maxLength={10}
            data-testid="pin-input"
          />
        </LabeledInput>
        <LabeledInput
          label={
            <I18n path="organization.organization-mobile-devices-edit.pin-label-input-member-pin-confirmation-edit" />
          }
          htmlFor="pin"
          data-testid="pin-confirmation-labeled-input"
        >
          <InputIcon
            id="pin"
            name="pin"
            value={pinConfirmation}
            hasError={errors.has('pinConfirmation')}
            errorMessage={errors.get('pinConfirmation')}
            onChange={(event) => validatePinConfirmation(event.target.value)}
            onBlur={(event) => validatePinConfirmation(event.target.value)}
            icon={isPinConfirmationVisible ? 'eye' : 'eye-blocked'}
            type={isPinConfirmationVisible ? 'text' : 'password'}
            onIconClick={() => setIsPinConfirmationVisible(!isPinConfirmationVisible)}
            isDisabled={noPinRequired}
            maxLength={10}
            data-testid="pin-confirmation-input"
          />
        </LabeledInput>
        <Checkbox
          id="require_pin_change"
          className={styles.checkbox}
          name="require-pin-change"
          value={requirePinChange}
          label={REQUIRE_PIN_CHANGE_CHECKBOX}
          isChecked={requirePinChange}
          onClick={() => setRequirePinChange(!requirePinChange)}
          data-testid="require-pin-change-checkbox"
        />
        <Checkbox
          id="no_pin_required"
          className={styles.checkbox}
          name="no-pin-required"
          value={noPinRequired}
          label={NO_PIN_REQUIRED_CHECKBOX}
          isChecked={noPinRequired}
          onClick={() => onClickNoPinRequiredCheckbox()}
          data-testid="no-pin-required-checkbox"
        />
      </Body>
      <Footer>
        <Button type="primary" isLoading={isLoading} onClick={handleSubmit} data-testid="submit-button">
          <I18n path="organization.organization-mobile-devices-edit.button-confirm-dialog-member-pin-edit" />
        </Button>
        <Button onClick={handleClose} data-testid="cancel-button">
          <I18n path="organization.organization-mobile-devices-edit.button-cancel" />
        </Button>
      </Footer>
    </Dialog>
  );
}

export default DialogMemberPinUpdate;
