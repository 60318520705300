import AppWizard from 'components/templates/app-wizard';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChoosePinPolicy from '../steps/ChoosePinPolicy';
import DeviceQRCode from '../steps/QRCode';
import OperationConcluded from '../steps/MobileDeviceSummary';
import { isTrustedDevice, validateDeviceStatus } from '../../../../../utils/mobile-devices';

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_DEVICES = <I18n path="organization.breadcrumbs.manage-devices" />;
const LOAD_FAILURE = <I18n path="messages.load-failure" />;

const STEP_1 = <I18n path="organization.organization-mobile-devices-create.wizard-pin-policy" />;
const STEP_2 = <I18n path="organization.organization-mobile-devices-create.wizard-qr-code" />;
const STEP_3 = <I18n path="organization.organization-mobile-devices-create.wizard-end" />;

function MobileDeviceRegistration({ currentNav, device, title }) {
  const [pinPolicy, setPinPolicy] = useState(null);
  const [registeredDevice, setRegisteredDevice] = useState({});
  const [requirePinChange, setRequirePinChange] = useState(null);
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const status = searchParams.get('status');

  useEffect(() => {
    if (!validateDeviceStatus(status)) {
      dispatch(addToast('error', LOAD_FAILURE));
      navigate('/org-mobile-devices/devices');
    }
  }, []);

  const renderStep = () => {
    const SUBTITLE_REGISTRATION_CONCLUDED = device ? (
      <I18n path="organization.organization-mobile-devices-edit.title-sub-device-updated" />
    ) : (
      <I18n path="organization.organization-mobile-devices-create.title-sub-device-registered" />
    );
    const TEXT_REGISTRATION_CONCLUDED = device ? (
      <I18n path="organization.organization-mobile-devices-edit.text-device-updated" />
    ) : (
      <I18n path="organization.organization-mobile-devices-create.text-device-registered" />
    );

    switch (isTrustedDevice(status) ? step : step + 1) {
      case 0:
        return (
          <ChoosePinPolicy
            onClickNextStep={(state) => {
              setStep(step + 1);
              setPinPolicy(state.pinPolicy);
              setRequirePinChange(state.requirePinChange);
            }}
          />
        );
      case 1:
        return (
          <DeviceQRCode
            device={device}
            status={status}
            pinPolicy={pinPolicy}
            requirePinChange={requirePinChange}
            onQRCodeRead={(device) => {
              setStep(step + 1);
              setRegisteredDevice(device);
            }}
          />
        );
      case 2:
        return (
          <OperationConcluded
            subtitle={SUBTITLE_REGISTRATION_CONCLUDED}
            text={TEXT_REGISTRATION_CONCLUDED}
            device={registeredDevice}
          />
        );
      default:
        return null;
    }
  };

  const BREADCRUMBS = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/org-mobile-devices/devices',
      title: MANAGE_DEVICES,
    },
    {
      path: `${window.location.pathname}${window.location.search}`,
      title: currentNav,
      isActive: true,
    },
  ];

  const STEPS = isTrustedDevice(status) ? [STEP_1, STEP_2, STEP_3] : [STEP_1, STEP_2];

  return (
    <AppWizard title={title} breadcrumbs={BREADCRUMBS} steps={STEPS} currentStep={step}>
      <SlideRightFadeContainer>
        <Section>{renderStep()}</Section>
      </SlideRightFadeContainer>
    </AppWizard>
  );
}

export default MobileDeviceRegistration;
