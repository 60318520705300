import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Loader from 'components/atoms/loader';
import styles from './styles.module.css';

const LOADER_THEMES = {
  default: styles.loaderDefault,
  primary: styles.loaderDefault,
  danger: styles.loaderDanger,
};

const CustomButton = styled(MuiButton)(({ theme }) => ({
  fontSize: 14,
  borderRadius: 40,
  padding: '7px 16px',
  textTransform: 'none',
  '&.MuiButton-outlinedPrimary': {
    border: `solid 1px ${theme.palette.primary.main}`,
  },
  '&.MuiButton-outlinedError': {
    border: `solid 1px ${theme.palette.error.main}`,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: 'white',
    },
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
    border: `solid 1px ${theme.palette.disabled.main}`,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
  },
}));

export default function Button({ loading = false, color = 'primary', children, ...props }) {
  return (
    <CustomButton color={color} {...props}>
      {loading ? <Loader className={LOADER_THEMES[color]} /> : children}
    </CustomButton>
  );
}
