import PropTypes from 'prop-types';
import styles from './styles.module.css';

const DialogBody = ({ children, ...props }) => (
  <div className={styles.body} {...props}>
    {children}
  </div>
);

DialogBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DialogBody;
