import PropTypes from 'prop-types';
import styles from './styles.module.css';

const DropdownToggle = ({ onClick, hasNotification, children, ...props }) => (
  <div onClick={onClick} className={styles.toggle} role="presentation" {...props}>
    {hasNotification && <div className={styles.notification} data-testid="unread-notifications-icon" />}
    {children}
  </div>
);

DropdownToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  hasNotification: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

DropdownToggle.defaultProps = {
  hasNotification: false,
};

export default DropdownToggle;
