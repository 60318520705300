import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from 'components/atoms/loader';
import styles from './styles.module.css';

const TYPE = {
  default: styles.default,
  primary: styles.primary,
  warning: styles.warning,
  success: styles.success,
  danger: styles.danger,
  ghost: styles.ghost,
};

const LOADER_THEMES = {
  default: styles.loaderDefault,
  primary: styles.loaderPrimary,
  danger: styles.loaderDanger,
};

const Button = ({
  type,
  isBlock,
  isCentralized,
  isLarge,
  isDisabled,
  isLoading,
  onClick,
  children,
  className,
  actionType,
  ...props
}) => (
  <button
    onClick={onClick}
    className={classNames(
      TYPE[type],
      styles.button,
      {
        [styles.block]: isBlock,
        [styles.center]: isCentralized,
        [styles.large]: isLarge,
        [styles.disabled]: isDisabled,
        [styles.loading]: isLoading,
      },
      className,
    )}
    disabled={isDisabled}
    type={actionType}
    {...props}
  >
    {isLoading ? <Loader className={LOADER_THEMES[type]} /> : children}
  </button>
);

Button.propTypes = {
  type: PropTypes.string,
  testId: PropTypes.string,
  isBlock: PropTypes.bool,
  isCentralized: PropTypes.bool,
  isLarge: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  actionType: PropTypes.string,
};

Button.defaultProps = {
  type: 'default',
  isBlock: false,
  isCentralized: false,
  isLarge: false,
  isLoading: false,
  isDisabled: false,
  onClick: () => {},
  className: undefined,
  actionType: 'button',
};

export default Button;
