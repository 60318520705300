import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const DropdownMenu = ({ isOpen, className, onScroll, header, children, ...props }) => (
  <div className={classNames(styles.parent, { [styles.isOpen]: isOpen })} {...props}>
    {header && <div className={styles.header}>{header}</div>}
    <div onScroll={onScroll} className={classNames(styles.dropdownMenu, className)}>
      {children}
    </div>
  </div>
);

DropdownMenu.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onScroll: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
};

DropdownMenu.defaultProps = {
  isOpen: false,
  className: undefined,
  onScroll: () => {},
  header: undefined,
};

export default DropdownMenu;
