import { useEffect, useRef, useState } from 'react';
import Text from 'components/atoms/text';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Button from 'components/atoms/button';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';

function Timeout({ children, timeout = 30, warningTime = 30 }) {
  // useRef allows to persist references between rerenders
  const savedIntervalCallback = useRef();
  const savedTimeoutCallback = useRef();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(warningTime);
  const [timeoutTracker, setTimeoutTracker] = useState(null);

  const navigate = useNavigate();

  function timeoutCallback() {
    if (!isDialogOpen) {
      localStorage.setItem('_expiredTime', Date.now() + (warningTime + timeout) * 1000);
    }
  }

  function intervalCallback() {
    const expiredTime = parseInt(localStorage.getItem('_expiredTime') || 0, 10);
    const timeRemaining = expiredTime - Date.now();

    if (timeRemaining <= warningTime * 1000 && timeRemaining > (warningTime - 1) * 1000) {
      setIsDialogOpen(true);
    }

    if (timeRemaining <= warningTime * 1000 && isDialogOpen) {
      setTimeRemaining(Math.ceil(timeRemaining / 1000));
    }

    if (timeRemaining < 0) {
      navigate('/auth/logout');
    }
  }

  const updateExpiredTime = () => {
    // Clears old timeout tracker to avoid having two
    if (timeoutTracker) {
      clearTimeout(timeoutTracker);
    }

    function detectInteraction() {
      savedTimeoutCallback.current();
    }
    // Only updates the expired time if the user remains 300ms without interacting (performance improvement)
    setTimeoutTracker(setTimeout(detectInteraction, 300));
  };

  const cleanUp = () => {
    localStorage.removeItem('_expiredTime');
    window.removeEventListener('mousemove', updateExpiredTime);
    window.removeEventListener('scroll', updateExpiredTime);
    window.removeEventListener('keydown', updateExpiredTime);
  };

  const tracker = () => {
    localStorage.setItem('_expiredTime', Date.now() + (warningTime + timeout) * 1000);
    window.addEventListener('mousemove', updateExpiredTime);
    window.addEventListener('scroll', updateExpiredTime);
    window.addEventListener('keydown', updateExpiredTime);
  };
  // Reset callbacks at every rerender
  useEffect(() => {
    savedIntervalCallback.current = intervalCallback;
    savedTimeoutCallback.current = timeoutCallback;
  });

  useEffect(() => {
    tracker();

    function tick() {
      savedIntervalCallback.current();
    }

    const interval = setInterval(tick, 1000);

    return () => {
      cleanUp();
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {children}
      <Dialog isOpen={isDialogOpen} data-testid="auto-logout-warning">
        <Header data-testid="auto-logout-warning-header">
          <I18n path="auth.inactivity-dialog.header" />
        </Header>
        <Body data-testid="auto-logout-warning-body">
          <Text>
            <I18n path="auth.inactivity-dialog.body" props={{ time_remaining: timeRemaining }} />
          </Text>
        </Body>
        <Footer>
          <Button type="primary" onClick={() => setIsDialogOpen(false)} data-testid="dismiss-button">
            <I18n path="auth.inactivity-dialog.button-keep-auth" />
          </Button>
        </Footer>
      </Dialog>
    </>
  );
}

export default Timeout;
