import { useState } from 'react';
import I18n from 'utils/i18n';
import { FormControl, FormHelperText, Grid } from '@mui/material';
import Select from '../Select';
import CustomMenuItem from '../MenuItem';
import TextField from '../TextField';
import Button from '../Button';

const OPTION_DAY = <I18n path="organization.organization-settings.password-policy.personal-data.date-format-day" />;

const OPTION_MONTH_NUMBER = (
  <I18n path="organization.organization-settings.password-policy.personal-data.date-format-month-number" />
);

const OPTION_MONTH_ABBREV = (
  <I18n path="organization.organization-settings.password-policy.personal-data.date-format-month-abbrev" />
);

const OPTION_MONTH = <I18n path="organization.organization-settings.password-policy.personal-data.date-format-month" />;

const OPTION_YEAR_ABBREV = (
  <I18n path="organization.organization-settings.password-policy.personal-data.date-format-year-abbrev" />
);

const OPTION_YEAR = <I18n path="organization.organization-settings.password-policy.personal-data.date-format-year" />;

export default function DateFormatEditor({
  disabled = false,
  error = false,
  helperText = '',
  onClick = () => {},
  ...props
}) {
  const [dateFormat1, setDateFormat1] = useState('%d');
  const [separator1, setSeparator1] = useState('/');
  const [dateFormat2, setDateFormat2] = useState('%m');
  const [separator2, setSeparator2] = useState('/');
  const [dateFormat3, setDateFormat3] = useState('%y');

  const handleSelect = () => {
    onClick(`${dateFormat1}${separator1}${dateFormat2}${separator2}${dateFormat3}`);
  };

  return (
    <div {...props}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={5}>
          <FormControl fullWidth disabled={disabled} error={error}>
            <Select
              id="date-format-1"
              value={dateFormat1}
              onChange={(event) => setDateFormat1(event.target.value)}
              data-testid="date-format-1"
            >
              <CustomMenuItem value="%d" data-testid="option-day">
                {OPTION_DAY}
              </CustomMenuItem>
              <CustomMenuItem value="%m" data-testid="option-month-number">
                {OPTION_MONTH_NUMBER}
              </CustomMenuItem>
              <CustomMenuItem value="%b" data-testid="option-month-abbrev">
                {OPTION_MONTH_ABBREV}
              </CustomMenuItem>
              <CustomMenuItem value="%B" data-testid="option-month">
                {OPTION_MONTH}
              </CustomMenuItem>
              <CustomMenuItem value="%y" data-testid="option-year-abbrev">
                {OPTION_YEAR_ABBREV}
              </CustomMenuItem>
              <CustomMenuItem value="%Y" data-testid="option-year">
                {OPTION_YEAR}
              </CustomMenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <TextField
            id="separator-1"
            value={separator1}
            onChange={(event) => setSeparator1(event.target.value)}
            variant="outlined"
            data-testid="separator-1-text-field"
            inputProps={{ 'data-testid': 'separator-1-input', maxLength: 1 }}
            disabled={disabled}
            error={error}
          />
        </Grid>

        <Grid item xs={5}>
          <FormControl fullWidth disabled={disabled} error={error}>
            <Select
              id="date-format-2"
              value={dateFormat2}
              onChange={(event) => setDateFormat2(event.target.value)}
              data-testid="date-format-2"
            >
              <CustomMenuItem value="%d" data-testid="option-day">
                {OPTION_DAY}
              </CustomMenuItem>
              <CustomMenuItem value="%m" data-testid="option-month-number">
                {OPTION_MONTH_NUMBER}
              </CustomMenuItem>
              <CustomMenuItem value="%b" data-testid="option-month-abbrev">
                {OPTION_MONTH_ABBREV}
              </CustomMenuItem>
              <CustomMenuItem value="%B" data-testid="option-month">
                {OPTION_MONTH}
              </CustomMenuItem>
              <CustomMenuItem value="%y" data-testid="option-year-abbrev">
                {OPTION_YEAR_ABBREV}
              </CustomMenuItem>
              <CustomMenuItem value="%Y" data-testid="option-year">
                {OPTION_YEAR}
              </CustomMenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <TextField
            id="separator-2"
            value={separator2}
            onChange={(event) => setSeparator2(event.target.value)}
            variant="outlined"
            data-testid="separator-2-text-field"
            inputProps={{ 'data-testid': 'separator-2-input', maxLength: 1 }}
            disabled={disabled}
            error={error}
          />
        </Grid>

        <Grid item xs={5}>
          <FormControl fullWidth disabled={disabled} error={error}>
            <Select
              id="date-format-3"
              value={dateFormat3}
              onChange={(event) => setDateFormat3(event.target.value)}
              data-testid="date-format-3"
            >
              <CustomMenuItem value="%d" data-testid="option-day">
                {OPTION_DAY}
              </CustomMenuItem>
              <CustomMenuItem value="%m" data-testid="option-month-number">
                {OPTION_MONTH_NUMBER}
              </CustomMenuItem>
              <CustomMenuItem value="%b" data-testid="option-month-abbrev">
                {OPTION_MONTH_ABBREV}
              </CustomMenuItem>
              <CustomMenuItem value="%B" data-testid="option-month">
                {OPTION_MONTH}
              </CustomMenuItem>
              <CustomMenuItem value="%y" data-testid="option-year-abbrev">
                {OPTION_YEAR_ABBREV}
              </CustomMenuItem>
              <CustomMenuItem value="%Y" data-testid="option-year">
                {OPTION_YEAR}
              </CustomMenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Button variant="outlined" disabled={disabled} onClick={handleSelect} data-testid="select-date-format">
            <I18n path="organization.organization-settings.password-policy.personal-data.select-date-format" />
          </Button>
        </Grid>
      </Grid>
      <FormHelperText data-testid="error" error={error}>
        {helperText}
      </FormHelperText>
    </div>
  );
}
