import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

const TableCell = ({ isHead, children, className, ...props }) => {
  if (isHead) {
    return (
      <th className={classnames(styles.cell, styles.cellHead, className)} {...props}>
        {children}
      </th>
    );
  }
  return (
    <td className={classnames(styles.cell, className)} {...props}>
      {children}
    </td>
  );
};

TableCell.propTypes = {
  isHead: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TableCell.defaultProps = {
  isHead: false,
  className: undefined,
};

export default TableCell;
