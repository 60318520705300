import { useState } from 'react';
import Text from 'components/atoms/text';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Button from 'components/atoms/button';
import LabeledInput from 'components/molecules/labeled-input';
import InputIcon from 'components/molecules/input-icon';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';

function LoginDialog({ errors, isOpen, isSubmitting, onClose, onSubmit, organization }) {
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    setPassword('');
    setIsPasswordVisible(false);
  };

  return (
    <Dialog isOpen={isOpen} data-testid="login-dialog">
      <Header onClose={handleClose}>
        {organization ? (
          <I18n path="auth.accounts-corp-login.button-account-config" />
        ) : (
          <I18n path="auth.accounts-personal-login.button-account-config" />
        )}
      </Header>
      <Body>
        <Text>
          {organization ? (
            <I18n path="auth.accounts-corp-login.title-box-corp-login" props={{ organization: organization.name }} />
          ) : (
            <I18n path="auth.accounts-personal-login.title-box-personal-login" />
          )}
        </Text>
        <LabeledInput
          label={<I18n path="auth.accounts-corp-login.input-password" />}
          htmlFor="password"
          data-testid="password-labeled-input"
        >
          <InputIcon
            id="password"
            name="password"
            value={password}
            hasError={errors.has('password')}
            errorMessage={errors.get('password')}
            onChange={(event) => setPassword(event.target.value)}
            type={isPasswordVisible ? 'text' : 'password'}
            icon={isPasswordVisible ? 'eye' : 'eye-blocked'}
            onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
            data-testid="password-input"
          />
        </LabeledInput>
        <Button
          type="ghost"
          onClick={() => {
            if (organization) {
              navigate(`/auth/forgot-password/${organization.id}`);
            } else {
              navigate('/auth/forgot-password');
            }
          }}
        >
          <I18n path="auth.accounts-corp-login.link-password-reset" />
        </Button>
      </Body>
      <Footer>
        <Button
          type="primary"
          onClick={() => onSubmit({ password })}
          isLoading={isSubmitting}
          data-testid="submit-button"
        >
          {organization ? (
            <I18n path="auth.accounts-corp-login.button-login-corp" />
          ) : (
            <I18n path="auth.accounts-personal-login.button-login-personal" />
          )}
        </Button>
        <Button onClick={handleClose}>
          <I18n path="auth.accounts-corp-login.button-cancel" />
        </Button>
      </Footer>
    </Dialog>
  );
}

export default LoginDialog;
