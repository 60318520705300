import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import { Header } from 'components/molecules/device-card';
import Label from 'components/atoms/input-label';
import InputGroup from 'components/atoms/input-group';
import Input from 'components/atoms/input';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Loader from 'components/molecules/section-loader';
import Hydra from 'components/molecules/hydra';
import Pagination from 'components/organisms/pagination';
import { SlideFadeInContainer, SlideRightFadeContainer } from 'utils/transitions';
import I18n, { translate } from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearOrganizationData, setOrganizationSelectedUnit } from 'features/organizationSlice';
import { fetchUnits } from 'api/portal';
import { Card } from './styles';
import styles from './styles.module.css';

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_ORGANIZATION = <I18n path="organization.breadcrumbs.manage-organization" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/organization/units',
    title: MANAGE_ORGANIZATION,
    isActive: true,
  },
];

const linkItems = [
  {
    path: '/organization/members',
    title: <I18n path="organization.organization-members.title-sub-member" />,
  },
  {
    path: '/organization/units',
    title: <I18n path="organization.organization-units.title-sub-unit-edit" />,
  },
];
const actionButton = {
  text: <I18n path="organization.organization-units.button-add-unit" />,
  href: '/organization/units/create',
};

function Units() {
  const [isUnitsLoading, setIsUnitsLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [members, setMembers] = useState({});
  const [filterValue, setFilterValue] = useState('');
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUnits = (page) => {
    setIsUnitsLoading(true);

    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', 8);
    searchParams.set('search_name', filterValue);

    fetchUnits(searchParams).then((response) => {
      const { data } = response;

      const units = data.results;

      setUnits(units);
      setRows(data.count);
      setCurrentPage(page);
      setIsUnitsLoading(false);

      const membersMap = {};

      units.forEach((unit) => {
        const { members } = unit;

        membersMap[unit.id] = {
          results: members,
          count: members.length,
          loaded: true,
        };
      });

      setMembers(membersMap);
    });
  };

  const onClearFilterClick = () => {
    setFilterValue('');
    getUnits(1);
  };

  useEffect(() => {
    dispatch(clearOrganizationData());
    getUnits(1);
  }, []);

  const openEditUnitPage = (unit) => {
    dispatch(setOrganizationSelectedUnit(unit));
    navigate(`/organization/units/${unit.id}/edit`);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <App
      title={<I18n path="organization.organization-units.title-main-units" />}
      breadcrumbs={breadcrumbs}
      linkItems={linkItems}
      actionButton={actionButton}
    >
      <Section>
        <Subtitle>
          <I18n path="organization.organization-units.title-filter-units" />
        </Subtitle>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
          <div className={styles.filters}>
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="filter">
                <I18n path="organization.organization-units.input-filter-name" />
              </Label>
              <Input
                id="filter"
                placeholder={translate('organization.organization-units.dropdown-filter-name')}
                onChange={(event) => setFilterValue(event.target.value)}
                value={filterValue}
                data-testid="input-filter-name"
              />
            </InputGroup>
            <Button onClick={onClearFilterClick} data-testid="clear-filter-button">
              <I18n path="organization.organization-units.button-filter-reset" />
            </Button>
            <Button isLoading={false} onClick={() => getUnits(1)} type="primary" data-testid="apply-filter-button">
              <I18n path="organization.organization-units.button-filter-apply" />
            </Button>
          </div>
        </form>
        {isUnitsLoading ? (
          <Loader />
        ) : (
          <>
            <SlideFadeInContainer className={styles.unitList}>
              {units.length === 0 ? (
                <div className={styles.notFoundText}>
                  <I18n path="messages.no-results-found" />
                </div>
              ) : null}
              {units.map((unit) => {
                let avatarList = [];
                let count = 0;
                let loaded = false;

                if (members[unit.id]) {
                  ({ count } = members[unit.id]);
                  avatarList = members[unit.id].results.map((member) => (member.photo ? member.photo : ''));
                  ({ loaded } = members[unit.id]);
                }

                return (
                  <Card key={`unit-${unit.id}`} onClick={() => (loaded ? openEditUnitPage(unit) : null)}>
                    <Header className={styles.cardTitle} title={unit.name} data-testid={`unit-${unit.id}`} />
                    <div>
                      <Text className={styles.cardSubtitle}>
                        {loaded ? (
                          <I18n path="organization.organization-units.card-member-members" props={{ count }} />
                        ) : (
                          <I18n path="organization.organization-units.card-member-members-loading" />
                        )}
                      </Text>
                      {loaded ? (
                        <SlideRightFadeContainer>
                          <Hydra avatarList={avatarList} count={count} />
                        </SlideRightFadeContainer>
                      ) : (
                        <Loader className={styles.cardLoader} />
                      )}
                    </div>
                  </Card>
                );
              })}
            </SlideFadeInContainer>
            <Pagination
              rows={rows}
              rowsPerPage={8}
              currentPage={currentPage}
              onPageSelect={getUnits}
              maxPagesDisplay={3}
            />
          </>
        )}
      </Section>
    </App>
  );
}

export default Units;
