import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputGroup from 'components/atoms/input-group';
import Label from 'components/atoms/input-label';
import styles from './styles.module.css';

const LabeledInput = ({ htmlFor, label, isDisabled, children, className, ...props }) => (
  <InputGroup className={className} {...props}>
    <Label htmlFor={htmlFor} className={classNames(styles.label, { [styles.disabled]: isDisabled })}>
      {label}
    </Label>
    {children}
  </InputGroup>
);

LabeledInput.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

LabeledInput.defaultProps = {
  htmlFor: undefined,
  isDisabled: false,
  className: undefined,
};

export default LabeledInput;
