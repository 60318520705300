export default class PasswordValidator {
  constructor(policy, policyCode, serverSideOnly, message) {
    this.policy = policy;
    this.policyCode = policyCode;
    this.serverSideOnly = serverSideOnly;
    this.message = message;
  }

  validate() {
    return true;
  }
}
