import styled from 'styled-components';
import { Card as CardMixin } from 'utils/mixins';

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: solid 1px var(--color-grey-light);
  border-radius: 4px;
  transition: var(--transition-default);
  &:hover {
    border: solid 1px var(--color-primary-light);
  }
  &:active {
    border: solid 1px var(--color-grey-light);
  }

  ${CardMixin}
`;
