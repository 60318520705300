import Immutable from 'seamless-immutable';

const initialState = Immutable({
  toasts: [],
  visible: [],
});

const getToastIndex = (state, key) => state.toasts.map((toast) => toast.key).indexOf(key);

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'TOASTS/ADD_TOAST':
      return state.setIn(['toasts'], [...state.toasts, action.payload]);
    case 'TOASTS/CLOSE_TOAST':
      return state.setIn(
        ['toasts'],
        Immutable.asMutable(state.toasts).filter((toast) => toast.key !== action.payload),
      );
    case 'TOASTS/TOGGLE_TOAST_VISIBILITY':
      if (getToastIndex(state, action.payload) === -1) return state;

      return state.setIn(
        ['toasts', getToastIndex(state, action.payload), 'visible'],
        !state.toasts[getToastIndex(state, action.payload)].visible,
      );
    default:
      return state;
  }
};
