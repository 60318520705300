import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  nextNotificationPage: 1,
  total: 0,
  count: 0,
  unreadCount: 0,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications() {
      return initialState;
    },
    setNotificationsData(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    setNotificationsProps(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { clearNotifications, setNotificationsData, setNotificationsProps } = notificationsSlice.actions;

export default notificationsSlice.reducer;
