import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const TYPES = {
  success: styles.success,
  warning: styles.warning,
  danger: styles.danger,
};

const Alert = ({ type, children }) => <div className={classNames(styles.alert, TYPES[type])}>{children}</div>;

Alert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Alert.defaultProps = {
  type: 'warning',
};

export default Alert;
