import { useState } from 'react';
import validateEmail from 'utils/validate-email';
import { validateCpf } from 'utils/validate-documents';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Input from 'components/atoms/input';
import Radio from 'components/atoms/input-radio';
import Label from 'components/atoms/input-label';
import Select from 'components/atoms/input-select';
import Hint from 'components/atoms/input-hint';
import Option from 'components/atoms/option';
import Button from 'components/atoms/button';
import Spinner from 'components/atoms/spinner';
import LabeledInput from 'components/molecules/labeled-input';
import { formatToCPFDocument, unformatDocument } from 'utils/format-documents';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { searchUser } from 'api/portal';
import styles from './styles.module.css';

const INVALID_DOCUMENT = <I18n path="messages.invalid-document" />;
const MEMBER_FOUND = <I18n path="organization.organization-members-create.member-found" />;
const MEMBER_NOT_FOUND = <I18n path="organization.organization-members-create.member-not-found" />;
const MEMBER_SEARCH = <I18n path="organization.organization-members-create.member-search" />;

function PersonalInfo({ errors, initialData, onSave }) {
  const [userId, setUserId] = useState(initialData.userId);

  const [documentTypeForSearch, setDocumentTypeForSearch] = useState(initialData.documentType);
  const [documentForSearch, setDocumentForSearch] = useState(initialData.document);

  const [documentType, setDocumentType] = useState(initialData.documentType);
  const [document, setDocument] = useState(initialData.document);
  const [firstName, setFirstName] = useState(initialData.firstName);
  const [lastName, setLastName] = useState(initialData.lastName);
  const [email, setEmail] = useState(initialData.email);
  const [birthYear, setBirthYear] = useState(initialData.birthYear);
  const [notificationLanguage, setNotificationLanguage] = useState(initialData.notificationLanguage);

  const [isNewUser, setIsNewUser] = useState(true);
  const [isValidUserDocument, setIsValidUserDocument] = useState(true);
  const [isValidUserEmail, setIsValidUserEmail] = useState(true);
  const [isFindingUser, setIsFindingUser] = useState(false);
  const navigate = useNavigate();

  const clearUserData = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setBirthYear('');
    setNotificationLanguage('pt-br');
    setIsNewUser(true);
    setIsValidUserEmail(true);
  };

  const checkUserExistence = () => {
    setUserId(undefined);

    const isCpf = documentTypeForSearch === 'CPF';

    const validDocument = (isCpf ? validateCpf(documentForSearch) : true) && documentForSearch !== '';
    setIsValidUserDocument(validDocument);

    if (validDocument) {
      setIsFindingUser(true);

      const documentValue = isCpf ? unformatDocument(documentForSearch) : documentForSearch;

      searchUser(documentValue)
        .then((response) => {
          setDocument(documentValue);
          setDocumentType(documentTypeForSearch);

          const { data } = response;

          setUserId(data.id);
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setIsNewUser(false);
          setIsValidUserEmail(true);

          setIsFindingUser(false);
        })
        .catch(() => {
          setDocument(documentValue);
          setDocumentType(documentTypeForSearch);

          setUserId(null);
          clearUserData();

          setIsFindingUser(false);
        });
    }
  };

  const userDocumentLabelHint = () => {
    if (isFindingUser) {
      return (
        <Hint data-testid="document-hint">
          <Spinner className={styles.spinner} />
          {MEMBER_SEARCH}
        </Hint>
      );
    }

    if (userId === undefined) {
      return null;
    }

    if (userId === null) {
      return <Hint data-testid="document-hint">{MEMBER_NOT_FOUND}</Hint>;
    }

    return (
      <Hint icon="checkmark" data-testid="document-hint">
        {MEMBER_FOUND}
      </Hint>
    );
  };

  return (
    <div>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-members-create.title-sub-member-username" />
        </Subtitle>
        <Text isItalic>
          <I18n path="organization.organization-members-create.text-member-create-username" />
        </Text>
        <LabeledInput
          label={<I18n path="organization.organization-members-create.input-doc-type-choice" />}
          htmlFor="document_type"
        >
          <Select
            id="document_type"
            name="document-type"
            value={documentTypeForSearch}
            onChange={(event) => {
              setDocumentForSearch('');
              setDocumentTypeForSearch(event.target.value);
            }}
            data-testid="select-document-type"
          >
            <Option value="CPF" data-testid="cpf-option">
              CPF
            </Option>
            <Option value="ID" data-testid="id-option">
              ID{' '}
            </Option>
          </Select>
        </LabeledInput>

        <LabeledInput
          label={<I18n path="organization.organization-members-create.input-document" />}
          htmlFor="number"
          data-testid="document-labeled-input"
        >
          <Input
            id="number"
            placeholder={documentTypeForSearch === 'CPF' ? '000.000.000-00' : ''}
            name="number"
            value={documentForSearch}
            onChange={(event) => {
              const { value } = event.target;
              setDocumentForSearch(documentTypeForSearch === 'CPF' ? formatToCPFDocument(value) : value);
            }}
            hasError={!isValidUserDocument || Boolean(errors && errors.get('document'))}
            errorMessage={
              (!isValidUserDocument && INVALID_DOCUMENT) ||
              (errors && errors.get('document') ? errors.get('document') : '')
            }
            maxLength={documentTypeForSearch === 'CPF' ? '14' : '32'}
            data-testid="document-input"
          />
          {userDocumentLabelHint()}
        </LabeledInput>

        <Button type="primary" onClick={checkUserExistence} data-testid="search-user-button">
          <I18n path="organization.organization-members-create.button-search-user" />
        </Button>
      </Section>

      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-members-create.title-sub-member-create" />
        </Subtitle>
        <Text isItalic>
          <I18n path="organization.organization-members-create.text-member-create-main" />
        </Text>

        <LabeledInput
          label={<I18n path="organization.organization-members-create.input-name" />}
          htmlFor="name"
          isDisabled={userId !== null}
          data-testid="first-name-labeled-input"
        >
          <Input
            id="name"
            placeholder="Theresa"
            name="name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            hasError={Boolean(errors && errors.get('firstName'))}
            errorMessage={errors && errors.get('firstName') ? errors.get('firstName') : ''}
            isDisabled={userId !== null}
            data-testid="first-name-input"
          />
        </LabeledInput>

        <LabeledInput
          label={<I18n path="organization.organization-members-create.input-surname" />}
          htmlFor="last_name"
          isDisabled={userId !== null}
          data-testid="last-name-labeled-input"
        >
          <Input
            id="last_name"
            placeholder="Mitchell"
            name="last-name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            hasError={Boolean(errors && errors.get('lastName'))}
            errorMessage={errors && errors.get('lastName') ? errors.get('lastName') : ''}
            isDisabled={userId !== null}
            data-testid="last-name-input"
          />
        </LabeledInput>

        {userId === null ? (
          <>
            <LabeledInput
              label={<I18n path="organization.organization-members-create.input-email" />}
              htmlFor="userEmail"
              isDisabled={userId === undefined}
              data-testid="email-labeled-input"
            >
              <Input
                id="userEmail"
                placeholder="mitchell@gmail.com"
                name="userEmail"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                hasError={Boolean(errors && errors.get('email'))}
                errorMessage={
                  (!isValidUserEmail && "It's not a valid email ") ||
                  (errors && errors.get('email') ? errors.get('email') : '')
                }
                isDisabled={userId === undefined}
                onBlur={(event) => {
                  const { value } = event.target;
                  setIsValidUserEmail(value === '' || validateEmail(value));
                }}
                data-testid="email-input"
              />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="account.me-edit-info.input-birth-year" />}
              htmlFor="birth_year"
              data-testid="birth-year-labeled-input"
            >
              <Input
                id="birth_year"
                name="Birth year"
                maxLength={4}
                value={birthYear}
                onChange={(event) => setBirthYear(event.target.value.replace(/\D/g, ''))}
                hasError={Boolean(errors && errors.get('birthYear'))}
                errorMessage={errors && errors.get('birthYear') ? errors.get('birthYear') : ''}
                data-testid="birth-year-input"
              />
            </LabeledInput>

            <div className={styles.radiosButton}>
              <div className={styles.language}>
                <Label isDisabled={userId === undefined}>
                  <I18n path="auth.signup.text-doc-choice-language" />
                </Label>
                <div className={styles.languageOptions}>
                  <Radio
                    id="pt-br"
                    name="notificationLanguage"
                    value="pt-br"
                    label={<I18n path="auth.signup.input-language-pt-br" />}
                    isChecked={notificationLanguage === 'pt-br'}
                    isDisabled={userId === undefined}
                    onChange={(event) => setNotificationLanguage(event.target.value)}
                    data-testid="pt-br-radio"
                  />
                  <Radio
                    id="en-us"
                    name="notificationLanguage"
                    value="en-us"
                    label={<I18n path="auth.signup.input-language-en-us" />}
                    isChecked={notificationLanguage === 'en-us'}
                    isDisabled={userId === undefined}
                    onChange={(event) => setNotificationLanguage(event.target.value)}
                    data-testid="en-us-radio"
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </Section>

      <div className={styles.buttonsContainer}>
        <Button
          type="primary"
          onClick={() => {
            if (isValidUserEmail) {
              onSave({
                userId,
                document,
                documentType,
                firstName,
                lastName,
                email,
                birthYear,
                notificationLanguage,
                isNewUser,
              });
            }
          }}
          isDisabled={userId === undefined}
          data-testid="continue-button"
        >
          <I18n path="organization.organization-members-create.button-continue" />
        </Button>
        <Button onClick={() => navigate('/organization/members')} data-testid="cancel-button">
          <I18n path="organization.organization-members-create.button-cancel" />
        </Button>
      </div>
    </div>
  );
}

export default PersonalInfo;
