import PropTypes from 'prop-types';
import Icon from 'components/atoms/icon';
import { Card } from './styles';
import styles from './styles.module.css';

export { default as Header } from './header';
export { default as Item } from './item';
export { default as Footer } from './footer';

const DeviceCard = ({ className, onClick, isBlocked, children, ...props }) => (
  <Card className={className} onClick={onClick} role="presentation" {...props}>
    {isBlocked && (
      <>
        <div className={styles.blockedFlag} />
        <Icon name="lock" className={styles.blockedIcon} />
      </>
    )}
    {children}
  </Card>
);

DeviceCard.propTypes = {
  className: PropTypes.string,
  isBlocked: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

DeviceCard.defaultProps = {
  className: '',
  isBlocked: false,
  onClick: undefined,
};

export default DeviceCard;
