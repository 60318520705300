import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import chunk from 'lodash/chunk';

import IconButton from 'components/atoms/icon-button';
import isFloat from 'utils/is-float';

import styles from './styles.module.css';

class Pagination extends Component {
  static propTypes = {
    rows: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    maxPagesDisplay: PropTypes.number,
    onPageSelect: PropTypes.func,
  };

  static defaultProps = {
    onPageSelect: () => {},
    maxPagesDisplay: undefined,
  };

  getPages = () => {
    const pages = this.props.rows / this.props.rowsPerPage;
    return isFloat(pages) ? Math.floor(pages) + 1 : pages;
  };

  handlePageSelect = (page) => () => {
    this.props.onPageSelect(page);
  };

  handlePrevSelect = () => {
    const { currentPage, onPageSelect } = this.props;
    const page = currentPage === 1 ? currentPage : currentPage - 1;
    onPageSelect(page);
  };

  handleNextSelect = () => {
    const { currentPage, onPageSelect } = this.props;
    const page = currentPage === this.getPages() ? currentPage : currentPage + 1;
    onPageSelect(page);
  };

  renderMaxPages = (pageButtons) => {
    const { maxPagesDisplay, currentPage } = this.props;
    const pageGroupsArr = chunk(pageButtons, maxPagesDisplay);
    const currentPageButton = pageButtons[currentPage - 1];
    const offset = pageGroupsArr.findIndex((pageGroup) => pageGroup.indexOf(currentPageButton) !== -1);
    const lessButton = (
      <button key="pagination-ellipsis-less" className={classNames(styles.pageButton, styles.button)}>
        ...
      </button>
    );
    const moreButton = (
      <button key="pagination-ellipsis-more" className={classNames(styles.pageButton, styles.button)}>
        ...
      </button>
    );
    const lastButton = (
      <button
        key="pagination-last-button"
        className={classNames(styles.pageButton, styles.button)}
        onClick={this.handlePageSelect(this.getPages())}
      >
        {this.getPages()}
      </button>
    );
    const firstButton = (
      <button
        key="pagination-first-button"
        className={classNames(styles.pageButton, styles.button)}
        onClick={this.handlePageSelect(1)}
      >
        1
      </button>
    );

    const unshiftButtons = () => {
      pageGroupsArr[offset].unshift(lessButton);
      pageGroupsArr[offset].unshift(firstButton);
    };

    if (pageGroupsArr.length !== offset + 1) {
      pageGroupsArr[offset].push(moreButton);
      pageGroupsArr[offset].push(lastButton);

      if (offset > 0) {
        unshiftButtons();
      }
    } else {
      unshiftButtons();
    }

    return pageGroupsArr[offset];
  };

  render() {
    const { rows, rowsPerPage, currentPage, maxPagesDisplay } = this.props;

    const pageButtons = [];
    for (let i = 1; i <= this.getPages(); i += 1) {
      pageButtons.push(
        <button
          key={`pagination-${i.toString()}`}
          onClick={this.handlePageSelect(i)}
          className={classNames(styles.pageButton, {
            [styles.button]: true,
            [styles.pageButtonPrevious]: i < currentPage,
            [styles.pageButtonActive]: i === currentPage,
          })}
        >
          {i}
        </button>,
      );
    }

    return (
      <div className={styles.container}>
        <IconButton
          name="arrow-left2"
          onClick={this.handlePrevSelect}
          iconClassName={styles.arrowIcon}
          className={classNames(styles.arrowButton, { [styles.inactive]: rows <= rowsPerPage })}
        />

        {maxPagesDisplay && maxPagesDisplay < this.getPages() ? this.renderMaxPages(pageButtons) : pageButtons}
        <IconButton
          name="arrow-right2"
          onClick={this.handleNextSelect}
          iconClassName={styles.arrowIcon}
          className={classNames(styles.arrowButton, { [styles.inactive]: rows <= rowsPerPage })}
        />
      </div>
    );
  }
}

export default Pagination;
