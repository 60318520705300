import { useEffect } from 'react';
import { addToast } from 'actions/toasts';
import I18n from 'utils/i18n';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMobileDevice, setOrganizationSelectedMobileDevice } from 'features/organizationSlice';
import { fetchMobileDevices } from 'api/portal';
import { isTrustedDevice, validateDeviceStatus } from 'utils/mobile-devices';
import MobileDeviceRegistration from '../../common/MobileDeviceRegistration';
import MobileDeviceStatusUpdate from '../../common/MobileDeviceStatusUpdate';

const CURRENT_NAV = <I18n path="organization.breadcrumbs.edit-organization-mobile-device" />;
const ERROR_FETCH_DEVICE = <I18n path="organization.organization-mobile-devices-edit.error-fetch-device" />;
const LOAD_FAILURE = <I18n path="messages.load-failure" />;

function EditMobileDeviceStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [searchParams] = useSearchParams();

  const { deviceId } = params;
  const status = searchParams.get('status');

  const mobileDevice = useSelector((state) => state.organization.selectedMobileDevice);

  const onErrorFetchingDevice = () => {
    navigate('/org-mobile-devices/devices');
    dispatch(addToast('error', ERROR_FETCH_DEVICE));
  };

  useEffect(() => {
    if (!validateDeviceStatus(status)) {
      dispatch(addToast('error', LOAD_FAILURE));
      navigate('/org-mobile-devices/devices');
      return;
    }

    if (mobileDevice.org_device_id !== deviceId) {
      dispatch(clearOrganizationSelectedMobileDevice());
    }

    fetchMobileDevices(new URLSearchParams(), { org_device_id: deviceId })
      .then((response) => {
        const { data } = response;

        if (!data.results) {
          onErrorFetchingDevice();
        } else if (data.results.length === 1) {
          const device = data.results[0];
          dispatch(clearOrganizationSelectedMobileDevice());
          dispatch(setOrganizationSelectedMobileDevice(device));
        } else {
          onErrorFetchingDevice();
        }
      })
      .catch(() => onErrorFetchingDevice());
  }, []);

  const TITLE = (
    <I18n props={{ name: mobileDevice.name }} path="organization.organization-mobile-devices-edit.title-edit-device" />
  );

  if (isTrustedDevice(status)) {
    return <MobileDeviceRegistration title={TITLE} currentNav={CURRENT_NAV} device={mobileDevice} />;
  }

  return <MobileDeviceStatusUpdate device={mobileDevice} />;
}

export default EditMobileDeviceStatus;
