import styled, { keyframes } from 'styled-components';
import { Separator as SeparatorMixin } from 'utils/mixins';

const SlideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px) scaleX(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scaleX(1);
  }
`;

export const SlideInContainer = styled.div`
  opacity: 0;
  animation: ${SlideIn} 300ms ease forwards;
`;

export const Separator = styled.div`
  ${SeparatorMixin}
`;
