import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import I18n, { translate } from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { updateMobileDevice } from 'api/portal';
import styles from './styles.module.css';

const ERROR_MESSAGE = <I18n path="organization.organization-mobile-devices-edit.error-device-update" />;

function ConfirmationStatusUpdate({ device, status, onClickNextStep }) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialStatus = device.status;
  const targetStatus = status;

  const updateDeviceStatus = () => {
    setIsLoading(true);

    const deviceData = {
      status,
      org_device_id: device.org_device_id,
    };

    updateMobileDevice(deviceData)
      .then((response) => {
        const { data } = response;
        const { status } = data;

        setIsLoading(false);
        onClickNextStep({ ...deviceData, ...device, status });
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(addToast('error', ERROR_MESSAGE));
      });
  };

  const renderStatusDescription = () => {
    if (status === 'NORMAL') {
      return (
        <div data-testid="normal-description">
          <Text isWithoutMargin>
            <I18n path="organization.organization-mobile-devices-info.text-normal-part-2" />
          </Text>
          <Text isWithoutMargin>
            <I18n path="organization.organization-mobile-devices-info.text-normal-part-3" />
          </Text>
          <Text>
            <I18n path="organization.organization-mobile-devices-info.text-normal-part-4" />
          </Text>
        </div>
      );
    }

    return (
      <div data-testid="blocked-description">
        <Text isWithoutMargin>
          <I18n path="organization.organization-mobile-devices-info.text-blocked-part-2" />
        </Text>
        <Text isWithoutMargin>
          <I18n path="organization.organization-mobile-devices-info.text-blocked-part-3" />
        </Text>
        <Text>
          <I18n path="organization.organization-mobile-devices-info.text-blocked-part-4" />
        </Text>
      </div>
    );
  };

  return (
    <>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-mobile-devices-edit.title-sub-status-update" />
        </Subtitle>
        <Text isItalic>
          <I18n
            props={{
              initial_status: translate(
                `organization.organization-mobile-devices-info.${initialStatus ? initialStatus.toLowerCase() : ''}`,
              ),
              target_status: translate(
                `organization.organization-mobile-devices-info.${targetStatus ? targetStatus.toLowerCase() : ''}`,
              ),
            }}
            path="organization.organization-mobile-devices-edit.text-status-update-1"
          />
          <br />
          <br />
          {renderStatusDescription()}
        </Text>
      </Section>
      <div className={styles.buttonsContainer}>
        <Button type="primary" isLoading={isLoading} onClick={updateDeviceStatus} data-testid="confirm-button">
          <I18n path="organization.organization-mobile-devices-edit.button-confirm" />
        </Button>
        <Button onClick={() => navigate('/org-mobile-devices/devices')} data-testid="cancel-button">
          <I18n path="organization.organization-mobile-devices-edit.button-cancel" />
        </Button>
      </div>
    </>
  );
}

export default ConfirmationStatusUpdate;
