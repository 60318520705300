import { useEffect, useState } from 'react';
import validateEmail from 'utils/validate-email';
import validatePhone from 'utils/validate-phone';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import LabeledInput from 'components/molecules/labeled-input';
import Units from 'components/organisms/member-units';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { fetchUnits } from 'api/portal';
import Permissions from 'components/organisms/Permissions';
import styles from './styles.module.css';

const INVALID_EMAIL = <I18n path="messages.invalid-email" />;
const INVALID_PHONE = <I18n path="messages.invalid-phone" />;

function MemberInfo({ errors, initialData, isLoadingSave, onPreviousStep, onSave }) {
  const [email, setEmail] = useState(initialData.email);
  const [phoneNumber, setPhoneNumber] = useState(initialData.phoneNumber);
  const [roles, setRoles] = useState(initialData.roles);
  const [isValidMemberEmail, setIsValidMemberEmail] = useState(true);
  const [isValidMemberPhone, setIsValidMemberPhone] = useState(true);

  const [selectedUnits, setSelectedUnits] = useState(initialData.units);
  const [units, setUnits] = useState([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [errorUnits, setErrorUnits] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchUnits(searchParams)
      .then((response) => {
        setIsLoadingUnits(false);

        const { data } = response;

        setUnits(data.results);
        setErrorUnits(false);
      })
      .catch(() => {
        setIsLoadingUnits(false);
        setErrorUnits(true);
      });
  }, []);

  const handleValidateEmail = () => setIsValidMemberEmail(email === '' || validateEmail(email));

  const handleValidatePhone = () => setIsValidMemberPhone(phoneNumber === '' || validatePhone(phoneNumber));

  return (
    <div>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-members-create.title-sub-organization-info" />
        </Subtitle>
        <Text>
          <I18n path="organization.organization-members-create.text-sub-organization-info" />
        </Text>

        <LabeledInput
          label={<I18n path="organization.organization-members-create.input-email" />}
          htmlFor="birth_date"
          data-testid="email-labeled-input"
        >
          <Input
            id="email"
            placeholder="eg.: johndoe@yourorganization.com"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            hasError={!isValidMemberEmail || Boolean(errors && !!errors.get('email'))}
            errorMessage={
              (!isValidMemberEmail && INVALID_EMAIL) || (errors && !!errors.get('email') ? errors.get('email') : '')
            }
            className={styles.inputs}
            onBlur={handleValidateEmail}
            data-testid="email-input"
          />
        </LabeledInput>

        <LabeledInput
          label={<I18n path="organization.organization-members-create.input-phone" />}
          htmlFor="phone_number"
          data-testid="phone-number-labeled-input"
        >
          <Input
            id="phone_number"
            placeholder="+1 123-456-7890"
            name="phone-number"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            hasError={!isValidMemberPhone || Boolean(errors && !!errors.get('phoneNumber'))}
            errorMessage={
              (!isValidMemberPhone && INVALID_PHONE) ||
              (errors && !!errors.get('phoneNumber') ? errors.get('phoneNumber') : '')
            }
            className={styles.inputs}
            onBlur={handleValidatePhone}
            data-testid="phone-number-input"
          />
        </LabeledInput>
      </Section>

      <Section data-testid="roles-section">
        <div className={styles.permissionsSection}>
          <div>
            <Subtitle>
              <I18n path="organization.organization-members-create.title-sub-member-roles" />
            </Subtitle>
            <Text>
              <I18n path="organization.organization-members-create.text-member-create-role" />
            </Text>
          </div>
          {errors && !!errors.get('roles') && (
            <Text className={styles.errorMessage} data-testid="roles-selection-error">
              {errors.get('roles')}
            </Text>
          )}
        </div>

        <Permissions
          selected={roles}
          onToggle={(selectedRoles) => setRoles(selectedRoles)}
          data-testid="roles-selection"
        />
      </Section>

      <Section>
        <Subtitle>
          <I18n path="organization.organization-members-create.title-sub-member-units" />
        </Subtitle>
        <Text>
          <I18n path="organization.organization-members-create.text-member-create-units" />
        </Text>

        <Units
          error={errorUnits}
          isLoading={isLoadingUnits}
          onToggle={(selectedUnits) => setSelectedUnits(selectedUnits)}
          selectedUnits={selectedUnits}
          units={units}
          data-testid="units-selection"
        />
        {errors && !!errors.get('units') && <Text className={styles.errorMessage}>{errors.get('units')}</Text>}
      </Section>

      <div className={styles.buttonsContainer}>
        <Button onClick={onPreviousStep} data-testid="previous-step-button">
          <I18n path="organization.organization-members-create.button-previous" />
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (isValidMemberEmail && isValidMemberPhone) {
              onSave({
                email,
                phoneNumber,
                roles,
                units: selectedUnits,
              });
            }
          }}
          isLoading={isLoadingSave}
          data-testid="save-button"
        >
          <I18n path="organization.organization-members-create.button-save" />
        </Button>
        <Button onClick={() => navigate('/organization/members')} data-testid="cancel-button">
          <I18n path="organization.organization-members-create.button-cancel" />
        </Button>
      </div>
    </div>
  );
}

export default MemberInfo;
