import { useState } from 'react';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToast } from 'actions/toasts';
import { clearAllTokens, setToken } from 'utils/tokens';
import { clearProfileData, setOrganizations, setProfileData } from 'features/userSlice';
import { fetchProfiles, login } from 'api/portal';
import LoginForm from '../common/LoginForm';

const USER_BLOCKED = <I18n path="auth.login.user-blocked" />;
const INVALID_CREDENTIAL = <I18n path="auth.login.auth-error" />;
const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const GENERIC_ERROR = <I18n path="messages.generic-error" />;

function Login() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getOrganizationProfiles = (braincarePermissions) => {
    const hasBraincarePermissions = Boolean(braincarePermissions.length);

    fetchProfiles()
      .then((response) => {
        const { data } = response;

        setIsSubmitting(false);

        dispatch(setOrganizations(data));

        if (data.length || hasBraincarePermissions) {
          navigate('/auth/accounts');
        } else {
          navigate('/');
        }
      })
      .catch(() => {
        dispatch(clearProfileData());
        clearAllTokens();
        dispatch(addToast('error', LOAD_FAILURE));
      });
  };

  const handleSubmit = (formData) => {
    setIsSubmitting(true);

    login({
      document: formData.document,
      password: formData.password,
    })
      .then((response) => {
        const { data } = response;

        if (data.hasOwnProperty('error')) {
          if (data ? !!data.error : data.message) {
            dispatch(addToast('error', INVALID_CREDENTIAL));
          }
          setIsSubmitting(false);
          return;
        }

        const { user } = data;

        dispatch(setProfileData(user));

        setToken('personal_access_token', data.access_token);
        setToken('personal_refresh_token', data.refresh_token);
        setToken('access_token', data.access_token);
        setToken('refresh_token', data.refresh_token);

        if (user.accept_terms === false) {
          setIsSubmitting(false);
          navigate('/auth/accept-terms');
        } else {
          getOrganizationProfiles(user.braincare_permissions);
        }
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        if (data.hasOwnProperty('blocked')) {
          setIsSubmitting(false);
          dispatch(addToast('error', USER_BLOCKED));
          return;
        }

        setIsSubmitting(false);
        dispatch(addToast('error', GENERIC_ERROR));
      });
  };

  return <LoginForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />;
}

export default Login;
