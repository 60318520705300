import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const FONT_SIZE = {
  small: styles.small,
  medium: styles.medium,
  big: styles.big,
  large: styles.large,
};

const Text = ({ size, isBold, isItalic, isPrimary, isError, isWithoutMargin, children, className, ...props }) => (
  <div
    className={classNames(
      FONT_SIZE[size],
      {
        [styles.bold]: isBold,
        [styles.italic]: isItalic,
        [styles.primary]: isPrimary,
        [styles.error]: isError,
        [styles.withoutMargin]: isWithoutMargin,
      },
      styles.text,
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

Text.propTypes = {
  size: PropTypes.string,
  isBold: PropTypes.bool,
  isItalic: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isError: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isWithoutMargin: PropTypes.bool,
};

Text.defaultProps = {
  size: 'medium',
  isBold: false,
  isItalic: false,
  isPrimary: false,
  isError: false,
  className: undefined,
  isWithoutMargin: false,
};

export default Text;
