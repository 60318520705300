import { useState } from 'react';
import Section from 'components/atoms/section';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import App from 'pages/organization/organization-settings/App';
import I18n from 'utils/i18n';
import Button from 'components/mui/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMemberOrganization } from 'api/portal';
import { addToast } from 'actions/toasts';
import { formatNumber } from 'utils/format-number';
import { setActiveOrganizationData, setOrganizations } from 'features/userSlice';
import { SlideRightFadeContainer } from 'utils/transitions';
import TextField from 'components/mui/TextField';
import FormControlLabel from 'components/mui/FormControlLabel';
import { Checkbox } from '@mui/material';

const INACTIVITY_TIME_EMPTY = (
  <I18n path="organization.organization-settings.inactivity-policy.inactivity-time-empty" />
);
const INACTIVITY_TIME_LOW = <I18n path="organization.organization-settings.inactivity-policy.inactivity-time-low" />;

const MAX_INACTIVITY_PERIOD_EMPTY = (
  <I18n path="organization.organization-settings.inactivity-policy.max-inactivity-period-empty" />
);

const SAVE_SUCCESS_MESSAGE = <I18n path="organization.organization-settings.save-success-message" />;
const SAVE_FAILURE_MESSAGE = <I18n path="organization.organization-settings.save-failure-message" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

function OrganizationInactivityPolicy() {
  const organization = useSelector((state) => state.user.active_organization);
  const organizations = useSelector((state) => state.user.organizations);

  const [enableAutoLogoutPortal, setEnableAutoLogoutPortal] = useState(organization.auto_logout_portal);
  const [inactivityTimePortal, setInactivityTimePortal] = useState(organization.auto_logout_time_portal || '');

  const [enableAutoLogoutApp, setEnableAutoLogoutApp] = useState(organization.auto_logout_app);
  const [inactivityTimeApp, setInactivityTimeApp] = useState(organization.auto_logout_time_app || '');

  const [autoSuspensionMembers, setAutoSuspensionMembers] = useState(organization.suspend_inactive_members);
  const [maxInactivityPeriod, setMaxInactivityPeriod] = useState(organization.maximum_inactive_period || '');

  const [errors, setErrors] = useState(new Map());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateForm = () => {
    const errors = new Map();

    if (enableAutoLogoutPortal) {
      if (!inactivityTimePortal) {
        errors.set('inactivityTimePortal', INACTIVITY_TIME_EMPTY);
      }
    }

    if (inactivityTimePortal !== '' && inactivityTimePortal !== null) {
      if (inactivityTimePortal < 1) {
        errors.set('inactivityTimePortal', INACTIVITY_TIME_LOW);
      }
    }

    if (enableAutoLogoutApp) {
      if (!inactivityTimeApp) {
        errors.set('inactivityTimeApp', INACTIVITY_TIME_EMPTY);
      }
    }

    if (inactivityTimeApp !== '' && inactivityTimeApp !== null) {
      if (inactivityTimeApp < 1) {
        errors.set('inactivityTimeApp', INACTIVITY_TIME_LOW);
      }
    }

    if (autoSuspensionMembers) {
      if (!maxInactivityPeriod) {
        errors.set('maxInactivityPeriod', MAX_INACTIVITY_PERIOD_EMPTY);
      }
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateMemberOrganization({
        auto_logout_portal: enableAutoLogoutPortal,
        auto_logout_time_portal: inactivityTimePortal === '' ? null : inactivityTimePortal,
        auto_logout_app: enableAutoLogoutApp,
        auto_logout_time_app: inactivityTimeApp === '' ? null : inactivityTimeApp,
        suspend_inactive_members: autoSuspensionMembers,
        maximum_inactive_period: maxInactivityPeriod === '' ? null : maxInactivityPeriod,
      })
        .then((response) => {
          const { data } = response;

          setIsSubmitting(false);

          dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
          dispatch(setActiveOrganizationData(data));
          dispatch(
            setOrganizations(
              organizations.map((item) => {
                if (item.id === data.id) {
                  return data;
                }

                return item;
              }),
            ),
          );
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
        });
    } else {
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  return (
    <App>
      <Section maxWidth="428px">
        <SlideRightFadeContainer>
          <Subtitle>
            <I18n path="organization.organization-settings.inactivity-policy.logout-policy-subtitle" />
          </Subtitle>

          <Text isItalic>
            <I18n path="organization.organization-settings.inactivity-policy.logout-policy-text" />
          </Text>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.inactivity-policy.portal-logout-subsubtitle" />
            </SubSubtitle>

            <FormControlLabel
              label={<I18n path="organization.organization-settings.inactivity-policy.portal-logout-enable-checkbox" />}
              control={
                <Checkbox
                  checked={enableAutoLogoutPortal}
                  onClick={() => setEnableAutoLogoutPortal(!enableAutoLogoutPortal)}
                  inputProps={{ 'data-testid': 'auto-logout-portal-checkbox' }}
                />
              }
            />

            <TextField
              id="inactivity_time_portal"
              sx={{ margin: '16px 0 32px 0' }}
              value={inactivityTimePortal}
              onChange={(event) => setInactivityTimePortal(formatNumber(event.target.value))}
              label={<I18n path="organization.organization-settings.inactivity-policy.portal-logout-time-input" />}
              variant="outlined"
              error={errors.has('inactivityTimePortal')}
              helperText={errors.get('inactivityTimePortal')}
              data-testid="auto-logout-portal-text-field"
              inputProps={{ 'data-testid': 'auto-logout-portal-input' }}
              FormHelperTextProps={{ 'data-testid': 'auto-logout-portal-error' }}
              fullWidth
            />

            <SubSubtitle>
              <I18n path="organization.organization-settings.inactivity-policy.app-logout-subsubtitle" />
            </SubSubtitle>

            <FormControlLabel
              label={<I18n path="organization.organization-settings.inactivity-policy.app-logout-enable-checkbox" />}
              control={
                <Checkbox
                  checked={enableAutoLogoutApp}
                  onClick={() => setEnableAutoLogoutApp(!enableAutoLogoutApp)}
                  inputProps={{ 'data-testid': 'auto-logout-app-checkbox' }}
                />
              }
            />

            <TextField
              id="inactivity_time_portal"
              sx={{ marginTop: 2 }}
              value={inactivityTimeApp}
              onChange={(event) => setInactivityTimeApp(formatNumber(event.target.value))}
              label={<I18n path="organization.organization-settings.inactivity-policy.app-logout-time-input" />}
              variant="outlined"
              error={errors.has('inactivityTimeApp')}
              helperText={errors.get('inactivityTimeApp')}
              data-testid="auto-logout-app-text-field"
              inputProps={{ 'data-testid': 'auto-logout-app-input' }}
              FormHelperTextProps={{ 'data-testid': 'auto-logout-app-error' }}
              fullWidth
            />
          </Section>

          <Section>
            <Subtitle>
              <I18n path="organization.organization-settings.inactivity-policy.auto-suspension-subtitle" />
            </Subtitle>

            <Section>
              <Text isItalic>
                <I18n path="organization.organization-settings.inactivity-policy.auto-suspension-text" />
              </Text>

              <FormControlLabel
                label={<I18n path="organization.organization-settings.inactivity-policy.auto-suspension-checkbox" />}
                control={
                  <Checkbox
                    checked={autoSuspensionMembers}
                    onClick={() => setAutoSuspensionMembers(!autoSuspensionMembers)}
                    inputProps={{ 'data-testid': 'auto-suspension-members-checkbox' }}
                  />
                }
              />

              <TextField
                id="maxInactivityPeriod"
                sx={{ marginTop: 2 }}
                value={maxInactivityPeriod}
                onChange={(event) => setMaxInactivityPeriod(formatNumber(event.target.value))}
                label={<I18n path="organization.organization-settings.inactivity-policy.auto-suspension-input" />}
                variant="outlined"
                error={errors.has('maxInactivityPeriod')}
                helperText={errors.get('maxInactivityPeriod')}
                data-testid="max-inactivity-period-text-field"
                inputProps={{ 'data-testid': 'max-inactivity-period-input' }}
                FormHelperTextProps={{ 'data-testid': 'max-inactivity-period-error' }}
                fullWidth
              />
            </Section>
          </Section>

          <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
            <I18n path="organization.organization-settings.inactivity-policy.submit-button-text" />
          </Button>
          <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate('/')} data-testid="cancel-button">
            <I18n path="organization.organization-settings.inactivity-policy.cancel-button-text" />
          </Button>
        </SlideRightFadeContainer>
      </Section>
    </App>
  );
}

export default OrganizationInactivityPolicy;
