import { useState } from 'react';
import Text from 'components/atoms/text';
import LabeledInput from 'components/molecules/labeled-input';
import InputIcon from 'components/molecules/input-icon';
import I18n from 'utils/i18n';
import PasswordRequirements from '../PasswordRequirements';

function RuledPasswordInput({ hasError, errors, passwordPolicy, onChange, value }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <LabeledInput
        label={<I18n path="organization.organization-members-edit-change-password.input-password-new" />}
        htmlFor="password"
      >
        <InputIcon
          id="password"
          value={value}
          onChange={(event) => onChange(event)}
          type={isVisible ? 'text' : 'password'}
          icon={isVisible ? 'eye' : 'eye-blocked'}
          onIconClick={() => setIsVisible(!isVisible)}
          hasError={hasError}
          onBlur={(event) => onChange(event)}
          data-testid="password-input"
        />
      </LabeledInput>
      <Text>
        <PasswordRequirements passwordPolicy={passwordPolicy} nonFulfilledRules={errors} />
      </Text>
    </>
  );
}

export default RuledPasswordInput;
