import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'components/atoms/input/styles.module.css';
import { Container } from './styles';

const TextArea = ({
  id,
  value,
  onChange,
  onFocus,
  onBlur,
  hasError,
  errorMessage,
  className,
  isDisabled,
  lines,
  inputRef,
  onKeyPress,
  ...props
}) => (
  <Container className={className} lines={lines}>
    <textarea
      id={id}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={isDisabled}
      ref={inputRef}
      className={classNames(styles.input, { [styles.error]: hasError, [styles.disabled]: isDisabled })}
      {...props}
    />
    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
  </Container>
);

TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  lines: PropTypes.number,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  className: PropTypes.string,
  inputRef: PropTypes.instanceOf(Object),
};

TextArea.defaultProps = {
  id: undefined,
  name: undefined,
  hasError: false,
  isDisabled: false,
  lines: 1,
  errorMessage: undefined,
  className: undefined,
  inputRef: undefined,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onKeyPress: () => {},
};

export default TextArea;
