import Text from 'components/atoms/text';
import mobileDeviceIcon from 'assets/images/ic_mobile.svg';
import I18n from 'utils/i18n';
import styles from './styles.module.css';
import { isTrustedDevice } from '../../../utils/mobile-devices';

const TEXT_MODEL = <I18n path="organization.organization-mobile-devices-info.text-model" />;
const TEXT_UID = <I18n path="organization.organization-mobile-devices-info.text-uid" />;
const TEXT_APP_VERSION = <I18n path="organization.organization-mobile-devices-info.text-app-version" />;
const TEXT_STATUS = <I18n path="organization.organization-mobile-devices-info.text-device-status" />;
const TEXT_SYNCED_MEMBERS = <I18n path="organization.organization-mobile-devices-info.text-pending-syncs" />;
const TEXT_OF = <I18n path="organization.organization-mobile-devices-info.text-of" />;

const Header = ({ device }) => {
  const deviceStatus = device.status.toLowerCase();
  const syncedMembers = device.synced_members;

  return (
    <div className={styles.header}>
      <div className={styles.deviceIconContainer}>
        <img src={mobileDeviceIcon} className={styles.deviceIcon} alt="Device icon" />
      </div>
      <div className={styles.headerInfo}>
        <Text className={styles.headerTitle}>{device.name}</Text>
        <Text className={styles.deviceType}>
          {TEXT_MODEL}: {device.model}
        </Text>
        <Text className={styles.deviceType}>
          {TEXT_UID}: {device.org_device_id}
        </Text>
        <Text className={styles.deviceType}>
          {TEXT_APP_VERSION}: {device.app_version}
        </Text>
        <Text className={styles.deviceType}>
          {TEXT_STATUS}:{' '}
          <b className={styles[`${deviceStatus}-text`]}>
            <I18n path={`organization.organization-mobile-devices-info.${deviceStatus}`} />
          </b>
        </Text>
        {isTrustedDevice(device.status) && syncedMembers ? (
          <Text className={styles.deviceType}>
            {TEXT_SYNCED_MEMBERS} : {syncedMembers.nb_synced_members} {TEXT_OF} {syncedMembers.nb_members}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
