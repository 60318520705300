import PropTypes from 'prop-types';
import AppMenu from 'components/templates/app-menu';
import I18n from 'utils/i18n';

const HOME = <I18n path="account.breadcrumbs.home" />;
const TITLE = <I18n path="account.breadcrumbs.title" />;

const INFO = <I18n path="account.member-edit-info.menu-title" />;
const PERMISSIONS = <I18n path="account.member-edit-permissions.menu-title" />;
const PASSWORD = <I18n path="account.edit-password.menu-personal-password" />;

const getBreadcrumb = (path) => {
  switch (path) {
    case '/account/member/edit/info':
      return INFO;
    case '/account/member/edit/permissions':
      return PERMISSIONS;
    case '/account/member/edit/password':
      return PASSWORD;
    default:
      return '';
  }
};

const App = ({ children }) => {
  const { pathname } = window.location;

  const SIDE_MENU = [
    {
      href: '/account/member/edit/info',
      text: INFO,
      isActive: pathname === '/account/member/edit/info',
    },
    {
      href: '/account/member/edit/permissions',
      text: PERMISSIONS,
      isActive: pathname === '/account/member/edit/permissions',
    },
    {
      href: '/account/member/edit/password',
      text: PASSWORD,
      isActive: pathname === '/account/member/edit/password',
    },
  ];
  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/account/member/edit/info',
      title: TITLE,
    },
    {
      path: pathname,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={TITLE} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
