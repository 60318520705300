import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ListItem from 'components/atoms/list-item';
import styles from './styles.module.css';

const MenuItem = ({ isActive, href, children }) => (
  <Link className={classNames(styles.menuItemLink, { [styles.activeItem]: isActive })} to={href}>
    <ListItem className={styles.menuItem}>{children}</ListItem>
  </Link>
);

MenuItem.propTypes = {
  isActive: PropTypes.bool,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

MenuItem.defaultProps = {
  isActive: false,
};

export default MenuItem;
