import { useState } from 'react';
import Text from 'components/atoms/text';
import Select from 'components/atoms/input-select';
import LabeledInput from 'components/molecules/labeled-input';
import Button from 'components/atoms/button';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Option from 'components/atoms/option';
import I18n from 'utils/i18n';
import { validateDeviceStatus } from '../../../../../utils/mobile-devices';

const SELECT_LABEL = <I18n path="organization.organization-mobile-devices-list.logdialog-register.select-label" />;
const STATUS_EMPTY_ERROR = (
  <I18n path="organization.organization-mobile-devices-list.logdialog-register.error-status-required" />
);

function StatusSetupDialog({ isOpen, onConfirm, onClose, excludeStatus = null, ...props }) {
  const [status, setStatus] = useState('');
  const [statusError, setStatusError] = useState('');

  const onClickConfirmButton = () => {
    if (!status) {
      setStatusError(STATUS_EMPTY_ERROR);
      return;
    }

    if (validateDeviceStatus(status)) {
      onConfirm(status);
    }

    setStatusError('');
  };

  const renderOptions = () => {
    const options = [
      <Option key="" value="">
        <I18n path="organization.organization-mobile-devices-list.logdialog-register.choose-the-status" />
      </Option>,
    ];

    if (excludeStatus !== 'TRUSTED') {
      options.push(
        <Option key="TRUSTED" value="TRUSTED" data-testid="trusted-status-option">
          <I18n path="organization.organization-mobile-devices-info.trusted" />
        </Option>,
      );
    }

    if (excludeStatus !== 'NORMAL') {
      options.push(
        <Option key="NORMAL" value="NORMAL" data-testid="normal-status-option">
          <I18n path="organization.organization-mobile-devices-info.normal" />
        </Option>,
      );
    }

    if (excludeStatus !== 'BLOCKED') {
      options.push(
        <Option key="BLOCKED" value="BLOCKED" data-testid="blocked-status-option">
          <I18n path="organization.organization-mobile-devices-info.blocked" />
        </Option>,
      );
    }

    return options;
  };

  return (
    <Dialog isOpen={isOpen} shouldOverflow {...props}>
      <Header>
        <I18n path="organization.organization-mobile-devices-list.logdialog-register.title-choose-the-status" />
      </Header>
      <Body>
        <Text>
          <I18n path="organization.organization-mobile-devices-list.logdialog-register.text-choose-the-status" />
        </Text>
        <LabeledInput label={SELECT_LABEL} htmlFor="trusted_level" data-testid="status-labeled-input">
          <Select
            id="trusted_level"
            onChange={(event) => setStatus(event.target.value)}
            value={status}
            hasError={statusError}
            errorMessage={statusError}
            data-testid="status-select"
          >
            {renderOptions()}
          </Select>
        </LabeledInput>
      </Body>
      <Footer>
        <Button onClick={onClickConfirmButton} type="primary" data-testid="confirm-button">
          <I18n path="organization.organization-mobile-devices-list.logdialog-register.button-apply" />
        </Button>
        <Button onClick={onClose} data-testid="dismiss-button">
          <I18n path="organization.organization-mobile-devices-list.logdialog-register.button-cancel" />
        </Button>
      </Footer>
    </Dialog>
  );
}

export default StatusSetupDialog;
