import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClickOutside from 'react-outside-click-handler';
import inputStyles from 'components/atoms/input/styles.module.css';
import { Select, Dropdown } from './styles';
import styles from './styles.module.css';

function InputSelect({
  className = undefined,
  children,
  isDisabled,
  hasError = false,
  errorMessage,
  onChange = () => {},
  value,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);

  const getOptions = (children) => {
    let options = [];

    children.forEach((child) => {
      if (child instanceof Array) {
        const childItems = getOptions(child);
        options = options.concat(childItems);
      } else {
        options.push(child);
      }
    });

    return options;
  };

  const getLabel = (children, value) => {
    const options = getOptions(children);

    const selectedOption = options.find((option) => {
      if (option.props) {
        return option.props.value === value;
      }

      return false;
    });

    return selectedOption ? selectedOption.props.children : '';
  };

  const handleToggleIsOpen = () => setIsOpen(!isOpen);

  const mapChildren = () =>
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        onClick: () => onChange({ target: { value: child.props.value } }),
        selected: child.props.value === value,
      }),
    );

  return (
    <div {...props}>
      <Select
        onClick={handleToggleIsOpen}
        className={classNames(inputStyles.input, styles.clickable, className, {
          [styles.disabled]: isDisabled,
          [styles.error]: hasError,
        })}
        disabled={isDisabled}
      >
        <span className={styles.selected} data-testid="selected-option">
          {getLabel(children, value)}
        </span>
        <ClickOutside onOutsideClick={() => setIsOpen(false)}>
          <Dropdown isOpen={isOpen} data-testid="select-dropdown">
            {mapChildren()}
          </Dropdown>
        </ClickOutside>
      </Select>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
}

InputSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default InputSelect;
