import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import LabeledInput from 'components/molecules/labeled-input';
import Checkbox from 'components/atoms/input-checkbox';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Option from 'components/atoms/option';
import Select from 'components/atoms/input-select';
import Warning from 'components/atoms/warning';
import InputIcon from 'components/molecules/input-icon';
import InputMulti from 'components/molecules/input-multi';
import I18n, { translate } from 'utils/i18n';
import { stringFromDate } from 'utils/format-date';
import getLanguage from 'utils/get-language';
import { analyticsApi, clientPhiApi, portalApi } from 'api/http';
import styles from './styles.module.css';

const SHARE_LINK_FAILURE = <I18n path="physio.physio-personal-share-box.share-link-failure" />;
const FILE_SHARE_SUCCESS = <I18n path="physio.physio-personal-share-box.file-share-success" />;
const FILE_SHARE_FAILURE = <I18n path="physio.physio-personal-share-box.file-share-failure" />;
const SHARE_LINK_NOK = <I18n path="physio.physio-personal-share-box.input-box-share-link-copy-nok" />;
const SHARE_LINK_OK = <I18n path="physio.physio-personal-share-box.input-box-share-link-copy-ok" />;

const SHARE_TYPES = [
  {
    label: translate('physio.physio-personal-share-box.option-share-full'),
    value: 'FULL',
  },
  {
    label: translate('physio.physio-personal-share-box.option-share-hide'),
    value: 'HIDE',
  },
  {
    label: translate('physio.physio-personal-share-box.option-share-link'),
    value: 'LINK',
  },
];

class ShareMonitorization extends Component {
  static propTypes = {
    showReportDialog: PropTypes.bool.isRequired,
    handleInputToggle: PropTypes.func.isRequired,
    acceptSharingTerms: PropTypes.bool.isRequired,
    protocolToShare: PropTypes.string,
    orgToShare: PropTypes.string,
    onShareFinished: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {
    protocolToShare: undefined,
    orgToShare: undefined,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.linkRef = React.createRef();
  }

  state = {
    membersToShare: [],
    isSharing: false,
    shareLink: '',
    shareLinkError: '',
    generatingLink: false,
    clipboardLink: '',
    dataVisibility: '',
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onGenerateLinkClick = () => {
    const { protocolToShare, orgToShare } = this.props;

    analyticsApi()
      .post('/share-link', {
        // 1. request para o physio API - get fileToken, confirmar qual eh a rota com lucas
        acquisition_id: protocolToShare,
        report_language: getLanguage(),
        report_format: 'PDF',
      })
      .then((response) => {
        const { data, error } = response.data;

        if (!error) {
          this.setState({ generatingLink: true });
          // 2. apos receber o tokenFile request report file using received token
          // 3. chamar lambda hospital - passar tokenFile. confirmar com lucas estrutura do json data
          clientPhiApi(orgToShare, true)
            .post('report', {
              token: data,
            })
            .then((response) => {
              // 4. hospital devolve o link do pdf no S3
              // open pre signed url
              const { data, error } = response.data;
              let timeOutCount = 0;

              const interval = setInterval(() => {
                fetch(data).then((res) => {
                  timeOutCount += 1; // cada tentativa equivale a 5s
                  if (res.status === 200) {
                    this.setState({ generatingLink: false });
                    if (!error) {
                      this.setState({ shareLink: data }); // 5. abre o link do pdf em uma nova aba
                    }
                    clearInterval(interval);
                  }
                  if (timeOutCount >= 180) {
                    // atingindo um total de 15min (180 tentativas), da o timeOut
                    // eslint-disable-next-line no-alert
                    alert('Report error, please try again');
                    this.setState({ generatingLink: false });
                    clearInterval(interval);
                  }
                });
              }, 2000);
            });
        }
      })
      .catch(() => {
        this.setState({ generatingLink: false, shareLinkError: SHARE_LINK_FAILURE });
      });
  };

  handleSearchMembers = (keyword) => {
    const { dataVisibility } = this.state;

    const searchParams = new URLSearchParams();

    searchParams.set('search', keyword);
    searchParams.set('share_type', dataVisibility);

    return portalApi().get(`search-user?${searchParams}`);
  };

  handleSelectMember = (item) => {
    const { membersToShare } = this.state;
    const { handleInputToggle } = this.props;
    this.setState({
      membersToShare: [
        ...membersToShare,
        {
          id: item.id,
          name: item.full_name,
          image: item.photo,
          orgId: item.organization_physio_id,
        },
      ],
    });
    handleInputToggle('showDropdownMembers');
  };

  removeMemberOnShareList = (id) => {
    const { membersToShare } = this.state;
    this.setState({ membersToShare: membersToShare.filter((member) => member.id !== id) });
  };

  handleShareMembers = () => {
    const { membersToShare, dataVisibility } = this.state;
    const { protocolToShare, orgToShare, onShareFinished, handleInputToggle } = this.props;

    this.setState({ isSharing: true });

    analyticsApi()
      .post('/share', {
        acquisition_id: protocolToShare,
        org_id: orgToShare,
        shared: membersToShare.map((member) => ({
          toUser: member.id.toString(),
          toOrganization: member.orgId ? member.orgId : 'null',
          shareType: dataVisibility,
          expirationDate: stringFromDate(moment().add(6, 'months').toDate()),
        })),
      })
      .then((response) => {
        if (!this.mounted) return;

        const { error } = response.data;

        if (!error) {
          this.setState({ isSharing: false });
          handleInputToggle('showReportDialog');
          onShareFinished(FILE_SHARE_SUCCESS, 'success');
          this.closeDialogHandler(true);
        }

        onShareFinished(FILE_SHARE_FAILURE, 'error');
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState({ isSharing: false });
        onShareFinished(FILE_SHARE_FAILURE, 'error');
      });
  };

  closeDialogHandler = (shared) => {
    const { onCloseDialog } = this.props;
    this.setState({
      membersToShare: [],
      shareLink: '',
      shareLinkError: '',
      clipboardLink: '',
      dataVisibility: '',
    });

    onCloseDialog(shared);
  };

  onChangeShareType = (event) => {
    const { dataVisibility } = this.state;

    if (dataVisibility === 'HIDE') {
      this.setState({ membersToShare: [] });
    }

    this.setState({ dataVisibility: event.target.value });
  };

  copyTextToClipboard = () => {
    const { shareLink } = this.state;
    if (shareLink === '') {
      this.setState({ clipboardLink: SHARE_LINK_NOK });
      return;
    }
    this.linkRef.current.select();
    document.execCommand('copy');

    this.setState({ clipboardLink: SHARE_LINK_OK });
  };

  getDataVisibilityText = () => {
    const { dataVisibility } = this.state;
    if (!dataVisibility) {
      return <I18n path="physio.physio-personal-share-box.text-share-empty" />;
    }
    return dataVisibility === 'FULL' ? (
      <I18n path="physio.physio-personal-share-box.text-share-full" />
    ) : (
      <I18n path="physio.physio-personal-share-box.text-share-anonymous" />
    );
  };

  renderSharingField = () => {
    const { membersToShare, isSharing } = this.state;

    return (
      <>
        <InputMulti
          label={<I18n path="physio.physio-personal-share-box.input-box-search-user" />}
          selected={membersToShare}
          onSearch={this.handleSearchMembers}
          onSelect={(item) => this.handleSelectMember(item)}
          onRemoveMember={(id) => this.removeMemberOnShareList(id)}
        />

        <Button
          type="primary"
          className={styles.confirmShareButton}
          onClick={this.handleShareMembers}
          isLoading={isSharing}
          isDisabled={membersToShare.length <= 0}
        >
          <I18n path="physio.physio-personal-share-box.box-button-confirm-share" />
        </Button>
      </>
    );
  };

  renderFullShare = () => (
    <>
      <Warning>
        <I18n path="physio.physio-personal-share-box.warning-share-full" />
      </Warning>

      <Text className={styles.shareDescription}>
        <I18n path="physio.physio-personal-share-box.text-share-full" />
      </Text>
      {this.renderSharingField()}
    </>
  );

  renderHideShare = () => (
    <>
      <Text className={styles.shareDescription}>
        <I18n path="physio.physio-personal-share-box.text-share-hide" />
      </Text>

      {this.renderSharingField()}
    </>
  );

  renderLinkShare = () => {
    const { handleInputToggle, acceptSharingTerms } = this.props;
    const { shareLink, shareLinkError, generatingLink, clipboardLink } = this.state;

    return (
      <>
        <Warning>
          <I18n path="physio.physio-personal-share-box.warning-share-link" />
        </Warning>

        <div className={styles.contentWrapper}>
          <Checkbox
            className={styles.checkbox}
            id="accept_sharing"
            label={<I18n path="physio.physio-personal-share-box.input-box-share-link-terms" />}
            isChecked={acceptSharingTerms}
            onClick={() => handleInputToggle('acceptSharingTerms')}
          />
          <Button onClick={this.onGenerateLinkClick} isDisabled={!acceptSharingTerms} isLoading={generatingLink}>
            <I18n path="physio.physio-personal-share-box.button-box-generate-link" />
          </Button>
        </div>

        <InputIcon
          id="share_link"
          inputRef={this.linkRef}
          value={shareLink}
          hasError={false}
          errorMessage={shareLinkError}
          isDisabled={!acceptSharingTerms}
          icon="copy"
          onIconClick={this.copyTextToClipboard}
        />
        <Text isPrimary className={styles.clipboardLink}>
          {clipboardLink}
        </Text>
        <Text>
          <I18n path="physio.physio-personal-share-box.text-box-share-link-pdf" />
        </Text>
      </>
    );
  };

  renderShareForm = () => {
    const { dataVisibility } = this.state;

    switch (dataVisibility) {
      case 'FULL':
        return this.renderFullShare();
      case 'HIDE':
        return this.renderHideShare();
      case 'LINK':
        return this.renderLinkShare();
      default:
        return null;
    }
  };

  render() {
    const { showReportDialog } = this.props;
    const { dataVisibility } = this.state;
    return (
      <Dialog isOpen={showReportDialog} shouldOverflow={!dataVisibility}>
        <Header onClose={() => this.closeDialogHandler(false)}>
          <I18n path="physio.physio-personal-share-box.title-box-share-report" />
        </Header>
        <Body>
          <Text size="medium">
            <I18n path="physio.physio-personal-share-box.text-share-select" />
          </Text>
          <LabeledInput
            label={<I18n path="physio.physio-personal-share-box.label-share-select" />}
            htmlFor="dataVisibility"
          >
            <Select id="dataVisibility" onChange={this.onChangeShareType} value={dataVisibility}>
              {SHARE_TYPES.map((type) => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </LabeledInput>
          {this.renderShareForm()}
          <br />
          <br />
        </Body>
      </Dialog>
    );
  }
}

export default ShareMonitorization;
