import styled from 'styled-components';

import IconRaw from 'components/atoms/icon';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: var(--color-grey-light);
  color: var(--color-secondary-grey-light);
  font-size: 14px;
  margin-bottom: 12px;
  padding: 8px 16px;
  border-radius: var(--border-default);

  & > div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > span {
    color: var(--color-secondary-grey-dark);
    font-weight: 700;
  }
`;

export const Icon = styled(IconRaw)`
  fill: var(--color-secondary-grey-light) !important;
  min-width: 35px;
  max-width: 35px;
  margin-right: 16px;
`;
