import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconSuccess from 'assets/images/ic_success.svg';
import IconWarning from 'assets/images/ic_warning.svg';
import IconError from 'assets/images/ic_error.svg';
import IconInfo from 'assets/images/ic_info.svg';
import IconButton from 'components/atoms/icon-button';
import styles from './styles.module.css';
import { IconBrand } from './styles';

const getSvg = (type) => {
  switch (type) {
    case 'success':
      return IconSuccess;
    case 'warning':
      return IconWarning;
    case 'error':
      return IconError;
    default:
      return IconInfo;
  }
};

const DialogHeader = ({ children, type, onClose, ...props }) => (
  <div className={classNames(styles.header, { [styles.centralized]: type !== 'default' })} {...props}>
    {type !== 'default' ? <IconBrand type={type} src={getSvg(type)} /> : null}
    <h3 className={styles.title}>{children}</h3>
    {onClose ? <IconButton className={styles.closeButton} onClick={onClose} name="cross" /> : null}
  </div>
);

DialogHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  type: PropTypes.string,
  onClose: PropTypes.func,
};

DialogHeader.defaultProps = {
  children: undefined,
  onClose: undefined,
  type: 'default',
};

export default DialogHeader;
