import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

const SubSubtitle = ({ children, className, ...props }) => (
  <h4 className={classnames(styles.subSubtitle, className)} {...props}>
    {children}
  </h4>
);

SubSubtitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SubSubtitle.defaultProps = {
  className: undefined,
};

export default SubSubtitle;
