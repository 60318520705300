import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import braincare from 'components/routes/braincare';
import { addToast } from 'actions/toasts';
import App from 'components/templates/app';
import Title from 'components/atoms/auth-title';
import Section from 'components/atoms/section';
import Label from 'components/atoms/input-label';
import InputGroup from 'components/atoms/input-group';
import Button from 'components/atoms/button';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import InputIcon from 'components/molecules/input-icon';
import Loader from 'components/molecules/section-loader';
import DeviceAggregate from 'components/molecules/device-aggregate';
import Card, { Header, Footer } from 'components/molecules/device-card';
import Pagination from 'components/organisms/pagination';
import { SlideFadeInContainer } from 'utils/transitions';
import I18n, { translate } from 'utils/i18n';
import { round } from 'utils/format-number';
import formatDate from 'utils/format-full-date';
import formatTime from 'utils/format-time';
import { AGGREGATES_TEXTS, DEVICES, DEVICE_ICON, DEVICE_STATUS } from 'utils/devices';
import { fetchAllOrganizations, fetchAllBraincareDevices } from 'api/portal';
import Text from 'components/atoms/text';
import styles from './styles.module.css';

const HOME = <I18n path="braincare.breadcrumbs.home" />;
const BC_DEVICES = <I18n path="braincare.breadcrumbs.devices" />;
const TITLE = <I18n path="braincare.devices.title" />;
const LOAD_DEVICES_ERROR = <I18n path="braincare.devices.load-devices-error" />;
const LOAD_ORGANIZATIONS_ERROR = <I18n path="braincare.devices.load-organizations-error" />;
const LOADING = <I18n path="messages.loading" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/braincare/devices',
    title: BC_DEVICES,
    isActive: true,
  },
];

const PAGE_SIZE = 8;

function BraincareDevices() {
  const [filterValue, setFilterValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [devices, setDevices] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false);
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadDevicesError, setLoadDevicesError] = useState(false);
  const [loadOrganizationsError, setLoadOrganizationsError] = useState(false);

  const dispatch = useDispatch();

  const fetchDevicesHandler = (searchParams) => {
    setIsLoading(true);
    setLoadDevicesError(false);

    fetchAllBraincareDevices(searchParams)
      .then((response) => {
        setIsLoading(false);

        const { data } = response;

        setDevices(data.results);
        setRows(data.count);
        setCurrentPage(Number(searchParams.get('page')));
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(addToast('error', LOAD_DEVICES_ERROR));
        setLoadDevicesError(true);
      });
  };

  const fetchDevicesWithoutFilter = () => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', PAGE_SIZE);

    fetchDevicesHandler(searchParams);
  };

  const fetchDevicesWithFilter = (page) => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', PAGE_SIZE);
    searchParams.set('search_udi', filterValue);
    searchParams.set('device_type', selectedDevice);
    searchParams.set('current_status', selectedStatus);
    searchParams.set('organization', selectedOrganization);

    fetchDevicesHandler(searchParams);
  };

  const fetchOrganizations = () => {
    setIsLoadingOrganizations(true);
    setLoadOrganizationsError(false);

    fetchAllOrganizations()
      .then((response) => {
        setIsLoadingOrganizations(false);

        const { data } = response;

        setOrganizations(data.results);
      })
      .catch(() => {
        setIsLoadingOrganizations(false);
        dispatch(addToast('error', LOAD_ORGANIZATIONS_ERROR));
        setLoadOrganizationsError(true);
      });
  };

  useEffect(() => {
    fetchDevicesWithFilter(1);
    fetchOrganizations();
  }, []);

  const handleClearFilterClick = () => {
    setFilterValue('');
    setSelectedStatus('');
    setSelectedDevice('');
    setSelectedOrganization('');
    fetchDevicesWithoutFilter();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const renderAggregateItems = (aggregates) => (
    <>
      {Object.keys(aggregates)
        .slice(0, 3)
        .map((key) => (
          <DeviceAggregate
            key={_.uniqueId(`aggregates-${key}`)}
            icon={AGGREGATES_TEXTS[key].icon}
            value={typeof aggregates[key] === 'number' ? round(aggregates[key]) : aggregates[key] || '-'}
            label={AGGREGATES_TEXTS[key].text}
          />
        ))}
    </>
  );

  const renderDevices = () => {
    if (loadDevicesError) {
      return (
        <Text isError data-testid="load-devices-error">
          {LOAD_DEVICES_ERROR}
        </Text>
      );
    }

    if (devices.length === 0) {
      return (
        <div className={styles.noActiveDevices} data-testid="no-devices-message">
          <I18n path="braincare.devices.no-devices-found" />
        </div>
      );
    }

    return devices.map((device) => (
      <Card
        key={`device-card-${device.udi}`}
        isBlocked={device.status === 'BLOCKED' || device.status === 'SUSPENDED'}
        data-testid={`device-card-${device.id}`}
      >
        <Header
          title={device.udi}
          type={device.device_type}
          inUse={device.current_status === 'IN USE'}
          status={device.current_status}
          icon={DEVICE_ICON[device.device_type]}
        />
        <DeviceAggregate
          key={_.uniqueId(`aggregates-organization-${device.organization}`)}
          icon="office"
          label={
            isLoadingOrganizations
              ? LOADING
              : {
                  ...organizations.find((org) => org.id === device.organization),
                }.name
          }
        />
        {device.aggregates && renderAggregateItems(device.aggregates)}
        <Footer
          date={
            <I18n
              path="braincare.devices.card-device-last-update"
              props={{
                last_update: `${formatDate(device.modified)} ${formatTime(device.modified)}`,
              }}
            />
          }
        />
      </Card>
    ));
  };

  return (
    <App breadcrumbs={breadcrumbs}>
      <Section className={styles.title}>
        <Title>{TITLE}</Title>
      </Section>

      <Section className={styles.container}>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
          <div className={styles.filters}>
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="filter">
                <I18n path="braincare.devices.input-filter-uid" />
              </Label>
              <InputIcon
                id="filter"
                onChange={(event) => setFilterValue(event.target.value)}
                onIconClick={() => fetchDevicesWithFilter(1)}
                value={filterValue}
                icon="search"
                data-testid="udi-input"
              />
            </InputGroup>
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="status">
                <I18n path="braincare.devices.input-filter-status" />
              </Label>
              <Select
                id="status"
                onChange={(event) => setSelectedStatus(event.target.value)}
                value={selectedStatus}
                data-testid="status-select"
              >
                <Option value="">{translate('braincare.devices.dropdown-filter-status')}</Option>
                {DEVICE_STATUS.map((status) => (
                  <Option key={`filter-${status.id}`} value={status.id}>
                    {status.text}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="device">
                <I18n path="braincare.devices.input-filter-type" />
              </Label>
              <Select
                id="device"
                onChange={(event) => setSelectedDevice(event.target.value)}
                value={selectedDevice}
                data-testid="device-select"
              >
                <Option value="">{translate('braincare.devices.dropdown-filter-type')}</Option>
                {DEVICES.map((device) => (
                  <Option key={`device-${device.id}`} value={device.id}>
                    {device.text}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="organization">
                <I18n path="braincare.devices.input-filter-organization" />
              </Label>
              <Select
                id="organization"
                onChange={(event) => setSelectedOrganization(event.target.value)}
                value={selectedOrganization}
                data-testid="organization-select"
                hasError={loadOrganizationsError}
                errorMessage={loadOrganizationsError ? LOAD_ORGANIZATIONS_ERROR : ''}
              >
                <Option value="">{translate('braincare.devices.dropdown-filter-organization')}</Option>
                {organizations.map((organization) => (
                  <Option key={`filter-organization-${organization.id}`} value={organization.id}>
                    {organization.name}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <Button className={styles.applyFilter} onClick={handleClearFilterClick} data-testid="reset-filters-button">
              <I18n path="braincare.devices.button-filter-reset" />
            </Button>
            <Button
              className={styles.applyFilter}
              onClick={() => fetchDevicesWithFilter(1)}
              type="primary"
              data-testid="apply-filters-button"
            >
              <I18n path="braincare.devices.button-filter-apply" />
            </Button>
          </div>
        </form>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <SlideFadeInContainer>
              <Section className={styles.devicesContainer}>{renderDevices()}</Section>
            </SlideFadeInContainer>
            <Pagination
              rows={rows}
              rowsPerPage={PAGE_SIZE}
              currentPage={currentPage}
              onPageSelect={fetchDevicesWithFilter}
              maxPagesDisplay={3}
            />
          </>
        )}
      </Section>
    </App>
  );
}

export default braincare(BraincareDevices);
