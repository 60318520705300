import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/atoms/icon';
import Button from 'components/atoms/button';
import styles from './styles.module.css';

const POSITION = {
  left: styles.iconLeft,
  right: styles.iconRight,
};

const ButtonIcon = ({ icon, iconPosition, type, onClick, children, className, isLoading, isDisabled, ...props }) => (
  <Button
    className={classNames(
      {
        [iconPosition === 'right' ? styles.withIconRight : styles.withIconLeft]: !!icon,
        [styles.loading]: isLoading,
        [styles.container]: true,
      },
      className,
    )}
    type={type}
    onClick={onClick}
    isLoading={isLoading}
    isDisabled={isDisabled}
    {...props}
  >
    {children}
    <Icon name={icon} className={classNames(styles.icon, POSITION[iconPosition])} type={type} />
  </Button>
);

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconPosition: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

ButtonIcon.defaultProps = {
  onClick: () => {},
  iconPosition: 'left',
  type: 'default',
  className: undefined,
  isLoading: false,
  isDisabled: false,
};

export default ButtonIcon;
