import AppWizard from 'components/templates/app-wizard';
import Section from 'components/atoms/section';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import ImportCSV from './steps/import-csv';

const STEP_1 = <I18n path="organization.organization-members-create.wizard-import-csv-bulk-upload" />;
const STEPS = [STEP_1];

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_ORGANIZATION = <I18n path="organization.breadcrumbs.manage-organization" />;
const BULK_UPLOAD = <I18n path="organization.breadcrumbs.bulk-upload" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/organization/members',
    title: MANAGE_ORGANIZATION,
  },
  {
    path: '/organization/members/bulk-upload-instructions',
    title: BULK_UPLOAD,
    isActive: true,
  },
];

function BulkUpload() {
  return (
    <AppWizard title={BULK_UPLOAD} breadcrumbs={breadcrumbs} steps={STEPS} currentStep={0}>
      <SlideRightFadeContainer>
        <Section>
          <ImportCSV />
        </Section>
      </SlideRightFadeContainer>
    </AppWizard>
  );
}

export default BulkUpload;
