import { useEffect, useState } from 'react';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import Loader from 'components/molecules/section-loader';
import I18n from 'utils/i18n';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SlideRightFadeContainer } from 'utils/transitions';
import { clearOrganizationSelectedMobileDevice, setOrganizationSelectedMobileDevice } from 'features/organizationSlice';
import { fetchActiveMembers, fetchMobileDevices, redefineMemberPin } from 'api/portal';
import styles from '../styles.module.css';
import App from '../../template';
import { isTrustedDevice } from '../../../../../utils/mobile-devices';
import DialogMemberPinUpdate from '../../common/PinRedefinitionDialog';
import MemberItem from '../../../../../components/molecules/member-item';

const ERROR_FETCHING_MEMBERS = (
  <I18n path="organization.organization-mobile-devices-edit.error-fetching-device-members" />
);
const SUCCESS_MEMBER_PIN_UPDATE = <I18n path="organization.organization-mobile-devices-edit.success-member-pin-edit" />;
const ERROR_MEMBER_PIN_UPDATE = <I18n path="organization.organization-mobile-devices-edit.error-member-pin-edit" />;
const ERROR_FETCH_DEVICE = <I18n path="organization.organization-mobile-devices-edit.error-fetch-device" />;
const TEXT_REDEFINE_PIN = (
  <I18n path="organization.organization-mobile-devices-edit.text-member-card-edit-member-pin" />
);
const TEXT_NON_ANSWERED_INVITATION = (
  <I18n path="organization.organization-mobile-devices-edit.text-non-answered-invitation" />
);
const TEXT_NOT_SYNCED_MEMBER = (
  <I18n path="organization.organization-mobile-devices-edit.text-member-card-not-synced-member" />
);

function MemberPinRedefinition() {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isToShowErrorAsync, setIsToShowErrorAsync] = useState(false);
  const [isToShowErrorPending, setIsToShowErrorPending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMembers, setErrorMembers] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const { deviceId } = params;

  const mobileDevice = useSelector((state) => state.organization.selectedMobileDevice);

  const onErrorFetchingDevice = () => {
    navigate('/org-mobile-devices/devices');
    dispatch(addToast('error', ERROR_FETCH_DEVICE));
  };

  const getActiveMembers = () => {
    setIsLoading(true);
    setErrorMembers(false);

    fetchActiveMembers(deviceId)
      .then((response) => {
        const { data } = response;
        setMembers(data.active_members);
        setIsLoading(false);
        setErrorMembers(false);
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_FETCHING_MEMBERS));
        setIsLoading(false);
        setErrorMembers(true);
      });
  };

  useEffect(() => {
    if (mobileDevice.org_device_id !== deviceId) {
      dispatch(clearOrganizationSelectedMobileDevice());
    }

    setIsLoading(true);

    fetchMobileDevices(new URLSearchParams(), { org_device_id: deviceId })
      .then((response) => {
        const { data } = response;

        if (!data.results) {
          onErrorFetchingDevice();
        } else if (data.results.length === 1) {
          const device = data.results[0];

          if (isTrustedDevice(device.status)) {
            dispatch(clearOrganizationSelectedMobileDevice());
            dispatch(setOrganizationSelectedMobileDevice(device));
            getActiveMembers();
          } else {
            onErrorFetchingDevice();
          }
        } else {
          onErrorFetchingDevice();
        }
      })
      .catch(() => {
        onErrorFetchingDevice();
      });
  }, []);

  const updateMemberPin = (formData) => {
    setIsSubmitting(true);

    redefineMemberPin({
      org_device_id: deviceId,
      member_id: selectedMember.id,
      new_pin: formData.pin,
      local_reset_required: formData.requirePinChange,
    })
      .then(() => {
        setSelectedMember(null);
        setIsSubmitting(false);
        dispatch(addToast('success', SUCCESS_MEMBER_PIN_UPDATE));
      })
      .catch(() => {
        setSelectedMember(null);
        setIsSubmitting(false);
        dispatch(addToast('error', ERROR_MEMBER_PIN_UPDATE));
      });
  };

  const renderPinRedefinitionDialog = () => (
    <DialogMemberPinUpdate
      isOpen={selectedMember !== null}
      isLoading={isSubmitting}
      memberName={selectedMember !== null ? selectedMember.name : ''}
      onSubmit={(formData) => updateMemberPin(formData)}
      onClose={() => setSelectedMember(null)}
    />
  );

  const renderErrorAsyncUser = () => (
    <Dialog isOpen={isToShowErrorAsync} data-testid="non-synced-dialog">
      <Header>
        <I18n path="organization.organization-mobile-devices-edit.log-dialog.header-async" />
      </Header>
      <Body>
        <Text>
          <I18n path="organization.organization-mobile-devices-edit.log-dialog.text-async" />
        </Text>
      </Body>
      <Footer>
        <Button isBlock onClick={() => setIsToShowErrorAsync(false)} type="ghost">
          <I18n path="organization.organization-mobile-devices-edit.log-dialog.button-back" />
        </Button>
      </Footer>
    </Dialog>
  );

  const renderErrorPendingUser = () => (
    <Dialog isOpen={isToShowErrorPending} data-testid="pending-dialog">
      <Header>
        <I18n path="organization.organization-mobile-devices-edit.log-dialog.header-pending" />
      </Header>
      <Body>
        <Text>
          <I18n path="organization.organization-mobile-devices-edit.log-dialog.text-pending" />
        </Text>
      </Body>
      <Footer>
        <Button isBlock onClick={() => setIsToShowErrorPending(false)} type="ghost">
          <I18n path="organization.organization-mobile-devices-edit.log-dialog.button-back" />
        </Button>
      </Footer>
    </Dialog>
  );

  const getSecondaryText = (member) => {
    if (member.synced) {
      return TEXT_REDEFINE_PIN;
    }

    if (member.status === 'PENDING') {
      return TEXT_NON_ANSWERED_INVITATION;
    }

    return TEXT_NOT_SYNCED_MEMBER;
  };

  const renderResults = () => {
    if (errorMembers) {
      return <Text isError>{ERROR_FETCHING_MEMBERS}</Text>;
    }

    return members.map((member) => (
      <MemberItem
        key={`member-${member.id}`}
        image={member.picture}
        primaryText={member.name}
        secondaryText={getSecondaryText(member)}
        disabled={!member.synced}
        hasIcon={!member.synced || member.status === 'PENDING'}
        onClick={() => {
          if (member.synced) {
            setSelectedMember(member);
          } else if (member.status === 'PENDING') {
            setIsToShowErrorPending(true);
          } else {
            setIsToShowErrorAsync(true);
          }
        }}
        data-testid={`member-item-${member.id}`}
      />
    ));
  };

  return (
    <App device={mobileDevice}>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-mobile-devices-edit.title-sub-member-pin-edit" />
        </Subtitle>
        {renderErrorPendingUser()}
        {renderErrorAsyncUser()}
        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <Text isItalic>
              <I18n path="organization.organization-mobile-devices-edit.text-member-pin-edit" />
            </Text>
            <Section maxWidth="428px">{renderResults()}</Section>
          </SlideRightFadeContainer>
        )}
      </Section>
      {renderPinRedefinitionDialog()}
      <div className={styles['buttons-container']}>
        <Button onClick={() => navigate('/org-mobile-devices/devices')} data-testid="back-button">
          <I18n path="organization.organization-mobile-devices-create.button-back" />
        </Button>
      </div>
    </App>
  );
}

export default MemberPinRedefinition;
