import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Container = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: undefined,
};

export default Container;
