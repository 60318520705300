import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Caption = ({ children, className }) => (
  <caption className={classNames(styles.caption, className)}>{children}</caption>
);

Caption.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Caption.defaultProps = {
  className: undefined,
};

export default Caption;
