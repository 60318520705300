import PropTypes from 'prop-types';
import Icon from 'components/atoms/icon';
import Text from 'components/atoms/text';
import styles from './styles.module.css';

const InputHint = ({ icon, children, ...props }) => (
  <div className={styles.hint} {...props}>
    {icon ? <Icon className={styles.icon} name={icon} /> : undefined}
    <Text className={styles.text} isItalic>
      {children}
    </Text>
  </div>
);

InputHint.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InputHint.defaultProps = {
  icon: undefined,
};

export default InputHint;
