import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const TagButton = ({ isSelected, onToggle, children, ...props }) => (
  <button onClick={onToggle} className={classNames(styles.tag, { [styles.selected]: isSelected })} {...props}>
    {children}
  </button>
);

TagButton.propTypes = {
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func,
  isSelected: PropTypes.bool,
};

TagButton.defaultProps = {
  onToggle: () => {},
  isSelected: false,
};

export default TagButton;
