import styled, { keyframes } from 'styled-components';

export const SlideKeyframes = keyframes`
  0% {
    opacity: 0;
    pointer-events: none;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    pointer-events: initial;
    transform: translateX(0px);
  }
`;

export const SlideIn = styled.div`
  animation: ${SlideKeyframes} 200ms ease-in forwards;
`;

export const SlideOut = styled.div`
  animation: ${SlideKeyframes} 200ms ease-in reverse;
`;

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  min-width: 250px;
  display: flex;
  flex-direction: column-reverse;
  padding: 8px 16px;
  pointer-events: none;
`;
