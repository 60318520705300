import styled from 'styled-components';

import DeviceCard from 'components/molecules/device-card';
import { Card as CardMixin } from 'utils/mixins';

export const Card = styled(DeviceCard)`
  width: 100%;
  border-color: #c3cfd8;
  padding: 24px;
  ${CardMixin}
`;
