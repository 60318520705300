import { useEffect, useRef, useState } from 'react';
import { addToast } from 'actions/toasts';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Input from 'components/atoms/input';
import Button from 'components/atoms/button';
import ButtonIcon from 'components/molecules/button-icon';
import LabeledInput from 'components/molecules/labeled-input';
import I18n from 'utils/i18n';
import validateEmail from 'utils/validate-email';
import validatePhone from 'utils/validate-phone';
import App from 'pages/account/member/template';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_LOADING, setOrganizationProfileData } from 'features/userSlice';
import { requestRemoval, updateCorporateAccount } from 'api/portal';
import { SlideRightFadeContainer } from 'utils/transitions';
import Loader from 'components/molecules/section-loader';
import styles from '../styles.module.css';

const FIX_FIELDS = <I18n path="messages.fix-fields" />;

const EMAIL_INVALID_ERROR = <I18n path="messages.invalid-email" />;
const INVALID_PHONE_ERROR = <I18n path="messages.invalid-phone" />;

const SUCCESS_MESSAGE = <I18n path="account.member-edit-info.save-success-message" />;
const FAILURE_MESSAGE = <I18n path="account.member-edit-info.save-failure-message" />;

const REQUEST_SUCCESS = <I18n path="messages.request-success" />;
const REQUEST_FAILURE = <I18n path="messages.request-failure" />;

function CorporateProfileInfo() {
  const user = useSelector((state) => state.user);
  const ref = useRef();

  const organizationProfile = user.organization_profile;

  const [email, setEmail] = useState(organizationProfile.email);
  const [phoneNumber, setPhoneNumber] = useState(organizationProfile.phone_number);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading && ref.current === STATUS_LOADING && user.status !== STATUS_LOADING) {
      setIsLoading(false);

      setEmail(organizationProfile.email);
      setPhoneNumber(organizationProfile.phone_number);
    }

    ref.current = user.status;
  }, [user.status]);

  const onClickRemoveButton = () => {
    requestRemoval()
      .then(() => dispatch(addToast('success', REQUEST_SUCCESS)))
      .catch(() => dispatch(addToast('error', REQUEST_FAILURE)));
  };

  const validateForm = () => {
    const error = new Map();

    if (email === '' || !validateEmail(email)) {
      error.set('email', EMAIL_INVALID_ERROR);
    }

    if (phoneNumber === '' || !validatePhone(phoneNumber)) {
      error.set('phone', INVALID_PHONE_ERROR);
    }

    return error;
  };

  const handleOnSaveChanges = () => {
    const error = validateForm();

    setError(error);

    if (error.size > 0) {
      dispatch(addToast('error', FIX_FIELDS));
      return;
    }

    setIsSaving(true);

    updateCorporateAccount({
      email,
      phone_number: phoneNumber,
    })
      .then((response) => {
        setIsSaving(false);

        const { data } = response;

        dispatch(setOrganizationProfileData(data));
        dispatch(addToast('success', SUCCESS_MESSAGE));
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(addToast('error', FAILURE_MESSAGE));
      });
  };

  const onBlur = () => {
    const error = validateForm();
    setError(error);
  };

  const formPreventDefault = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <App>
      <Section maxWidth="428px">
        <form autoComplete="off" onSubmit={formPreventDefault} onKeyDown={handleKeyDown}>
          <Subtitle>
            <I18n path="account.member-edit-info.menu-title" />
          </Subtitle>
          {isLoading ? (
            <Loader />
          ) : (
            <SlideRightFadeContainer>
              <LabeledInput
                label={<I18n path="account.member-edit-info.input-email" />}
                htmlFor="email"
                className={styles.labeledInput}
                data-testid="email-labeled-input"
              >
                <Input
                  id="email"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onBlur={onBlur}
                  hasError={error.has('email')}
                  errorMessage={error.get('email')}
                  data-testid="email-input"
                />
              </LabeledInput>

              <LabeledInput
                label={<I18n path="account.member-edit-info.input-phone" />}
                htmlFor="phone"
                className={styles.labeledInput}
                data-testid="phone-labeled-input"
              >
                <Input
                  id="phone"
                  name="phone"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                  onBlur={onBlur}
                  hasError={error.has('phone')}
                  errorMessage={error.get('phone')}
                  data-testid="phone-input"
                />
              </LabeledInput>

              <ButtonIcon icon="cross" type="danger" onClick={onClickRemoveButton} data-testid="request-removal-button">
                <I18n path="account.member-edit-info.button-request-remove" />
              </ButtonIcon>

              <div className={styles.buttons}>
                <Button type="primary" isLoading={isSaving} onClick={handleOnSaveChanges} data-testid="save-button">
                  <I18n path="account.member-edit-info.button-save" />
                </Button>
                <Button onClick={() => navigate('/')} data-testid="cancel-button">
                  <I18n path="account.member-edit-info.button-cancel" />
                </Button>
              </div>
            </SlideRightFadeContainer>
          )}
        </form>
      </Section>
    </App>
  );
}

export default CorporateProfileInfo;
