import { jwtDecode } from 'jwt-decode';
import Cookie from 'js-cookie';

export const PERSONAL_ACCESS_TOKEN_KEY = 'personal_access_token';
export const PERSONAL_REFRESH_TOKEN_KEY = 'personal_refresh_token';
export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export const PERSONAL_TOKEN = 'personal';
export const CORPORATE_TOKEN = 'corporate';

export const getTokenType = (token) => {
  let payload = {};

  try {
    payload = jwtDecode(token);
  } catch {
    payload = {};
  }

  if (payload.member_id) {
    return CORPORATE_TOKEN;
  }

  if (payload.identity) {
    return PERSONAL_TOKEN;
  }

  return null;
};

export const setToken = (type, token) => {
  Cookie.set(type, token, { sameSite: 'strict', expires: 365 });
};

export const getToken = (type) => Cookie.get(type);

export const clearAllTokens = () => {
  Cookie.remove(ACCESS_TOKEN_KEY);
  Cookie.remove(REFRESH_TOKEN_KEY);
  Cookie.remove(PERSONAL_ACCESS_TOKEN_KEY);
  Cookie.remove(PERSONAL_REFRESH_TOKEN_KEY);
};
