import { Select as MuiSelect } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

function arrowIcon(props) {
  return <ExpandMoreIcon {...props} />;
}

const CustomSelect = styled(MuiSelect)(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.colorGreyDark.light,
  borderRadius: 40,
  '& .MuiOutlinedInput-notchedOutline': {
    border: `solid 1px ${theme.palette.colorGrey.light}`,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: `solid 1px ${theme.palette.colorGreyDark.light}`,
  },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: `solid 1px ${theme.palette.colorGreyLight.light}`,
  },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `solid 1px ${theme.palette.error.light}`,
  },
  '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
    border: `solid 1px ${theme.palette.colorGreyLight.light}`,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `solid 1px ${theme.palette.colorGreyLight.light}`,
  },
  '& .MuiSelect-iconOpen': {
    transform: 'none',
  },
  '& .MuiSelect-select': {
    padding: '12px',
  },
}));

export default function Select(props) {
  return <CustomSelect IconComponent={arrowIcon} {...props} />;
}
