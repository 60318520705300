import App from 'components/templates/app';
import MonitorizationList from 'components/organisms/monitorization-list';
import I18n from 'utils/i18n';

const HOME = <I18n path="physio.breadcrumbs.home" />;
const TITLE = <I18n path="physio.breadcrumbs.title-organization" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/organization/physio',
    title: TITLE,
    isActive: true,
  },
];

const Reports = () => (
  <App title={TITLE} breadcrumbs={breadcrumbs}>
    <MonitorizationList isOrganization />
  </App>
);

export default Reports;
