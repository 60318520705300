import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from 'components/atoms/avatar';
import Text from 'components/atoms/text';
import styles from './styles.module.css';

const DropdownAccount = ({ image, title, subtitle, onClick, className, ...props }) => (
  <div className={classnames(styles.accountItem, className)} onClick={onClick} role="presentation" {...props}>
    <Avatar image={image} description="Account image" size="small" />
    <div className={styles.textContainer}>
      <Text className={styles.title} data-testid="account-title">
        {title}
      </Text>
      <Text className={styles.subtitle} data-testid="account-subtitle">
        {subtitle}
      </Text>
    </div>
  </div>
);

DropdownAccount.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

DropdownAccount.defaultProps = {
  image: undefined,
  subtitle: '',
  className: undefined,
  onClick: () => {},
};

export default DropdownAccount;
