import { useEffect, useState } from 'react';
import { addToast } from 'actions/toasts';
import Badge from 'components/atoms/badge';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import Radio from 'components/atoms/input-radio';
import Label from 'components/atoms/input-label';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Text from 'components/atoms/text';
import Avatar from 'components/atoms/avatar';
import Checkbox from 'components/atoms/input-checkbox';
import Loader from 'components/molecules/section-loader';
import LabeledInput from 'components/molecules/labeled-input';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import { MEMBER_STATUS } from 'utils/members';
import App from 'pages/braincare/AdminOrganizations/members/edit/template';
import { useNavigate, useParams } from 'react-router-dom';
import braincare from 'components/routes/braincare';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMember, setOrganizationSelectedMember } from 'features/organizationSlice';
import { fetchMemberByOrganization, updateMemberByOrganization } from 'api/portal';
import styles from './styles.module.css';

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const REQUEST_SUCCESS = <I18n path="messages.request-success" />;
const REQUEST_FAILURE = <I18n path="messages.request-failure" />;

const BADGE_STATUS = {
  ACTIVE: 'success',
  PENDING: 'pending',
  SUSPENDED: 'danger',
  DECLINED: 'alert',
  BLOCKED: 'blocked',
};

function InfoAdmin() {
  const member = useSelector((state) => state.organization.selectedMember);

  const { user } = member;

  const [photo, setPhoto] = useState(user ? user.photo : '');
  const status = member.status || 'ACTIVE';
  const reason = member.reason || '';
  const [firstName, setFirstName] = useState(user ? user.first_name : '');
  const [lastName, setLastName] = useState(user ? user.last_name : '');
  const [birthDate, setBirthDate] = useState(user ? user.birth_date : '');
  const [gender, setGender] = useState(user ? user.gender : '');
  const [notificationLanguage, setNotificationLanguage] = useState(user ? user.notification_language : '');
  const [doctorDocument, setDoctorDocument] = useState(user ? user.doctor_document : '');
  const hasDoctorDocument = user ? Boolean(user.doctor_document) : true;
  const [documentType, setDocumentType] = useState(user ? user.document_type : '');
  const [document, setDocument] = useState(user ? user.document : '');

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { orgId, memberId } = params;

  useEffect(() => {
    const { organization } = member;
    const persistedOrgId = organization ? organization.id : undefined;

    if (member.id !== Number(memberId) || persistedOrgId !== Number(orgId)) {
      dispatch(clearOrganizationSelectedMember());

      setIsLoading(true);

      fetchMemberByOrganization(orgId, memberId)
        .then((response) => {
          const { data } = response;

          dispatch(setOrganizationSelectedMember(data));

          const { user } = data;

          setPhoto(user.photo);
          setFirstName(user.first_name);
          setLastName(user.last_name);
          setBirthDate(user.birth_date);
          setGender(user.gender);
          setDoctorDocument(user.doctor_document);
          setDocumentType(user.document_type);
          setDocument(user.document);
          setNotificationLanguage(user.notification_language);
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(addToast('error', LOAD_FAILURE));
          navigate(`/braincare/organizations/${orgId}/members`);
        });
    }
  }, []);

  const handleResendInvite = () => {
    setIsUpdating(true);

    updateMemberByOrganization(orgId, memberId, { mail_again: true })
      .then(() => {
        setIsUpdating(false);
        dispatch(addToast('success', REQUEST_SUCCESS));
      })
      .catch(() => {
        setIsUpdating(false);
        dispatch(addToast('error', REQUEST_FAILURE));
      });
  };

  return (
    <App>
      <Section className={styles.section} maxWidth="428px">
        <Subtitle data-testid="personal-info-subtitle">
          <I18n path="organization.organization-members-edit.title-sub-member-edit" />
        </Subtitle>
        <Text isItalic data-testid="personal-info-text">
          <I18n path="organization.organization-members-edit.text-organization-members-main" />
        </Text>

        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <Avatar image={photo} description="profile image" className={styles.profileImage} />

            <div className={styles.statusContainer}>
              <div>
                <b className={styles.label}>
                  <I18n path="organization.organization-members-edit.label-status" />
                </b>
                <Badge className={styles.statusBadge} type={BADGE_STATUS[status]}>
                  {MEMBER_STATUS.find((item) => item.id === status).text}
                </Badge>
              </div>

              {status === 'DECLINED' && (
                <div>
                  <b className={styles.label}>
                    <I18n path="organization.organization-members-edit.label-reason" />
                  </b>
                  <span>{reason}</span>
                </div>
              )}

              {status === 'DECLINED' && (
                <Button
                  type="danger"
                  onClick={handleResendInvite}
                  isLoading={isUpdating}
                  data-testid="resend-invitation-button"
                >
                  <I18n path="organization.organization-members-edit.button-resend-invite" />
                </Button>
              )}
            </div>

            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-name" />}
              htmlFor="first_name"
              isDisabled
              data-testid="first-name-labeled-input"
            >
              <Input
                id="first_name"
                name="first-name"
                value={firstName || ''}
                isDisabled
                data-testid="first-name-input"
              />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-surname" />}
              htmlFor="last_name"
              isDisabled
              data-testid="last-name-labeled-input"
            >
              <Input id="last_name" name="last-name" value={lastName || ''} isDisabled data-testid="last-name-input" />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-birthday" />}
              htmlFor="birth_date"
              isDisabled
              data-testid="birth-date-labeled-input"
            >
              <Input
                id="birth_date"
                name="birth-date"
                value={birthDate || ''}
                isDisabled
                data-testid="birth-date-input"
              />
            </LabeledInput>
            <div>
              <Label isDisabled>
                <I18n path="organization.organization-members-edit.input-sex" />
              </Label>
              <div className={styles.genderOptions}>
                <Radio
                  id="member-edit-gender-female"
                  name="gender"
                  value="FEMALE"
                  label={<I18n path="organization.organization-members-edit.input-gender-female" />}
                  isChecked={gender === 'FEMALE'}
                  isDisabled
                  data-testid="female-radio"
                />
                <Radio
                  id="member-edit-gender-male"
                  name="gender"
                  value="MALE"
                  label={<I18n path="organization.organization-members-edit.input-gender-male" />}
                  isChecked={gender === 'MALE'}
                  isDisabled
                  data-testid="male-radio"
                />
              </div>
              <div className={styles.language}>
                <Label isDisabled>
                  <I18n path="auth.signup.text-doc-choice-language" />
                </Label>
                <div className={styles.languageOptions}>
                  <Radio
                    id="pt-br"
                    name="notificationLanguage"
                    value="pt-br"
                    label={<I18n path="auth.signup.input-language-pt-br" />}
                    isChecked={notificationLanguage === 'pt-br'}
                    isDisabled
                    data-testid="pt-br-radio"
                  />
                  <Radio
                    id="en-us"
                    name="notificationLanguage"
                    value="en-us"
                    label={<I18n path="auth.signup.input-language-en-us" />}
                    isChecked={notificationLanguage === 'en-us'}
                    isDisabled
                    data-testid="en-us-radio"
                  />
                </div>
              </div>
            </div>
          </SlideRightFadeContainer>
        )}
      </Section>

      <Section maxWidth="428px">
        <Subtitle data-testid="medical-license-subtitle">
          <I18n path="organization.organization-members-edit.title-sub-member-license" />
        </Subtitle>
        <Text isItalic data-testid="medical-license-text">
          <I18n path="organization.organization-members-edit.text-organization-members-license" />
        </Text>

        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-license" />}
              htmlFor="license_number"
              isDisabled
              data-testid="license-number-labeled-input"
            >
              <Input
                id="license_number"
                name="license-number"
                value={doctorDocument || ''}
                isDisabled
                placeholder="ex: BC (Doctors), CNP (Nurses), etc..."
                data-testid="license-number-input"
              />
            </LabeledInput>
            <Checkbox
              id="license"
              name="license"
              value={hasDoctorDocument}
              label={<I18n path="organization.organization-members-edit.input-license-check-box" />}
              isChecked={!doctorDocument}
              isDisabled
              data-testid="needs-license-checkbox"
            />
          </SlideRightFadeContainer>
        )}
      </Section>

      <Section maxWidth="428px">
        <Subtitle data-testid="identification-subtitle">
          <I18n path="organization.organization-members-edit.title-sub-member-identification" />
        </Subtitle>
        <Text isItalic data-testid="identification-text">
          <I18n path="organization.organization-members-edit.text-organization-members-username" />
        </Text>

        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-doc-type-choice" />}
              htmlFor="document_type"
              isDisabled
              data-testid="identification-type-input"
            >
              <Select
                id="document_type"
                name="document-type"
                value={documentType || ''}
                isDisabled
                data-testid="identification-type-select"
              >
                <Option value="CPF" data-testid="identification-type-cpf-option">
                  CPF
                </Option>
                <Option value="ID" data-testid="identification-type-ssn-option">
                  ID
                </Option>
              </Select>
            </LabeledInput>

            <LabeledInput
              label={<I18n path="organization.organization-members-edit.input-document-number" />}
              htmlFor="document"
              isDisabled
              data-testid="identification-labeled-input"
            >
              <Input
                id="document"
                name="document"
                value={document || ''}
                isDisabled
                data-testid="identification-input"
              />
            </LabeledInput>
          </SlideRightFadeContainer>
        )}
      </Section>
      <Button onClick={() => navigate(`/braincare/organizations/${orgId}/members`)} data-testid="back-button">
        <I18n path="organization.organization-members-edit.button-cancel" />
      </Button>
    </App>
  );
}

export default braincare(InfoAdmin);
