import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo-braincare.png';
import Button from 'components/atoms/button';
import I18n from 'utils/i18n';
import styles from './styles.module.css';

const AppPublic = ({ className, children }) => (
  <div className={classNames(className, styles.app)}>
    <header className={styles.header}>
      <h1 className={styles.brand}>
        <Link to="/auth/direct-login">
          <img src={logo} alt="Braincare Cloud" />
        </Link>
      </h1>
      <nav className={styles.nav}>
        <Link to="/auth/signup">
          <I18n path="navbar-public.sign-up" />
        </Link>
        <Link to="/auth/direct-login">
          <Button>
            <I18n path="navbar-public.sign-in" />
          </Button>
        </Link>
      </nav>
    </header>
    <main>{children}</main>
  </div>
);

AppPublic.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AppPublic.defaultProps = {
  className: '',
};

export default AppPublic;
