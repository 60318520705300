import styled, { css } from 'styled-components';
import Down from 'assets/static/down.svg';

export const Select = styled.div`
  position: relative;
  appearance: none;
  background-image: url(${Down});
  background-repeat: no-repeat;
  background-position: calc(100% - 16px);

  &:focus {
    outline: none;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: var(--border-half);
  background-color: var(--color-white);
  border: solid 1px var(--color-grey);
  transition: all 200ms ease;
  overflow: scroll;
  z-index: 2;
  max-height: 400px;

  ${(props) =>
    !props.isOpen
      ? css`
          opacity: 0;
          visibility: hidden;
          transform: translateY(24px);
        `
      : css`
          opacity: 1;
          pointer-events: initial;
          transform: translateY(39px);
        `}
`;
