import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Checkbox = ({ id, isChecked, isDisabled, onChange, onClick, ...props }) => (
  <input
    id={id}
    className={classNames(styles.checkbox, { [styles.disabled]: isDisabled })}
    type="checkbox"
    checked={isChecked}
    onChange={onChange}
    onClick={onClick}
    disabled={isDisabled}
    {...props}
  />
);

Checkbox.propTypes = {
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  id: undefined,
  isChecked: false,
  isDisabled: false,
  onChange: undefined,
  onClick: undefined,
};

export default Checkbox;
