import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from 'components/atoms/avatar';
import Text from 'components/atoms/text';
import helpIcon from 'assets/images/ic_help.svg';
import styles from './styles.module.css';

const MemberItem = ({ image, primaryText, secondaryText, onClick, className, disabled, hasIcon, ...props }) => {
  const secondaryTextColor = disabled ? styles['secondary-text-disabled'] : styles['secondary-text-enabled'];

  return (
    <div className={classnames(styles.accountItem, className)} onClick={onClick} role="presentation" {...props}>
      <div className={styles['primary-info']}>
        <Avatar image={image} description="Profile account image" size="small" />
        <Text className={styles['primary-text']}>{primaryText}</Text>
      </div>
      <div className={styles['secondary-info']}>
        <Text isBold={!disabled} className={`${styles['secondary-text']} ${secondaryTextColor}`}>
          {secondaryText}
        </Text>
        {hasIcon ? <img src={helpIcon} alt="Help icon" /> : null}
      </div>
    </div>
  );
};

MemberItem.propTypes = {
  image: PropTypes.string,
  primaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasIcon: PropTypes.bool,
};

MemberItem.defaultProps = {
  image: undefined,
  className: undefined,
  onClick: () => {},
  disabled: false,
  hasIcon: false,
};

export default MemberItem;
