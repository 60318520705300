import I18n from 'utils/i18n';
import PasswordValidator from './PasswordValidator';

export default class NonAlphanumericValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { nonAlphanumericRange } = passwordPolicy;

    const { min, max } = nonAlphanumericRange;

    let translation = null;

    if (min !== undefined && max !== undefined) {
      translation = <I18n props={{ min, max }} path="organization.password-rules.non-alphanumeric-constraint-1" />;
    } else if (min !== undefined) {
      translation = <I18n props={{ min }} path="organization.password-rules.non-alphanumeric-constraint-2" />;
    } else if (max !== undefined) {
      translation = <I18n props={{ max }} path="organization.password-rules.non-alphanumeric-constraint-3" />;
    }

    super(nonAlphanumericRange, 'non_alphanumeric_range', false, translation);
  }

  validate(password) {
    if (this.policy) {
      const nonAlphanumericChars = password.match(/[^0-9a-zA-Z]/g);

      const min = this.policy.min ? this.policy.min : 0;
      const max = this.policy.max ? this.policy.max : 128;

      const numberNonAlphanumeric = nonAlphanumericChars ? nonAlphanumericChars.length : 0;

      if (min > numberNonAlphanumeric || max < numberNonAlphanumeric) {
        return false;
      }
    }

    return true;
  }
}
