import getLanguage from 'utils/get-language';

export const ptbrStringFromDate = (value) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return value.toLocaleString('pt-BR', options);
};

export const ptbrStringFromString = (value) => {
  const newValue = new Date(value);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return newValue.toLocaleString('pt-BR', options);
};

export const dateFromString = (value) => new Date(value);

export const getLocalizedDatetimeFromString = (date) => new Date(date).toLocaleString(getLanguage());
