import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import App from 'pages/braincare/AdminOrganizations/members/edit/template';
import I18n from 'utils/i18n';
import { addToast } from 'actions/toasts';
import Loader from 'components/molecules/section-loader';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Section from 'components/atoms/section';
import RuledPasswordInput from 'components/organisms/RuledPasswordInput';
import LabeledInput from 'components/molecules/labeled-input';
import InputIcon from 'components/molecules/input-icon';
import Checkbox from 'components/atoms/input-checkbox';
import Button from 'components/atoms/button';
import braincare from 'components/routes/braincare';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMember, setOrganizationSelectedMember } from 'features/organizationSlice';
import { validatePassword as applyPolicyValidation } from 'utils/password-validators';
import { fetchMemberByOrganization, updateMemberByOrganization } from 'api/portal';
import styles from '../styles.module.css';

const SAVE_SUCCESS = <I18n path="messages.save-success" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;
const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const REQUIRED_FIELD_ERROR = <I18n path="account.edit-password.fill-required" />;
const MISMATCH_PASSWORD_ERROR = <I18n path="messages.mismatch-error" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

function Password() {
  const member = useSelector((state) => state.organization.selectedMember);

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [emailNotification, setEmailNotification] = useState(false);

  const [errors, setErrors] = useState(new Map());
  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);

  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);

  const [isLoadingMemberData, setIsLoadingMemberData] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { orgId, memberId } = params;

  const { organization } = member;
  const passwordPolicy = organization ? organization.password_policy : {};

  useEffect(() => {
    const persistedOrgId = organization ? organization.id : undefined;

    if (member.id !== Number(memberId) || persistedOrgId !== Number(orgId)) {
      setIsLoadingMemberData(true);

      dispatch(clearOrganizationSelectedMember());

      fetchMemberByOrganization(orgId, memberId)
        .then((response) => {
          const { data } = response;
          dispatch(setOrganizationSelectedMember(data));
          setIsLoadingMemberData(false);
        })
        .catch(() => {
          dispatch(addToast('error', LOAD_FAILURE));
          navigate(`/braincare/organizations/${orgId}/members`);
        });
    } else {
      setIsLoadingMemberData(false);
    }
  }, []);

  const validatePassword = (password) => {
    const policyErrors = applyPolicyValidation(passwordPolicy, password);
    setPasswordPolicyErrors(policyErrors);

    if (policyErrors.length === 0) {
      errors.delete('password');
    } else {
      errors.set('password', policyErrors);
    }

    if (password) {
      errors.delete('password');
    } else {
      errors.set('password', REQUIRED_FIELD_ERROR);
    }

    if (password === passwordConfirmation) {
      errors.delete('passwordConfirmation');
    } else {
      errors.set('passwordConfirmation', MISMATCH_PASSWORD_ERROR);
    }

    setPassword(password);
    setPasswordConfirmation(passwordConfirmation);
    setErrors(errors);
  };

  const validatePasswordConfirmation = (passwordConfirmation) => {
    if (password === passwordConfirmation) {
      errors.delete('passwordConfirmation');
    } else {
      errors.set('passwordConfirmation', MISMATCH_PASSWORD_ERROR);
    }

    setPasswordConfirmation(passwordConfirmation);
    setErrors(errors);
  };

  const handleSubmit = () => {
    validatePassword(password);
    validatePasswordConfirmation(passwordConfirmation);

    if (errors.size !== 0) {
      dispatch(addToast('error', FIX_FIELDS));
      return;
    }

    setIsSaving(true);

    updateMemberByOrganization(orgId, memberId, {
      password,
      force_password_change: false,
      mail_again: emailNotification,
    })
      .then(() => {
        setIsSaving(false);
        dispatch(addToast('success', SAVE_SUCCESS));
      })
      .catch((error) => {
        setIsSaving(false);

        const { response } = error;
        const { data } = response;

        const passwordErrors = data.password;

        if (Array.isArray(passwordErrors)) {
          const policyErrors = passwordErrors.map((passwordError) => passwordError.split(']')[0].split('[')[1]);
          setPasswordPolicyErrors(policyErrors);
        }

        dispatch(addToast('error', SAVE_FAILURE));
      });
  };

  return (
    <App>
      <Section className={styles.section} maxWidth="428px" data-testid="change-member-form">
        <Subtitle data-testid="page-subtitle">
          <I18n path="organization.organization-members-edit-change-password.title-sub-member-change-password" />
        </Subtitle>
        <Text isItalic>
          <I18n path="organization.organization-members-edit-change-password.text-member-change-password" />
        </Text>
        {isLoadingMemberData ? (
          <Loader />
        ) : (
          <>
            <RuledPasswordInput
              errors={passwordPolicyErrors}
              hasError={errors.has('password')}
              passwordPolicy={passwordPolicy}
              onChange={(event) => validatePassword(event.target.value, errors)}
              value={password}
            />
            <LabeledInput
              label={<I18n path="organization.organization-members-edit-change-password.input-password-confirmation" />}
              htmlFor="password"
              data-testid="password-confirmation-labeled-input"
            >
              <InputIcon
                id="password_confirmation"
                value={passwordConfirmation}
                onChange={(event) => validatePasswordConfirmation(event.target.value)}
                type={isPasswordConfirmationVisible ? 'text' : 'password'}
                icon={isPasswordConfirmationVisible ? 'eye' : 'eye-blocked'}
                onIconClick={() => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)}
                hasError={errors.has('passwordConfirmation')}
                errorMessage={errors.get('passwordConfirmation')}
                onBlur={(event) => validatePasswordConfirmation(event.target.value)}
                data-testid="password-confirmation-input"
              />
            </LabeledInput>
            <Section>
              <Checkbox
                id="emailNotification"
                className={styles.checkbox}
                name="emailNotification"
                value={emailNotification}
                label={
                  <I18n
                    props={{ email: member.email }}
                    path="organization.organization-members-edit-change-password.input-mail-notification-check-box"
                  />
                }
                isChecked={emailNotification}
                onClick={() => setEmailNotification(!emailNotification)}
                data-testid="email-notification-checkbox"
              />
            </Section>
            <div className={styles.buttons}>
              <Button isLoading={isSaving} onClick={handleSubmit} data-testid="submit-button">
                <I18n path="organization.organization-members-edit-permissions.button-save" />
              </Button>
              <Button onClick={() => navigate(`/braincare/organizations/${orgId}/members`)} data-testid="cancel-button">
                <I18n path="organization.organization-members-edit-permissions.button-cancel" />
              </Button>
            </div>
          </>
        )}
      </Section>
    </App>
  );
}

export default braincare(Password);
