import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Breadcrumbs = ({ children, ...props }) => (
  <div className={styles.breadcrumbs} {...props}>
    {children}
  </div>
);

Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Breadcrumbs;
