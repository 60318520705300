import PropTypes from 'prop-types';
import IconButton from 'components/atoms/icon-button';
import Text from 'components/atoms/text';
import Slide from './slide';
import { Separator, SlideInContainer } from './styles';
import styles from './styles.module.css';

const Slider = ({ title, onChange, children, currentIndex }) => {
  const handlePreviousClick = () => {
    const previous = currentIndex - 1;
    const newIndex = previous < 1 ? 0 : previous;
    onChange(newIndex);
  };

  const handleNextClick = () => {
    const next = currentIndex + 1;
    const lastIndex = children.length - 1;
    const newIndex = next > lastIndex ? lastIndex : next;
    onChange(newIndex);
  };

  return (
    <SlideInContainer className={styles.slider}>
      <div className={styles.header}>
        <Text className={styles.sliderTitle}>{title}</Text>
        {children.length > 1 && (
          <div className={styles.buttons}>
            <IconButton className={styles.button} onClick={handlePreviousClick} name="arrow_left" />
            <IconButton className={styles.button} onClick={handleNextClick} name="arrow_right" />
            <Text className={styles.counter}>
              {currentIndex + 1} of {children.length}
            </Text>
          </div>
        )}
      </div>
      <Separator />
      <div className={styles.content}>{children[currentIndex]}</div>
    </SlideInContainer>
  );
};

Slider.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  currentIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Slide), PropTypes.instanceOf(Array)]).isRequired,
};

Slider.defaultProps = {
  currentIndex: 0,
};

export { Slide };

export default Slider;
