import PropTypes from 'prop-types';
import classNames from 'classnames';
import I18n from 'utils/i18n';
import Icon from 'components/atoms/icon';
import { Card } from './styles';
import styles from './styles.module.css';
import Header from './header';
import Footer from './footer';
import { getLocalizedDatetimeFromString } from '../../../utils/format-date-time';

const DeviceCard = ({ className, device, onClick, ...props }) => {
  const lastAccessDate = device.last_access ? getLocalizedDatetimeFromString(device.last_access) : '-';
  return (
    <Card className={className} onClick={onClick} role="presentation" {...props}>
      <>
        <div className={classNames(styles.statusFlag, styles[device.status.toLowerCase()])} />
        {device.status === 'BLOCKED' && <Icon name="lock" className={styles.icon} />}
      </>
      <Header device={device} />
      <Footer
        date={
          <I18n
            path="organization.organization-mobile-devices-info.text-last-access"
            props={{ last_access: `${lastAccessDate}` }}
          />
        }
      />
    </Card>
  );
};

DeviceCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  device: PropTypes.object,
};

DeviceCard.defaultProps = {
  className: '',
  onClick: undefined,
  device: {},
};

export default DeviceCard;
