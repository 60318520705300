import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const List = ({ className, children }) => <ul className={classNames(styles.list, className)}>{children}</ul>;

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

List.defaultProps = {
  className: undefined,
};

export default List;
