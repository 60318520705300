import { useState } from 'react';

// Translation
import I18n from 'utils/i18n';

// Actions
import { addToast } from 'actions/toasts';

// Components
import Avatar from 'components/atoms/avatar';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import Icon from 'components/atoms/icon';
import InputGroup from 'components/atoms/input-group';
import Label from 'components/atoms/input-label';
import LinkItem from 'components/atoms/link-item';
import Radio from 'components/atoms/input-radio';
import Text from 'components/atoms/text';
import AuthTitle from 'components/atoms/auth-title';
import Card, { Header } from 'components/molecules/organization-card';
import InputIcon from 'components/molecules/input-icon';
import Translator from 'components/molecules/translator';
import { acceptEduTerms, eduLogin, resetEduPassword } from 'api/portal';

// Utilities
import { clearAllTokens, setToken } from 'utils/tokens';
import { formatToCPFDocument, unformatDocument } from 'utils/format-documents';

// Assets
import BraincareLogo from 'assets/images/logo-braincare.png';

// Redux
import { useDispatch } from 'react-redux';

// Styles
import { WebViewBrand } from './styles';
import styles from '../styles.module.css';

// Messages
const USER_BLOCKED = <I18n path="auth.login.user-blocked" />;
const GENERIC_ERROR = <I18n path="messages.generic-error" />;
const INVALID_CREDENTIAL = <I18n path="auth.login.auth-error" />;
const DOCUMENT_EMPTY_ERROR = <I18n path="auth.login.doc-empty-error" />;
const PASSWORD_EMPTY_ERROR = <I18n path="auth.login.pwd-empty-error" />;
const CHECK_EMAIL = <I18n path="auth.forgot-password.check-email" />;
const REDEFINE_FAILURE = <I18n path="auth.forgot-password.redefine-failure" />;
const INTEGRATION_SUCCESS = <I18n path="auth.login.integration-auth-success" />;
const USER_TERMS_UPDATE_FAIL = <I18n path="auth.b4c-edu.update-error" />;

function EduLogin() {
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [formattedDocument, setFormattedDocument] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetDocument, setResetDocument] = useState('');
  const [documentResetError, setDocumentResetError] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [documentLength, setDocumentLength] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [expiration, setExpiration] = useState(0);
  const [loginToken, setLoginToken] = useState('');
  const [accounts, setAccounts] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectAccount, setSelectAccount] = useState(false);
  const [inputAccountPassword, setInputAccountPassword] = useState(false);

  const dispatch = useDispatch();

  const resetState = () => {
    setPassword('');
    setFormattedDocument('');
    setDocumentError('');
    setPasswordError('');
    setResetSuccess(false);
    setResetDocument('');
    setDocumentResetError('');
    setDocumentType('');
    setDocumentLength('');
    setIsPasswordVisible(false);
    setIsLoading(false);
    setIsLogin(true);
    setHasAcceptedTerms(false);
    setAccessToken('');
    setRefreshToken('');
    setExpiration(0);
    setLoginToken('');
    setAccounts('');
    setSelectedAccount(null);
    setSelectAccount(false);
    setInputAccountPassword(false);
  };

  const formatDocument = (event) => {
    if (documentType === 'CPF') {
      setDocument(unformatDocument(formatToCPFDocument(event.target.value)));
      setFormattedDocument(formatToCPFDocument(event.target.value));
    }

    if (documentType === 'ID') {
      setDocument(event.target.value);
      setFormattedDocument(event.target.value);
    }
  };

  const handleDocumentType = (event) => {
    setDocumentType(event.target.value);
    setDocumentLength(event.target.value === 'CPF' ? '14' : '32');
    setDocument('');
    setFormattedDocument('');
  };

  const handleLoginError = (error) => {
    const { response } = error;
    const { data, status } = response;

    setIsLoading(false);

    if (response && status === 401) {
      if (data.hasOwnProperty('blocked')) {
        dispatch(addToast('error', USER_BLOCKED));
      } else {
        dispatch(addToast('error', INVALID_CREDENTIAL));
      }

      setPassword('');
      return;
    }

    if (data) {
      setPassword('');
      dispatch(addToast('error', GENERIC_ERROR));
    }
  };

  const handleLoginResponse = (response) => {
    const { data } = response;

    setIsLoading(false);

    if (data.hasOwnProperty('accounts')) {
      setLoginToken(data.token);
      setAccounts(data.accounts);
      setSelectAccount(true);
      setPassword('');
      return;
    }

    setToken('access_token', data.access_token);
    setToken('refresh_token', data.refresh_token);

    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    setExpiration(data.expiration);
    setSelectAccount(false);
    setInputAccountPassword(false);

    if (data.hasOwnProperty('edu_terms') && data.edu_terms === false) {
      setPassword('');
      setFormattedDocument('');
      setHasAcceptedTerms(true);
      return;
    }

    window.location.assign(
      `muvecrio://?token=${data.access_token}&refresh_token=${data.refresh_token}&expire_date*=${data.expiration}`,
    );

    dispatch(addToast('success', INTEGRATION_SUCCESS));
    setPassword('');
    setFormattedDocument('');
    clearAllTokens();
  };

  // Normal Login
  const handleLoginSubmit = () => {
    if (!document) {
      setDocumentError(DOCUMENT_EMPTY_ERROR);
      return;
    }

    if (!password) {
      setPasswordError(PASSWORD_EMPTY_ERROR);
      return;
    }

    setDocumentError('');
    setPasswordError('');
    setIsLoading(true);

    eduLogin({ document, password }).then(handleLoginResponse).catch(handleLoginError);
  };

  const handleAccountLoginError = (error) => {
    const { response } = error;
    const { data, status } = response;

    setIsLoading(false);

    if (status === 401) {
      if (data.hasOwnProperty('blocked')) {
        dispatch(addToast('error', USER_BLOCKED));
        setPassword('');
        setAccounts(null);
        setSelectedAccount(null);
        setFormattedDocument('');
        setSelectAccount(false);
        setInputAccountPassword(false);
      } else {
        dispatch(addToast('error', INVALID_CREDENTIAL));
        setPassword('');
      }
      return;
    }

    if (data) {
      dispatch(addToast('error', GENERIC_ERROR));
      resetState();
    }
  };

  const handleAccountLoginResponse = (response) => {
    const { data } = response;

    setIsLoading(false);

    setToken('access_token', data.access_token);
    setToken('refresh_token', data.refresh_token);

    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    setExpiration(data.expiration);
    setSelectAccount(false);
    setInputAccountPassword(false);

    if (data.hasOwnProperty('edu_terms') && data.edu_terms === false) {
      setPassword('');
      setFormattedDocument('');
      setHasAcceptedTerms(true);
      return;
    }

    window.location.assign(
      `muvecrio://?token=${data.access_token}&refresh_token=${data.refresh_token}&expire_date*=${data.expiration}`,
    );

    dispatch(addToast('success', INTEGRATION_SUCCESS));
    setPassword('');
    setFormattedDocument('');
    setSelectAccount(false);
    setInputAccountPassword(false);
    clearAllTokens();
  };

  // Account Login
  const handleAccountLoginSubmit = () => {
    if (!password) {
      setPasswordError(PASSWORD_EMPTY_ERROR);
      return;
    }

    setPasswordError('');
    setIsLoading(true);

    eduLogin({
      account: selectedAccount.id,
      document,
      password,
    })
      .then(handleAccountLoginResponse)
      .catch(handleAccountLoginError);
  };

  // Login with token
  const handleAccountListLogin = (account) => {
    if (account.login_status) {
      setIsLoading(true);

      eduLogin({
        account: account.id,
        token: loginToken,
        document,
      })
        .then(handleLoginResponse)
        .catch(handleLoginError);
      return;
    }

    setSelectedAccount(account);
    setInputAccountPassword(true);
  };

  // Terms
  const handleDialogConfirmation = () => {
    setIsLoading(true);

    // eslint-disable-next-line no-unused-vars
    acceptEduTerms()
      .then(() => {
        setIsLoading(false);

        window.location.assign(
          `muvecrio://?token=${accessToken}&refresh_token=${refreshToken}&expire_date*=${expiration}`,
        );

        dispatch(addToast('success', INTEGRATION_SUCCESS));
        setHasAcceptedTerms(false);

        clearAllTokens();
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(addToast('error', USER_TERMS_UPDATE_FAIL));
        setHasAcceptedTerms(false);
      });
  };

  // Password reset functions
  const handleReset = () => {
    setIsLogin(!isLogin);
    setDocumentResetError('');
    setResetDocument('');
    setResetSuccess(false);
  };

  const handleResetSubmit = () => {
    if (!resetDocument) {
      setDocumentResetError(DOCUMENT_EMPTY_ERROR);
      return;
    }

    const payload = {
      document: resetDocument,
    };

    if (selectedAccount && selectedAccount.id !== 0) {
      payload.token = loginToken;
      payload.organization = selectAccount.id;
    }

    resetEduPassword(payload)
      .then(() => {
        resetState();
        dispatch(addToast('warning', CHECK_EMAIL));
      })
      .catch(() => {
        resetState();
        dispatch(addToast('error', REDEFINE_FAILURE));
      });
  };

  // Render login functions
  const renderLoginForm = () => (
    <div className={styles.webview_container_form}>
      <WebViewBrand image={BraincareLogo} title="Braincare logo" />

      <div className={styles.login_form}>
        <div className={styles.documentType}>
          <Radio
            id="login-radio-ssn"
            name="documentType"
            value="ID"
            label="ID"
            isChecked={documentType === 'ID'}
            onChange={handleDocumentType}
            data-testid="ssn-radio-button"
          />
          <Radio
            id="login-radio-cpf"
            name="documentType"
            value="CPF"
            label="CPF"
            isChecked={documentType === 'CPF'}
            onChange={handleDocumentType}
            data-testid="cpf-radio-button"
          />
        </div>

        <InputGroup>
          <Label htmlFor="document">
            <I18n path="auth.login.link-input-document" />
          </Label>
          <Input
            id="document"
            name="document"
            value={formattedDocument}
            hasError={Boolean(documentError)}
            errorMessage={documentError && DOCUMENT_EMPTY_ERROR}
            onChange={(event) => formatDocument(event)}
            isDisabled={!documentType}
            maxLength={documentLength}
            data-testid="document-input"
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="password">
            <I18n path="auth.login.input-pwd" />
          </Label>
          <InputIcon
            id="password"
            name="password"
            value={password}
            hasError={Boolean(passwordError)}
            errorMessage={passwordError && PASSWORD_EMPTY_ERROR}
            onChange={(event) => setPassword(event.target.value)}
            type={isPasswordVisible ? 'text' : 'password'}
            icon={isPasswordVisible ? 'eye' : 'eye-blocked'}
            onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
            data-testid="password-input"
          />
        </InputGroup>

        <LinkItem href="#" onClick={handleReset} data-testid="link-reset-password">
          <I18n path="auth.login.link-password-reset" />
        </LinkItem>

        <Button
          type="primary"
          isLarge
          isBlock
          className={styles.button}
          onClick={handleLoginSubmit}
          isLoading={isLoading}
          data-testid="submit-button"
        >
          <I18n path="auth.login.button-login" />
        </Button>

        <Text size="medium" className={styles.signUp}>
          <I18n path="auth.login.text-signup" />
          &nbsp;
          <LinkItem href="/auth/edu/signup" data-testid="signup-link">
            <I18n path="auth.login.link-signup" />
          </LinkItem>
        </Text>
      </div>
      <Translator />
    </div>
  );

  const renderAccountsList = () => (
    <div className={styles.webview_container_form}>
      <AuthTitle className={styles.eduChooseAccountTitle}>
        <I18n path="auth.accounts-start.title-sub-account-center" />
      </AuthTitle>

      <div className={styles.eduAccountsContainer}>
        <Card
          key={accounts[0].id}
          className={styles.eduAccountsCard}
          isBlocked={!accounts[0].login_status}
          onClick={() => handleAccountListLogin(accounts[0])}
          data-testid="personal-account-card"
        >
          <Header title={<I18n path="auth.accounts-start.title-card-personal" />} image={accounts[0].picture} />
        </Card>
        {accounts.slice(1, accounts.length).map((account) => (
          <Card
            key={account.id}
            isBlocked={!account.login_status}
            className={styles.eduAccountsCard}
            onClick={() => handleAccountListLogin(account)}
            data-testid={`corporate-account-${account.id}-card`}
          >
            <Header title={account.name} image={account.picture} />
            <Text size="small" className={styles.subtitle}>
              {account.address}
            </Text>
          </Card>
        ))}
      </div>

      <Button
        isBlock
        onClick={() => {
          setPassword('');
          setAccounts(null);
          setSelectedAccount(null);
          setFormattedDocument('');
          setSelectAccount(false);
          setInputAccountPassword(false);
        }}
      >
        <I18n path="auth.forgot-password.button-cancel" />
      </Button>
    </div>
  );

  const renderAccountDialog = () => (
    <div className={styles.webview_container_form} data-testid="account-dialog">
      <div className={styles.eduAccountDialogContainer}>
        <AuthTitle className={styles.eduChooseAccountTitle}>
          <I18n path="auth.accounts-corp-login.title-box-corp-login" props={{ organization: selectedAccount.name }} />
        </AuthTitle>
        <Avatar image={selectedAccount.photo} size="big" />
      </div>

      <InputGroup>
        <Label htmlFor="password">
          <I18n path="auth.login.input-pwd" />
        </Label>
        <InputIcon
          id="password"
          name="password"
          value={password}
          hasError={Boolean(passwordError)}
          errorMessage={passwordError && PASSWORD_EMPTY_ERROR}
          onChange={(event) => setPassword(event.target.value)}
          type={isPasswordVisible ? 'text' : 'password'}
          icon={isPasswordVisible ? 'eye' : 'eye-blocked'}
          onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
          data-testid="password-input"
        />
      </InputGroup>

      <LinkItem href="#" onClick={handleReset} data-testid="link-reset-password">
        <I18n path="auth.login.link-password-reset" />
      </LinkItem>

      <Button
        type="primary"
        isBlock
        className={styles.button}
        onClick={handleAccountLoginSubmit}
        isLoading={isLoading}
        data-testid="submit-button"
      >
        <I18n path="auth.forgot-password.button-reset-password" />
      </Button>

      <Button
        isBlock
        onClick={() => {
          setPassword('');
          setPasswordError(false);
          setInputAccountPassword(false);
        }}
      >
        <I18n path="auth.forgot-password.button-cancel" />
      </Button>
    </div>
  );

  // Reset render functions
  const renderResetForm = () => (
    <div className={styles.reset_form}>
      <AuthTitle>
        <I18n path="auth.forgot-password.title-main-password" />
      </AuthTitle>

      <Text size="medium" className={styles.descriptions}>
        <I18n path="auth.forgot-password.text-password-forgot" />
      </Text>

      <Text size="medium">
        <b className={styles.warning}>
          <Icon className={styles.warningIcon} name="warning" />
          <I18n path="auth.forgot-password.warning-brand" />
        </b>
        <I18n path="auth.forgot-password.warning-password-forgot" />
      </Text>

      <InputGroup>
        <Label htmlFor="resetDocument">
          <I18n path="auth.login.link-input-document" />
        </Label>
        <Input
          id="resetDocument"
          name="resetDocument"
          onChange={(event) => setResetDocument(event.target.value)}
          value={resetDocument}
          hasError={!!documentResetError}
          errorMessage={documentResetError}
          data-testid="reset-document-input"
        />
      </InputGroup>

      <Button
        type="primary"
        isBlock
        className={styles.nextButton}
        onClick={handleResetSubmit}
        isLoading={isLoading}
        data-testid="reset-password-button"
      >
        <I18n path="auth.forgot-password.button-reset-password" />
      </Button>

      <Button isBlock onClick={handleReset}>
        <I18n path="auth.forgot-password.button-cancel" />
      </Button>
    </div>
  );

  const renderResetSuccess = () => (
    <div className={styles.reset_form}>
      <AuthTitle>
        <I18n path="auth.forgot-password-success.title" />
      </AuthTitle>
      <Text>
        <I18n path="auth.forgot-password-success.text" />
      </Text>
      <Button isBlock type="primary" className={styles.buttonSuccess} onClick={handleReset}>
        <I18n path="auth.forgot-password-success.button-close" />
      </Button>
    </div>
  );

  const renderReset = () => (
    <div className={styles.webview_container_form}>{resetSuccess ? renderResetSuccess() : renderResetForm()}</div>
  );

  const renderAccounts = () => (
    <div className={styles.webview_container_form}>
      {inputAccountPassword ? renderAccountDialog() : renderAccountsList()}
    </div>
  );

  // Terms render
  const renderDialog = () => (
    <div className={styles.reset_form} data-testid="terms-dialog">
      <AuthTitle>
        <I18n path="auth.b4c-edu.title" />
      </AuthTitle>

      <Text>
        <I18n path="auth.b4c-edu.terms" />
      </Text>

      <Button
        isBlock
        type="primary"
        className={styles.buttonSuccess}
        onClick={handleDialogConfirmation}
        isLoading={isLoading}
        data-testid="accept-terms-button"
      >
        <I18n path="auth.b4c-edu.confirm" />
      </Button>

      <Button isBlock onClick={resetState}>
        <I18n path="auth.b4c-edu.cancel" />
      </Button>
    </div>
  );

  const renderLogin = () => (
    <div className={styles.webview_container_form}>{hasAcceptedTerms ? renderDialog() : renderLoginForm()}</div>
  );

  const renderAuthentication = () => (
    <div className={styles.webview_container_form}>{selectAccount ? renderAccounts() : renderLogin()}</div>
  );

  return <div className={styles.webview_container}>{isLogin ? renderAuthentication() : renderReset()}</div>;
}

export default EduLogin;
