import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import braincare from 'components/routes/braincare';
import { addToast } from 'actions/toasts';
import Badge from 'components/atoms/badge';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import InputImage from 'components/atoms/input-image';
import Avatar from 'components/atoms/avatar';
import Loader from 'components/molecules/section-loader';
import ButtonIcon from 'components/molecules/button-icon';
import LabeledInput from 'components/molecules/labeled-input';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n, { translate } from 'utils/i18n';
import App from 'pages/braincare/AdminOrganizations/template';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchOrganization, updateOrganization } from 'api/portal';
import styles from './styles.module.css';

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const SAVE_SUCCESS = <I18n path="messages.save-success" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;

const ORGANIZATION_STATUS = [
  { id: 'ACTIVE', text: translate('utils.status.active') },
  { id: 'INACTIVE', text: translate('utils.status.inactive') },
];

function AdminOrganizationInfo() {
  const [id, setId] = useState(-1);
  const [photo, setPhoto] = useState('');
  const [photoFile, setPhotoFile] = useState(undefined);
  const [name, setName] = useState('');
  const [physioOrgId, setPhysioOrgId] = useState('');
  const [address, setAddress] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [errors, setErrors] = useState(new Map());
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const handleFetchOrganization = (id) => {
    setIsLoading(true);

    fetchOrganization(id)
      .then((response) => {
        setIsLoading(false);

        const { data } = response;

        setId(id);
        setPhoto(data.photo);
        setName(data.name);
        setPhysioOrgId(data.physio_org_id);
        setAddress(data.address);
        setIsActive(data.is_active);
      })
      .catch(() => {
        setIsLoading(false);
        navigate('/braincare/organizations');
        dispatch(addToast('error', LOAD_FAILURE));
      });
  };

  useEffect(() => {
    const { orgId } = params;
    handleFetchOrganization(orgId);
  }, []);

  const handleOnSaveChanges = () => {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('address', address);

    if (photoFile) {
      formData.append('photo', photoFile);
    }

    setIsSaving(true);

    updateOrganization(id, formData)
      .then(() => {
        setIsSaving(false);
        dispatch(addToast('success', SAVE_SUCCESS));
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(addToast('error', SAVE_FAILURE));
      });
  };

  const showImagePreview = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => callback(reader.result);
  };

  const handleOnImageChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    showImagePreview(file, (previewUrl) => {
      setPhoto(previewUrl);
      setPhotoFile(file);
    });
  };

  const validateRequiredFields = () => {
    const requiredFields = {
      name,
      physioOrgId,
      address,
    };

    const errors = new Map();
    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    if (!errors.size) {
      handleOnSaveChanges();
    } else {
      setErrors(errors);
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  const status = isActive ? 'ACTIVE' : 'INACTIVE';

  return (
    <App title={name}>
      <Section className={styles.section} maxWidth="428px">
        <Subtitle className={styles.subtitle}>
          <I18n path="braincare.organizations-edit.step-basic-info.title" />
        </Subtitle>

        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <div className={styles.imageWrapper}>
              <Avatar image={photo} description="organization image" className={styles.profileImage} />

              <InputImage alt="profile image" onChange={(event) => handleOnImageChange(event)}>
                <ButtonIcon icon="camera" className={styles.uploadPhoto}>
                  <I18n path="braincare.organizations-edit.step-basic-info.button-add-photo" />
                </ButtonIcon>
              </InputImage>

              <Button
                type="danger"
                onClick={() => {
                  setPhoto(null);
                  setPhotoFile('');
                }}
                className={styles.removeButton}
              >
                <I18n path="braincare.organizations-edit.step-basic-info.button-remove-photo" />
              </Button>
            </div>

            <div className={styles.statusContainer}>
              <div>
                <p>
                  <I18n path="organization.organization-members-edit.label-status" />
                </p>
                <Badge className={styles.statusBadge} type={isActive ? 'success' : 'danger'}>
                  {ORGANIZATION_STATUS.find((item) => item.id === status).text}
                </Badge>
              </div>
            </div>

            <LabeledInput
              label={<I18n path="braincare.organizations-edit.step-basic-info.input-label-name" />}
              htmlFor="name"
              data-testid="name-labeled-input"
            >
              <Input
                id="name"
                name="name"
                value={name || ''}
                onChange={(event) => setName(event.target.value)}
                hasError={Boolean(errors.get('name'))}
                errorMessage={errors.get('name') ? errors.get('name') : ''}
                data-testid="name-input"
              />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="braincare.organizations-edit.step-basic-info.input-label-physio-org-id" />}
              htmlFor="physio_org_id"
              isDisabled
            >
              <Input
                id="physio_org_id"
                name="physio_org_id"
                value={physioOrgId || ''}
                onChange={(event) => setPhysioOrgId(event.target.value)}
                hasError={Boolean(errors.get('physioOrgId'))}
                errorMessage={errors.get('physioOrgId') ? errors.get('physioOrgId') : ''}
                isDisabled
                data-testid="physio-org-id-input"
              />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="braincare.organizations-edit.step-basic-info.input-label-address" />}
              htmlFor="address"
              data-testid="address-labeled-input"
            >
              <Input
                id="address"
                name="address"
                value={address || ''}
                onChange={(event) => setAddress(event.target.value)}
                hasError={Boolean(errors.get('address'))}
                errorMessage={errors.get('address') ? errors.get('address') : ''}
                data-testid="address-input"
              />
            </LabeledInput>
          </SlideRightFadeContainer>
        )}
      </Section>

      <div className={styles.buttons}>
        <Button
          type="primary"
          isDisabled={isLoading}
          isLoading={isSaving}
          onClick={validateRequiredFields}
          data-testid="save-button"
        >
          <I18n path="braincare.organizations-edit.buttons.button-save" />
        </Button>
        <Button isDisabled={isLoading} onClick={() => navigate('/braincare/organizations')} data-testid="cancel-button">
          <I18n path="braincare.organizations-edit.buttons.button-cancel" />
        </Button>
      </div>
    </App>
  );
}

export default braincare(AdminOrganizationInfo);
