import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from 'components/atoms/checkbox';
import styles from './styles.module.css';

const InputCheckbox = ({ className, id, label, isChecked, isDisabled, onChange, onClick, ...props }) => (
  <label htmlFor={id} className={classNames(styles.container, className)} {...props}>
    <Checkbox id={id} isChecked={isChecked} isDisabled={isDisabled} onChange={onChange} onClick={onClick} />
    <div className={classNames(styles.label, { [styles.disabled]: isDisabled })}>{label}</div>
  </label>
);

InputCheckbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

InputCheckbox.defaultProps = {
  className: '',
  id: undefined,
  label: undefined,
  isChecked: false,
  isDisabled: false,
  onChange: () => {},
  onClick: () => {},
};

export default InputCheckbox;
