import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'assets/styles/variables.css';
import 'assets/styles/normalize.css';
import 'assets/styles/index.css';
import ToastContainer from 'components/organisms/toast-container';
import NotFound from 'pages/404';
import Index from 'pages/Home';
import Support from 'pages/support';
import Security from 'pages/public/security';
import TermsAndService from 'pages/public/terms-and-service';
import PrivacyPolicy from 'pages/public/privacy-policy';
import ForgotPassword from 'pages/auth/forgot-password';
import DefinePassword from 'pages/auth/DefinePassword';
import Login from 'pages/auth/login';
import DirectLogin from 'pages/auth/DirectLogin';
import EduLogin from 'pages/auth/EduLogin';
import EduSignup from 'pages/auth/EduSignup';
import SignUp from 'pages/auth/Signup';
import SignUpSuccess from 'pages/auth/signup-success';
import AcceptTerms from 'pages/auth/accept-terms';
import Logout from 'pages/auth/logout';
import Accounts from 'pages/auth/accounts';
import CorporatePermissions from 'pages/account/member/edit/CorporatePermissions';
import CorporateProfileInfo from 'pages/account/member/edit/CorporateProfileInfo';
import AccountMemberEditPassword from 'pages/account/member/edit/CorporatePassword';
import AccountMeEditInfo from 'pages/account/me/edit/PersonalProfileInfo';
import AccountMeEditDocuments from 'pages/account/me/edit/Documents';
import AccountMeEditPassword from 'pages/account/me/edit/PersonalPassword';
import Physio from 'pages/physio';
import OrganizationMembers from 'pages/organization/members';
import MemberInfo from 'pages/organization/members/edit/steps/MemberInfo';
import MemberPermissions from 'pages/organization/members/edit/steps/MemberPermissions';
import MemberSuspension from 'pages/organization/members/edit/steps/MemberSuspension';
import OrganizationMembersEditPassword from 'pages/organization/members/edit/steps/password';
import OrganizationMembersCreate from 'pages/organization/members/create';
import BulkUpload from 'pages/organization/members/BulkUpload';
import OrganizationUnits from 'pages/organization/units';
import OrganizationUnitsEdit from 'pages/organization/units/edit';
import OrganizationUnitsEditMembers from 'pages/organization/units/edit/members';
import OrganizationUnitsCreate from 'pages/organization/units/create';
import OrganizationBraincareDevices from 'pages/organization/BraincareDevices';
import EditMobileDeviceStatus from 'pages/organization/mobile-devices/edit/EditMobileDeviceStatus';
import EditMobileDevicePinPolicy from 'pages/organization/mobile-devices/edit/EditMobileDevicePinPolicy';
import OrganizationMobileDevices from 'pages/organization/mobile-devices/list';
import CreateMobileDevice from 'pages/organization/mobile-devices/CreateMobileDevice';
import OrganizationMobileDeviceInfo from 'pages/organization/mobile-devices/list/MobileDeviceInfo';
import OrganizationPhysio from 'pages/organization/physio';
import AdminOrganizations from 'pages/braincare/AdminOrganizations';
import AdminOrganizationInfo from 'pages/braincare/AdminOrganizations/AdminOrganizationInfo';
import AdminOrganizationInactivityPolicy from 'pages/braincare/AdminOrganizations/AdminOrganizationInactivityPolicy';
import AdminOrganizationPasswordPolicy from 'pages/braincare/AdminOrganizations/AdminOrganizationPasswordPolicy';
import AdminOrganizationReportsPolicies from 'pages/braincare/AdminOrganizations/AdminOrganizationReportsPolicies';
import AdminUsers from 'pages/braincare/AdminUsers';
import AdminUsersCreate from 'pages/braincare/AdminUsers/AdminUsersCreate';
import AdminUsersSuspend from 'pages/braincare/AdminUsers/edit/AdminUsersSuspend';
import AdminUsersInfo from 'pages/braincare/AdminUsers/edit/AdminUsersInfo';
import AdminBraincareDevices from 'pages/braincare/AdminBraincareDevices';
import Logs from 'pages/Logs';
import AdminOrganizationMembers from 'pages/braincare/AdminOrganizations/members';
import AdminOrganizationMemberInfo from 'pages/braincare/AdminOrganizations/members/edit/steps/info';
import AdminOrganizationMemberPermissions from 'pages/braincare/AdminOrganizations/members/edit/steps/permissions';
import AdminOrganizationMemberSuspend from 'pages/braincare/AdminOrganizations/members/edit/steps/suspend';
import AdminOrganizationMemberPassword from 'pages/braincare/AdminOrganizations/members/edit/steps/password';
import RedefinitionDeviceMemberPin from 'pages/organization/mobile-devices/edit/MemberPinRedefinition';
import OrganizationPasswordPolicy from 'pages/organization/organization-settings/OrganizationPasswordPolicy';
import OrganizationInactivityPolicy from 'pages/organization/organization-settings/OrganizationInactivityPolicy';
import OrganizationReportsPolicies from 'pages/organization/organization-settings/OrganizationReportsPolicies';
import OrganizationInfo from 'pages/organization/organization-settings/OrganizationInfo';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'components/mui/theme';
import { Store } from 'store';
import withoutAuth from './components/routes/unsigned-route/withoutAuth';
import withAuth from './components/routes/signed-route/withAuth';
import Base from './pages/public/base';

const App = () => (
  <Provider store={Store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/auth/login" element={withoutAuth(<Login />)} />
          <Route path="/auth/direct-login" element={withoutAuth(<DirectLogin />)} />
          <Route path="/auth/edu/login" element={withoutAuth(<EduLogin />)} />
          <Route path="/auth/edu/signup" element={withoutAuth(<EduSignup />)} />
          <Route path="/auth/accounts" element={withAuth(<Accounts />)} />
          <Route path="/auth/accept-terms" element={withoutAuth(<AcceptTerms />)} />
          <Route path="/" element={<Base />}>
            <Route path="/404" element={<NotFound />} />
            <Route path="/public/security" element={<Security />} />
            <Route path="/public/terms-and-service" element={<TermsAndService />} />
            <Route path="/public/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/auth/forgot-password/:orgId?" element={<ForgotPassword />} />
            <Route exact path="/auth/define-password/:uid1/:uid2/:token" element={<DefinePassword />} />
            <Route path="/auth/signup" element={withoutAuth(<SignUp />)} />
            <Route path="/auth/signup-success" element={withoutAuth(<SignUpSuccess />)} />
            <Route path="/auth/logout" element={<Logout />} />
            <Route path="/account/member/edit/info" element={withAuth(<CorporateProfileInfo />)} />
            <Route path="/account/member/edit/permissions" element={withAuth(<CorporatePermissions />)} />
            <Route path="/account/member/edit/password" element={withAuth(<AccountMemberEditPassword />)} />
            <Route path="/account/me/edit/info" element={withAuth(<AccountMeEditInfo />)} />
            <Route path="/account/me/edit/documents" element={withAuth(<AccountMeEditDocuments />)} />
            <Route path="/account/me/edit/password" element={withAuth(<AccountMeEditPassword />)} />
            <Route path="/physio" element={withAuth(<Physio />)} />
            <Route exact path="/organization/members" element={withAuth(<OrganizationMembers />)} />
            <Route path="/organization/members/:memberId/edit/info" element={withAuth(<MemberInfo />)} />
            <Route path="/organization/members/:memberId/edit/permissions" element={withAuth(<MemberPermissions />)} />
            <Route path="/organization/members/:memberId/edit/suspend" element={withAuth(<MemberSuspension />)} />
            <Route
              path="/organization/members/:memberId/edit/password"
              element={withAuth(<OrganizationMembersEditPassword />)}
            />
            <Route path="/organization/members/create" element={withAuth(<OrganizationMembersCreate />)} />
            <Route path="/organization/members/bulk-upload-instructions" element={withAuth(<BulkUpload />)} />
            <Route exact path="/organization/units" element={withAuth(<OrganizationUnits />)} />
            <Route path="/organization/units/:unitId/edit" element={withAuth(<OrganizationUnitsEdit />)} />
            <Route path="/organization/units/:unitId/members" element={withAuth(<OrganizationUnitsEditMembers />)} />
            <Route path="/organization/units/create" element={withAuth(<OrganizationUnitsCreate />)} />
            <Route exact path="/organization/devices" element={withAuth(<OrganizationBraincareDevices />)} />
            <Route path="/org-mobile-devices/devices/add" element={withAuth(<CreateMobileDevice />)} />
            <Route
              path="/org-mobile-devices/devices/:deviceId/edit-status"
              element={withAuth(<EditMobileDeviceStatus />)}
            />
            <Route
              path="/org-mobile-devices/devices/:deviceId/pin-policy"
              element={withAuth(<EditMobileDevicePinPolicy />)}
            />
            <Route
              path="/org-mobile-devices/devices/:deviceId/pin-edit"
              element={withAuth(<RedefinitionDeviceMemberPin />)}
            />
            <Route exact path="/org-mobile-devices/devices" element={withAuth(<OrganizationMobileDevices />)} />
            <Route
              path="/org-mobile-devices/devices/:deviceId/info"
              element={withAuth(<OrganizationMobileDeviceInfo />)}
            />
            <Route exact path="/organization/physio" element={withAuth(<OrganizationPhysio />)} />
            <Route exact path="/braincare/organizations" element={withAuth(<AdminOrganizations />)} />
            <Route path="/braincare/organizations/:orgId/edit/info" element={withAuth(<AdminOrganizationInfo />)} />
            <Route
              path="/braincare/organizations/:orgId/edit/inactivity-policy"
              element={withAuth(<AdminOrganizationInactivityPolicy />)}
            />
            <Route
              path="/braincare/organizations/:orgId/edit/password-policy"
              element={withAuth(<AdminOrganizationPasswordPolicy />)}
            />
            <Route
              path="/braincare/organizations/:orgId/edit/reports"
              element={withAuth(<AdminOrganizationReportsPolicies />)}
            />
            <Route exact path="/braincare/users" element={withAuth(<AdminUsers />)} />
            <Route exact path="/braincare/users/create" element={withAuth(<AdminUsersCreate />)} />
            <Route path="/braincare/users/:userId/info" element={withAuth(<AdminUsersInfo />)} />
            <Route path="/braincare/users/:userId/suspend" element={withAuth(<AdminUsersSuspend />)} />
            <Route exact path="/braincare/devices" element={withAuth(<AdminBraincareDevices />)} />
            <Route exact path="/" element={withAuth(<Index />)} />
            <Route exact path="/support" element={<Support />} />
            <Route exact path="/logs" element={withAuth(<Logs />)} />
            <Route
              exact
              path="/braincare/organizations/:orgId/members"
              element={withAuth(<AdminOrganizationMembers />)}
            />
            <Route
              exact
              path="/braincare/organizations/:orgId/members/:memberId/edit/info"
              element={withAuth(<AdminOrganizationMemberInfo />)}
            />
            <Route
              exact
              path="/braincare/organizations/:orgId/members/:memberId/edit/permissions"
              element={withAuth(<AdminOrganizationMemberPermissions />)}
            />
            <Route
              exact
              path="/braincare/organizations/:orgId/members/:memberId/edit/suspend"
              element={withAuth(<AdminOrganizationMemberSuspend />)}
            />
            <Route
              exact
              path="/braincare/organizations/:orgId/members/:memberId/edit/password"
              element={withAuth(<AdminOrganizationMemberPassword />)}
            />
            <Route exact path="/organization/info" element={withAuth(<OrganizationInfo />)} />
            <Route exact path="/organization/password-policy" element={withAuth(<OrganizationPasswordPolicy />)} />
            <Route exact path="/organization/inactivity-policy" element={withAuth(<OrganizationInactivityPolicy />)} />
            <Route exact path="/organization/reports" element={withAuth(<OrganizationReportsPolicies />)} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </ThemeProvider>
  </Provider>
);

export default App;
