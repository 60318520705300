import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const Brand = ({ image, title, className, href }) => {
  const renderImage = () => (
    <h1 className={classNames(styles.heading, className)}>
      <img src={image} alt={title} className={styles.brand} />
    </h1>
  );

  return href ? (
    <Link to={href} className={styles.link}>
      {renderImage()}
    </Link>
  ) : (
    renderImage()
  );
};

Brand.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
};

Brand.defaultProps = {
  className: undefined,
  href: undefined,
};

export default Brand;
