import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Tag from 'components/atoms/tag-button';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Label from 'components/atoms/input-label';
import InputGroup from 'components/atoms/input-group';
import Input from 'components/atoms/input';
import Button from 'components/atoms/button';
import Card from 'components/molecules/member-card';
import Loader from 'components/molecules/section-loader';
import Pagination from 'components/organisms/pagination/index';
import I18n, { translate } from 'utils/i18n';
import { MEMBER_STATUS } from 'utils/members';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationData, setOrganizationRoles, setOrganizationSelectedMember } from 'features/organizationSlice';
import { fetchMembers, fetchRoles, fetchUnits } from 'api/portal';
import { addToast } from 'actions/toasts';
import Text from 'components/atoms/text';
import styles from './styles.module.css';

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_ORGANIZATION = <I18n path="organization.breadcrumbs.manage-organization" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/organization/members',
    title: MANAGE_ORGANIZATION,
    isActive: true,
  },
];

const linkItems = [
  {
    path: '/organization/members',
    title: <I18n path="organization.organization-members.title-sub-member" />,
  },
  {
    path: '/organization/units',
    title: <I18n path="organization.organization-units.title-sub-unit-edit" />,
  },
];

const actionButton = {
  text: <I18n path="organization.organization-members.button-add-member" />,
  href: '/organization/members/create',
};

const secondActionButton = {
  text: <I18n path="organization.organization-members.button-bulk-upload-member" />,
  href: '/organization/members/bulk-upload-instructions',
};

const ERROR_UNITS = <I18n path="messages.error-units" />;
const ERROR_ROLES = <I18n path="messages.error-roles" />;

function Members() {
  const [members, setMembers] = useState([]);
  const [units, setUnits] = useState([]);
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [isLoadingMembers, setIsLoadingMembers] = useState(true);
  const [errorMembers, setErrorMembers] = useState(false);

  const roles = useSelector((state) => state.organization.roles);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchMembers = (searchParams) => {
    setIsLoadingMembers(true);
    setErrorMembers(false);

    fetchMembers(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoadingMembers(false);
        setErrorMembers(false);

        setMembers(data.results);
        setRows(data.count);
        setCurrentPage(Number(searchParams.get('page')));
      })
      .catch(() => {
        setIsLoadingMembers(false);
        setErrorMembers(true);
      });
  };

  const fetchMembersPage = (page) => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', 8);
    searchParams.set('unit', selectedUnit);
    searchParams.set('status', selectedStatus);
    searchParams.set('role', selectedRole);
    searchParams.set('search_name', filterValue);

    searchMembers(searchParams);
  };

  const onClearFilterClick = () => {
    setSelectedUnit('');
    setSelectedStatus('');
    setSelectedRole('');
    setFilterValue('');

    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', 8);

    searchMembers(searchParams);
  };

  useEffect(() => {
    dispatch(clearOrganizationData());
    fetchMembersPage(1);

    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchUnits(searchParams)
      .then((response) => {
        const { data } = response;
        setUnits(data.results);
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_UNITS));
      });

    fetchRoles()
      .then((response) => {
        const { data } = response;
        dispatch(setOrganizationRoles(data));
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_ROLES));
      });
  }, []);

  const openEditMemberPage = (id) => {
    const member = members.find((index) => index.id === id);
    dispatch(setOrganizationSelectedMember(member));
    navigate(`/organization/members/${id}/edit/info`);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const renderResults = () => {
    if (errorMembers) {
      return (
        <div className={styles.errorMessage} data-testid="error-members">
          <Text isError>
            <I18n path="messages.error-members" />
          </Text>
        </div>
      );
    }

    return (
      <>
        <SlideFadeInContainer className={styles.memberList}>
          {members.length === 0 ? (
            <div className={styles.notFoundText}>
              <I18n path="organization.organization-members.no-results-found" />
            </div>
          ) : null}
          {members.map((member) => (
            <Card
              key={`member-${member.id}`}
              status={member.status}
              onClick={() => openEditMemberPage(member.id)}
              className={styles.card}
              data-testid={`member-card-${member.id}`}
            >
              <div className={styles.cardHeader}>
                <Card.Header name={`${member.user.first_name} ${member.user.last_name}`} image={member.user.photo} />
              </div>
              <Card.Body>
                <div>
                  <p className={styles.cardSubtitle}>
                    <I18n path="organization.organization-members.card-member-roles" />
                  </p>
                  <p className={styles.cardContent}>
                    {member.roles
                      .map((role) => translate(`organization.organization-members.card-member-role-${role.id}`))
                      .join(', ')}
                  </p>
                </div>
                <div>
                  <p className={styles.cardSubtitle}>
                    <I18n path="organization.organization-members.card-member-units" />
                  </p>
                  <div>
                    {member.units.slice(0, 4).map((unit) => (
                      <Tag key={`tag-${member.id}-${unit.name}`} isSelected>
                        {unit.name}
                      </Tag>
                    ))}
                    {member.units.length > 4 && <Tag isSelected>+ {member.units.length - 4}</Tag>}
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}
        </SlideFadeInContainer>
        <Pagination
          rows={rows}
          rowsPerPage={8}
          currentPage={currentPage}
          onPageSelect={fetchMembersPage}
          maxPagesDisplay={3}
        />
      </>
    );
  };

  return (
    <App
      title={<I18n path="organization.organization-members.title-main-member" />}
      breadcrumbs={breadcrumbs}
      linkItems={linkItems}
      actionButton={actionButton}
      secondActionButton={secondActionButton}
    >
      <Section>
        <Subtitle>
          <I18n path="organization.organization-members.title-filter-members" />
        </Subtitle>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
          <div className={styles.filters}>
            <InputGroup className={styles.filterInputs}>
              <Label htmfor="unit">
                <I18n path="organization.organization-members.input-filter-unit" />
              </Label>
              <Select id="unit" onChange={(event) => setSelectedUnit(event.target.value)} value={selectedUnit}>
                <Option value="">{translate('organization.organization-members.dropdown-filter-unit')}</Option>
                {units.map((unit) => (
                  <Option key={`unit-${unit.id}`} value={unit.id.toString()} data-testid={`unit-option-${unit.id}`}>
                    {unit.name}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="status">{translate('organization.organization-members.input-filter-protocol')}</Label>
              <Select id="status" onChange={(event) => setSelectedStatus(event.target.value)} value={selectedStatus}>
                <Option value="">{translate('organization.organization-members.dropdown-filter-protocol')}</Option>
                {MEMBER_STATUS.map((status) => (
                  <Option key={`status-${status.id}`} value={status.id} data-testid={`status-option-${status.id}`}>
                    {status.text}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="role">
                <I18n path="organization.organization-members.input-filter-doctor" />
              </Label>
              <Select id="role" onChange={(event) => setSelectedRole(event.target.value)} value={selectedRole}>
                <Option value="">{translate('organization.organization-members.dropdown-filter-doctor')}</Option>
                {roles.map((role) => (
                  <Option key={`role-${role.id}`} value={role.id.toString()} data-testid={`role-option-${role.id}`}>
                    <I18n path={`organization.organization-members.card-member-role-${role.id}`} />
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="filter">
                <I18n path="organization.organization-members.input-filter-name" />
              </Label>
              <Input
                id="filter"
                placeholder={translate('organization.organization-members.dropdown-filter-name')}
                onChange={(event) => setFilterValue(event.target.value)}
                value={filterValue}
                data-testid="name-filter-input"
              />
            </InputGroup>
            <Button onClick={onClearFilterClick} data-testid="reset-button">
              <I18n path="organization.organization-members.button-filter-reset" />
            </Button>
            <Button onClick={() => fetchMembersPage(1)} type="primary" data-testid="apply-button">
              <I18n path="organization.organization-members.button-filter-apply" />
            </Button>
          </div>
        </form>
        {isLoadingMembers ? <Loader /> : renderResults()}
      </Section>
    </App>
  );
}

export default Members;
