import PropTypes from 'prop-types';
import classnames from 'classnames';
import IcoMoon from 'assets/static/icomoon.json';
import styles from './styles.module.css';

const TYPE = {
  default: styles.default,
  primary: styles.primary,
  warning: styles.warning,
  success: styles.success,
  danger: styles.danger,
  ghost: styles.ghost,
};

const getPath = (name) => {
  const index = IcoMoon.selection.findIndex((selection) => selection.name === name);
  if (index !== -1) {
    return IcoMoon.icons[index].paths.join(' ');
  }
  return null;
};

const Icon = ({ name, type, className }) => (
  <svg className={classnames(styles.icon, TYPE[type], className)} width="22" height="22" viewBox="0 0 1024 1024">
    <path d={getPath(name)} />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

Icon.defaultProps = {
  name: undefined,
  type: 'default',
  className: undefined,
};

export default Icon;
