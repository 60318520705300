import I18n from 'utils/i18n';
import PasswordValidator from './PasswordValidator';

export default class DigitsValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { digitsRange } = passwordPolicy;

    const { min, max } = digitsRange;

    let translation = null;

    if (min !== undefined && max !== undefined) {
      translation = <I18n props={{ min, max }} path="organization.password-rules.digits-constraint-1" />;
    } else if (min !== undefined) {
      translation = <I18n props={{ min }} path="organization.password-rules.digits-constraint-2" />;
    } else if (max !== undefined) {
      translation = <I18n props={{ max }} path="organization.password-rules.digits-constraint-3" />;
    }

    super(digitsRange, 'digits_range', false, translation);
  }

  validate(password) {
    if (this.policy) {
      const digitsChars = password.match(/\d/g);

      const min = this.policy.min ? this.policy.min : 0;
      const max = this.policy.max ? this.policy.max : 128;

      const numberDigits = digitsChars ? digitsChars.length : 0;

      if (min > numberDigits || max < numberDigits) {
        return false;
      }
    }

    return true;
  }
}
