import { useState } from 'react';
import { addToast } from 'actions/toasts';
import Text from 'components/atoms/text';
import Auth from 'components/templates/auth';
import Button from 'components/atoms/button';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import AuthTitle from 'components/atoms/auth-title';
import I18n from 'utils/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllTokens } from 'utils/tokens';
import { clearProfileData, setOrganizations } from 'features/userSlice';
import { acceptTerms, fetchProfiles } from 'api/portal';
import styles from './styles.module.css';
import PrivacyPolicyPortuguese from '../../public/privacy-policy/pt';
import TermsAndServicePortuguese from '../../public/terms-and-service/pt';
import TermsAndServiceEnglish from '../../public/terms-and-service/en';
import PrivacyPolicyEnglish from '../../public/privacy-policy/en';

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const UPDATE_FAILED = <I18n path="messages.accept-terms-failed" />;

function AcceptTerms() {
  const [loadMessage, setLoadMessage] = useState(true);
  const [readPrivacyPolicy, setReadPrivacyPolicy] = useState(false);
  const [readTermsAndConditions, setReadTermsAndConditions] = useState(false);
  const [searchParams] = useSearchParams();

  const user = useSelector((state) => state.user);

  const { profile } = user;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getOrganizationProfiles = () => {
    const isBraincareUser = Boolean(profile.braincare_permissions.length);

    fetchProfiles()
      .then((response) => {
        const { data } = response;

        dispatch(setOrganizations(data));

        const next = searchParams.get('next');

        if (next) {
          navigate(next);
          return;
        }

        if (data.length || isBraincareUser) {
          navigate('/auth/accounts');
          return;
        }

        navigate('/');
      })
      .catch(() => {
        dispatch(clearProfileData());
        clearAllTokens();
        dispatch(addToast('error', LOAD_FAILURE));
        navigate('/');
      });
  };

  const updateTerms = () => {
    acceptTerms()
      .then(() => {
        getOrganizationProfiles();
      })
      .catch(() => {
        dispatch(addToast('error', UPDATE_FAILED));
      });
  };

  const cancel = () => {
    dispatch(clearProfileData());
    clearAllTokens();
    navigate('/');
  };

  const renderMessage = () => (
    <Auth className={styles.container}>
      <Section maxWidth="512px">
        <Subtitle>
          <I18n path="auth.accept-terms.title" />
        </Subtitle>
        <Text size="big">
          <br />
          <I18n path="auth.accept-terms.text" />
          <br />
        </Text>

        <Button
          type="success"
          isBlock
          isLarge
          className={styles.button}
          onClick={() => setLoadMessage(false)}
          data-testid="continue-button"
        >
          <I18n path="auth.accept-terms.button-continue" />
        </Button>

        <Button type="danger" isBlock isLarge className={styles.button} onClick={cancel} data-testid="cancel-button">
          <I18n path="auth.accept-terms.button-cancel" />
        </Button>
      </Section>
    </Auth>
  );

  const renderTerms = () => (
    <Auth className={styles.container} maxWidth="90vw">
      <Section>
        {user.language === 'pt-br' ? <TermsAndServicePortuguese /> : <TermsAndServiceEnglish />}

        <div className={styles['buttons-container']}>
          <Button
            type="primary"
            className={styles.button}
            onClick={() => setReadTermsAndConditions(true)}
            data-testid="accept-terms-button"
          >
            <I18n path="auth.accept-terms.button-accept" />
          </Button>

          <Button type="danger" className={styles.button} onClick={cancel} data-testid="cancel-terms-button">
            <I18n path="auth.accept-terms.button-cancel" />
          </Button>
        </div>
      </Section>
    </Auth>
  );

  const renderPolicy = () => {
    window.scrollTo(0, 0);
    return (
      <Auth className={styles.container} maxWidth="90vw">
        <Section>
          {user.language === 'pt-br' ? <PrivacyPolicyPortuguese /> : <PrivacyPolicyEnglish />}

          <div className={styles['buttons-container']}>
            <Button
              type="primary"
              className={styles.button}
              onClick={() => {
                setReadPrivacyPolicy(true);
                updateTerms();
              }}
              data-testid="accept-privacy-policy-button"
            >
              <I18n path="auth.accept-terms.button-accept" />
            </Button>

            <Button type="danger" className={styles.button} onClick={cancel} data-testid="cancel-privacy-policy-button">
              <I18n path="auth.accept-terms.button-cancel" />
            </Button>
          </div>
        </Section>
      </Auth>
    );
  };

  if (loadMessage) {
    return renderMessage();
  }

  if (!readTermsAndConditions) {
    return renderTerms();
  }

  if (!readPrivacyPolicy) {
    return renderPolicy();
  }

  return (
    <Auth className={styles.container}>
      <Section maxWidth="512px">
        <AuthTitle>
          <I18n path="auth.accept-terms.thanks-title" />
        </AuthTitle>
        <Text size="big">
          <I18n path="auth.accept-terms.thanks-text" />
        </Text>
      </Section>
    </Auth>
  );
}

export default AcceptTerms;
