import BCAppOrgAdmin from 'assets/images/braincare-user/app-org-admin.png';
import BCAppUserAdmin from 'assets/images/braincare-user/app-user-admin.png';
import BCAppDeviceAdmin from 'assets/images/braincare-user/app-device-admin.png';
import BCAppLogAdmin from 'assets/images/braincare-user/app-log-admin.png';

export const BRAINCARE_USER_BUSINESS_SUPPORT = 'BC_BUSINESS_SUPPORT';
export const BRAINCARE_USER_ACCOUNTS_ADMIN = 'BC_ACCOUNTS_ADMIN';
export const BRAINCARE_USER_USERS_ADMIN = 'BC_USERS_ADMIN';
export const BRAINCARE_USER_DEVICE_SUPPORT = 'BC_DEVICE_SUPPORT';

export const braincareApps = [
  {
    id: 0,
    href: '/braincare/users',
    image: BCAppUserAdmin,
    name: 'applications.braincare.user-admin',
    permission: BRAINCARE_USER_USERS_ADMIN,
  },
  {
    id: 1,
    href: '/braincare/organizations',
    image: BCAppOrgAdmin,
    name: 'applications.braincare.org-admin',
    permission: BRAINCARE_USER_ACCOUNTS_ADMIN,
  },
  {
    id: 2,
    href: '/braincare/devices',
    image: BCAppDeviceAdmin,
    name: 'applications.braincare.device-admin',
    permission: BRAINCARE_USER_DEVICE_SUPPORT,
  },
  {
    id: 4,
    href: '/logs',
    image: BCAppLogAdmin,
    name: 'applications.braincare.log-admin',
    permission: BRAINCARE_USER_ACCOUNTS_ADMIN,
  },
];
