import Auth from 'components/templates/auth';
import AuthTitle from 'components/atoms/auth-title';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import Section from 'components/atoms/section';
import I18n from 'utils/i18n';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import styles from './styles.module.css';

function NotFound() {
  const navigate = useNavigate();

  return (
    <Auth>
      <Section>
        <SlideFadeInContainer>
          <Box display="flex" flexDirection="column" textAlign="center" justifyContent="center" alignItems="center">
            <AuthTitle>
              <I18n path="not-found.title" />
            </AuthTitle>
            <Text>
              <I18n path="not-found.text" />
            </Text>
            <Button className={styles.button} type="primary" onClick={() => navigate('/')}>
              <I18n path="not-found.button-back" />
            </Button>
          </Box>
        </SlideFadeInContainer>
      </Section>
    </Auth>
  );
}

export default NotFound;
