import I18n from 'utils/i18n';
import PasswordValidator from './PasswordValidator';

export default class PasswordReuseValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const max = passwordPolicy.passwordReuse;

    let translation = null;

    if (max !== undefined) {
      translation = <I18n props={{ max }} path="organization.password-rules.password-reuse" />;
    }

    super(max, 'password_reuse', true, translation);
  }
}
