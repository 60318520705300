import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: 13,
    color: theme.palette.primary.main,
    '&.Mui-error': {
      color: theme.palette.error.light,
    },
  },
}));

export default FormControlLabel;
