import Cookie from 'js-cookie';
import { Store } from 'store';

export default () => {
  let language = Cookie.get('language');
  if (!language) {
    language = Store.getState().user.language;
    Cookie.set('language', language, { sameSite: 'strict', expires: 365 });
  }

  return language;
};
