import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  position: relative;
  width: 100%;
  padding: 16px 24px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  transition: all 200ms ease;
  border: none;
  cursor: pointer;
`;

export const Icon = styled.i`
  display: block;
  height: 15px;
  width: 15px;
  font-size: 14px;
  margin-right: 16px;
`;
