import { css } from 'styled-components';

export const Card = css`
  cursor: pointer;
  transition: var(--transition-default);
  border-radius: var(--border-half);
  box-shadow: var(--shadow-default);
  border: 1px solid var(--color-grey-light);

  &:hover {
    border-color: var(--color-primary-light);
  }

  &:active {
    border-color: var(--color-grey);
  }
`;

export const Separator = css`
  border-top: 1px solid var(--color-grey-light);
`;

export const Container = css`
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
`;
