import PropTypes from 'prop-types';
import { ptbrStringFromString } from 'utils/format-date-time';
import logDetails from 'assets/images/log-details.png';
import Avatar from 'components/atoms/avatar';
import Table, { Head, Body, Row, Cell } from 'components/atoms/table';
import I18n from 'utils/i18n';
import classNames from 'classnames';
import styles from './styles.module.css';

function LogTable({ logObjs = undefined, membersMap, operationsMap, onClick }) {
  return (
    <Table>
      <Head>
        <Row className={styles.row}>
          <Cell isHead align="left" className={styles.cell}>
            <I18n path="logs.activities-report.table-header.date" />
          </Cell>
          <Cell isHead align="left" className={styles.cell}>
            <I18n path="logs.activities-report.table-header.user" />
          </Cell>
          <Cell isHead align="left" className={styles.cell}>
            <I18n path="logs.activities-report.table-header.action" />
          </Cell>
          <Cell isHead align="left" className={styles.cell}>
            <I18n path="logs.activities-report.table-header.data" />
          </Cell>
          <Cell isHead align="left" className={styles.cell}>
            <I18n path="logs.activities-report.table-header.resource" />
          </Cell>
          <Cell isHead align="left" className={styles.cell}>
            <I18n path="logs.activities-report.table-header.ip" />
          </Cell>
          <Cell isHead align="left" className={styles.cell}>
            &nbsp;
          </Cell>
        </Row>
      </Head>
      <Body>
        {logObjs &&
          logObjs.map((logObj) => (
            <Row className={styles.row} key={logObj['@ptr']} data-testid={logObj['@ptr']}>
              <Cell className={classNames(styles.cell, styles.bodyCell, styles.dateColumn)}>
                {ptbrStringFromString(`${logObj.timestamp}`)}
              </Cell>
              <Cell className={classNames(styles.cell, styles.bodyCell, styles.userColumn)}>
                {membersMap.get(Number(logObj.user_id))}
              </Cell>
              <Cell className={classNames(styles.cell, styles.bodyCell, styles.actionColumn)}>
                {operationsMap.get(logObj.action)}
              </Cell>
              <Cell className={classNames(styles.cell, styles.bodyCell, styles.dataColumn)}>
                <div className={styles.dataDiv}>{logObj.additional_data}</div>
              </Cell>
              <Cell className={classNames(styles.cell, styles.bodyCell, styles.resourceColumn)}>
                {logObj.resource.replace('/api/v1/', '')}
              </Cell>
              <Cell className={classNames(styles.cell, styles.bodyCell, styles.ipColumn)}>{logObj.ip}</Cell>
              <Cell
                className={classNames(styles.cell, styles.bodyCell, styles.detailsColumn)}
                onClick={() => {
                  onClick(logObj);
                }}
              >
                <div className={styles.avatarDiv} data-testid="details-button">
                  <Avatar image={logDetails} size="xsmall" />
                </div>
              </Cell>
            </Row>
          ))}
      </Body>
    </Table>
  );
}

LogTable.propTypes = {
  logObjs: PropTypes.arrayOf(PropTypes.object.isRequired),
  membersMap: PropTypes.object.isRequired,
  operationsMap: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LogTable;
