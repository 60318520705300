const TRUSTED = 'TRUSTED';
const NORMAL = 'NORMAL';
const BLOCKED = 'BLOCKED';

export const STATUS_CHOICES = new Map([
  [TRUSTED, 'Trusted'],
  [NORMAL, 'Normal'],
  [BLOCKED, 'Blocked'],
]);

export function validateDeviceStatus(status) {
  return STATUS_CHOICES.has(status);
}

export function isTrustedDevice(status) {
  return status === TRUSTED;
}
