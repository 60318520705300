import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ListItem from 'components/atoms/list-item';
import styles from './styles.module.css';

const NavItem = ({ href, icon, isActive, children }) =>
  href ? (
    <Link className={classNames(styles.link, { [styles.linkActive]: isActive })} to={href}>
      <ListItem icon={icon}>{children}</ListItem>
    </Link>
  ) : (
    <div className={classNames(styles.link, { [styles.linkActive]: isActive })}>
      <ListItem icon={icon}>{children}</ListItem>
    </div>
  );

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

NavItem.defaultProps = {
  href: undefined,
  icon: undefined,
  isActive: false,
};

export default NavItem;
