import { useEffect, useState } from 'react';
import _ from 'lodash';
import Hint from 'components/atoms/input-hint';
import Spinner from 'components/atoms/spinner';
import { addToast } from 'actions/toasts';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Option from 'components/atoms/option';
import Select from 'components/atoms/input-select';
import Text from 'components/atoms/text';
import InputDatePicker from 'components/molecules/input-date-picker';
import LabeledInput from 'components/molecules/labeled-input';
import Loader from 'components/molecules/section-loader';
import I18n, { translate } from 'utils/i18n';
import { validateISOCompleteDate } from 'utils/validate-date';
import { formatFromString, stringFromDate, getValidDate } from 'utils/format-date';
import formatTime from 'utils/format-time';
import { dateFromString } from 'utils/format-date-time';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogs, fetchMemberHistory, fetchOrganizations } from 'api/portal';
import LogDialog from './dialog/LogDialog';
import LogTable from './table';
import styles from './styles.module.css';

const INVALID_DATE_ERROR = <I18n path="logs.activities-report.messages.invalid-date" />;
const MAXIMUM_DATE_ERROR = <I18n path="logs.activities-report.messages.maximum-date" />;
const FETCH_DATA_FAILURE = <I18n path="messages.fetch-data-failure" />;
const FILL_REQUIRED = <I18n path="logs.activities-report.messages.fill-required" />;
const LOADING_ORGANIZATIONS = <I18n path="logs.activities-report.loading-organizations" />;
const LOADING_MEMBERS = <I18n path="logs.activities-report.loading-members" />;

const DEFAULT_MEMBER = { id: '', name: <I18n path="logs.activities-report.default-value-input-user" /> };
const DEFAULT_OPERATION = {
  id: '',
  action: '',
  description: <I18n path="logs.activities-report.default-value-input-operation" />,
};
const ACCOUNT_MANAGER_ROLE_NAME = 'Gestor de Contas';

function Logs() {
  const user = useSelector((state) => state.user);

  const { profile, isBraincareUser } = user;
  const organizationProfile = user.organization_profile;
  const activeOrganization = user.active_organization;

  const [accountType, setAccountType] = useState(undefined);

  const [searchDate, setSearchDate] = useState('');
  const [date, setDate] = useState(undefined);
  const [isValidDate, setIsValidDate] = useState(true);

  const [time, setTime] = useState('');

  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState('');

  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [members, setMembers] = useState([DEFAULT_MEMBER]);
  const [membersMap, setMembersMap] = useState(new Map([[DEFAULT_MEMBER.id, DEFAULT_MEMBER.name]]));
  const [member, setMember] = useState('');

  const [operations, setOperations] = useState([DEFAULT_OPERATION]);
  const [operationsMap, setOperationsMap] = useState(new Map([[DEFAULT_OPERATION.id, DEFAULT_OPERATION.description]]));
  const [operation, setOperation] = useState('');

  const [errors, setErrors] = useState(new Map());

  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [logObjects, setLogObjects] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);

  const [isLoadMoreButtonLoading, setIsLoadMoreButtonLoading] = useState(false);
  const [searchedData, setSearchedData] = useState(undefined);

  const [dialogLogObject, setDialogLogObject] = useState(null);

  const dispatch = useDispatch();

  const getOperations = () => {
    const logOperations = [
      {
        action: 'update-personal-data',
        description: translate('logs.activities-report.default-operations.update-personal-data'),
      },
      {
        action: 'update-password',
        description: translate('logs.activities-report.default-operations.update-password'),
      },
      {
        action: 'view-doctor-list',
        description: translate('logs.activities-report.default-operations.view-doctor-list'),
      },
      {
        action: 'view-notifications',
        description: translate('logs.activities-report.default-operations.view-notifications'),
      },
      {
        action: 'view-invitations',
        description: translate('logs.activities-report.default-operations.view-invitations'),
      },
      {
        action: 'update-invitation-status-accepted',
        description: translate('logs.activities-report.default-operations.update-invitation-status-accepted'),
      },
      {
        action: 'update-invitation-status-declined',
        description: translate('logs.activities-report.default-operations.update-invitation-status-declined'),
      },
      {
        action: 'view-user-personal-data',
        description: translate('logs.activities-report.default-operations.view-user-personal-data'),
      },
      { action: 'add-member', description: translate('logs.activities-report.default-operations.add-member') },
      {
        action: 'update-member-data',
        description: translate('logs.activities-report.default-operations.update-member-data'),
      },
      {
        action: 'update-member-status',
        description: translate('logs.activities-report.default-operations.update-member-status'),
      },
      {
        action: 'delete-member',
        description: translate('logs.activities-report.default-operations.delete-member'),
      },
      {
        action: 'add-user-signup',
        description: translate('logs.activities-report.default-operations.add-user-signup'),
      },
      {
        action: 'activate-account',
        description: translate('logs.activities-report.default-operations.activate-account'),
      },
      {
        action: 'reset-password',
        description: translate('logs.activities-report.default-operations.reset-password'),
      },
      {
        action: 'request-reset-password',
        description: translate('logs.activities-report.default-operations.request-reset-password'),
      },
      {
        action: 'request-bulk-upload',
        description: translate('logs.activities-report.default-operations.request-bulk-upload'),
      },
      {
        action: 'add-member-bulk',
        description: translate('logs.activities-report.default-operations.add-member-bulk'),
      },
      {
        action: 'add-signup-user-bulk',
        description: translate('logs.activities-report.default-operations.add-signup-user-bulk'),
      },
      {
        action: 'add-unit',
        description: translate('logs.activities-report.default-operations.add-unit'),
      },
      {
        action: 'delete-unit',
        description: translate('logs.activities-report.default-operations.delete-unit'),
      },
      {
        action: 'update-unit-members',
        description: translate('logs.activities-report.default-operations.update-unit-members'),
      },
      {
        action: 'login',
        description: translate('logs.activities-report.default-operations.login'),
      },
      {
        action: 'session-refresh',
        description: translate('logs.activities-report.default-operations.session-refresh'),
      },
      {
        action: 'view-acquisitions-list',
        description: translate('logs.activities-report.default-operations.view-acquisitions-list'),
      },
      {
        action: 'view-patient-data',
        description: translate('logs.activities-report.default-operations.view-patient-data'),
      },
      {
        action: 'view-session-data',
        description: translate('logs.activities-report.default-operations.view-session-data'),
      },
      {
        action: 'request-report-authorization',
        description: translate('logs.activities-report.default-operations.request-report-authorization'),
      },
      {
        action: 'view-report',
        description: translate('logs.activities-report.default-operations.view-report'),
      },
      {
        action: 'update-share-report',
        description: translate('logs.activities-report.default-operations.update-share-report'),
      },
      {
        action: 'request-encrypted-patients-data',
        description: translate('logs.activities-report.default-operations.request-encrypted-patients-data'),
      },
      {
        action: 'request-org-key',
        description: translate('logs.activities-report.default-operations.request-org-key'),
      },
      {
        action: 'request-current-user',
        description: translate('logs.activities-report.default-operations.request-current-user'),
      },
      {
        action: 'request-share-link',
        description: translate('logs.activities-report.default-operations.request-share-link'),
      },
      {
        action: 'add-acquisition-patient-data',
        description: translate('logs.activities-report.default-operations.add-acquisition-patient-data'),
      },
      {
        action: 'member-auto-suspension',
        description: translate('logs.activities-report.default-operations.member-auto-suspension'),
      },
    ];

    let operations = logOperations.map((el, index) => ({ ...el, id: String(index) }));
    operations = operations.sort((a, b) => (a.description > b.description ? 1 : -1));
    operations.unshift(DEFAULT_OPERATION);
    return operations;
  };

  const handleOrganizationsFetched = (orgs) => {
    const noOrganization = {
      id: -1,
      name: <I18n path="logs.activities-report.personal-logs-item" />,
      orgId: '',
    };

    const organizations = orgs.map((obj) => ({
      id: obj.id,
      name: `${obj.name} (${obj.physio_org_id})`,
      orgId: obj.physio_org_id,
    }));
    const sortedOrganizations = organizations.sort((a, b) => (a.name > b.name ? 1 : -1));

    setOrganizations([noOrganization, ...sortedOrganizations]);
    setOrganization(-1);
  };

  const onFetchDataFailure = () => dispatch(addToast('error', FETCH_DATA_FAILURE));

  const fetchOrganizationsHandler = () => {
    setIsLoadingOrganizations(true);
    fetchOrganizations(new URLSearchParams())
      .then((response) => {
        const { data } = response;

        if (data && !data.error) {
          if (!data.next) {
            setIsLoadingOrganizations(false);
            handleOrganizationsFetched(data.results);
            return;
          }

          const pageLength = data.results.length;
          const nOrganizations = data.count;
          const nRemainingPages = Math.floor((nOrganizations - 1) / pageLength);

          const promises = [...Array(nRemainingPages).keys()].map((el) => {
            const searchParams = new URLSearchParams();

            searchParams.set('page', el + 2);

            return fetchOrganizations(searchParams);
          });

          Promise.all(promises)
            .then((response) => {
              const notError = response.every((item) => item.data && !item.data.error);
              if (notError) {
                const orgArrays = response.map((el) => el.data.results);
                setIsLoadingOrganizations(false);
                handleOrganizationsFetched([...data.results, ...orgArrays.flat(1)]);
                return;
              }
              setIsLoadingOrganizations(false);
              onFetchDataFailure();
            })
            .catch(() => {
              setIsLoadingOrganizations(false);
              onFetchDataFailure();
            });
        } else {
          setIsLoadingOrganizations(false);
          onFetchDataFailure();
        }
      })
      .catch(() => {
        setIsLoadingOrganizations(false);
        onFetchDataFailure();
      });
  };

  const filterAccountType = () => {
    if (isBraincareUser) {
      setAccountType('BraincareUser');
      fetchOrganizationsHandler();
    } else if (activeOrganization.id === -1) {
      const members = [{ id: profile.id, name: `${profile.first_name} ${profile.last_name}` }];
      setMember(profile.id);
      setMembers(members);
      setMembersMap(new Map([[members[0].id, members[0].name]]));
      setAccountType('PersonalAccount');
    } else {
      const isAccountManager =
        organizationProfile.roles.filter((obj) => obj.name === ACCOUNT_MANAGER_ROLE_NAME).length > 0;

      if (isAccountManager) {
        setOrganization(0);
        setOrganizations([{ id: 0, name: '', orgId: activeOrganization.physio_org_id }]);
        setAccountType('AccountManager');
      } else {
        const members = [{ id: profile.id, name: `${profile.first_name} ${profile.last_name}` }];
        setOrganization(0);
        setOrganizations([{ id: 0, name: '', orgId: activeOrganization.physio_org_id }]);
        setMember(profile.id);
        setMembers(members);
        setMembersMap(new Map([[members[0].id, members[0].name]]));
        setAccountType('HealthcareProfissional');
      }
    }
  };

  useEffect(() => {
    const date = new Date();
    const h = date.getHours();
    const m = date.getMinutes();
    const time = `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`;

    filterAccountType();
    const operations = getOperations();

    setTime(time);
    setDate(date);
    setSearchDate(stringFromDate(date));
    setOperations(operations);
    setOperation('');
    setOperationsMap(new Map(operations.map((obj) => [obj.action, obj.description])));
  }, []);

  const handleMembersFetched = (results) => {
    const members = results.map((obj) => ({ id: obj.id, name: `${obj.first_name} ${obj.last_name}` }));
    const sortedMembers = members.sort((a, b) => (a.name > b.name ? 1 : -1));
    sortedMembers.unshift(DEFAULT_MEMBER);

    setMembers(sortedMembers);
    setMember('');
    setMembersMap(new Map(sortedMembers.map((obj) => [obj.id, obj.name])));
  };

  const fetchMembersHandler = () => {
    if (accountType === 'BraincareUser' || accountType === 'AccountManager') {
      const { orgId } = organizations.filter((obj) => obj.id === organization)[0];
      setIsLoadingMembers(true);

      const searchParams = new URLSearchParams();

      if (orgId) {
        searchParams.set('org_id', orgId);
      }

      fetchMemberHistory(searchParams)
        .then((response) => {
          const { data } = response;

          setIsLoadingMembers(false);

          if (data && !data.error) {
            handleMembersFetched(data);
            return;
          }

          onFetchDataFailure();
        })
        .catch(() => {
          setIsLoadingMembers(false);
          onFetchDataFailure();
        });
    }
  };

  useEffect(() => {
    if (accountType === 'BraincareUser') {
      setMembers([DEFAULT_MEMBER]);
      setMember('');
      setMembersMap(new Map([[DEFAULT_MEMBER.id, DEFAULT_MEMBER.name]]));
      fetchMembersHandler();
    }

    if (accountType === 'AccountManager') {
      fetchMembersHandler();
    }
  }, [organization]);

  useEffect(() => {
    errors.delete('member');
    setErrors(errors);
  }, [member]);

  const getLogsFilters = (date, time, orgId, userId, action) => {
    const searchParams = new URLSearchParams();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    searchParams.set('date', date);
    searchParams.set('time', time);
    searchParams.set('timezone', timezone);

    if (orgId !== '') {
      searchParams.set('org_id', orgId);
    }

    if (userId !== '') {
      searchParams.set('user_id', userId);
    }

    if (action !== '') {
      searchParams.set('action', action);
    }

    return searchParams;
  };

  const fetchLogsHandler = (searchParams) => {
    setIsLoadingLogs(true);
    setLogObjects([]);

    fetchLogs(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoadingLogs(false);

        if (data && !data.error) {
          setLogObjects(data.logs);
          setTotalLogs(data.recordsMatched);
          return;
        }

        onFetchDataFailure();
      })
      .catch(() => {
        setIsLoadingLogs(false);
        onFetchDataFailure();
      });
  };

  const handleSearchDate = (date) => {
    if (date instanceof Date) {
      setSearchDate(stringFromDate(date));
      setDate(date);
      setIsValidDate(true);
      return;
    }

    setSearchDate(formatFromString(date.target.value));
    setDate(getValidDate(date.target.value));
  };

  const onClearClick = () => {
    if (accountType === 'BraincareUser' || accountType === 'AccountManager') {
      setMember('');
    }

    setSearchDate('');
    setDate(new Date());
    setTime('');
    setOperation('');
  };

  const updateLogs = (logs) => {
    const { searchDate, time } = searchedData;

    const prevLogs = logObjects;
    const lastLog = prevLogs[prevLogs.length - 1];
    // looking for the last log already in the table in the logs returned;
    // it must be removed from logs when it's founded
    while (logs.length > 0 && !_.isEqual(lastLog, logs[0])) logs.shift();
    logs.shift();

    const limitDate = dateFromString(`${searchDate} ${time}`);
    limitDate.setDate(limitDate.getDate() - 1);

    const newLogs = logs.filter((el) => dateFromString(el.timestamp) >= limitDate);
    setLogObjects([...prevLogs, ...newLogs]);
  };

  const loadMoreLogsHandle = () => {
    const { orgId, member, operation } = searchedData;

    const date = dateFromString(logObjects[logObjects.length - 1].timestamp);
    date.setSeconds(date.getSeconds() + 1);
    const searchDate = stringFromDate(date);
    const time = formatTime(date);

    setIsLoadMoreButtonLoading(true);

    const searchParams = getLogsFilters(searchDate, time, orgId, member, operation);

    fetchLogs(searchParams)
      .then((response) => {
        const { data } = response;

        if (data && !data.error) {
          updateLogs(data.logs);
          return;
        }

        onFetchDataFailure();
      })
      .catch(() => {
        setIsLoadingLogs(false);
        onFetchDataFailure();
      })
      .finally(() => {
        setIsLoadMoreButtonLoading(false);
      });
  };

  const renderLogTable = () => {
    if (isLoadingLogs) {
      return <Loader />;
    }

    if (logObjects.length > 0) {
      return (
        <>
          <LogTable
            logObjs={logObjects}
            membersMap={membersMap}
            operationsMap={operationsMap}
            onClick={(logObject) => {
              setDialogLogObject(logObject);
            }}
          />
          <div className={styles.tableFooter}>
            <div className={styles.tableFooterLeft}>
              <p className={styles.nLoad}>
                {`${translate('logs.activities-report.n-logs-loaded-label')}: ${logObjects.length}/${totalLogs}`}
              </p>
            </div>
            {logObjects && logObjects.length < totalLogs && (
              <Button
                type="default"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={loadMoreLogsHandle}
                isLoading={isLoadMoreButtonLoading}
                isCentralized
                className={styles.loadMoreButton}
              >
                <I18n path="logs.activities-report.load-more-button-label" />
              </Button>
            )}
            <div className={styles.tableFooterRight} />
          </div>
        </>
      );
    }

    if (logObjects.length === 0) {
      return (
        <p className={styles.noLogsFound} data-testid="no-logs-message">
          <I18n path="logs.activities-report.not-found-logs-message" />
        </p>
      );
    }

    return null;
  };

  const onFilterClick = () => {
    const errors = new Map();

    if (searchDate === '') {
      errors.set('searchDate', FILL_REQUIRED);
    } else if (isValidDate) {
      let currentDate = new Date();
      currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

      const onlyDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      if (currentDate - onlyDate < 0) {
        errors.set('searchDate', MAXIMUM_DATE_ERROR);
      }
    }

    if (time === '') {
      errors.set('time', FILL_REQUIRED);
    }

    setErrors(errors);

    if (errors.size === 0) {
      const hasOrg = ['BraincareUser', 'AccountManager', 'HealthcareProfissional'].indexOf(accountType) > -1;

      const orgId = hasOrg ? organizations.filter((el) => el.id === organization)[0].orgId : '';

      setSearchedData({ searchDate, time, orgId, member, operation });

      const searchParams = getLogsFilters(searchDate, time, orgId, member, operation);

      fetchLogsHandler(searchParams);
    }
  };

  return (
    <>
      <Section className={styles.sectionFilters}>
        <Subtitle>
          <I18n path="physio.physio-personal.title-filter-physio" />
        </Subtitle>
        <Text isWithoutMargin>
          <I18n path="logs.activities-report.filter-text" />
        </Text>
        <div className={styles.filters}>
          <InputDatePicker
            label={<I18n path="logs.activities-report.label-input-date" />}
            labelDate={searchDate}
            date={date}
            maxDate={new Date()}
            onChange={handleSearchDate}
            onBlur={(event) => setIsValidDate(event.target.value === '' || validateISOCompleteDate(event.target.value))}
            placeholder="1992-11-22"
            hasError={!isValidDate || Boolean(errors && errors.get('searchDate'))}
            className={styles.dateInputDiv}
            errorMessage={
              (!isValidDate && INVALID_DATE_ERROR) ||
              (errors && errors.get('searchDate') ? errors.get('searchDate') : '')
            }
            data-testid="date-input"
          />
          <LabeledInput label={<I18n path="logs.activities-report.label-input-time" />} htmlFor="time">
            <Input
              id="time"
              name="Horario"
              type="time"
              value={time}
              onChange={(event) => setTime(event.target.value)}
              hasError={Boolean(errors && errors.get('time'))}
              errorMessage={errors && errors.get('time') ? errors.get('time') : ''}
              data-testid="time-input"
            />
          </LabeledInput>

          {accountType === 'BraincareUser' && (
            <div>
              <LabeledInput
                label={<I18n path="logs.activities-report.label-input-organization" />}
                htmlFor="organization"
                isDisabled={isLoadingOrganizations}
              >
                <Select
                  id="organization"
                  onChange={(event) => setOrganization(event.target.value)}
                  value={organization}
                  isDisabled={isLoadingOrganizations}
                  data-testid="organization-select"
                >
                  {organizations.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </LabeledInput>
              {isLoadingOrganizations ? (
                <Hint>
                  <Spinner className={styles.spinner} />
                  {LOADING_ORGANIZATIONS}
                </Hint>
              ) : null}
            </div>
          )}

          {(accountType === 'BraincareUser' || accountType === 'AccountManager') && (
            <div>
              <LabeledInput
                label={<I18n path="logs.activities-report.label-input-user" />}
                htmlFor="member"
                isDisabled={isLoadingMembers}
              >
                <Select
                  id="member"
                  onChange={(event) => setMember(event.target.value)}
                  value={member}
                  isDisabled={isLoadingMembers}
                  hasError={errors.has('member')}
                  errorMessage={errors.has('member') ? FILL_REQUIRED : ''}
                  data-testid="member-select"
                >
                  {members.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </LabeledInput>
              {isLoadingMembers ? (
                <Hint>
                  <Spinner className={styles.spinner} />
                  {LOADING_MEMBERS}
                </Hint>
              ) : null}
            </div>
          )}
          <LabeledInput label={<I18n path="logs.activities-report.label-input-operation" />} htmlFor="operation">
            <Select
              id="operation"
              onChange={(event) => setOperation(event.target.value)}
              value={operation}
              data-testid="operation-select"
            >
              {operations.map((item) => (
                <Option key={item.action} value={item.action}>
                  {item.description}
                </Option>
              ))}
            </Select>
          </LabeledInput>
          <Button
            onClick={onClearClick}
            className={styles.applyFilter}
            type="default"
            data-testid="clear-filters-button"
          >
            <I18n path="logs.activities-report.label-button-clear-filter" />
          </Button>
          <Button
            onClick={onFilterClick}
            className={styles.applyFilter}
            type="primary"
            isDisabled={isLoadingOrganizations || isLoadingMembers || isLoadingLogs}
            data-testid="apply-filters-button"
          >
            <I18n path="logs.activities-report.label-button-apply-filter" />
          </Button>
        </div>
        {renderLogTable()}
      </Section>
      <LogDialog isOpen={dialogLogObject !== null} onClose={() => setDialogLogObject(null)} log={dialogLogObject} />
    </>
  );
}

export default Logs;
