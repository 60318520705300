import PropTypes from 'prop-types';
import styles from './styles.module.css';

const CardTitle = ({ children }) => <h4 className={styles.cardTitle}>{children}</h4>;

CardTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardTitle;
