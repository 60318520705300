import PropTypes from 'prop-types';
import avatarPlaceholder from 'assets/images/avatar-placeholder.png';
import Avatar from 'components/atoms/avatar';
import styles from './styles.module.css';

const NavbarProfile = ({
  profileImage,
  profileAlt,
  universityFlag,
  universityAlt,
  userName,
  universityName,
  hasOrganization,
  ...props
}) => (
  <div className={styles.wrapper} {...props}>
    <div className={styles.images}>
      <Avatar
        image={profileImage}
        description={profileAlt}
        size="small"
        className={styles.profileImg}
        data-testid="profile-image"
      />
      {hasOrganization && (
        <img
          src={universityFlag || avatarPlaceholder}
          alt={universityAlt}
          className={styles.universityImg}
          data-testid="organization-image"
        />
      )}
    </div>
    <div>
      <p className={styles.userName} data-testid="profile-username">
        {userName}
      </p>
      {universityName && (
        <p className={styles.universityName} data-testid="profile-organization">
          {universityName}
        </p>
      )}
    </div>
  </div>
);

NavbarProfile.propTypes = {
  profileImage: PropTypes.string,
  profileAlt: PropTypes.string,
  universityFlag: PropTypes.string,
  universityAlt: PropTypes.string,
  userName: PropTypes.string.isRequired,
  universityName: PropTypes.string,
  hasOrganization: PropTypes.bool,
};

NavbarProfile.defaultProps = {
  profileImage: avatarPlaceholder,
  profileAlt: undefined,
  universityFlag: avatarPlaceholder,
  universityAlt: undefined,
  universityName: undefined,
  hasOrganization: false,
};

export default NavbarProfile;
