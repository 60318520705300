import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Auth from 'components/templates/auth';
import App from 'components/templates/app';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setLanguage } from 'features/userSlice';
import { getToken } from 'utils/tokens';
import PrivacyPolicyPortuguese from './pt';
import PrivacyPolicyEnglish from './en';

function PrivacyPolicy() {
  const user = useSelector((state) => state.user);

  const accessToken = getToken('access_token');
  const refreshToken = getToken('refresh_token');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const defaultLanguage = searchParams.get('language');

  useEffect(() => {
    if (defaultLanguage) {
      if (defaultLanguage === 'pt-br' || defaultLanguage === 'en-us') {
        dispatch(setLanguage(defaultLanguage));
        navigate('/public/privacy-policy');
      }
    }
  }, []);

  const renderContent = () => {
    window.scrollTo(0, 0);

    if (user.language === 'pt-br') {
      return <PrivacyPolicyPortuguese />;
    }

    return <PrivacyPolicyEnglish />;
  };

  const signed = accessToken !== undefined && refreshToken !== undefined;

  if (signed) {
    return <App>{renderContent()}</App>;
  }

  return <Auth maxWidth="90vw">{renderContent()}</Auth>;
}

export default PrivacyPolicy;
