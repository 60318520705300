import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/atoms/icon';
import styles from './styles.module.css';

const BreadcrumbItem = ({ isActive, path, children, ...props }) => (
  <Link className={classNames(styles.breadcrumb, { [styles.active]: isActive })} to={path} {...props}>
    {children}
    {!isActive ? <Icon className={styles.icon} name="ctrl" /> : null}
  </Link>
);

BreadcrumbItem.propTypes = {
  isActive: PropTypes.bool,
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

BreadcrumbItem.defaultProps = {
  isActive: false,
};

export default BreadcrumbItem;
