import { useRef } from 'react';
import Section from 'components/atoms/section';
import AuthTitle from 'components/atoms/auth-title';
import Text from 'components/atoms/text';
import Subtitle from 'components/atoms/subtitle';
import I18n from 'utils/i18n';
import Table, { Head, Body, Row, Cell } from 'components/atoms/table';
import styles from '../styles.module.css';

export default function PrivacyPolicyEnglish() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const ref11 = useRef(null);
  const ref12 = useRef(null);
  const ref13 = useRef(null);
  const ref14 = useRef(null);
  const ref15 = useRef(null);
  const ref16 = useRef(null);
  const ref17 = useRef(null);
  const ref18 = useRef(null);
  const ref19 = useRef(null);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  };

  return (
    <Section className={styles.section}>
      <AuthTitle>
        <I18n path="auth.privacy-policy-en.title00" />
      </AuthTitle>

      <Text size="medium">
        <I18n path="auth.privacy-policy-en.text00" />
        <br />
        <br />
        <I18n path="auth.privacy-policy-en.text01" />
        <br />
        <br />
        <I18n path="auth.privacy-policy-en.text02" />
        <br />
        <br />
      </Text>

      <Text size="medium">
        <strong>
          <I18n path="auth.privacy-policy-en.text03" />
        </strong>
        <ul>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref1)}
            onKeyDown={() => scrollToSection(ref1)}
          >
            <I18n path="auth.privacy-policy-en.text04" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref2)}
            onKeyDown={() => scrollToSection(ref2)}
          >
            <I18n path="auth.privacy-policy-en.text05" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref3)}
            onKeyDown={() => scrollToSection(ref3)}
          >
            <I18n path="auth.privacy-policy-en.text06" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref4)}
            onKeyDown={() => scrollToSection(ref4)}
          >
            <I18n path="auth.privacy-policy-en.text07" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref5)}
            onKeyDown={() => scrollToSection(ref5)}
          >
            <I18n path="auth.privacy-policy-en.text08" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref6)}
            onKeyDown={() => scrollToSection(ref6)}
          >
            <I18n path="auth.privacy-policy-en.text09" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref7)}
            onKeyDown={() => scrollToSection(ref7)}
          >
            <I18n path="auth.privacy-policy-en.text10" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref8)}
            onKeyDown={() => scrollToSection(ref8)}
          >
            <I18n path="auth.privacy-policy-en.text11" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref9)}
            onKeyDown={() => scrollToSection(ref9)}
          >
            <I18n path="auth.privacy-policy-en.text12" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref10)}
            onKeyDown={() => scrollToSection(ref10)}
          >
            <I18n path="auth.privacy-policy-en.text13" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref11)}
            onKeyDown={() => scrollToSection(ref11)}
          >
            <I18n path="auth.privacy-policy-en.text14" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref12)}
            onKeyDown={() => scrollToSection(ref12)}
          >
            <I18n path="auth.privacy-policy-en.text15" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref13)}
            onKeyDown={() => scrollToSection(ref13)}
          >
            <I18n path="auth.privacy-policy-en.text16" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref14)}
            onKeyDown={() => scrollToSection(ref14)}
          >
            <I18n path="auth.privacy-policy-en.text17" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref15)}
            onKeyDown={() => scrollToSection(ref15)}
          >
            <I18n path="auth.privacy-policy-en.text18" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref16)}
            onKeyDown={() => scrollToSection(ref16)}
          >
            <I18n path="auth.privacy-policy-en.text19" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref17)}
            onKeyDown={() => scrollToSection(ref17)}
          >
            <I18n path="auth.privacy-policy-en.text20" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref18)}
            onKeyDown={() => scrollToSection(ref18)}
          >
            <I18n path="auth.privacy-policy-en.text21" />
          </li>
          <li
            className={styles['quick-links']}
            onClick={() => scrollToSection(ref19)}
            onKeyDown={() => scrollToSection(ref19)}
          >
            <I18n path="auth.privacy-policy-en.text22" />
          </li>
        </ul>
      </Text>

      <section ref={ref1}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title01" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text23" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text24" />
        </Text>
      </section>

      <section ref={ref2}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title02" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text25" />
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text26" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text27" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text28" />
            </li>
          </ul>
          <I18n path="auth.privacy-policy-en.text29" />
        </Text>
      </section>

      <section ref={ref3}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title03" />
        </Subtitle>
        <Text size="medium">
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text30" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text31" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text32" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text33" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text34" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text35" />
            </li>
          </ul>
        </Text>
      </section>

      <section ref={ref4}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title04" />
        </Subtitle>
        <Text size="medium">
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text36" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text37" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text38" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text39" />
            </li>
          </ul>
        </Text>
      </section>

      <section ref={ref5}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title05" />
        </Subtitle>
        <Text size="medium">
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text40" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text41" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text42" />
            </li>
          </ul>
        </Text>
      </section>

      <section ref={ref6}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title06" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text43" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text44" />
        </Text>
      </section>

      <section ref={ref7}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title07" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text45" />
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text46" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text47" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text48" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text49" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text50" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text51" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text52" />
            </li>
          </ul>
          <I18n path="auth.privacy-policy-en.text53" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text54" />
          <br />
          <I18n path="auth.privacy-policy-en.text55" />
          <br />
          <I18n path="auth.privacy-policy-en.text56" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text57" />
          </strong>
        </Text>
      </section>

      <section ref={ref8}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title08" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text58" />
          <I18n path="auth.privacy-policy-en.text59" />
          <I18n path="auth.privacy-policy-en.text60" />
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text61" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text62" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text63" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text64" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text65" />
            </li>
          </ul>
          <I18n path="auth.privacy-policy-en.text66" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text67" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text68" />
          <Table canScroll={false}>
            <Head>
              <Row>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table1.headers.column0" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table1.headers.column1" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table1.headers.column2" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table1.headers.column3" />
                </Cell>
              </Row>
            </Head>
            <Body>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row0.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row0.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row0.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row0.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row1.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row1.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row1.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row1.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row2.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row2.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row2.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row2.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row3.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row3.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row3.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row3.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row4.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row4.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row4.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row4.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row5.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row5.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row5.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row5.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row6.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row6.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row6.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row6.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row7.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row7.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row7.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row7.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row8.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row8.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row8.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row8.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row9.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row9.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row9.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row9.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row10.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row10.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row10.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row10.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row11.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row11.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row11.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row11.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row12.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row12.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row12.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row12.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row13.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row13.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row13.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row13.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row14.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row14.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row14.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row14.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row15.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row15.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row15.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row15.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row16.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row16.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row16.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row16.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row17.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row17.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row17.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row17.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row18.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row18.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row18.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row18.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row19.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row19.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row19.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row19.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row20.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row20.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row20.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row20.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row21.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row21.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row21.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row21.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row22.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row22.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row22.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row22.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row23.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row23.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row23.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row23.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row24.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row24.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row24.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row24.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row25.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row25.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row25.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row25.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row26.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row26.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row26.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row26.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row27.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row27.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row27.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row27.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row28.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row28.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row28.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row28.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row29.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row29.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row29.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row29.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row30.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row30.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row30.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row30.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row31.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row31.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row31.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row31.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row32.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row32.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row32.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row32.column3" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row33.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row33.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row33.column2" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table1.body.row33.column3" />
                </Cell>
              </Row>
            </Body>
          </Table>
          <br />
          <I18n path="auth.privacy-policy-en.text69" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text70" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text71" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text72" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text73" />
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text74" />
              <a href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies">
                Internet Explorer
              </a>
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text74" />
              <a href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s">
                Firefox
              </a>
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text74" />
              <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">Safari</a>
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text74" />
              <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%253DDesktop&oco=1&hl=pt-BR">
                Google Chrome
              </a>
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text74" />
              <a href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies">
                Microsoft Edge
              </a>
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text74" />
              <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a>
            </li>
          </ul>
          <I18n path="auth.privacy-policy-en.text75" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text76" />
          <br />
          <br />
          <span>
            <I18n path="auth.privacy-policy-en.text77" />
            <a href="http://www.aboutcookies.org/">http://www.aboutcookies.org/</a>
            <I18n path="auth.privacy-policy-en.text78" />
            <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>
            <br />
            <br />
            <strong>
              <I18n path="auth.privacy-policy-en.text79" />
            </strong>
            <br />
            <br />
            <I18n path="auth.privacy-policy-en.text80" />
          </span>
        </Text>
      </section>

      <section ref={ref9}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title09" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text81" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text82" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text83" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text84" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text85" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text86" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text87" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text88" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text89" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text90" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text91" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text92" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text93" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text94" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text95" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text96" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text97" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text98" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text99" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text100" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text101" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text102" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text103" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text104" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text105" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text106" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text107" />
        </Text>
      </section>

      <section ref={ref10}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title10" />
        </Subtitle>
        <Text size="medium">
          <Table>
            <Head>
              <Row>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table0.headers.column0" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table0.headers.column1" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table0.headers.column2" />
                </Cell>
              </Row>
            </Head>
            <Body>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row0.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row0.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row0.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row1.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row1.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row1.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row2.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row2.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row2.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row3.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row3.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row3.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row4.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row4.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row4.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row5.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row5.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row5.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row6.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row6.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row6.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row7.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row7.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row7.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row8.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row8.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row8.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row9.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row9.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row9.column2" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row10.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row10.column1" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table0.body.row10.column2" />
                </Cell>
              </Row>
            </Body>
          </Table>
        </Text>
      </section>

      <section ref={ref11}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title11" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text108" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text109" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text110" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text111" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text112" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text113" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text114" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text115" />
          </strong>
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text116" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text117" />
        </Text>
      </section>

      <section ref={ref12}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title12" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text118" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text119" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text120" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text121" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text122" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text123" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text124" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text125" />
        </Text>
      </section>

      <section ref={ref13}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title13" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text126" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text127" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text128" />
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text129" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text130" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text131" />
            </li>
          </ul>
          <I18n path="auth.privacy-policy-en.text132" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text133" />
        </Text>
      </section>

      <section ref={ref14}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title14" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text134" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text135" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text136" />
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text137" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text138" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text139" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text140" />
            </li>
            <li>
              <I18n path="auth.privacy-policy-en.text141" />
            </li>
          </ul>
          <I18n path="auth.privacy-policy-en.text142" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text143" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text144" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text145" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text146" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text147" />
          <br />
          <br />
          <strong>
            <I18n path="auth.privacy-policy-en.text148" />
          </strong>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text149" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text150" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text151" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text152" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text153" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text154" />
        </Text>
      </section>

      <section ref={ref15}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title15" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text155" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text156" />
        </Text>
      </section>

      <section ref={ref16}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title16" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text157" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text158" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text159" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text160" />
        </Text>
      </section>

      <section ref={ref17}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title17" />
        </Subtitle>
        <Text size="medium">
          <I18n path="auth.privacy-policy-en.text161" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text162" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text163" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text164" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text165" />
        </Text>
      </section>

      <section ref={ref18}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title18" />
        </Subtitle>
        <Text size="medium">
          <ul>
            <li>
              <I18n path="auth.privacy-policy-en.text166" />
            </li>
          </ul>
        </Text>
      </section>

      <section ref={ref19}>
        <Subtitle>
          <I18n path="auth.privacy-policy-en.title19" />
        </Subtitle>
        <Text size="medium">
          <Table>
            <Head>
              <Row>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table2.headers.column0" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table2.headers.column1" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table2.headers.column2" />
                </Cell>
                <Cell isHead align="left">
                  <I18n path="auth.privacy-policy-en.table2.headers.column3" />
                </Cell>
              </Row>
            </Head>
            <Body>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row0.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column1.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column1.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column1.item2" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item6" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item7" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item8" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column2.item9" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column3.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column3.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column3.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row0.column3.item6" />
                    </li>
                  </ul>
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row1.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column1.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column1.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column1.item2" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item6" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item7" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item8" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column2.item9" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column3.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column3.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column3.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row1.column3.item6" />
                    </li>
                  </ul>
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row2.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column1.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column1.item1" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item6" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item7" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item8" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column2.item9" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column3.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column3.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row2.column3.item5" />
                    </li>
                  </ul>
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row3.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row3.column1.item0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row3.column2.item0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row3.column3.item0" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row4.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column1.item0" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column2.item2" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column3.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row4.column3.item4" />
                    </li>
                  </ul>
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row5.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column1.item0" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column2.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column2.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column2.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column2.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column2.item6" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column2.item7" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column3.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row5.column3.item4" />
                    </li>
                  </ul>
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row6.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column1.item0" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column2.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column2.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column2.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column2.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column2.item6" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row6.column3.item3" />
                    </li>
                  </ul>
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row7.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row7.column1.item0" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row7.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row7.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row7.column2.item2" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row7.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row7.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row7.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row7.column3.item3" />
                    </li>
                  </ul>
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row8.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row8.column1.item0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row8.column2.item0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row8.column3.item0" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row9.column0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row9.column1.item0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row9.column2.item0" />
                </Cell>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row9.column3.item0" />
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row10.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column1.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column1.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column1.item2" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item6" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item7" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column2.item8" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column3.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column3.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row10.column3.item5" />
                    </li>
                  </ul>
                </Cell>
              </Row>
              <Row>
                <Cell align="left">
                  <I18n path="auth.privacy-policy-en.table2.body.row11.column0" />
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column1.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column1.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column1.item2" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item6" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item7" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column2.item8" />
                    </li>
                  </ul>
                </Cell>
                <Cell align="left">
                  <ul>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column3.item0" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column3.item1" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column3.item2" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column3.item3" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column3.item4" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column3.item5" />
                    </li>
                    <li>
                      <I18n path="auth.privacy-policy-en.table2.body.row11.column3.item6" />
                    </li>
                  </ul>
                </Cell>
              </Row>
            </Body>
          </Table>
        </Text>
        <Text size="medium">
          <b>
            <I18n path="auth.privacy-policy-en.text167" />
          </b>
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text168" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text169" />
          <br />
          <br />
          <I18n path="auth.privacy-policy-en.text170" />
        </Text>
      </section>
    </Section>
  );
}
