import { logout } from 'api/portal';
import { clearNotifications } from 'features/notificationsSlice';
import { clearOrganizationData } from 'features/organizationSlice';
import { clearUserData, setIsBraincareUser } from 'features/userSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REFRESH_TOKEN_KEY, clearAllTokens, getToken } from 'utils/tokens';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const refreshToken = getToken(REFRESH_TOKEN_KEY);
    logout(refreshToken).catch(() => {});
    dispatch(clearUserData());
    dispatch(clearOrganizationData());
    clearAllTokens();
    dispatch(clearNotifications());
    dispatch(setIsBraincareUser(false));
    navigate('/auth/direct-login');
  }, []);

  return null;
};

export default Logout;
