import HumanizeDuration from 'humanize-duration';
import getLanguage from 'utils/get-language';
import { translate } from 'utils/i18n';

export default (date) => {
  const parseDate = Date.parse(date);
  const currentDate = Date.now();
  const createdDate = currentDate - parseDate;
  const language = getLanguage().split('-')[0];
  const humanized = HumanizeDuration(createdDate, {
    language,
    largest: 2,
    units: ['mo', 'w', 'd', 'h', 'm'],
    delimiter: translate('utils.delimiter'),
    round: true,
  });

  return humanized;
};

export const formatDuration = (date) => {
  const duration = date * 1000;
  const language = getLanguage().split('-')[0];
  return HumanizeDuration(duration, {
    language,
    units: ['h', 'm', 's'],
    round: true,
  });
};
