import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import LabeledInput from 'components/molecules/labeled-input';
import Input from 'components/atoms/input';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import I18n from 'utils/i18n';
import { formatToSSNDocument, formatToCPFDocument } from 'utils/format-documents';
import App from 'pages/account/me/template';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SlideRightFadeContainer } from 'utils/transitions';
import { useEffect, useRef, useState } from 'react';
import { STATUS_LOADING } from 'features/userSlice';
import Loader from 'components/molecules/section-loader';

function Documents() {
  const user = useSelector((state) => state.user);

  const ref = useRef();

  const { profile } = user;

  const documentType = profile.document_type;
  const { document } = profile;

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const formatDocument = (value) => (documentType === 'CPF' ? formatToCPFDocument(value) : formatToSSNDocument(value));

  useEffect(() => {
    if (isLoading && ref.current === STATUS_LOADING && user.status !== STATUS_LOADING) {
      setIsLoading(false);
    }

    ref.current = user.status;
  }, [user.status]);

  return (
    <App>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="account.me-edit-documents.title-sub-me-identification" />
        </Subtitle>
        <Text>
          <I18n path="account.me-edit-documents.text-account-me-identification" />
        </Text>
        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <Section>
              <LabeledInput
                label={<I18n path="account.me-edit-documents.input-doc-type-choice" />}
                htmlFor="document_type"
              >
                <Select
                  id="document_type"
                  name="document-type"
                  value={documentType}
                  isDisabled
                  data-testid="document-type-select"
                >
                  <Option value="CPF" data-testid="cpf-select-option">
                    CPF
                  </Option>
                  <Option value="ID" data-testid="id-select-option">
                    ID
                  </Option>
                </Select>
              </LabeledInput>

              <LabeledInput label={<I18n path="account.me-edit-documents.input-document" />} htmlFor="number">
                <Input
                  id="number"
                  name="number"
                  value={formatDocument(document)}
                  placeholder={documentType === 'ID' ? '123-45-6789' : '123.456.789-10'}
                  maxLength={documentType === 'ID' ? '11' : '14'}
                  isDisabled
                  data-testid="document-input"
                />
              </LabeledInput>
            </Section>
            <Button onClick={() => navigate('/')} data-testid="cancel-button">
              <I18n path="account.me-edit-documents.button-cancel" />
            </Button>
          </SlideRightFadeContainer>
        )}
      </Section>
    </App>
  );
}

export default Documents;
