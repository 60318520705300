import PropTypes from 'prop-types';
import Text from 'components/atoms/text';
import Avatar from 'components/atoms/avatar';
import styles from './styles.module.css';

const Header = ({ image, title, hasNotification, notificationCount }) => (
  <div className={styles.header}>
    <Avatar className={styles.iconContainer} image={image} description={title} size="small" />

    <div className={styles.headerInfo}>
      <Text className={styles.headerTitle}>
        {title}

        {hasNotification && (
          <div className={styles.warning}>
            <Text className={styles.warningCount}>{notificationCount}</Text>
          </div>
        )}
      </Text>
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  hasNotification: PropTypes.bool,
  notificationCount: PropTypes.number,
  image: PropTypes.string,
};

Header.defaultProps = {
  hasNotification: false,
  image: undefined,
  notificationCount: 0,
};

export default Header;
