export default class PasswordPolicy {
  constructor(policy) {
    if (policy) {
      this.sizeRange = policy.size_range ? policy.size_range : { max: undefined, min: undefined };
      this.digitsRange = policy.digits_range ? policy.digits_range : { max: undefined, min: undefined };
      this.nonAlphanumericRange = policy.non_alphanumeric_range
        ? policy.non_alphanumeric_range
        : { max: undefined, min: undefined };
      this.uppercaseRange = policy.uppercase_range ? policy.uppercase_range : { max: undefined, min: undefined };
      this.lowercaseRange = policy.lowercase_range ? policy.lowercase_range : { max: undefined, min: undefined };
      this.forbiddenWords = policy.forbidden_words ? policy.forbidden_words : { words: [], is_case_sensitive: false };
      this.sequentialStrings = policy.sequential_strings
        ? policy.sequential_strings
        : { max: undefined, looping: false, reverse_order: false, repeated_letters: false, is_case_sensitive: false };
      this.excludeCommonPasswords = policy.exclude_common_passwords ? policy.exclude_common_passwords : false;
      this.excludePersonalInformation = policy.exclude_personal_information
        ? policy.exclude_personal_information
        : { fields: [], similarity_ratio: 0.7, date_formatters: [] };
      this.repeatedCharSequences = policy.repeated_char_sequences
        ? policy.repeated_char_sequences
        : { max: undefined, is_case_sensitive: false };
      this.passwordReuse = policy.password_reuse !== undefined ? policy.password_reuse : undefined;
    } else {
      this.sizeRange = { max: 128, min: 8 };
      this.digitsRange = { max: 128, min: 1 };
      this.nonAlphanumericRange = { max: 128, min: 1 };
      this.uppercaseRange = { max: 128, min: 1 };
      this.lowercaseRange = { max: 128, min: 1 };
      this.forbiddenWords = { words: [], is_case_sensitive: false };
      this.sequentialStrings = {
        max: undefined,
        looping: false,
        reverse_order: false,
        repeated_letters: false,
        is_case_sensitive: false,
      };
      this.excludeCommonPasswords = false;
      this.excludePersonalInformation = { fields: [], similarity_ratio: 0.7, date_formatters: [] };
      this.repeatedCharSequences = { max: undefined, is_case_sensitive: false };
      this.passwordReuse = 0;
    }
  }
}
