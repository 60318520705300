import styled, { keyframes } from 'styled-components';

const SlideFadeInAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const SlideFadeInContainer = styled.div`
  opacity: 0;
  animation: ${SlideFadeInAnim} 500ms ease forwards;
  animation-delay: ${(props) => props.delay || 0}ms;
`;

const SlideRightFadeAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const SlideRightFadeContainer = styled.div`
  opacity: 0;
  animation: ${SlideRightFadeAnim} 500ms ease forwards;
  animation-delay: ${(props) => props.delay || 0}ms;
`;
