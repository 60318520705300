import styled from 'styled-components';
import { Card as CardMixin, Separator as SeparatorMixin } from 'utils/mixins';

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;

  ${CardMixin}
`;

export const Separator = styled.div`
  ${SeparatorMixin}
`;
