import { useEffect, useState } from 'react';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import Input from 'components/atoms/input';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Loader from 'components/molecules/section-loader';
import LabeledInput from 'components/molecules/labeled-input';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedUnit, setOrganizationSelectedUnit } from 'features/organizationSlice';
import { deleteUnit, fetchUnit, updateUnit } from 'api/portal';
import { addToast } from 'actions/toasts';
import App from './template';

const REQUIRED_FIELD = <I18n path="messages.fill-required" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;
const DELETE_FAILURE = <I18n path="messages.delete-failure" />;

function EditUnit() {
  const unit = useSelector((state) => state.organization.selectedUnit);

  const [name, setName] = useState(unit.name || '');
  const [additionalInformation, setAdditionalInformation] = useState(unit.additional_information || '');
  const [id, setId] = useState(unit.id || -1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isToShowDeleteDialog, setIsToShowDeleteDialog] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { unitId } = params;

  const handleGetUnitRequest = (id) => {
    setIsLoading(true);

    fetchUnit(id)
      .then((response) => {
        const { data } = response;

        dispatch(setOrganizationSelectedUnit(data));
        setAdditionalInformation(data.additional_information);
        setId(data.id);
        setName(data.name);
        setIsLoading(false);
      })
      .catch(() => {
        navigate('/organization/units');
      });
  };

  useEffect(() => {
    if (unit.id !== Number(unitId)) {
      dispatch(clearOrganizationSelectedUnit());
      handleGetUnitRequest(unitId);
    }
  }, []);

  const handleOnSaveChanges = () => {
    const errors = new Map();

    if (!name) {
      errors.set('name', REQUIRED_FIELD);
    }

    if (!additionalInformation) {
      errors.set('additionalInformation', REQUIRED_FIELD);
    }

    if (errors.size > 0) {
      setErrors(errors);
      return;
    }

    setErrors(errors);
    setIsSaving(true);

    updateUnit(id, {
      name,
      additional_information: additionalInformation,
    })
      .then(() => {
        setIsSaving(false);
        navigate('/organization/units');
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(addToast('error', SAVE_FAILURE));
      });
  };

  const handleDeleteUnit = () => {
    setIsDeleting(true);
    setIsToShowDeleteDialog(false);

    deleteUnit(id)
      .then(() => {
        setIsDeleting(false);
        navigate('/organization/units');
      })
      .catch(() => {
        setIsDeleting(false);
        dispatch(addToast('error', DELETE_FAILURE));
      });
  };

  const renderDeleteDialog = () => (
    <Dialog isOpen={isToShowDeleteDialog} data-testid="delete-dialog">
      <Header onClose={() => setIsToShowDeleteDialog(false)}>
        <I18n path="organization.organization-units-edit-delete-dialog.title" />
      </Header>
      <Body>
        <Text>
          <I18n path="organization.organization-units-edit-delete-dialog.text" />
        </Text>
      </Body>
      <Footer>
        <Button isBlock onClick={() => setIsToShowDeleteDialog(false)} type="ghost" data-testid="dialog-button-cancel">
          <I18n path="organization.organization-units-edit-delete-dialog.button-cancel" />
        </Button>
        <Button isBlock type="primary" onClick={handleDeleteUnit} data-testid="dialog-button-delete">
          <I18n path="organization.organization-units-edit-delete-dialog.button-delete" />
        </Button>
      </Footer>
    </Dialog>
  );

  return (
    <App>
      <SlideRightFadeContainer>
        <Section>
          <Subtitle>
            <I18n path="organization.organization-units-edit.title-sub-unit-info" />
          </Subtitle>
          <Text>
            <I18n path="organization.organization-units-edit.text-unit-edit-info" />
          </Text>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <LabeledInput
                label={<I18n path="organization.organization-units-edit.input-unit-name" />}
                htmlFor="unit_name"
              >
                <Input
                  id="unit_name"
                  name="unit-name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  hasError={errors.has('name')}
                  errorMessage={errors.has('name') ? errors.get('name') : ''}
                  data-testid="input-unit-name"
                />
              </LabeledInput>

              <LabeledInput label={<I18n path="organization.organization-units-edit.input-unit-info" />} htmlFor="info">
                <Input
                  id="info"
                  name="info"
                  value={additionalInformation}
                  onChange={(event) => setAdditionalInformation(event.target.value)}
                  hasError={errors.has('additionalInformation')}
                  errorMessage={errors.has('additionalInformation') ? errors.get('additionalInformation') : ''}
                  data-testid="input-info"
                />
              </LabeledInput>
            </>
          )}
        </Section>
        <Section>
          <Subtitle>
            <I18n path="organization.organization-units-edit.title-sub-unit-delete" />
          </Subtitle>
          <Text>
            <I18n path="organization.organization-units-edit.text-unit-edit-delete" />
          </Text>
          <Button
            isDisabled={isLoading}
            isLoading={isDeleting}
            onClick={() => setIsToShowDeleteDialog(true)}
            type="danger"
            data-testid="button-delete-unit"
          >
            <I18n path="organization.organization-units-edit.button-unit-delete" />
          </Button>
        </Section>

        <Button
          type="primary"
          isDisabled={isLoading}
          isLoading={isSaving}
          onClick={handleOnSaveChanges}
          data-testid="button-save"
        >
          <I18n path="organization.organization-units-edit.button-save" />
        </Button>
        <Button onClick={() => navigate('/organization/units')} data-testid="button-cancel">
          <I18n path="organization.organization-units-edit.button-cancel" />
        </Button>
      </SlideRightFadeContainer>
      {renderDeleteDialog()}
    </App>
  );
}

export default EditUnit;
