import styled, { css } from 'styled-components';
import LoaderRaw from 'components/atoms/loader';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
`;

export const Bars = styled.i`
  display: block;
  height: fit-content;
  width: fit-content;
  padding: 4px;
  color: transparent;
  background: var(--color-primary-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 24px;
  cursor: pointer;
  z-index: 0;
`;

const getMounting = (mount) => {
  switch (mount) {
    case 'bottom left':
      return css`
        bottom: 0;
        left: 0;
      `;
    case 'bottom right':
      return css`
        bottom: 0;
        right: 0;
      `;
    case 'top left':
      return css`
        top: 0;
        left: 0;
      `;
    case 'top right':
    default:
      return css`
        top: 0;
        right: 0;
      `;
  }
};

export const Menu = styled.div`
  position: absolute;
  box-shadow: var(--shadow-default);
  z-index: 1;
  ${(props) => getMounting(props.mount)}
  background: var(--color-white);
  border-radius: var(--border-half);
  overflow: hidden;

  opacity: 0;
  transition: all 200ms ease;
  transform: scaleX(0.9) scaleY(0.9);
  transform-origin: ${(props) => props.mount};
  pointer-events: none;
  ${(props) =>
    props.open
      ? css`
          opacity: 1;
          transform: scaleX(1) scaleY(1);
          pointer-events: initial;
        `
      : null}
`;

export const Loader = styled(LoaderRaw)`
  & > * {
    background: var(--color-primary-gradient) !important;
  }
`;
