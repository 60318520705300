import { useEffect, useRef, useState } from 'react';
import { addToast } from 'actions/toasts';
import I18n from 'utils/i18n';
import App from 'pages/account/member/template';
import Loader from 'components/molecules/section-loader';
import Section from 'components/atoms/section';
import { useNavigate } from 'react-router-dom';
import Button from 'components/atoms/button';
import InputIcon from 'components/molecules/input-icon';
import LabeledInput from 'components/molecules/labeled-input';
import RuledPasswordInput from 'components/organisms/RuledPasswordInput';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import { useDispatch, useSelector } from 'react-redux';
import { validatePassword } from 'utils/password-validators';
import { changePassword } from 'api/portal';
import { SlideRightFadeContainer } from 'utils/transitions';
import { STATUS_LOADING } from 'features/userSlice';
import styles from './styles.module.css';

const OLD_PASSWORD_ERROR = <I18n path="account.edit-password.old-password-error" />;
const SAVE_SUCCESS_MESSAGE = <I18n path="messages.save-success" />;
const SAVE_FAILURE_MESSAGE = <I18n path="messages.save-failure" />;
const REQUIRED_FIELD_ERROR = <I18n path="account.edit-password.fill-required" />;
const PASSWORD_MISMATCH_ERROR = <I18n path="account.edit-password.mismatch-error" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

function ChangePassword() {
  const user = useSelector((state) => state.user);
  const ref = useRef();

  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(undefined);

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(undefined);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(undefined);

  const [isSaving, setIsSaving] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState(undefined);
  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);

  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (passwordPolicy === undefined && ref.current === STATUS_LOADING && user.status !== STATUS_LOADING) {
      const passwordPolicy = user.active_organization.password_policy;

      setPasswordPolicy(passwordPolicy);
    }

    ref.current = user.status;
  }, [user.status]);

  const validateOldPassword = (oldPassword) => {
    setOldPassword(oldPassword);

    if (oldPassword) {
      setOldPasswordError(undefined);
    } else {
      setOldPasswordError(REQUIRED_FIELD_ERROR);
      return false;
    }

    return true;
  };

  const validateNewPassword = (newPassword) => {
    const policyErrors = validatePassword(passwordPolicy, newPassword);

    setNewPassword(newPassword);
    setPasswordPolicyErrors(policyErrors);

    if (!policyErrors || policyErrors.length === 0) {
      setNewPasswordError(undefined);
    } else {
      setNewPasswordError(policyErrors);
      return false;
    }

    if (newPassword) {
      setNewPasswordError(undefined);
    } else {
      setNewPasswordError(REQUIRED_FIELD_ERROR);
      return false;
    }

    if (newPassword === confirmPassword) {
      setConfirmPasswordError(undefined);
    } else {
      setConfirmPasswordError(PASSWORD_MISMATCH_ERROR);
      return false;
    }

    return true;
  };

  const validateConfirmPassword = (confirmPassword) => {
    setConfirmPassword(confirmPassword);

    if (newPassword === confirmPassword) {
      setConfirmPasswordError(undefined);
    } else {
      setConfirmPasswordError(PASSWORD_MISMATCH_ERROR);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const isOldPasswordValid = validateOldPassword(oldPassword);
    const isNewPasswordValid = validateNewPassword(newPassword);
    const isConfirmPasswordValid = validateConfirmPassword(confirmPassword);

    if (!(isOldPasswordValid && isNewPasswordValid && isConfirmPasswordValid)) {
      dispatch(addToast('error', FIX_FIELDS));
      return;
    }

    setIsSaving(true);

    changePassword({
      old_password: oldPassword,
      password: newPassword,
    })
      .then(() => {
        setIsSaving(false);
        dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        setIsSaving(false);

        if (data.old_password) {
          dispatch(addToast('error', OLD_PASSWORD_ERROR));
        }

        const passwordErrors = data.password;

        if (Array.isArray(passwordErrors)) {
          const policyErrors = passwordErrors.map((passwordError) => passwordError.split(']')[0].split('[')[1]);
          setPasswordPolicyErrors(policyErrors);
        }

        dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
      });
  };

  return (
    <App>
      <Section maxWidth="428px" data-testid="change-password-form">
        <Subtitle>
          <I18n path="account.edit-password.menu-personal-password" />
        </Subtitle>
        {passwordPolicy ? (
          <SlideRightFadeContainer>
            <Text>
              <I18n path="account.edit-password.text-account-me-main" />
            </Text>
            <LabeledInput
              label={<I18n path="account.edit-password.input-password-old" />}
              htmlFor="oldPassword"
              className={styles.oldPassword}
              data-testid="old-password-labeled-input"
            >
              <InputIcon
                id="oldPassword"
                value={oldPassword}
                onChange={(event) => validateOldPassword(event.target.value)}
                type={isOldPasswordVisible ? 'text' : 'password'}
                icon={isOldPasswordVisible ? 'eye' : 'eye-blocked'}
                onIconClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}
                hasError={oldPasswordError !== undefined}
                errorMessage={oldPasswordError}
                onBlur={(event) => validateOldPassword(event.target.value)}
                data-testid="old-password-input"
              />
            </LabeledInput>
            <RuledPasswordInput
              errors={passwordPolicyErrors}
              hasError={newPasswordError !== undefined}
              passwordPolicy={passwordPolicy}
              onChange={(event) => validateNewPassword(event.target.value)}
              value={newPassword}
            />
            <LabeledInput
              label={<I18n path="account.edit-password.input-password-new-2" />}
              type="password"
              data-testid="confirm-password-labeled-input"
            >
              <InputIcon
                id="confirmedPassword"
                value={confirmPassword}
                onChange={(event) => validateConfirmPassword(event.target.value)}
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                icon={isConfirmPasswordVisible ? 'eye' : 'eye-blocked'}
                onIconClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                hasError={confirmPasswordError !== undefined}
                errorMessage={confirmPasswordError}
                onBlur={(event) => validateConfirmPassword(event.target.value)}
                data-testid="confirm-password-input"
              />
            </LabeledInput>
            <div className={styles.buttons}>
              <Button type="primary" onClick={handleSubmit} isLoading={isSaving} data-testid="save-button">
                <I18n path="account.edit-password.button-save" />
              </Button>
              <Button onClick={() => navigate('/')} data-testid="cancel-button">
                <I18n path="account.edit-password.button-cancel" />
              </Button>
            </div>
          </SlideRightFadeContainer>
        ) : (
          <Loader />
        )}
      </Section>
    </App>
  );
}

export default ChangePassword;
