import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const TYPE = {
  finished: styles.finished,
  current: styles.current,
  next: styles.next,
};

const Step = ({ type, index, children, ...props }) => (
  <div className={classNames(styles.step, TYPE[type])} {...props}>
    <div className={styles.circle}>{index}</div>
    <span className={styles.text}>{children}</span>
  </div>
);

Step.propTypes = {
  type: PropTypes.string,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

Step.defaultProps = {
  type: 'next',
};

export default Step;
