import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from 'utils/tokens';
import Timeout from '../timeout';

function SignedPage({ children }) {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const accessToken = getToken('access_token');
  const refreshToken = getToken('refresh_token');

  const activeOrganization = user.active_organization;

  const autoLogoutPortal = activeOrganization.auto_logout_portal;
  const autoLogoutTimePortal = activeOrganization.auto_logout_time_portal;

  useEffect(() => {
    if (accessToken === undefined || refreshToken === undefined) {
      navigate('/auth/logout');
    }
  }, []);

  if (autoLogoutPortal && autoLogoutTimePortal) {
    return (
      <Timeout timeout={autoLogoutTimePortal * 60 - 30} warningTime={30}>
        {children}
      </Timeout>
    );
  }

  return children;
}

export default (component) => <SignedPage>{component}</SignedPage>;
