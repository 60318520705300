import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#002f72',
      light: '#0080e5',
      dark: '#110e2d',
      contrastText: '#ffffff',
    },
    error: {
      main: '#f84949',
      light: '#f84949',
      dark: '#cc1818',
      contrastText: '#ffffff',
    },
    disabled: {
      main: '#bababa',
      light: '#bababa',
      dark: '#bababa',
      contrastText: '#bababa',
    },
    colorGrey: {
      main: '#d5d5d5',
      light: '#d5d5d5',
      dark: '#d5d5d5',
      contrastText: '#d5d5d5',
    },
    colorGreyLight: {
      main: '#ededf0',
      light: '#ededf0',
      dark: '#ededf0',
      contrastText: '#ededf0',
    },
    colorGreyDark: {
      main: '#888696',
      light: '#888696',
      dark: '#888696',
      contrastText: '#888696',
    },
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
  },
  border: {
    half: 20,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          border: 'solid 1px #d5d5d5',
          boxShadow: 'none',
        },
      },
    },
  },
});

export default theme;
