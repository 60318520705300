import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from 'components/atoms/avatar';
import IconButton from 'components/atoms/icon-button';
import getNameInitials from 'utils/get-name-initials';
import styles from './styles.module.css';

const ProfileTag = ({ avatar, name, iconName, iconClick, className, ...props }) => (
  <div className={classnames(styles.profileTag, className)} {...props}>
    <div className={styles.avatarContainer}>
      {avatar ? (
        <Avatar image={avatar} description={name} size="xsmall" />
      ) : (
        <div className={styles.placeholder}>{getNameInitials(name)}</div>
      )}
    </div>
    <div className={styles.name} data-testid="profile-tag-name">
      {name}
    </div>
    {iconName && (
      <IconButton name={iconName} onClick={iconClick} className={styles.icon} data-testid="profile-tag-icon" />
    )}
  </div>
);

ProfileTag.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  iconClick: PropTypes.func,
  className: PropTypes.string,
};

ProfileTag.defaultProps = {
  avatar: undefined,
  iconName: undefined,
  iconClick: () => {},
  className: undefined,
};

export default ProfileTag;
