import PropTypes from 'prop-types';
import classNames from 'classnames';
import Placeholder from 'assets/images/avatar-placeholder.png';
import styles from './styles.module.css';

const SIZE = {
  default: styles.default,
  xsmall: styles.xsmall,
  small: styles.small,
  medium: styles.medium,
  big: styles.big,
};

const Avatar = ({ image, description, size, className, ...props }) => (
  <img
    className={classNames(styles.avatar, SIZE[size], className)}
    src={image || Placeholder}
    alt={description}
    {...props}
  />
);

Avatar.propTypes = {
  image: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  image: Placeholder,
  // eslint-disable-next-line react/default-props-match-prop-types
  description: '',
  size: 'default',
  className: undefined,
};

export default Avatar;
