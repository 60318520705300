export const formatToCPFDocument = (document) => {
  let val = document.replace(/\D/g, '');
  val = val.replace(/\D/g, '');
  val = val.replace(/(\d{3})(\d)/, '$1.$2');
  val = val.replace(/(\d{3})(\d)/, '$1.$2');
  val = val.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return val;
};

export const formatToSSNDocument = (document) => {
  let val = document.replace(/\D/g, '');
  let newVal = '';

  if (val.length > 3 && val.length < 6) {
    newVal += `${val.substr(0, 3)}-`;
    val = val.substr(3);
  }
  if (val.length > 5) {
    newVal += `${val.substr(0, 3)}-`;
    newVal += `${val.substr(3, 2)}-`;
    val = val.substr(5);
  }
  newVal += val;
  const formattedDocument = newVal.substring(0, 11);
  return formattedDocument;
};

export const unformatDocument = (document) => document.replace(/\D/g, '');
