import { useEffect, useState } from 'react';
import { addToast } from 'actions/toasts';
import I18n from 'utils/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AccountItem from 'components/molecules/account-item';
import { clearOrganizationSelectedMobileDevice, setOrganizationSelectedMobileDevice } from 'features/organizationSlice';
import Button from 'components/atoms/button';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import InputCheckbox from 'components/atoms/input-checkbox';
import InputHint from 'components/atoms/input-hint';
import Spinner from 'components/atoms/spinner';
import Option from 'components/atoms/option';
import LabeledInput from 'components/molecules/labeled-input';
import Select from 'components/atoms/input-select';
import { fetchMobileDevices, fetchMembersEligibleToPinPolicy, updateMobileDevice } from 'api/portal';
import Loader from 'components/molecules/section-loader';
import { SlideRightFadeContainer } from 'utils/transitions';
import styles from './styles.module.css';
import App from '../../template';
import { isTrustedDevice } from '../../../../../utils/mobile-devices';

const NO_PIN = <I18n path="organization.organization-mobile-devices-create.pin-policy-options.no-pin" />;
const YEAR_BIRTH_DATE = (
  <I18n path="organization.organization-mobile-devices-create.pin-policy-options.year-birth-date" />
);
const MONTH_DAY_BIRTH_DATE = (
  <I18n path="organization.organization-mobile-devices-create.pin-policy-options.month-day-birth-date" />
);
const PHONE_LAST_4_DIGITS = (
  <I18n path="organization.organization-mobile-devices-create.pin-policy-options.phone-last-4-digits" />
);

const PIN_POLICY_CHOICES = [
  {
    value: 'NO_PIN',
    description: NO_PIN,
  },
  {
    value: 'YEAR_BIRTH_DATE',
    description: YEAR_BIRTH_DATE,
  },
  {
    value: 'MONTH_DAY_BIRTH_DATE',
    description: MONTH_DAY_BIRTH_DATE,
  },
  {
    value: 'PHONE_LAST_4_DIGITS',
    description: PHONE_LAST_4_DIGITS,
  },
];

const SUCCESS_PIN_POLICY_UPDATE = (
  <I18n path="organization.organization-mobile-devices-edit.success-device-pin-policy-update" />
);
const ERROR_PIN_POLICY_UPDATE = (
  <I18n path="organization.organization-mobile-devices-edit.error-device-pin-policy-update" />
);
const ERROR_FETCH_DEVICE = <I18n path="organization.organization-mobile-devices-edit.error-fetch-device" />;

const NON_ELIGIBLE_MEMBER_SEARCH = (
  <I18n path="organization.organization-mobile-devices-create.loading-non-eligible-members" />
);
const ERROR_FETCH_NON_ELIGIBLE_MEMBERS = (
  <I18n path="organization.organization-mobile-devices-create.load-non-eligible-members-failure" />
);
const REQUIRE_PIN_CHANGE_CHECKBOX = (
  <I18n path="organization.organization-mobile-devices-create.input-require-pin-change-check-box" />
);
const SUBTITLE_NON_ELIGIBLE_MEMBERS = (
  <I18n path="organization.organization-mobile-devices-create.link-non-eligible-members" />
);

function EditMobileDevicePinPolicy() {
  const mobileDevice = useSelector((state) => state.organization.selectedMobileDevice);

  const [isLoading, setIsLoading] = useState(true);
  const [pinPolicy, setPinPolicy] = useState(PIN_POLICY_CHOICES[0].value);
  const [requirePinChange, setRequirePinChange] = useState(false);
  const [nonEligibleMembers, setNonEligibleMembers] = useState([]);
  const [isFetchingMembers, setIsFetchingMembers] = useState(false);
  const [errorFetchingMembers, setErrorFetchingMembers] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();

  const { deviceId } = params;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onErrorFetchingDevice = () => {
    setIsLoading(false);
    navigate('/org-mobile-devices/devices');
    dispatch(addToast('error', ERROR_FETCH_DEVICE));
  };

  useEffect(() => {
    if (mobileDevice.org_device_id !== deviceId) {
      dispatch(clearOrganizationSelectedMobileDevice());
    }

    setIsLoading(true);

    fetchMobileDevices(new URLSearchParams(), { org_device_id: deviceId })
      .then((response) => {
        const { data } = response;

        if (!data.results) {
          onErrorFetchingDevice();
        } else if (data.results.length === 1) {
          const device = data.results[0];

          if (isTrustedDevice(device.status)) {
            dispatch(clearOrganizationSelectedMobileDevice());
            dispatch(setOrganizationSelectedMobileDevice(device));
            setPinPolicy(device.pin_policy);
            setRequirePinChange(device.change_pin_first_access);
            setIsLoading(false);
          } else {
            onErrorFetchingDevice();
          }
        } else {
          onErrorFetchingDevice();
        }
      })
      .catch(() => onErrorFetchingDevice());
  }, []);

  const onSelectChange = (pinPolicy) => {
    setPinPolicy(pinPolicy);
    setIsFetchingMembers(true);
    setErrorFetchingMembers(false);

    fetchMembersEligibleToPinPolicy(pinPolicy, deviceId)
      .then((response) => {
        const { data } = response;
        setNonEligibleMembers(data.non_eligible);
        setErrorFetchingMembers(false);
        setIsFetchingMembers(false);
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_FETCH_NON_ELIGIBLE_MEMBERS));
        setErrorFetchingMembers(true);
        setIsFetchingMembers(false);
      });
  };

  const onSubmit = () => {
    setIsSubmitting(true);

    updateMobileDevice({
      org_device_id: deviceId,
      pin_policy: pinPolicy,
      change_pin_first_access: requirePinChange,
    })
      .then(() => {
        setIsSubmitting(false);
        dispatch(addToast('success', SUCCESS_PIN_POLICY_UPDATE));
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(addToast('error', ERROR_PIN_POLICY_UPDATE));
      });
  };

  const renderHint = () => {
    if (errorFetchingMembers) {
      return (
        <InputHint>
          <Text isError data-testid="hint-error">
            {ERROR_FETCH_NON_ELIGIBLE_MEMBERS}
          </Text>
        </InputHint>
      );
    }

    if (isFetchingMembers) {
      return (
        <InputHint>
          <Spinner />
          {NON_ELIGIBLE_MEMBER_SEARCH}
        </InputHint>
      );
    }

    return null;
  };

  const numberNonEligibleMembers = nonEligibleMembers.length;

  return (
    <App device={mobileDevice}>
      <Subtitle>
        <I18n path="organization.organization-mobile-devices-create.title-sub-pin-policy-setup" />
      </Subtitle>
      <Section maxWidth="428px">
        {isLoading ? (
          <Loader />
        ) : (
          <SlideRightFadeContainer>
            <Section maxWidth="428px">
              <Text isItalic>
                <I18n path="organization.organization-mobile-devices-create.text-pin-policy-setup" />
              </Text>
              <LabeledInput
                label={<I18n path="organization.organization-mobile-devices-create.input-pin-policy-choice" />}
                htmlFor="pin_policy"
              >
                <Select
                  id="pin_policy"
                  name="pin-policy"
                  value={pinPolicy}
                  onChange={(event) => onSelectChange(event.target.value)}
                  hasError={numberNonEligibleMembers !== 0}
                  errorMessage={
                    numberNonEligibleMembers !== 0 ? (
                      <I18n
                        path="organization.organization-mobile-devices-create.error-message-non-eligible-members"
                        props={{ number_members: numberNonEligibleMembers }}
                      />
                    ) : (
                      ''
                    )
                  }
                  data-testid="pin-policy-select"
                >
                  {PIN_POLICY_CHOICES.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.description}
                    </Option>
                  ))}
                </Select>
                {renderHint()}
              </LabeledInput>
              <InputCheckbox
                id="require_pin_change"
                name="require-pin-change"
                value={requirePinChange}
                label={REQUIRE_PIN_CHANGE_CHECKBOX}
                isChecked={requirePinChange}
                onClick={() => setRequirePinChange(!requirePinChange)}
                data-testid="require-pin-change-checkbox"
              />
            </Section>

            {numberNonEligibleMembers !== 0 ? (
              <Section maxWidth="428px">
                <Subtitle isError>
                  <I18n path="organization.organization-mobile-devices-create.title-sub-non-eligible-members" />
                </Subtitle>
                <Text isItalic>
                  <I18n path="organization.organization-mobile-devices-create.text-non-eligible-members" />
                </Text>
                {nonEligibleMembers.map((member) => (
                  <AccountItem
                    key={`member-${member.id}`}
                    image={member.user ? member.user.photo : null}
                    title={`${member.user.first_name} ${member.user.last_name}`}
                    subtitle={SUBTITLE_NON_ELIGIBLE_MEMBERS}
                    onClick={() => navigate(`/organization/members/${member.id}/edit/info`)}
                    data-testid={`account-item-${member.id}`}
                  />
                ))}
              </Section>
            ) : null}

            <div className={styles.buttonsContainer}>
              <Button type="primary" isLoading={isSubmitting} onClick={onSubmit} data-testid="save-button">
                <I18n path="organization.organization-mobile-devices-create.button-confirm-pin-policy-setup" />
              </Button>
              <Button onClick={() => navigate('/org-mobile-devices/devices')} data-testid="back-button">
                <I18n path="organization.organization-mobile-devices-create.button-back" />
              </Button>
            </div>
          </SlideRightFadeContainer>
        )}
      </Section>
    </App>
  );
}

export default EditMobileDevicePinPolicy;
