import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Spinner = ({ className, ...props }) => (
  <div className={classNames(styles.spinner, styles.small, className)} {...props}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: undefined,
};

export default Spinner;
