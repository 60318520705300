import styled from 'styled-components';
import { Card as CardMixin } from 'utils/mixins';

export const Card = styled.div`
  display: inline-block;
  padding: 16px;
  height: auto;

  ${CardMixin}
`;
