import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Dropdown = ({ children, className, ...props }) => (
  <div className={classNames(styles.dropdown, className)} {...props}>
    {children}
  </div>
);

Dropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Dropdown.defaultProps = {
  className: undefined,
};

export default Dropdown;
