import { useEffect, useState } from 'react';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Alert from 'components/atoms/alert';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import Loader from 'components/molecules/section-loader';
import { SlideRightFadeContainer } from 'utils/transitions';
import I18n from 'utils/i18n';
import App from 'pages/organization/members/edit/template';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMember, setOrganizationSelectedMember } from 'features/organizationSlice';
import { deleteMember, fetchMember, updateMember } from 'api/portal';
import { addToast } from 'actions/toasts';

const LOAD_FAILURE = <I18n path="messages.load-failure" />;
const DELETE_FAILURE = <I18n path="organization.organization-members-edit-suspend-exclude-toasts.delete-failure" />;
const UPDATE_FAILURE = <I18n path="organization.organization-members-edit-suspend-exclude-toasts.update-failure" />;

function MemberSuspension() {
  const member = useSelector((state) => state.organization.selectedMember);

  const [isSuspended, setIsSuspended] = useState(member.status === 'SUSPENDED' || false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isToShowSuspendDialog, setIsToShowSuspendDialog] = useState(false);
  const [isToShowDeleteDialog, setIsToShowDeleteDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { memberId } = params;

  useEffect(() => {
    if (member.id !== Number(memberId)) {
      dispatch(clearOrganizationSelectedMember());

      setIsLoading(true);

      fetchMember(memberId)
        .then((response) => {
          setIsLoading(false);

          const { data } = response;
          const { status } = data;

          dispatch(setOrganizationSelectedMember(data));
          setIsSuspended(status === 'SUSPENDED');
        })
        .catch(() => {
          setIsLoading(false);
          dispatch(addToast('error', LOAD_FAILURE));
          navigate('/organization/members');
        });
    }
  }, []);

  const changeAccountStatus = (newStatus) => {
    setIsLoading(true);
    setIsToShowSuspendDialog(false);

    updateMember(memberId, { status: newStatus })
      .then((response) => {
        setIsLoading(false);

        const { data } = response;

        const newStatus = data.status;

        dispatch(setOrganizationSelectedMember(newStatus));
        setIsSuspended(newStatus === 'SUSPENDED');
      })
      .catch(() => {
        dispatch(addToast('error', UPDATE_FAILURE));
      });
  };

  const deleteMemberAccount = () => {
    setIsDeleting(true);
    setIsToShowDeleteDialog(false);

    deleteMember(memberId)
      .then(() => {
        setIsDeleting(false);
        dispatch(clearOrganizationSelectedMember());
        navigate('/organization/members');
      })
      .catch(() => {
        dispatch(addToast('error', DELETE_FAILURE));
      });
  };

  const renderSuspendDialog = () => (
    <Dialog isOpen={isToShowSuspendDialog}>
      <Header onClose={() => setIsToShowSuspendDialog(false)}>
        <I18n path="organization.organization-members-edit-suspend-box.title-box-member-suspend" />
      </Header>
      <Body>
        <Text>
          <I18n path="organization.organization-members-edit-suspend-box.box-text-organization-members-exclude" />
        </Text>
      </Body>
      <Footer>
        <Button
          isBlock
          onClick={() => setIsToShowSuspendDialog(false)}
          type="ghost"
          data-testid="dismiss-suspension-button"
        >
          <I18n path="organization.organization-members-edit-suspend-box.button-cancel" />
        </Button>
        <Button
          isBlock
          type="primary"
          onClick={() => changeAccountStatus('SUSPENDED')}
          data-testid="confirm-suspension-button"
        >
          <I18n path="organization.organization-members-edit-suspend-box.button-suspend" />
        </Button>
      </Footer>
    </Dialog>
  );

  const renderDeleteDialog = () => (
    <Dialog isOpen={isToShowDeleteDialog}>
      <Header onClose={() => setIsToShowDeleteDialog(false)}>
        <I18n path="organization.organization-members-edit-suspend-exclude.title-box-member-exclude" />
      </Header>
      <Body>
        <Text>
          <I18n path="organization.organization-members-edit-suspend-exclude.text-box-organization-members-delete" />
        </Text>
      </Body>
      <Footer>
        <Button
          isBlock
          onClick={() => setIsToShowDeleteDialog(false)}
          type="ghost"
          data-testid="dismiss-deletion-button"
        >
          <I18n path="organization.organization-members-edit-suspend-exclude.box-button-cancel" />
        </Button>
        <Button isBlock type="primary" onClick={deleteMemberAccount} data-testid="confirm-deletion-button">
          <I18n path="organization.organization-members-edit-suspend-exclude.box-button-suspend" />
        </Button>
      </Footer>
    </Dialog>
  );

  const renderSuspendAccount = () => (
    <div>
      <SubSubtitle>
        <I18n path="organization.organization-members-edit-suspend.title-sub-member-suspend" />
      </SubSubtitle>
      <Text>
        <I18n path="organization.organization-members-edit-suspend.text-organization-members-suspend" />
      </Text>
      <Button isLoading={isLoading} onClick={() => setIsToShowSuspendDialog(true)} data-testid="suspend-button">
        <I18n path="organization.organization-members-edit-suspend.button-member-suspend" />
      </Button>
    </div>
  );

  const renderUnsuspendAccount = () => (
    <Alert>
      <Text>
        <I18n path="organization.organization-members-edit-suspend-revert.title-box-member-suspended" />
      </Text>
      <Text>
        <I18n path="organization.organization-members-edit-suspend-revert.text-box-organization-member-suspend" />
      </Text>
      <Button type="warning" onClick={() => changeAccountStatus('PENDING')} data-testid="unsuspend-button">
        <I18n path="organization.organization-members-edit-suspend-revert.button-suspend-revert" />
      </Button>
    </Alert>
  );

  const renderStatusSection = () => (isSuspended ? renderUnsuspendAccount() : renderSuspendAccount());

  return (
    <App>
      <SlideRightFadeContainer>
        <Subtitle>
          <I18n path="organization.organization-members-edit-suspend.title-main-member-suspend" />
        </Subtitle>
        <Text>
          <I18n path="organization.organization-members-edit-suspend.text-organization-members-suspend-exclude" />
        </Text>
        <Section>{isLoading ? <Loader /> : renderStatusSection()}</Section>

        <Section>
          <SubSubtitle>
            <I18n path="organization.organization-members-edit-suspend.title-sub-member-exclude" />
          </SubSubtitle>
          <Text>
            <I18n path="organization.organization-members-edit-suspend.text-organization-members-exclude" />
          </Text>
          <Button
            type="danger"
            onClick={() => setIsToShowDeleteDialog(true)}
            isLoading={isDeleting}
            isDisabled={isLoading}
            data-testid="delete-button"
          >
            <I18n path="organization.organization-members-edit-suspend.button-member-exclude" />
          </Button>
        </Section>
        {renderSuspendDialog()}
        {renderDeleteDialog()}
        <Button onClick={() => navigate('/organization/members')} data-testid="cancel-button">
          <I18n path="organization.organization-members-edit-suspend.button-cancel" />
        </Button>
      </SlideRightFadeContainer>
    </App>
  );
}

export default MemberSuspension;
