import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AppMenu from 'components/templates/app-menu';
import I18n from 'utils/i18n';
import { useParams } from 'react-router-dom';

const HOME = <I18n path="organization.breadcrumbs.home" />;
const MANAGE_ORGANIZATION = <I18n path="organization.breadcrumbs.manage-organization" />;
const EDIT_UNIT = <I18n path="organization.breadcrumbs.edit-unit" />;

const INFO = <I18n path="organization.organization-units-edit.menu-unit-info" />;
const MEMBERS = <I18n path="organization.organization-units-members.menu-member-info" />;

const App = ({ children }) => {
  const unit = useSelector((state) => state.organization.selectedUnit);

  const params = useParams();

  const { unitId } = params;

  const { pathname, search } = window.location;

  const EDIT_UNIT_INFO = `/organization/units/${unitId}/edit`;
  const MEMBERS_UNIT = `/organization/units/${unitId}/members`;

  const SIDE_MENU = [
    {
      href: EDIT_UNIT_INFO,
      text: INFO,
      isActive: pathname === EDIT_UNIT_INFO,
    },
    {
      href: MEMBERS_UNIT,
      text: MEMBERS,
      isActive: pathname === MEMBERS_UNIT,
    },
  ];

  const getBreadcrumb = (path) => {
    switch (path) {
      case EDIT_UNIT_INFO:
        return INFO;
      case MEMBERS_UNIT:
        return MEMBERS;
      default:
        return '';
    }
  };

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/units',
      title: MANAGE_ORGANIZATION,
    },
    {
      path: `${pathname}${search}`,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];
  const TITLE = unit.id !== -1 ? unit.name : EDIT_UNIT;

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={TITLE} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
