import PropTypes from 'prop-types';
import List from 'components/atoms/list';
import styles from './styles.module.css';

const Menu = ({ children }) => <List className={styles.menu}>{children}</List>;

Menu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Menu;
