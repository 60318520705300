import PropTypes from 'prop-types';
import App from 'components/templates/app';
import Menu from 'components/molecules/menu';
import MenuItem from 'components/molecules/menu-item';
import styles from './styles.module.css';

const AppMenu = ({ className, menuItems, breadcrumbs, title, children }) => (
  <App className={className} breadcrumbs={breadcrumbs} title={title}>
    <div className={styles.contentSection}>
      {menuItems && menuItems.length > 0 && (
        <Menu className={styles.menu}>
          {menuItems.map((item, index) => (
            <MenuItem key={index.toString()} isActive={item.isActive} href={item.href}>
              {item.text}
            </MenuItem>
          ))}
        </Menu>
      )}

      <div className={styles.separator} />
      <div className={styles.main}>{children}</div>
    </div>
  </App>
);

AppMenu.propTypes = {
  menuItems: PropTypes.instanceOf(Array),
  breadcrumbs: PropTypes.instanceOf(Array),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AppMenu.defaultProps = {
  className: '',
  menuItems: undefined,
  breadcrumbs: undefined,
  title: undefined,
};

export default AppMenu;
