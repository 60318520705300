import { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Calendar } from 'react-date-range';
import ClickOutside from 'react-outside-click-handler';
import LabeledInput from 'components/molecules/labeled-input';
import InputIcon from 'components/molecules/input-icon';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from './styles.module.css';

class InputDateRange extends Component {
  static propTypes = {
    date: PropTypes.instanceOf(Object),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    labelDate: PropTypes.string,
    isLabelDisabled: PropTypes.bool,
    maxDate: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    date: new Date(),
    label: '',
    labelDate: '',
    isLabelDisabled: false,
    maxDate: undefined,
    // eslint-disable-next-line react/default-props-match-prop-types
    className: undefined,
  };

  state = {
    showPicker: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onPickDate = (date) => {
    const { onChange } = this.props;

    onChange(date);
    this.handleIconButtonClick();
  };

  handleIconButtonClick = () => {
    if (this.mounted) this.setState((prevState) => ({ showPicker: !prevState.showPicker }));
  };

  handleOnOutsideClick = () => {
    if (this.mounted) this.setState({ showPicker: false });
  };

  render() {
    const { date, isLabelDisabled, label, labelDate, maxDate, className, ...props } = this.props;
    const { showPicker } = this.state;

    return (
      <div
        className={classnames(
          styles.inputPicker,
          {
            [styles.open]: showPicker,
            [styles.close]: !showPicker,
          },
          className,
        )}
      >
        <LabeledInput label={label} isDisabled={isLabelDisabled}>
          <InputIcon
            value={labelDate}
            type="text"
            icon="calendar"
            onIconClick={this.handleIconButtonClick}
            {...props}
          />
        </LabeledInput>
        <ClickOutside onOutsideClick={this.handleOnOutsideClick}>
          <Calendar className={styles.dateRange} onChange={this.onPickDate} date={date} maxDate={maxDate} />
        </ClickOutside>
      </div>
    );
  }
}

export default InputDateRange;
