import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/atoms/icon';
import styles from './styles.module.css';

const IconButton = ({ name, onClick, isDisabled, className, iconClassName, ...props }) => (
  <button
    className={classNames(styles.button, className, { [styles.disabled]: isDisabled })}
    onClick={onClick}
    disabled={isDisabled}
    aria-label="icon-button"
    {...props}
  >
    <Icon className={classNames(styles.icon, iconClassName)} name={name} />
  </button>
);

IconButton.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

IconButton.defaultProps = {
  name: undefined,
  onClick: () => {},
  isDisabled: false,
  className: undefined,
  iconClassName: undefined,
};

export default IconButton;
