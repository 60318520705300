import PropTypes from 'prop-types';
import Avatar from 'components/atoms/avatar';
import Text from 'components/atoms/text';
import { AnimCardContainer, Card } from './styles';
import styles from './styles.module.css';

const ProfileCard = ({ onClick, picture, pictureDescription, title, subtitle, delay, icon, ...props }) => (
  <AnimCardContainer delay={delay}>
    <Card onClick={onClick} {...props}>
      {icon ? <img src={icon} className={styles.blockedIcon} alt="Device icon" /> : null}
      <Avatar image={picture} size="medium" description={pictureDescription} />
      <div className={styles.text}>
        <Text size="big" className={styles.title} data-testid="profile-card-title">
          {title}
        </Text>
        <Text size="medium" className={styles.subtitle} data-testid="profile-card-subtitle">
          {subtitle}
        </Text>
      </div>
    </Card>
  </AnimCardContainer>
);

ProfileCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  picture: PropTypes.string,
  pictureDescription: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  subtitle: PropTypes.string,
  delay: PropTypes.number,
  icon: PropTypes.string,
};

ProfileCard.defaultProps = {
  picture: '',
  subtitle: '',
  delay: 0,
  icon: undefined,
};

export default ProfileCard;
