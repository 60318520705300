import PropTypes from 'prop-types';
import Brand from 'components/atoms/brand';
import List from 'components/atoms/list';
import { Navbar as NavbarRaw } from './styles';
import styles from './styles.module.css';

const Navbar = ({ companyLogo, companyLogoText, children, brandHref }) => (
  <header className={styles.header}>
    <NavbarRaw>
      <Brand image={companyLogo} title={companyLogoText} href={brandHref} />
      <nav className={styles.navItems}>
        <List>{children}</List>
      </nav>
    </NavbarRaw>
  </header>
);

Navbar.propTypes = {
  companyLogo: PropTypes.string.isRequired,
  companyLogoText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  brandHref: PropTypes.string.isRequired,
};

export default Navbar;
