import { useEffect, useState } from 'react';
import { addToast } from 'actions/toasts';
import { validateCpf } from 'utils/validate-documents';
import validateEmail from 'utils/validate-email';
import { formatToCPFDocument, unformatDocument } from 'utils/format-documents';
import Auth from 'components/templates/auth';
import Section from 'components/atoms/section';
import AuthTitle from 'components/atoms/auth-title';
import Input from 'components/atoms/input';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import LinkItem from 'components/atoms/link-item';
import LabeledInput from 'components/molecules/labeled-input';
import Radio from 'components/atoms/input-radio';
import Label from 'components/atoms/input-label';
import I18n from 'utils/i18n';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchProfessions, signup } from 'api/portal';
import styles from '../styles.module.css';

const INVALID_DOCUMENT_ERROR = <I18n path="messages.invalid-document" />;
const INVALID_EMAIL_ERROR = <I18n path="messages.invalid-email" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;
const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const ACCOUNT_EXISTS = <I18n path="messages.account-exists" />;
const ERROR_LOADING_PROFESSIONS = <I18n path="messages.error-professions" />;

function Signup() {
  const [document, setDocument] = useState('');
  const [documentType, setDocumentType] = useState('CPF');
  const [profession, setProfession] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [hasReadTermsAndConditions, setHasReadTermsAndConditions] = useState(false);
  const [hasReadPrivacyPolicy, setHasReadPrivacyPolicy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidDocument, setIsValidDocument] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [notificationLanguage, setNotificationLanguage] = useState('en-us');
  const [errors, setErrors] = useState(new Map());
  const [professions, setProfessions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchProfessions()
      .then((response) => {
        const { data } = response;

        const professions = [];

        const returnedProfessions = data.professions;

        returnedProfessions.forEach((returnedProfession) => {
          professions.push(
            <Option key={returnedProfession} value={returnedProfession}>
              <I18n path={'professions.text-' + returnedProfession} />
            </Option>,
          );
        });

        setProfessions(professions);
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_LOADING_PROFESSIONS));
      });
  }, []);

  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsLoading(true);

    signup({
      accept_terms: true,
      document: documentType === 'CPF' ? unformatDocument(document) : document,
      document_type: documentType,
      doctor_document: null,
      profession,
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: '',
      birth_date: `${birthYear}-01-01`,
      has_read_privacy_policy: hasReadPrivacyPolicy,
      has_read_terms_and_conditions: hasReadTermsAndConditions,
      notification_language: notificationLanguage,
    })
      .then(() => {
        setIsLoading(false);
        navigate('/auth/signup-success');
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        setIsLoading(false);

        if (data) {
          if (data.document || data.email) {
            dispatch(addToast('error', ACCOUNT_EXISTS));
          } else {
            dispatch(addToast('error', SAVE_FAILURE));
          }
        }
      });
  };

  const validateRequiredFields = () => {
    const requiredFields = {
      firstName,
      lastName,
      documentType,
      document,
      email,
      birthYear,
      notificationLanguage,
    };

    const errors = new Map();

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    setErrors(errors);

    if (!errors.size && isValidEmail && isValidDocument) {
      handleSubmit();
      return;
    }

    dispatch(addToast('error', FIX_FIELDS));
  };

  return (
    <Auth>
      <SlideFadeInContainer>
        <Section>
          <AuthTitle className={styles.signupTitle}>
            <I18n path="auth.signup.title-main-signup" />
          </AuthTitle>

          <Text size="medium" className={styles.signupSubtitle}>
            <I18n path="auth.signup.text-signin" />
            &nbsp;
            <LinkItem href="/auth/direct-login">
              <I18n path="auth.signup.link-signin" />
            </LinkItem>
          </Text>
          <LabeledInput label={<I18n path="auth.signup.input-name" />} data-testid="first-name-labeled-input">
            <Input
              placeholder="e.g.: John"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              hasError={Boolean(errors && errors.get('firstName'))}
              errorMessage={errors && errors.get('firstName') ? errors.get('firstName') : ''}
              data-testid="first-name-input"
            />
          </LabeledInput>

          <LabeledInput label={<I18n path="auth.signup.input-surname" />} data-testid="last-name-labeled-input">
            <Input
              placeholder="e.g.: Doe"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              hasError={Boolean(errors && errors.get('lastName'))}
              errorMessage={errors && errors.get('lastName') ? errors.get('lastName') : ''}
              data-testid="last-name-input"
            />
          </LabeledInput>

          <LabeledInput label={<I18n path="auth.signup.input-email" />} data-testid="email-labeled-input">
            <Input
              placeholder="eg.: johndoe@yourorganization.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              hasError={!isValidEmail || Boolean(errors && errors.get('email'))}
              errorMessage={
                (!isValidEmail && INVALID_EMAIL_ERROR) || (errors && !!errors.get('email') ? errors.get('email') : '')
              }
              onBlur={() => setIsValidEmail(email === '' || validateEmail(email))}
              data-testid="email-input"
            />
          </LabeledInput>

          <LabeledInput
            label={<I18n path="account.me-edit-info.input-birth-year" />}
            htmlFor="birth_year"
            data-testid="birth-year-labeled-input"
          >
            <Input
              id="birth_year"
              name="Birth year"
              maxLength={4}
              value={birthYear}
              onChange={(event) => setBirthYear(event.target.value.replace(/\D/g, ''))}
              hasError={Boolean(errors && errors.get('birthYear'))}
              errorMessage={errors && errors.get('birthYear') ? errors.get('birthYear') : ''}
              data-testid="birth-year-input"
            />
          </LabeledInput>

          <LabeledInput label={<I18n path="auth.signup.input-profession" />} data-testid="profession-labeled-input">
            <Select
              value={profession}
              onChange={(event) => setProfession(event.target.value)}
              data-testid="profession-select"
            >
              {professions}
            </Select>
          </LabeledInput>

          <LabeledInput label={<I18n path="auth.signup.input-doc-type-choice" />}>
            <Select
              value={documentType}
              onChange={(event) => {
                setDocument('');
                setDocumentType(event.target.value);
                setIsValidDocument(true);
              }}
              hasError={Boolean(errors && errors.get('documentType'))}
              errorMessage={errors && errors.get('documentType') ? errors.get('documentType') : ''}
              data-testid="signup-input-doc-type-choice"
            >
              <Option value="CPF" data-testid="cpf-option">
                CPF (BR)
              </Option>
              <Option value="ID" data-testid="id-option">
                ID (USA)
              </Option>
            </Select>
          </LabeledInput>

          <LabeledInput label={<I18n path="auth.signup.input-document" />} data-testid="document-labeled-input">
            <Input
              placeholder={documentType === 'CPF' ? '000.000.000-00' : ''}
              value={document}
              onChange={(event) => {
                setDocument(documentType === 'CPF' ? formatToCPFDocument(event.target.value) : event.target.value);
              }}
              hasError={!isValidDocument || Boolean(errors && errors.get('document'))}
              errorMessage={
                (!isValidDocument && INVALID_DOCUMENT_ERROR) ||
                (errors && errors.get('document') ? errors.get('document') : '')
              }
              onBlur={() =>
                setIsValidDocument(document === '' || (documentType === 'CPF' ? validateCpf(document) : true))
              }
              maxLength={documentType === 'CPF' ? '14' : '32'}
              data-testid="document-input"
            />
          </LabeledInput>

          <div className={styles.language}>
            <Label>
              <I18n path="auth.signup.text-doc-choice-language" />
            </Label>
            <div className={styles.languageOptions}>
              <Radio
                id="1"
                name={notificationLanguage}
                value="pt-br"
                label={<I18n path="auth.signup.input-language-pt-br" />}
                isChecked={notificationLanguage === 'pt-br'}
                onChange={(event) => setNotificationLanguage(event.target.value)}
                data-testid="pt-br-radio"
              />
              <Radio
                id="2"
                name={notificationLanguage}
                value="en-us"
                label={<I18n path="auth.signup.input-language-en-us" />}
                isChecked={notificationLanguage === 'en-us'}
                onChange={(event) => setNotificationLanguage(event.target.value)}
                data-testid="en-us-radio"
              />
            </div>
          </div>

          <div className={styles.signupText}>
            <Text size="medium" isBold>
              <I18n path="auth.signup.text-signup-document-reason1" />
            </Text>
            <Text size="medium">
              <I18n path="auth.signup.text-signup-document-reason2" />
            </Text>
          </div>

          <div className={styles.acceptTermsCheckbox}>
            <Text size="medium">
              <I18n path="auth.signup.text-signup-terms-conditions" />
              &nbsp;
              <LinkItem
                href="/public/terms-and-service"
                onClick={() => setHasReadTermsAndConditions(true)}
                target="_blank"
                data-testid="terms-and-service-link"
              >
                <I18n path="auth.signup.text-signup-terms" />
              </LinkItem>
              <I18n path="auth.signup.text-signup-terms-conditions-between" />
              &nbsp;
              <LinkItem
                href="/public/privacy-policy"
                onClick={() => setHasReadPrivacyPolicy(true)}
                target="_blank"
                data-testid="privacy-policy-link"
              >
                <I18n path="auth.signup.text-signup-conditions" />
              </LinkItem>
              <I18n path="auth.signup.text-signup-after" />
            </Text>
          </div>

          <Button
            type="primary"
            isBlock
            isLarge
            onClick={validateRequiredFields}
            isDisabled={!(hasReadPrivacyPolicy && hasReadTermsAndConditions)}
            isLoading={isLoading}
            data-testid="submit-button"
          >
            <I18n path="auth.signup.button-create-account" />
          </Button>
        </Section>
      </SlideFadeInContainer>
    </Auth>
  );
}

export default Signup;
