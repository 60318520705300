import PropTypes from 'prop-types';
import Avatar from 'components/atoms/avatar';
import styles from './styles.module.css';

const MemberHeader = ({ image, imageDescription, name, subtitle }) => (
  <div className={styles.header}>
    <Avatar className={styles.profileImage} image={image} description={imageDescription} size="small" />
    <div className={styles.info}>
      <h3 className={styles.name}>{name}</h3>
      {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
    </div>
  </div>
);

MemberHeader.propTypes = {
  image: PropTypes.string,
  imageDescription: PropTypes.string,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

MemberHeader.defaultProps = {
  image: '',
  imageDescription: '',
  subtitle: undefined,
};

export default MemberHeader;
