import { useEffect, useState } from 'react';
import Text from 'components/atoms/text';
import AuthTitle from 'components/atoms/auth-title';
import Subtitle from 'components/atoms/subtitle';
import Button from 'components/atoms/button';
import BCAvatar from 'assets/images/braincare-user/organization-avatar.png';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveOrganizationData,
  setIsBraincareUser,
  setOrganizationProfileData,
  setOrganizations,
} from 'features/userSlice';
import { fetchOrganizationProfile, fetchProfiles, memberLogin } from 'api/portal';
import { addToast } from 'actions/toasts';
import { setToken } from 'utils/tokens';
import styles from './styles.module.css';
import AccountList from '../common/AccountList';
import LoginDialog from '../common/LoginDialog';

const PASSWORD_EMPTY_ERROR = <I18n path="auth.accounts-corp-login.text-corp-login-empty-error" />;
const INVALID_CREDENTIAL = <I18n path="auth.accounts-corp-login.text-corp-login-pwd-error" />;
const LOAD_ORGANIZATION_PROFILE_FAILURE = <I18n path="messages.load-organization-profiles-failure" />;
const LOAD_PROFILES_FAILURE = <I18n path="messages.load-profiles-failure" />;

function ChooseProfile() {
  const [account, setAccount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const { organizations, profile } = user;

  const braincarePermissions = profile.braincare_permissions ? profile.braincare_permissions : [];

  useEffect(() => {
    setIsLoading(true);

    fetchProfiles()
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        dispatch(setOrganizations(data));
      })
      .catch(() => {
        navigate('/');
        dispatch(addToast('error', LOAD_PROFILES_FAILURE));
      });
  }, []);

  const getOrganizationProfile = () => {
    fetchOrganizationProfile()
      .then((response) => {
        const { data } = response;
        dispatch(setOrganizationProfileData(data));
      })
      .catch(() => {
        dispatch(addToast('error', LOAD_ORGANIZATION_PROFILE_FAILURE));
      });
  };

  const getMainText = () => (
    <>
      {profile.first_name && (
        <Subtitle className={styles.accountUser}>
          <I18n path="auth.accounts-start.title-main-account" props={{ firstname: profile.first_name }} />
        </Subtitle>
      )}
      <AuthTitle className={styles.accountTitle}>
        <I18n path="auth.accounts-start.title-sub-account-center" />
      </AuthTitle>
    </>
  );

  const getLoggoutText = () => (
    <Text size="medium" className={styles.signOutLink}>
      <I18n path="auth.accounts-start.text-account-not-user" props={{ firstname: profile.first_name }} />
      <Button type="ghost" onClick={() => navigate('/auth/logout')}>
        <I18n path="auth.accounts-start.button-logout" />
      </Button>
    </Text>
  );

  const onAccountSelected = (account) => {
    if (account.id === 0) {
      navigate('/');
    } else if (account.id === -1) {
      dispatch(setIsBraincareUser(true));
      navigate('/');
    } else {
      setAccount(account);
    }
  };

  const onSubmitLoginDialog = (formData) => {
    const { password } = formData;

    if (!password) {
      setErrors(new Map([['password', PASSWORD_EMPTY_ERROR]]));
      return;
    }

    setIsSubmitting(true);

    memberLogin({
      org_id: account.id,
      password,
    })
      .then((response) => {
        const { data } = response;

        setToken('access_token', data.access_token);
        setToken('refresh_token', data.refresh_token);

        dispatch(setActiveOrganizationData(account));

        getOrganizationProfile();

        setAccount(undefined);
        setIsSubmitting(false);

        navigate('/');
      })
      .catch(() => {
        setIsSubmitting(false);
        setErrors(new Map([['password', INVALID_CREDENTIAL]]));
      });
  };

  const accounts = [
    {
      id: 0,
      name: <I18n path="auth.accounts-start.title-card-personal" />,
      photo: profile.photo,
    },
  ];

  organizations.forEach((organization) => {
    accounts.push({
      id: organization.id,
      name: organization.name,
      physio_org_id: organization.physio_org_id,
      address: organization.address,
      photo: organization.photo,
      is_active: organization.is_active,
    });
  });

  if (braincarePermissions.length) {
    accounts.push({
      id: -1,
      name: <I18n path="auth.accounts-start.title-card-braincare" />,
      photo: BCAvatar,
    });
  }

  return (
    <>
      <AccountList
        mainText={getMainText()}
        accounts={accounts}
        isLoading={isLoading}
        onAccountSelected={(account) => onAccountSelected(account)}
        loggoutText={getLoggoutText()}
      />
      <LoginDialog
        organization={account}
        isOpen={account !== undefined}
        onClose={() => {
          setAccount(undefined);
          setErrors(new Map());
        }}
        isSubmitting={isSubmitting}
        onSubmit={(data) => onSubmitLoginDialog(data)}
        errors={errors}
      />
    </>
  );
}

export default ChooseProfile;
