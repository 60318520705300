import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const LinkItem = ({ isBlock, isActive, href, children, className, ...props }) => (
  <Link
    className={classNames(
      styles.link,
      { [styles.block]: isBlock, [styles.active]: isActive, [styles.disabled]: !isActive },
      className,
    )}
    to={href}
    {...props}
  >
    {children}
  </Link>
);

LinkItem.propTypes = {
  isBlock: PropTypes.bool,
  isActive: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

LinkItem.defaultProps = {
  isBlock: false,
  isActive: true,
  className: undefined,
  href: undefined,
};

export default LinkItem;
