import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppWizard from 'components/templates/app-wizard';
import braincare from 'components/routes/braincare';
import { addToast } from 'actions/toasts';
import Button from 'components/atoms/button';
import Section from 'components/atoms/section';
import Input from 'components/atoms/input';
import Subtitle from 'components/atoms/subtitle';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import LabeledInput from 'components/molecules/labeled-input';
import InputDatePicker from 'components/molecules/input-date-picker';
import I18n from 'utils/i18n';
import { formatToSSNDocument, formatToCPFDocument, unformatDocument } from 'utils/format-documents';
import { formatFromString, stringFromDate, dateFromString, getValidDate } from 'utils/format-date';
import { validateCpf, validateSsn } from 'utils/validate-documents';
import { createBuUser } from 'api/portal';
import validateDate from 'utils/validate-date';
import validateEmail from 'utils/validate-email';
import { SlideRightFadeContainer } from 'utils/transitions';
import { useNavigate } from 'react-router-dom';
import InputLabel from 'components/atoms/input-label';
import InputRadio from 'components/atoms/input-radio';
import styles from './styles.module.css';

// eslint-disable-next-line react/jsx-key
const STEPS = [<I18n path="braincare.users-create.sidebar.step-user-info" />];

const FILL_REQUIRED = <I18n path="messages.fill-required" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const INVALID_DOCUMENT_ERROR = <I18n path="messages.invalid-document" />;
const INVALID_EMAIL_ERROR = <I18n path="messages.invalid-email" />;
const INVALID_DATE_ERROR = <I18n path="messages.invalid-date" />;
const SAVE_SUCCESS = <I18n path="messages.save-success" />;
const SAVE_FAILURE = <I18n path="messages.save-failure" />;

const HOME = <I18n path="braincare.breadcrumbs.home" />;
const USERS = <I18n path="braincare.breadcrumbs.users" />;
const USERS_CREATE = <I18n path="braincare.breadcrumbs.users-create" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/braincare/users',
    title: USERS,
  },
  {
    path: '/braincare/users/create',
    title: USERS_CREATE,
    isActive: true,
  },
];

function AdminUsersCreate() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [date, setDate] = useState(dateFromString(''));
  const [document, setDocument] = useState('');
  const [documentType, setDocumentType] = useState('CPF');
  const [notificationLanguage, setNotificationLanguage] = useState('en-us');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidDate, setIsValidDate] = useState(true);
  const [isValidDocument, setIsValidDocument] = useState(true);
  const [errors, setErrors] = useState(new Map());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnCreate = () => {
    setIsSubmitting(true);

    createBuUser({
      first_name: firstName,
      last_name: lastName,
      email,
      birth_date: birthDate,
      document_type: documentType,
      document: unformatDocument(document),
      has_read_privacy_policy: false,
      has_read_terms_and_conditions: false,
      accept_terms: true,
      notification_language: notificationLanguage,
    })
      .then(() => {
        setIsSubmitting(false);
        dispatch(addToast('success', SAVE_SUCCESS));
        navigate('/braincare/users');
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(addToast('error', SAVE_FAILURE));
      });
  };

  const handleValidateEmail = (value) => {
    setIsValidEmail(value === '' || validateEmail(value));
  };

  const handleValidateDate = (value) => {
    setIsValidDate(value === '' || validateDate(value));
  };

  const handleValidateDocument = (value) => {
    setIsValidDocument(value === '' || (documentType === 'CPF' ? validateCpf(value) : validateSsn(value)));
  };

  const handleChangeBirthDate = (date) => {
    if (date instanceof Date) {
      setIsValidDate(birthDate === '' || validateDate(birthDate));
      setBirthDate(stringFromDate(date));
      setDate(date);
      return;
    }

    setBirthDate(formatFromString(date.target.value));
    setDate(getValidDate(date.target.value));
  };

  const validateUserInfoFields = () => {
    const requiredFields = {
      firstName,
      lastName,
      email,
      birthDate,
      document,
    };

    const errors = new Map();
    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    setErrors(errors);

    if (errors.size === 0 && isValidDocument && isValidDate && isValidEmail) {
      handleOnCreate();
    } else {
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  const setFormattedDocument = (value) => {
    const document = documentType === 'CPF' ? formatToCPFDocument(value) : formatToSSNDocument(value);
    setDocument(document);
  };

  const handleChangeDocumentType = (value) => {
    setDocumentType(value);
    setDocument('CPF');
  };

  return (
    <AppWizard title={USERS_CREATE} breadcrumbs={breadcrumbs} steps={STEPS} currentStep={0}>
      <Subtitle>
        <I18n path="braincare.users-create.sidebar.step-user-info" />
      </Subtitle>
      <Section maxWidth="428px">
        <SlideRightFadeContainer>
          <div className={styles.inputWrapper}>
            <LabeledInput
              label={<I18n path="braincare.users-create.input-first-name" />}
              data-testid="first-name-labeled-input"
            >
              <Input
                placeholder="e.g.: John"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                hasError={Boolean(errors && errors.get('firstName'))}
                errorMessage={errors && errors.get('firstName') ? errors.get('firstName') : ''}
                data-testid="first-name-input"
              />
            </LabeledInput>

            <LabeledInput
              label={<I18n path="braincare.users-create.input-last-name" />}
              data-testid="last-name-labeled-input"
            >
              <Input
                placeholder="e.g.: Doe"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                hasError={Boolean(errors && errors.get('lastName'))}
                errorMessage={errors && errors.get('lastName') ? errors.get('lastName') : ''}
                data-testid="last-name-input"
              />
            </LabeledInput>

            <LabeledInput label={<I18n path="braincare.users-create.input-email" />} data-testid="email-labeled-input">
              <Input
                placeholder="eg.: johndoe@yourorganization.com"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                hasError={!isValidEmail || Boolean(errors && errors.get('email'))}
                errorMessage={
                  (!isValidEmail && INVALID_EMAIL_ERROR) || (errors && !!errors.get('email') ? errors.get('email') : '')
                }
                onBlur={() => handleValidateEmail(email)}
                data-testid="email-input"
              />
            </LabeledInput>

            <InputDatePicker
              label={<I18n path="braincare.users-create.input-birthdate" />}
              labelDate={birthDate}
              date={date}
              maxDate={new Date()}
              placeholder="1992-11-22"
              onChange={handleChangeBirthDate}
              onBlur={() => handleValidateDate(birthDate)}
              hasError={!isValidDate || Boolean(errors && errors.get('birthDate'))}
              errorMessage={
                (!isValidDate && INVALID_DATE_ERROR) ||
                (errors && errors.get('birthDate') ? errors.get('birthDate') : '')
              }
              data-testid="birth-date-input"
            />

            <LabeledInput label={<I18n path="braincare.users-create.input-doc-type-choice" />}>
              <Select
                value={documentType}
                onChange={(event) => handleChangeDocumentType(event.target.value)}
                hasError={Boolean(errors && errors.get('documentType'))}
                errorMessage={errors && errors.get('documentType') ? errors.get('documentType') : ''}
                data-testid="document-type-select"
              >
                <Option value="CPF" data-testid="cpf-option">
                  CPF
                </Option>
                <Option value="ID" data-testid="id-option">
                  ID
                </Option>
              </Select>
            </LabeledInput>

            <LabeledInput
              label={<I18n path="braincare.users-create.input-document" />}
              data-testid="document-labeled-input"
            >
              <Input
                placeholder={documentType === 'CPF' ? '000.000.000-00' : '000-00-0000'}
                value={document}
                onChange={(event) => setFormattedDocument(event.target.value)}
                hasError={!isValidDocument || Boolean(errors && errors.get('document'))}
                errorMessage={
                  (!isValidDocument && INVALID_DOCUMENT_ERROR) ||
                  (errors && errors.get('document') ? errors.get('document') : '')
                }
                onBlur={() => handleValidateDocument(document)}
                maxLength={documentType === 'CPF' ? '14' : '11'}
                data-testid="document-input"
              />
            </LabeledInput>

            <div className={styles.language}>
              <InputLabel>
                <I18n path="auth.signup.text-doc-choice-language" />
              </InputLabel>
              <div className={styles.languageOptions}>
                <InputRadio
                  id="1"
                  name={notificationLanguage}
                  value="pt-br"
                  label={<I18n path="auth.signup.input-language-pt-br" />}
                  isChecked={notificationLanguage === 'pt-br'}
                  onChange={(event) => setNotificationLanguage(event.target.value)}
                  data-testid="pt-br-radio"
                />
                <InputRadio
                  id="2"
                  name={notificationLanguage}
                  value="en-us"
                  label={<I18n path="auth.signup.input-language-en-us" />}
                  isChecked={notificationLanguage === 'en-us'}
                  onChange={(event) => setNotificationLanguage(event.target.value)}
                  data-testid="en-us-radio"
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <Button isLoading={isSubmitting} type="primary" onClick={validateUserInfoFields} data-testid="save-button">
              <I18n path="braincare.users-create.buttons.button-confirm-and-save" />
            </Button>
            <Button onClick={() => navigate('/braincare/users')} data-testid="cancel-button">
              <I18n path="braincare.users-create.buttons.button-cancel" />
            </Button>
          </div>
        </SlideRightFadeContainer>
      </Section>
    </AppWizard>
  );
}

export default braincare(AdminUsersCreate);
