import { Checkbox } from '@mui/material';
import Section from 'components/atoms/section';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/mui/Button';
import FormControlLabel from 'components/mui/FormControlLabel';
import TextField from 'components/mui/TextField';
import App from 'pages/braincare/AdminOrganizations/template';
import I18n from 'utils/i18n';
import { SlideRightFadeContainer } from 'utils/transitions';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { formatNumber } from 'utils/format-number';
import SectionLoader from 'components/molecules/section-loader';
import { fetchOrganization, updateOrganization } from 'api/portal';
import { addToast } from 'actions/toasts';
import { useDispatch } from 'react-redux';
import braincare from 'components/routes/braincare';

const INACTIVITY_TIME_EMPTY = (
  <I18n path="organization.organization-settings.inactivity-policy.inactivity-time-empty" />
);
const INACTIVITY_TIME_LOW = <I18n path="organization.organization-settings.inactivity-policy.inactivity-time-low" />;

const MAX_INACTIVITY_PERIOD_EMPTY = (
  <I18n path="organization.organization-settings.inactivity-policy.max-inactivity-period-empty" />
);

const SAVE_SUCCESS_MESSAGE = <I18n path="organization.organization-settings.save-success-message" />;
const SAVE_FAILURE_MESSAGE = <I18n path="organization.organization-settings.save-failure-message" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const LOAD_FAILURE = <I18n path="messages.load-failure" />;

function AdminOrganizationInactivityPolicy() {
  const [organizationId, setOrganizationId] = useState();
  const [name, setName] = useState('');

  const [enableAutoLogoutPortal, setEnableAutoLogoutPortal] = useState(false);
  const [inactivityTimePortal, setInactivityTimePortal] = useState('');

  const [enableAutoLogoutApp, setEnableAutoLogoutApp] = useState(false);
  const [inactivityTimeApp, setInactivityTimeApp] = useState('');

  const [autoSuspensionMembers, setAutoSuspensionMembers] = useState(false);
  const [maxInactivityPeriod, setMaxInactivityPeriod] = useState('');

  const [errors, setErrors] = useState(new Map());

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    setIsLoading(true);

    const { orgId } = params;

    setOrganizationId(orgId);

    fetchOrganization(orgId)
      .then((response) => {
        setIsLoading(false);

        const { data } = response;

        setName(data.name);
        setEnableAutoLogoutApp(data.auto_logout_app);
        setInactivityTimeApp(data.auto_logout_time_app || '');
        setEnableAutoLogoutPortal(data.auto_logout_portal);
        setInactivityTimePortal(data.auto_logout_time_portal || '');
        setAutoSuspensionMembers(data.suspend_inactive_members);
        setMaxInactivityPeriod(data.maximum_inactive_period || '');
      })
      .catch(() => {
        setIsLoading(false);
        navigate('/braincare/organizations');
        dispatch(addToast('error', LOAD_FAILURE));
      });
  }, []);

  const validateForm = () => {
    const errors = new Map();

    if (enableAutoLogoutPortal) {
      if (!inactivityTimePortal) {
        errors.set('inactivityTimePortal', INACTIVITY_TIME_EMPTY);
      }
    }

    if (inactivityTimePortal !== '' && inactivityTimePortal !== null) {
      if (inactivityTimePortal < 1) {
        errors.set('inactivityTimePortal', INACTIVITY_TIME_LOW);
      }
    }

    if (enableAutoLogoutApp) {
      if (!inactivityTimeApp) {
        errors.set('inactivityTimeApp', INACTIVITY_TIME_EMPTY);
      }
    }

    if (inactivityTimeApp !== '' && inactivityTimeApp !== null) {
      if (inactivityTimeApp < 1) {
        errors.set('inactivityTimeApp', INACTIVITY_TIME_LOW);
      }
    }

    if (autoSuspensionMembers) {
      if (!maxInactivityPeriod) {
        errors.set('maxInactivityPeriod', MAX_INACTIVITY_PERIOD_EMPTY);
      }
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateOrganization(organizationId, {
        auto_logout_portal: enableAutoLogoutPortal,
        auto_logout_time_portal: inactivityTimePortal === '' ? null : inactivityTimePortal,
        auto_logout_app: enableAutoLogoutApp,
        auto_logout_time_app: inactivityTimeApp === '' ? null : inactivityTimeApp,
        suspend_inactive_members: autoSuspensionMembers,
        maximum_inactive_period: maxInactivityPeriod === '' ? null : maxInactivityPeriod,
      })
        .then(() => {
          setIsSubmitting(false);
          dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
        });
    } else {
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  return (
    <App title={name}>
      <Section maxWidth="428px">
        <Subtitle>
          <I18n path="organization.organization-settings.inactivity-policy.logout-policy-subtitle" />
        </Subtitle>

        <Text isItalic>
          <I18n path="organization.organization-settings.inactivity-policy.logout-policy-text" />
        </Text>

        {isLoading ? (
          <SectionLoader />
        ) : (
          <SlideRightFadeContainer>
            <Section>
              <SubSubtitle>
                <I18n path="organization.organization-settings.inactivity-policy.portal-logout-subsubtitle" />
              </SubSubtitle>

              <FormControlLabel
                label={
                  <I18n path="organization.organization-settings.inactivity-policy.portal-logout-enable-checkbox" />
                }
                control={
                  <Checkbox
                    checked={enableAutoLogoutPortal}
                    onClick={() => setEnableAutoLogoutPortal(!enableAutoLogoutPortal)}
                    inputProps={{ 'data-testid': 'auto-logout-portal-checkbox' }}
                  />
                }
              />

              <TextField
                id="inactivity_time_portal"
                sx={{ margin: '16px 0 32px 0' }}
                value={inactivityTimePortal}
                onChange={(event) => setInactivityTimePortal(formatNumber(event.target.value))}
                label={<I18n path="organization.organization-settings.inactivity-policy.portal-logout-time-input" />}
                variant="outlined"
                error={errors.has('inactivityTimePortal')}
                helperText={errors.get('inactivityTimePortal')}
                data-testid="auto-logout-portal-text-field"
                inputProps={{ 'data-testid': 'auto-logout-portal-input' }}
                FormHelperTextProps={{ 'data-testid': 'auto-logout-portal-error' }}
                fullWidth
              />

              <SubSubtitle>
                <I18n path="organization.organization-settings.inactivity-policy.app-logout-subsubtitle" />
              </SubSubtitle>

              <FormControlLabel
                label={<I18n path="organization.organization-settings.inactivity-policy.app-logout-enable-checkbox" />}
                control={
                  <Checkbox
                    checked={enableAutoLogoutApp}
                    onClick={() => setEnableAutoLogoutApp(!enableAutoLogoutApp)}
                    inputProps={{ 'data-testid': 'auto-logout-app-checkbox' }}
                  />
                }
              />

              <TextField
                id="inactivity_time_portal"
                sx={{ marginTop: 2 }}
                value={inactivityTimeApp}
                onChange={(event) => setInactivityTimeApp(formatNumber(event.target.value))}
                label={<I18n path="organization.organization-settings.inactivity-policy.app-logout-time-input" />}
                variant="outlined"
                error={errors.has('inactivityTimeApp')}
                helperText={errors.get('inactivityTimeApp')}
                data-testid="auto-logout-app-text-field"
                inputProps={{ 'data-testid': 'auto-logout-app-input' }}
                FormHelperTextProps={{ 'data-testid': 'auto-logout-app-error' }}
                fullWidth
              />
            </Section>

            <Section>
              <Subtitle>
                <I18n path="organization.organization-settings.inactivity-policy.auto-suspension-subtitle" />
              </Subtitle>

              <Section>
                <Text isItalic>
                  <I18n path="organization.organization-settings.inactivity-policy.auto-suspension-text" />
                </Text>

                <FormControlLabel
                  label={<I18n path="organization.organization-settings.inactivity-policy.auto-suspension-checkbox" />}
                  control={
                    <Checkbox
                      checked={autoSuspensionMembers}
                      onClick={() => setAutoSuspensionMembers(!autoSuspensionMembers)}
                      inputProps={{ 'data-testid': 'auto-suspension-members-checkbox' }}
                    />
                  }
                />

                <TextField
                  id="maxInactivityPeriod"
                  sx={{ marginTop: 2 }}
                  value={maxInactivityPeriod}
                  onChange={(event) => setMaxInactivityPeriod(formatNumber(event.target.value))}
                  label={<I18n path="organization.organization-settings.inactivity-policy.auto-suspension-input" />}
                  variant="outlined"
                  error={errors.has('maxInactivityPeriod')}
                  helperText={errors.get('maxInactivityPeriod')}
                  data-testid="max-inactivity-period-text-field"
                  inputProps={{ 'data-testid': 'max-inactivity-period-input' }}
                  FormHelperTextProps={{ 'data-testid': 'max-inactivity-period-error' }}
                  fullWidth
                />
              </Section>
            </Section>

            <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
              <I18n path="organization.organization-settings.inactivity-policy.submit-button-text" />
            </Button>
            <Button
              sx={{ marginLeft: 2 }}
              variant="outlined"
              onClick={() => navigate('/braincare/organizations')}
              data-testid="cancel-button"
            >
              <I18n path="organization.organization-settings.inactivity-policy.cancel-button-text" />
            </Button>
          </SlideRightFadeContainer>
        )}
      </Section>
    </App>
  );
}

export default braincare(AdminOrganizationInactivityPolicy);
