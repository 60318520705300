import I18n, { translate } from 'utils/i18n';
import PasswordValidator from './PasswordValidator';

export default class PersonalInformationValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { excludePersonalInformation } = passwordPolicy;

    let fields = '';

    if (excludePersonalInformation) {
      const fieldsTranslations = new Map([
        ['email', translate('organization.password-rules.email-field')],
        ['phone_number', translate('organization.password-rules.corporate-phone-number-field')],
        ['user__document', translate('organization.password-rules.document-field')],
        ['user__first_name', translate('organization.password-rules.first-name-field')],
        ['user__last_name', translate('organization.password-rules.last-name-field')],
        ['user__email', translate('organization.password-rules.user-email-field')],
        ['user__phone_number', translate('organization.password-rules.user-phone-number-field')],
        ['organization__name', translate('organization.password-rules.organization-name-field')],
      ]);

      if (excludePersonalInformation.fields) {
        excludePersonalInformation.fields.forEach((field) => {
          if (fieldsTranslations.has(field)) {
            if (fields.length !== 0) {
              fields += ', ';
            }

            fields += fieldsTranslations.get(field);
          }
        });
      }
    }

    let translation = null;

    if (fields !== '') {
      translation = <I18n props={{ fields }} path="organization.password-rules.exclude-personal-information" />;
    }

    super(excludePersonalInformation, 'exclude_personal_information', true, translation);
  }
}
