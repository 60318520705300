import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const InputRadio = ({ id, value, name, isChecked, isDisabled, onChange, label, className, ...props }) => (
  <label htmlFor={id} className={classNames(styles.container, className)}>
    <input
      className={classNames(styles.radio, { [styles.disabled]: isDisabled })}
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={isChecked}
      onChange={onChange}
      disabled={isDisabled}
      {...props}
    />
    <div className={classNames(styles.label, { [styles.disabledLabel]: isDisabled })}>{label}</div>
  </label>
);

InputRadio.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

InputRadio.defaultProps = {
  name: undefined,
  isChecked: false,
  isDisabled: false,
  onChange: () => {},
  label: undefined,
  className: undefined,
};

export default InputRadio;
