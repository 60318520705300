import Button from 'components/mui/Button';
import TextField from 'components/mui/TextField';
import Section from 'components/atoms/section';
import SubSubtitle from 'components/atoms/sub-subtitle';
import Subtitle from 'components/atoms/subtitle';
import App from 'pages/organization/organization-settings/App';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from 'utils/format-number';
import I18n from 'utils/i18n';
import Text from 'components/atoms/text';
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from 'actions/toasts';
import { setActiveOrganizationData, setOrganizations } from 'features/userSlice';
import { SlideRightFadeContainer } from 'utils/transitions';
import { updateMemberOrganization } from 'api/portal';
import FormControlLabel from 'components/mui/FormControlLabel';
import { Checkbox } from '@mui/material';
import Dialog from 'components/atoms/dialog';
import DialogHeader from 'components/atoms/dialog-header';
import DialogBody from 'components/atoms/dialog-body';
import DialogFooter from 'components/atoms/dialog-footer';

const AUTO_PURGE_PERIOD_EMPTY = <I18n path="organization.organization-settings.reports.auto-purge-period-empty" />;
const SAVE_SUCCESS_MESSAGE = <I18n path="organization.organization-settings.save-success-message" />;
const SAVE_FAILURE_MESSAGE = <I18n path="organization.organization-settings.save-failure-message" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;

function OrganizationReportsPolicies() {
  const organization = useSelector((state) => state.user.active_organization);
  const organizations = useSelector((state) => state.user.organizations);

  const [globalAutoShare, setGlobalAutoShare] = useState(organization.global_auto_share);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [autoPurge, setAutoPurge] = useState(organization.auto_purge);
  const [autoPurgePeriod, setAutoPurgePeriod] = useState(organization.expiration_days || '');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateForm = () => {
    const errors = new Map();

    if (autoPurge) {
      if (autoPurgePeriod === '') {
        errors.set('autoPurgePeriod', AUTO_PURGE_PERIOD_EMPTY);
      }
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateMemberOrganization({
        global_auto_share: globalAutoShare,
        auto_purge: autoPurge,
        expiration_days: autoPurgePeriod === '' ? null : autoPurgePeriod,
      })
        .then((response) => {
          const { data } = response;
          setIsSubmitting(false);

          dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
          dispatch(setActiveOrganizationData(data));
          dispatch(
            setOrganizations(
              organizations.map((item) => {
                if (item.id === data.id) {
                  return data;
                }

                return item;
              }),
            ),
          );
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
        });
    } else {
      dispatch(addToast('error', FIX_FIELDS));
    }
  };

  return (
    <App>
      <Section maxWidth="428px">
        <SlideRightFadeContainer>
          <Subtitle>
            <I18n path="organization.organization-settings.reports.subtitle" />
          </Subtitle>

          <Dialog isOpen={isConfirmDialogOpen} data-testid="confirm-auto-purge-dialog">
            <DialogHeader type="warning">
              <I18n path="organization.organization-settings.reports.auto-purge-confirmation-title" />
            </DialogHeader>
            <DialogBody>
              <Text>
                <I18n path="organization.organization-settings.reports.auto-purge-confirmation-body" />
              </Text>
            </DialogBody>
            <DialogFooter>
              <Button
                fullWidth
                onClick={() => {
                  setIsConfirmDialogOpen(false);
                  setAutoPurge(true);
                }}
                loading={false}
                data-testid="confirm-auto-purge-button"
              >
                <I18n path="organization.organization-settings.reports.auto-purge-confirmation-confirm-button" />
              </Button>
              <Button fullWidth type="primary" onClick={() => setIsConfirmDialogOpen(false)}>
                <I18n path="organization.organization-settings.reports.auto-purge-confirmation-cancel-button" />
              </Button>
            </DialogFooter>
          </Dialog>

          <Section>
            <br />
            <SubSubtitle>
              <I18n path="organization.organization-settings.reports.global-auto-share-subsubtitle" />
            </SubSubtitle>

            <Text isItalic>
              <I18n path="organization.organization-settings.reports.global-auto-share-text" />
            </Text>

            <FormControlLabel
              label={<I18n path="organization.organization-settings.reports.global-auto-share-checkbox" />}
              control={
                <Checkbox
                  checked={globalAutoShare}
                  inputProps={{ 'data-testid': 'auto-share-checkbox-input' }}
                  onClick={() => setGlobalAutoShare(!globalAutoShare)}
                  data-testid="auto-share-checkbox"
                />
              }
            />
          </Section>

          <Section>
            <SubSubtitle>
              <I18n path="organization.organization-settings.reports.auto-purge-subsubtitle" />
            </SubSubtitle>

            <Text isItalic>
              <I18n path="organization.organization-settings.reports.auto-purge-text" />
            </Text>

            <FormControlLabel
              label={<I18n path="organization.organization-settings.reports.auto-purge-checkbox" />}
              control={
                <Checkbox
                  checked={autoPurge}
                  inputProps={{ 'data-testid': 'auto-purge-checkbox-input' }}
                  onClick={() => {
                    if (autoPurge) {
                      setAutoPurge(false);
                    } else {
                      setIsConfirmDialogOpen(true);
                    }
                  }}
                  data-testid="auto-purge-checkbox"
                />
              }
            />

            <TextField
              id="autoPurgePeriod"
              sx={{ marginTop: 2 }}
              value={autoPurgePeriod}
              onChange={(event) => setAutoPurgePeriod(formatNumber(event.target.value))}
              label={<I18n path="organization.organization-settings.reports.auto-purge-input" />}
              variant="outlined"
              error={errors.has('autoPurgePeriod')}
              helperText={errors.get('autoPurgePeriod')}
              data-testid="auto-purge-text-field"
              inputProps={{ 'data-testid': 'auto-purge-input' }}
              FormHelperTextProps={{ 'data-testid': 'auto-purge-error' }}
              fullWidth
            />
          </Section>

          <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
            <I18n path="organization.organization-settings.reports.submit-button-text" />
          </Button>
          <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate('/')} data-testid="cancel-button">
            <I18n path="organization.organization-settings.reports.cancel-button-text" />
          </Button>
        </SlideRightFadeContainer>
      </Section>
    </App>
  );
}

export default OrganizationReportsPolicies;
