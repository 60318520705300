import PropTypes from 'prop-types';

const Wizard = ({ children, className }) => <div className={className}>{children}</div>;

Wizard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Wizard.defaultProps = {
  className: undefined,
};

export default Wizard;
