import PropTypes from 'prop-types';
import Wizard from 'components/atoms/wizard';
import Step from 'components/atoms/step';
import App from 'components/templates/app';
import styles from './styles.module.css';

const AppWizard = ({ className, steps, currentStep, breadcrumbs, title, children }) => {
  const getStepType = (itemStep) => {
    if (itemStep < currentStep) {
      return 'finished';
    }
    if (itemStep > currentStep) {
      return 'next';
    }

    return 'current';
  };

  return (
    <App className={className} breadcrumbs={breadcrumbs} title={title}>
      <div className={styles.contentSection}>
        <Wizard className={styles.menu}>
          {steps.map((step, index) => (
            <Step key={`step-${index + 1}`} index={index + 1} type={getStepType(index)}>
              {step}
            </Step>
          ))}
        </Wizard>
        <div className={styles.separator} />
        <div className={styles.main}>{children}</div>
      </div>
    </App>
  );
};

AppWizard.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.instanceOf(Array).isRequired,
  breadcrumbs: PropTypes.instanceOf(Array),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

AppWizard.defaultProps = {
  className: '',
  breadcrumbs: undefined,
  title: undefined,
};

export default AppWizard;
