import styled from 'styled-components';

import LoginImage from 'assets/images/login-background.png';
import Section from 'components/atoms/section';

export const Logo = styled(Section)`
  background-image: url(${LoginImage});
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  background-color: var(--color-white);
  height: 100%;
  margin-bottom: 0;
  padding: 60px;
  color: var(--color-primary);
  font-size: 32px;
  font-weight: 300;
`;
