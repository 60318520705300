import Input from 'components/atoms/input';
import classNames from 'classnames';
import { Chip } from '@mui/material';
import styles from './styles.module.css';
import LabeledInput from '../labeled-input';

function ChipInput({
  disabled = false,
  errorMessage,
  hasError,
  items,
  label,
  onCloseItem,
  onChange = () => {},
  onKeyPress = () => {},
  value,
  ...props
}) {
  return (
    <>
      <LabeledInput isDisabled={disabled} label={label} {...props}>
        <div className={classNames(styles.labeledInput, { [styles.error]: hasError })}>
          {items.map((item) => (
            <Chip
              key={item}
              label={item}
              onDelete={() => onCloseItem(item)}
              sx={{ margin: 1 }}
              data-testid={`chip-${item}`}
            />
          ))}

          <Input
            wrapperClassName={styles.inputWrapper}
            className={styles.input}
            isDisabled={disabled}
            value={value}
            onKeyPress={(event) => onKeyPress(event)}
            onChange={onChange}
            data-testid="chip-input"
          />
        </div>
      </LabeledInput>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </>
  );
}

export default ChipInput;
