import ProfileCard from 'components/molecules/profile-card';
import Loader from 'components/molecules/section-loader';
import IconLockOpened from 'assets/images/ic_lock_opened.svg';
import IconLockClosed from 'assets/images/ic_lock_closed.svg';
import styles from './styles.module.css';
import Footer from '../../../../components/organisms/Footer';

function AccountList({ accounts, isLoading, loggoutText, mainText, onAccountSelected }) {
  return (
    <div className={styles.accountsContainer}>
      {mainText}
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.accountList}>
          {accounts.map((account, index) => {
            let icon;

            if (account.login_status === false) {
              icon = IconLockOpened;
            }

            if (account.login_status === true) {
              icon = IconLockClosed;
            }

            return (
              <ProfileCard
                key={account.id}
                picture={account.photo}
                pictureDescription="Account"
                title={account.name}
                subtitle={account.address}
                delay={(index + 1) * 200}
                onClick={() => onAccountSelected(account)}
                icon={icon}
                data-testid={`account-${account.id}`}
              />
            );
          })}
        </div>
      )}
      {loggoutText}
      <Footer />
    </div>
  );
}

export default AccountList;
