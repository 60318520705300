import I18n from 'utils/i18n';
import PasswordValidator from './PasswordValidator';

export default class CommonPasswordValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { excludeCommonPasswords } = passwordPolicy;

    super(
      excludeCommonPasswords,
      'exclude_common_passwords',
      true,
      excludeCommonPasswords ? <I18n path="organization.password-rules.exclude-common-passwords" /> : null,
    );
  }
}
