import AuthTitle from 'components/atoms/auth-title';
import Section from 'components/atoms/section';
import Text from 'components/atoms/text';
import Subtitle from 'components/atoms/subtitle';
import I18n from 'utils/i18n';
import styles from '../styles.module.css';

export default function TermsAndServicesEnglish() {
  return (
    <Section className={styles.section}>
      <AuthTitle>
        <I18n path="auth.terms-and-service-en.title00" />
      </AuthTitle>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title01" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service-en.text00" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text01" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text02" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title02" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service-en.text03" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text04" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title03" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service-en.text05" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text06" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text07" />
        <br />
        <br />
        <ul>
          <li>
            <I18n path="auth.terms-and-service-en.text08" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text09" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text10" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text11" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text12" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text13" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text14" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text15" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text16" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text17" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text18" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text19" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text20" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text21" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text22" />
          </li>
          <li>
            <I18n path="auth.terms-and-service-en.text23" />
          </li>
        </ul>
        <I18n path="auth.terms-and-service-en.text24" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text25" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text26" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title04" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text27" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text28" />
        <br />
        <br />
      </Text>
      <Text className={styles['text-center']}>
        <strong>
          <I18n path="auth.terms-and-service-en.title05" />
        </strong>
        <br />
        <I18n path="auth.terms-and-service-en.text29" />
        <br />
        <I18n path="auth.terms-and-service-en.text30" />
      </Text>
      <Text className={styles['text-center']}>
        <strong>
          <I18n path="auth.terms-and-service-en.title06" />
        </strong>
        <br />
        <I18n path="auth.terms-and-service-en.text31" />
        <br />
        <I18n path="auth.terms-and-service-en.text32" />
        <br />
        <br />
      </Text>
      <Text>
        <I18n path="auth.terms-and-service-en.text33" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title07" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text34" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text35" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text36" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title08" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text37" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text38" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text39" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title09" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text40" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text41" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text42" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text43" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text44" />
        <br />
        <I18n path="auth.terms-and-service-en.text45" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title10" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text46" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text47" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text48" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text49" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text50" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title11" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text51" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text52" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text53" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text54" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text55" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text56" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text57" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title12" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text58" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text59" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text60" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text61" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title13" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text62" />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service-en.title14" />
      </Subtitle>
      <Text>
        <I18n path="auth.terms-and-service-en.text63" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text64" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text65" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text66" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text67" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text68" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text69" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text70" />
        <br />
        <br />
        <I18n path="auth.terms-and-service-en.text71" />
      </Text>
    </Section>
  );
}
