import { useState } from 'react';
import { addToast } from 'actions/toasts';
import AuthTitle from 'components/atoms/auth-title';
import Subtitle from 'components/atoms/subtitle';
import Text from 'components/atoms/text';
import Button from 'components/atoms/button';
import I18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setActiveOrganizationData,
  setOrganizationProfileData,
  setOrganizations,
  setProfileData,
} from 'features/userSlice';
import { directLogin, fetchOrganizationProfile } from 'api/portal';
import { getToken, setToken, CORPORATE_TOKEN, PERSONAL_TOKEN } from 'utils/tokens';
import LoginForm from '../common/LoginForm';
import styles from './styles.module.css';
import AccountList from '../common/AccountList';
import LoginDialog from '../common/LoginDialog';
import Timeout from '../../../components/routes/timeout';

const USER_BLOCKED = <I18n path="auth.login.user-blocked" />;
const INVALID_CREDENTIAL = <I18n path="auth.login.auth-error" />;
const LOAD_ORGANIZATION_PROFILE_FAILURE = <I18n path="messages.load-organization-profiles-failure" />;

function DirectLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profile, setProfile] = useState(undefined);
  const [credentials, setCredentials] = useState({});
  const [accounts, setAccounts] = useState(undefined);
  const [account, setAccount] = useState(undefined);
  const [errors, setErrors] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isValidTokenType = (data) => data.token_type === PERSONAL_TOKEN || data.token_type === CORPORATE_TOKEN;

  const savePersonalData = (data) => {
    // Persist personal data on Redux
    if (isValidTokenType(data)) {
      const { user } = data;

      setProfile(user);
      dispatch(setProfileData(user));
    } else {
      setProfile({ first_name: data.first_name });
    }
  };

  const saveTokenData = (data) => {
    if (isValidTokenType(data)) {
      const accessToken = data.access_token;
      const refreshToken = data.refresh_token;

      // Persist returned tokens on Redux
      setToken('access_token', accessToken);
      setToken('refresh_token', refreshToken);
      // If tokens are personal, save them as it
      if (data.token_type === PERSONAL_TOKEN) {
        setToken('personal_access_token', accessToken);
        setToken('personal_refresh_token', refreshToken);
      }
    }
  };

  const saveCurrentOrganization = (account) => {
    if (account.name !== 'Personal') {
      dispatch(setActiveOrganizationData(account));
    }
  };

  const saveOrganizationData = (data) => {
    const { accounts } = data;
    // Persist the organizations concerned by all the listed accounts, excluding the the personal account
    dispatch(setOrganizations(accounts.slice(1)));
    // If the login is a corporate one, persist organization that owns the authenticated member
    if (data.token_type === CORPORATE_TOKEN) {
      accounts.forEach((account) => {
        if (account.login_status) {
          saveCurrentOrganization(account);
        }
      });
    }
  };

  const fetchCorporateData = (data) => {
    if (data.token_type === CORPORATE_TOKEN) {
      fetchOrganizationProfile()
        .then((response) => {
          const { data } = response;
          dispatch(setOrganizationProfileData(data));
        })
        .catch(() => {
          dispatch(addToast('error', LOAD_ORGANIZATION_PROFILE_FAILURE));
        });
    }
  };

  const onSubmitLoginForm = (formData) => {
    setIsSubmitting(true);

    directLogin({
      document: formData.document,
      password: formData.password,
    })
      .then((response) => {
        const { data } = response;

        setIsSubmitting(false);

        savePersonalData(data);
        saveTokenData(data);
        saveOrganizationData(data);
        fetchCorporateData(data);

        const tokenType = data.token_type;
        const { accounts } = data;

        if (tokenType) {
          const { user } = data;

          if (user) {
            const hasAcceptedTerms = user.accept_terms;

            if (hasAcceptedTerms === false) {
              navigate('/auth/accept-terms?next=/');
              return;
            }

            if (tokenType === PERSONAL_TOKEN) {
              if (accounts.length === 1 && accounts[0].login_status === true) {
                navigate('/');
              } else if (hasAcceptedTerms === true) {
                setCredentials({
                  document: formData.document,
                  password: formData.password,
                });
                setAccounts(accounts);
              }
            }

            if (tokenType === CORPORATE_TOKEN) {
              navigate('/');
            }
          }
        } else {
          setCredentials({
            document: formData.document,
            password: formData.password,
          });
          setAccounts(accounts);
        }
      })
      .catch((error) => {
        const { response } = error;

        const { data } = response;

        setIsSubmitting(false);

        if (data.hasOwnProperty('blocked')) {
          dispatch(addToast('error', USER_BLOCKED));
          return;
        }

        dispatch(addToast('error', INVALID_CREDENTIAL));
      });
  };

  const directLoginWithAccount = (document, password, account) => {
    directLogin({ document, password, account: account.id })
      .then((response) => {
        const { data } = response;

        setIsSubmitting(false);
        setIsLoading(false);

        savePersonalData(data);
        saveTokenData(data);
        saveCurrentOrganization(account);
        fetchCorporateData(data);

        if (isValidTokenType(data)) {
          const { user } = data;

          if (user) {
            const hasAcceptedTerms = user.accept_terms;

            if (hasAcceptedTerms === false) {
              navigate('/auth/accept-terms?next=/');
              return;
            }

            navigate('/');
          }
        }
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        setIsSubmitting(false);
        setIsLoading(false);

        if (data.hasOwnProperty('blocked')) {
          setErrors(new Map([['password', USER_BLOCKED]]));
          return;
        }

        setErrors(new Map([['password', INVALID_CREDENTIAL]]));
      });
  };

  const onSubmitLoginDialog = (formData) => {
    setIsSubmitting(true);
    directLoginWithAccount(credentials.document, formData.password, account);
  };

  const onAccountSelected = (account) => {
    const accessToken = getToken('access_token');
    const refreshToken = getToken('refresh_token');

    if (account.login_status) {
      if (accessToken === undefined || refreshToken === undefined) {
        setIsLoading(true);
        directLoginWithAccount(credentials.document, credentials.password, account);
      } else {
        navigate('/');
      }
    } else {
      setAccount(account);
    }
  };

  const getMainText = () => (
    <>
      <Subtitle className={styles.accountUser}>
        <I18n path="auth.accounts-start.title-main-account" props={{ firstname: profile.first_name }} />
      </Subtitle>
      <AuthTitle className={styles.accountTitle}>
        <I18n path="auth.accounts-start.title-sub-account-center" />
      </AuthTitle>
    </>
  );

  const getLoggoutText = () => (
    <Text size="medium" className={styles.signOutLink}>
      <I18n path="auth.accounts-start.text-account-not-user" props={{ firstname: profile.first_name }} />
      <Button type="ghost" onClick={() => navigate('/auth/logout')}>
        <I18n path="auth.accounts-start.button-logout" />
      </Button>
    </Text>
  );

  const getSelectedOrganization = () => {
    if (account) {
      if (account.name === 'Personal') {
        return undefined;
      }

      return account;
    }

    return undefined;
  };

  if (accounts) {
    return (
      <Timeout>
        <AccountList
          mainText={getMainText()}
          accounts={accounts}
          isLoading={isLoading}
          onAccountSelected={(account) => onAccountSelected(account)}
          loggoutText={getLoggoutText()}
        />
        <LoginDialog
          organization={getSelectedOrganization()}
          isOpen={account !== undefined}
          onClose={() => {
            setAccount(undefined);
            setErrors(new Map());
          }}
          isSubmitting={isSubmitting}
          onSubmit={(data) => onSubmitLoginDialog(data)}
          errors={errors}
        />
      </Timeout>
    );
  }

  return <LoginForm isSubmitting={isSubmitting} onSubmit={onSubmitLoginForm} />;
}

export default DirectLogin;
