import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClickOutside from 'react-outside-click-handler';
import protocolIcon from 'assets/images/ic_monitorization.svg';
import { ANALYTICS_REPORTS_URL } from 'config/settings';
import { toast } from 'actions/toasts';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Option from 'components/atoms/option';
import Select from 'components/atoms/input-select';
import Avatar from 'components/atoms/avatar';
import Table, { Head, Body, Row, Cell } from 'components/atoms/table';
import Input from 'components/atoms/input';
import PopupMenu from 'components/atoms/popup-menu';
import PopupMenuItem from 'components/atoms/popup-menu-item';
import ProfileTag from 'components/molecules/profile-tag';
import LabeledInput from 'components/molecules/labeled-input';
import InputIcon from 'components/molecules/input-icon';
import ShareMonitorizationDialog from 'components/molecules/share-monitorization-dialog';
import SharedWithDialog from 'components/molecules/shared-with-dialog';
import Loader from 'components/molecules/section-loader';
import Dropdown from 'components/molecules/dropdown';
import DropdownItem from 'components/molecules/account-item';
import Pagination from 'components/organisms/pagination';
import { stringFromDate, formatFromString, getValidDate } from 'utils/format-date';
import { SlideFadeInContainer } from 'utils/transitions';
import I18n, { translate } from 'utils/i18n';
import Cookie from 'js-cookie';
import getLanguage from 'utils/get-language';
import { formatDuration } from 'utils/humanize-date';
import formatDate from 'utils/format-full-date';
import formatTime from 'utils/format-time';
import { analyticsApi, clientPhiApi, portalApi } from 'api/http';
import styles from './styles.module.css';
import TagsSelectDialog from '../TagFilterDialog';

const LOADING = <I18n path="messages.loading" />;
const FETCH_DATA_FAILURE = <I18n path="messages.fetch-data-failure" />;

class Monitorizations extends Component {
  static propTypes = {
    isOrganization: PropTypes.bool,
    showActivateProtocolHandler: PropTypes.func,
    shouldRefresh: PropTypes.bool,
    onRefreshSuccess: PropTypes.func,
  };

  static defaultProps = {
    isOrganization: false,
    shouldRefresh: false,
    onRefreshSuccess: () => {},
    showActivateProtocolHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  state = {
    patientName: '',
    protocol: '',
    doctor: '',
    tags: [],
    selectedTags: [],
    showFilterByTags: false,
    date: new Date(),
    formatedDate: '',
    decryptKeys: [],
    decryptData: {},
    acquisitions: [],
    acquisitionsKeys: null,
    showReportDialog: false,
    showSharedWithDialog: false,
    showDropdownMembers: false,
    acceptSharingTerms: false,
    members: [],
    protocolToShare: undefined,
    orgToShare: undefined,
    patientsFilterMatch: [],
    patientsFilterSelected: [],
    showDropdownPatients: false,
    isLoading: false,
    isLoadingDoctors: false,
    isLoadingPatients: false,
    isFirstAccess: false,
    doctors: [],
    isDownloadingReport: {},
    sharedWith: [],
    rows: 0,
    currentPage: 1,
    limitPerPage: 10,
  };

  componentDidMount() {
    this.mounted = true;

    analyticsApi({ statusException: false })
      .get('tags')
      .then((response) => {
        const { data, error } = response.data;

        if (!error) {
          this.setState({ tags: data.values });
        }
      });

    this.setState(
      {
        isLoading: true,
        isFirstAccess: true,
      },
      this.fetchAcquisitionsHandler,
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps) {
    const { shouldRefresh, onRefreshSuccess } = nextProps;

    if (shouldRefresh) {
      this.fetchAcquisitionsHandler();
      onRefreshSuccess();
    }
  }

  onFilterClick = () => this.setState({ currentPage: 1 }, this.fetchAcquisitionsHandler);

  onClearClick = () => {
    this.setState(
      {
        patientsFilterSelected: [],
        patientName: '',
        protocol: '',
        doctor: '',
        formatedDate: '',
        currentPage: 1,
      },
      this.fetchAcquisitionsHandler,
    );
  };

  onFilterByTagsClick = () => {
    this.setState({ showFilterByTags: true });
  };

  onPaginationChange = (page) => this.setState({ currentPage: page }, this.fetchAcquisitionsHandler);

  onConfirmShareHandler = (text, type) => {
    toast(type, text);
  };

  fetchAcquisitionsHandler = () => {
    if (!this.mounted) return;
    const { showActivateProtocolHandler } = this.props;
    const {
      patientsFilterSelected,
      protocol,
      doctor,
      formatedDate,
      currentPage,
      limitPerPage,
      isFirstAccess,
      selectedTags,
    } = this.state;

    this.setState({ isLoading: true });

    analyticsApi()
      .post('acquisitions', {
        filters: {
          patient_names: patientsFilterSelected.map((patient) => patient.id),
          tags: selectedTags,
          acquisition_id: [protocol],
          doctor_id: String(doctor),
          start_time: formatedDate,
        },
        paging: {
          page: currentPage,
          limit: limitPerPage,
        },
      })
      .then((response) => {
        if (!this.mounted) return;

        const { data, error } = response.data;

        this.setState({ isLoading: false, isFirstAccess: false });

        if (!error) {
          if (isFirstAccess && data.acquisitions.paging.count === 0) {
            showActivateProtocolHandler(true);
            return;
          }

          this.setState({
            acquisitions: data.acquisitions.values,
            acquisitionsKeys: data.acquisitions.keys,
            rows: data.acquisitions.paging.count,
          });

          this.fetchPatientsHashesHandler();
          this.fetchDoctorHandler();
          return;
        }

        this.onFetchDataFailure();
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState({ isLoading: false });
        this.onFetchDataFailure();
      });
  };

  fetchDoctorHandler = () => {
    if (!this.mounted) return;

    this.setState({ isLoadingDoctors: true });

    analyticsApi()
      .get('doctors')
      .then((response) => {
        if (!this.mounted) return;

        const { data, error } = response.data;

        this.setState({ isLoadingDoctors: false });

        if (!error && data && !data.error) {
          this.handleDoctorsFetch(data.values);
          return;
        }

        this.onFetchDataFailure();
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState({ isLoadingDoctors: false });
        this.onFetchDataFailure();
      });
  };

  fetchPatientsHashesHandler = () => {
    if (!this.mounted) return;

    this.setState({ isLoadingPatients: true });

    analyticsApi()
      .get('patients-hashes')
      .then((response) => {
        if (!this.mounted) return;

        const { data, error } = response.data;

        this.setState({ isLoadingPatients: false });

        if (!error) {
          const keys = [...data.keys];
          const dataGroupedByOrgId = this.groupPatientsByOrgId(data);

          this.fetchDecryptData(keys, dataGroupedByOrgId);
          return;
        }

        this.onFetchDataFailure();
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState({ isLoadingPatients: false });
        this.onFetchDataFailure();
      });
  };

  fetchDecryptData = (keys, dataObj) => {
    if (!this.mounted) return;
    Object.entries(dataObj).forEach(([key, value]) => {
      this.setState({ isLoadingPatients: true });
      const anonymous = value.filter((item) => item[0] === 'Anonymous');

      clientPhiApi(key)
        .post('decrypt', {
          hashes: {
            keys: ['patientHash', 'birthdayHash', 'dbId', 'name', 'birthday'],
            values: value.filter((item) => item[0] !== 'Anonymous'),
          },
        })
        .then((response) => {
          if (!this.mounted) return;

          const { data, error } = response.data;

          this.setState({ isLoadingPatients: false });

          if (!error) {
            const patientObj = {};
            data.hashes.values.forEach((patientData) => {
              patientObj[patientData[0]] = patientData;
            });

            anonymous.forEach((patientData) => {
              patientObj[patientData[0]] = patientData;
            });

            this.setState((prevState) => ({
              decryptData: { ...prevState.decryptData, ...patientObj },
              decryptKeys: [...data.hashes.keys],
            }));

            return;
          }

          this.onFetchDataFailure();
        })
        .catch(() => {
          this.setState({ isLoadingPatients: false });
          this.onFetchDataFailure();
        });
    });
  };

  downloadReport = (protocolId, orgId, index) => {
    if (!this.mounted) return;
    this.setState((prevState) => ({
      isDownloadingReport: { ...prevState.isDownloadingReport, [index]: true },
    }));

    analyticsApi()
      .post('report', {
        acquisition_id: protocolId,
        org_id: orgId,
      })
      .then((response) => {
        if (!this.mounted) return;

        const { data, error } = response.data.blob();

        this.setState((prevState) => ({
          isDownloadingReport: {
            ...prevState.isDownloadingReport,
            [index]: false,
          },
        }));

        if (!error) {
          const report = URL.createObjectURL(data);
          window.open(report, '_blank');
          return;
        }

        this.onFetchDataFailure();
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState((prevState) => ({
          isDownloadingReport: {
            ...prevState.isDownloadingReport,
            [index]: false,
          },
        }));
        this.onFetchDataFailure();
      });
  };

  downloadHospitalReport = (protocolId, orgId, index) => {
    // downloadReport V2 - new report
    if (!this.mounted) return;
    this.setState((prevState) => ({
      isDownloadingReport: { ...prevState.isDownloadingReport, [index]: true },
    }));

    analyticsApi()
      .post('report-authorization', {
        // 1. request para o physio API - get fileToken, confirmar qual eh a rota com lucas
        acquisition_id: protocolId,
        report_language: getLanguage(),
        report_format: 'PDF',
      })
      .then((response) => {
        const { data, error } = response.data;
        // 2. apos receber o tokenFile request report file using received token
        // 3. chamar lambda hospital - passar tokenFile. confirmar com lucas estrutura do json data
        clientPhiApi(orgId, true)
          .post('report', {
            token: data,
          })
          .then((response) => {
            // 4. hospital devolve o link do pdf no S3
            // open pre signed url
            const { data } = response;
            let timeOutCount = 0;

            const interval = setInterval(() => {
              fetch(data.data).then((res) => {
                timeOutCount += 1; // cada tentativa equivale a 5s
                if (res.status === 200) {
                  this.setState((prevState) => ({
                    isDownloadingReport: {
                      ...prevState.isDownloadingReport,
                      [index]: false,
                    },
                  }));
                  if (!error) {
                    window.open(data.data, '_blank'); // 5. abre o link do pdf em uma nova aba
                  }
                  clearInterval(interval);
                }
                if (timeOutCount >= 180) {
                  // atingindo um total de 15min (180 tentativas), da o timeOut
                  // eslint-disable-next-line no-alert
                  alert('Report error, please try again');
                  this.setState((prevState) => ({
                    isDownloadingReport: {
                      ...prevState.isDownloadingReport,
                      [index]: false,
                    },
                  }));
                  clearInterval(interval);
                }
              });
            }, 1000);
          });
      })
      .catch(() => {
        this.setState((prevState) => ({
          isDownloadingReport: {
            ...prevState.isDownloadingReport,
            [index]: false,
          },
        }));
        this.onFetchDataFailure();
      });
  };

  decryptPatientName = (encryptName) => {
    const { decryptData, decryptKeys } = this.state;
    if (encryptName in decryptData) {
      if (encryptName === 'Anonymous') {
        return <I18n path="physio.physio-personal.table-physio-patient-anonymous" />;
      }
      return decryptData[encryptName][decryptKeys.indexOf('name')];
    }
    return <I18n path="physio.physio-personal.table-physio-patient-not-found" />;
  };

  groupPatientsByOrgId = (data) => {
    const keys = [...data.keys];
    const values = [...data.values];
    const orgIndex = keys.indexOf('org_id');

    return values.reduce((acc, obj) => {
      const key = obj[orgIndex];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  shareReport = (protocolId, orgId) => {
    this.setState((prevState) => ({
      protocolToShare: protocolId,
      orgToShare: orgId,
      showReportDialog: !prevState.showReportDialog,
    }));
  };

  handleDoctorsFetch = (values) => {
    this.setState({ isLoadingDoctors: true });

    portalApi()
      .post('doctor-names', { doctors: values })
      .then((response) => {
        if (!this.mounted) return;
        const { data } = response;
        this.setState({ isLoadingDoctors: false, doctors: data });
      })
      .catch(() => {
        if (!this.mounted) return;
        this.setState({ isLoadingDoctors: false });
        this.onFetchDataFailure();
      });
  };

  handleInputChange = (field, event) => {
    this.setState({
      [field]: event.target.value,
    });
  };

  handleDateRange = (date) => {
    if (date instanceof Date) {
      return this.setState({ formatedDate: stringFromDate(date), date });
    }
    return this.setState({
      formatedDate: formatFromString(date.target.value),
      date: getValidDate(date.target.value),
    });
  };

  twoDigitsFormat = (date) => (date < '10' ? `0${date}` : date);

  handleInputToggle = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  handlePatientSearchChange = (event) => {
    const keyword = event.target.value;
    this.setState({ patientName: keyword });

    // TODO fix setTimeout
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.handleSearchPatients(keyword);
    }, 400);
  };

  handleSearchPatients = (keyword) => {
    const { decryptData } = this.state;

    if (keyword.trim() === '') {
      this.setState({ showDropdownPatients: false });
      return;
    }

    const allPatients = Object.values(decryptData);
    const patientsFilterMatch = allPatients.reduce((accum, curr) => {
      if (curr[3].toLowerCase().includes(keyword.toLowerCase())) {
        accum.push(curr);
      }
      return accum;
    }, []);

    this.setState({
      showDropdownPatients: Boolean(patientsFilterMatch.length),
      patientsFilterMatch,
    });
  };

  handlePatientSelected = (id, name) => {
    const { patientsFilterSelected } = this.state;
    this.inputRef.current.focus();

    if (patientsFilterSelected.find((patient) => patient.id === id)) {
      this.setState((prevState) => ({
        patientName: '',
        showDropdownPatients: !prevState.showDropdownPatients,
      }));
      return;
    }

    this.setState((prevState) => ({
      patientsFilterSelected: [...patientsFilterSelected, { id, name }],
      patientName: '',
      showDropdownPatients: !prevState.showDropdownPatients,
    }));
  };

  onFetchDataFailure = () => toast('error', FETCH_DATA_FAILURE);

  onOpenSharedWithDialog = (sharedWith) => {
    this.setState({ showSharedWithDialog: true, sharedWith });
  };

  onCloseShareDialog = (shared) => {
    this.setState((prevState) => ({
      acceptSharingTerms: !prevState.acceptSharingTerms,
      showReportDialog: false,
    }));
    if (shared) this.fetchAcquisitionsHandler();
  };

  onCloseSharedWithDialog = () => {
    this.setState({ showSharedWithDialog: false, sharedWith: [] });
  };

  removePatientOnFilterList = (id) => {
    const { patientsFilterSelected } = this.state;
    this.setState({
      patientsFilterSelected: patientsFilterSelected.filter((member) => member.id !== id),
    });
  };

  formPreventDefault(e) {
    e.preventDefault();
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  renderDropdownPatientsMatch = () => {
    const { patientsFilterMatch, showDropdownPatients } = this.state;

    return (
      <ClickOutside onOutsideClick={() => this.setState({ showDropdownPatients: false })}>
        <Dropdown
          className={classNames(styles.searchDropdown, {
            [styles.dropdownOpen]: showDropdownPatients,
          })}
        >
          {patientsFilterMatch.map((patient, index) => (
            <DropdownItem
              key={index.toString()}
              title={patient[3]}
              className={styles.dropdownItem}
              onClick={() => this.handlePatientSelected(patient[0], patient[3])}
            />
          ))}
        </Dropdown>
      </ClickOutside>
    );
  };

  renderTableAction = (protocolId, orgId, isShared, sharedWith, index) => {
    const { isOrganization } = this.props;
    const { isDownloadingReport } = this.state;
    const protocolEncoded = btoa(protocolId);

    /* eslint-disable-next-line max-len */
    const analyticsURL = `${ANALYTICS_REPORTS_URL}/home#!/analysis-tool/return/Y2xvc2Vfd2luZG93/acquisitions/${protocolEncoded}`;
    const encodedAnalyticsURL = btoa(analyticsURL);
    const encodedLocation = btoa(window.location);
    /* eslint-disable-next-line max-len */
    const authAndAnalyticsRedirectURL = `${ANALYTICS_REPORTS_URL}/auth-gateway?goTo=${encodedAnalyticsURL}&ifFail=${encodedLocation}`;

    return (
      <PopupMenu isLoading={isDownloadingReport[index] || false}>
        <PopupMenuItem
          onClick={() => this.downloadHospitalReport(protocolId, orgId, index)}
          icon="fas fa-eye"
          type="primary"
        >
          <I18n path="physio.physio-personal.button-report-view" />
        </PopupMenuItem>

        {isOrganization && (
          <form action={authAndAnalyticsRedirectURL} method="POST" target="_blank" className={styles.analyticsButton}>
            <input type="hidden" value={Cookie.get('access_token')} name="token" />
            {getLanguage() !== 'en-us' && (
              <PopupMenuItem icon="fas fa-chart-area" actionType="submit">
                Analytics
              </PopupMenuItem>
            )}
          </form>
        )}
        {!isShared && (
          <PopupMenuItem icon="fas fa-share-alt" onClick={() => this.shareReport(protocolId, orgId)}>
            <I18n path="physio.physio-personal.button-report-share" />
          </PopupMenuItem>
        )}
        {sharedWith.length > 0 && (
          <PopupMenuItem icon="fas fa-user-friends" onClick={() => this.onOpenSharedWithDialog(sharedWith)}>
            <I18n path="physio.physio-personal.button-report-shared-with" />
          </PopupMenuItem>
        )}
      </PopupMenu>
    );
  };

  renderMonitorizationTable = () => {
    const { acquisitions, acquisitionsKeys, doctors, isLoadingDoctors, isLoadingPatients } = this.state;
    const getName = (acquisition) => {
      const doctor =
        doctors.find((curr) => Number(acquisition[acquisitionsKeys.indexOf('doctor_id')]) === Number(curr.id)) || {};
      return doctor.full_name || '';
    };

    return acquisitions && acquisitions.length > 0 ? (
      <Table className={styles.table} canScroll={false}>
        <Head>
          <Row>
            <Cell isHead align="left">
              <I18n path="physio.physio-personal.table-physio-protocol" />
            </Cell>
            <Cell isHead align="left">
              <I18n path="physio.physio-personal.table-physio-patient" />
            </Cell>
            <Cell isHead align="left">
              <I18n path="physio.physio-personal.table-physio-date" />
            </Cell>
            <Cell isHead align="left">
              <I18n path="physio.physio-personal.table-physio-duration" />
            </Cell>
            <Cell isHead align="left">
              <I18n path="physio.physio-personal.table-physio-doctor" />
            </Cell>
            <Cell isHead align="left">
              &nbsp;
            </Cell>
          </Row>
        </Head>
        <Body>
          {acquisitions.map((acquisition, index) => (
            <Row key={index.toString()} className={styles.row}>
              <Cell>
                <div className={styles.cell}>
                  <img src={protocolIcon} alt="protocol icon" className={styles.protocolIcon} />
                  <div
                    className={styles.acquisitionLink}
                    onClick={() =>
                      this.downloadHospitalReport(
                        acquisition[acquisitionsKeys.indexOf('acquisition_id')],
                        acquisition[acquisitionsKeys.indexOf('org_id')],
                        index,
                      )
                    }
                    role="presentation"
                  >
                    {acquisition[acquisitionsKeys.indexOf('acquisition_id')]}
                  </div>
                </div>
              </Cell>
              <Cell>
                {isLoadingPatients
                  ? LOADING
                  : this.decryptPatientName(acquisition[acquisitionsKeys.indexOf('patient_name')])}
              </Cell>
              <Cell>
                {`${formatDate(acquisition[acquisitionsKeys.indexOf('start_time')])} ${formatTime(
                  acquisition[acquisitionsKeys.indexOf('start_time')],
                )}`}
              </Cell>
              <Cell>{formatDuration(acquisition[acquisitionsKeys.indexOf('duration')])}</Cell>
              <Cell>
                {isLoadingDoctors ? (
                  LOADING
                ) : (
                  <div className={styles.cell}>
                    <Avatar className={styles.avatar} />
                    <Text className={styles.doctorName}>{getName(acquisition)}</Text>
                  </div>
                )}
              </Cell>
              <Cell className={styles.buttonsAlignment}>
                {this.renderTableAction(
                  acquisition[acquisitionsKeys.indexOf('acquisition_id')],
                  acquisition[acquisitionsKeys.indexOf('org_id')],
                  acquisition[acquisitionsKeys.indexOf('shared')],
                  acquisition[acquisitionsKeys.indexOf('shared_with')],
                  index,
                )}
              </Cell>
            </Row>
          ))}
        </Body>
      </Table>
    ) : (
      <p className={styles.noMonitorizationsFound}>
        <I18n path="physio.physio-personal.no-monitorizations-found" />
      </p>
    );
  };

  render() {
    const {
      patientName,
      protocol,
      doctor,
      // date,
      // formatedDate,
      showReportDialog,
      showSharedWithDialog,
      showDropdownMembers,
      acceptSharingTerms,
      protocolToShare,
      orgToShare,
      patientsFilterSelected,
      isLoading,
      doctors,
      sharedWith,
      rows,
      currentPage,
      selectedTags,
      tags,
      showFilterByTags,
    } = this.state;

    return (
      <>
        <Section className={styles.sectionFilters}>
          <Subtitle>
            <I18n path="physio.physio-personal.title-filter-physio" />
          </Subtitle>
          <form autoComplete="off" onSubmit={this.formPreventDefault} onKeyDown={this.handleKeyDown}>
            <div className={styles.filters}>
              <div className={styles.searchPatientWrapper}>
                <LabeledInput label={<I18n path="physio.physio-personal.input-filter-name" />} htmlFor="patient_name">
                  <div className={styles.searchInput}>
                    {patientsFilterSelected.map((member) => (
                      <ProfileTag
                        key={member.id}
                        name={member.name}
                        avatar={member.image}
                        iconName="cross"
                        iconClick={() => this.removePatientOnFilterList(member.id)}
                        className={styles.profileTag}
                      />
                    ))}
                    <Input
                      id="patient_name"
                      onChange={this.handlePatientSearchChange}
                      inputRef={this.inputRef}
                      wrapperClassName={styles.inputSearch}
                      className={styles.inputSearchBorder}
                      value={patientName}
                    />
                  </div>
                </LabeledInput>

                {this.renderDropdownPatientsMatch()}
              </div>
              <LabeledInput label={<I18n path="physio.physio-personal.input-filter-protocol" />} htmlFor="protocol">
                <InputIcon
                  id="protocol"
                  onChange={(event) => this.handleInputChange('protocol', event)}
                  onIconClick={this.onFilterClick}
                  value={protocol}
                  icon="search"
                />
              </LabeledInput>
              <LabeledInput label={<I18n path="physio.physio-personal.input-filter-doctor" />} htmlFor="doctor">
                <Select id="doctor" onChange={(event) => this.handleInputChange('doctor', event)} value={doctor}>
                  <Option value="">{translate('physio.physio-personal.dropdown-filter-doctor-all')}</Option>
                  {doctors.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.full_name}
                    </Option>
                  ))}
                </Select>
              </LabeledInput>

              <Button onClick={this.onFilterByTagsClick} className={styles.applyFilter}>
                {selectedTags.length === 0 ? (
                  <I18n path="physio.physio-personal.button-filter-by-tag" />
                ) : (
                  <I18n
                    props={{ number_tags: selectedTags.length }}
                    path="physio.physio-personal.button-filter-by-tag-selected"
                  />
                )}
              </Button>

              <Button onClick={this.onClearClick} className={styles.applyFilter}>
                <I18n path="physio.physio-personal.button-filter-reset" />
              </Button>
              <Button onClick={this.onFilterClick} className={styles.applyFilter} type="primary">
                <I18n path="physio.physio-personal.button-filter-apply" />
              </Button>
            </div>
          </form>
        </Section>

        <Section>
          {!isLoading ? <SlideFadeInContainer>{this.renderMonitorizationTable()}</SlideFadeInContainer> : <Loader />}
        </Section>

        <ShareMonitorizationDialog
          showReportDialog={showReportDialog}
          handleInputToggle={this.handleInputToggle}
          showDropdownMembers={showDropdownMembers}
          acceptSharingTerms={acceptSharingTerms}
          protocolToShare={protocolToShare}
          orgToShare={orgToShare}
          onShareFinished={this.onConfirmShareHandler}
          onCloseDialog={this.onCloseShareDialog}
        />

        <SharedWithDialog
          isVisible={showSharedWithDialog}
          onCloseDialog={this.onCloseSharedWithDialog}
          sharedWith={sharedWith}
        />

        <TagsSelectDialog
          tags={tags}
          selectedTags={selectedTags}
          show={showFilterByTags}
          onCheckboxClick={(tag) => {
            this.setState((prevState) => {
              const { selectedTags } = prevState;

              if (selectedTags.includes(tag)) {
                return {
                  selectedTags: selectedTags.filter((selectedTag) => selectedTag !== tag),
                };
              }
              return {
                selectedTags: [...prevState.selectedTags, tag],
              };
            });
          }}
          onDialogToggle={() => this.setState({ showFilterByTags: false })}
        />

        <Pagination
          rows={rows}
          rowsPerPage={10}
          currentPage={currentPage}
          onPageSelect={this.onPaginationChange}
          maxPagesDisplay={3}
        />
      </>
    );
  }
}

export default Monitorizations;
