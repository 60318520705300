import Button from 'components/atoms/button';
import Text from 'components/atoms/text';
import Dialog from 'components/atoms/dialog';
import Header from 'components/atoms/dialog-header';
import Body from 'components/atoms/dialog-body';
import Footer from 'components/atoms/dialog-footer';
import I18n from 'utils/i18n';

function DeleteDialog({ isOpen, onClose, onDelete, ...props }) {
  return (
    <Dialog isOpen={isOpen} {...props}>
      <Header onClose={onClose} data-testid="delete-dialog-title">
        <I18n path="organization.organization-members-edit-suspend-exclude.title-box-member-exclude" />
      </Header>
      <Body>
        <Text>
          <I18n path="organization.organization-members-edit-suspend-exclude.text-box-organization-members-delete" />
        </Text>
      </Body>
      <Footer>
        <Button isBlock onClick={onClose} type="ghost" data-testid="cancel-button">
          <I18n path="organization.organization-members-edit-suspend-exclude.box-button-cancel" />
        </Button>
        <Button isBlock type="primary" onClick={onDelete} data-testid="confirm-button">
          <I18n path="organization.organization-members-edit-suspend-exclude.box-button-suspend" />
        </Button>
      </Footer>
    </Dialog>
  );
}

export default DeleteDialog;
