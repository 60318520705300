import Text from 'components/atoms/text';
import Badge from 'components/atoms/badge';
import I18n from 'utils/i18n';
import { isTrustedDevice } from '../../../../../utils/mobile-devices';
import { getLocalizedDatetimeFromString } from '../../../../../utils/format-date-time';

const TEXT_NAME = <I18n path="organization.organization-mobile-devices-info.name" />;
const TEXT_MODEL = <I18n path="organization.organization-mobile-devices-info.model" />;
const TEXT_OS_VERSION = <I18n path="organization.organization-mobile-devices-info.operating-system" />;
const TEXT_APP_VERSION = <I18n path="organization.organization-mobile-devices-info.app-version" />;
const TEXT_ORG_DEVICE_ID = <I18n path="organization.organization-mobile-devices-info.org-device-id" />;
const TEXT_LAST_ACCESS = (
  <I18n props={{ last_access: '' }} path="organization.organization-mobile-devices-info.text-last-access" />
);
const TEXT_EXPIRATION = <I18n path="organization.organization-mobile-devices-info.text-expiration" />;
const TEXT_PENDING_SYNCS = <I18n path="organization.organization-mobile-devices-info.text-pending-syncs" />;
const TEXT_OF = <I18n path="organization.organization-mobile-devices-info.text-of" />;
const TEXT_STATUS = <I18n path="organization.organization-mobile-devices-info.device-status" />;

function MobileDeviceDetails({ device }) {
  const BADGE_STATUS = {
    TRUSTED: 'success',
    NORMAL: 'default',
    BLOCKED: 'danger',
  };

  function renderStatusBadge() {
    const { status } = device;

    if (status) {
      return (
        <Badge type={BADGE_STATUS[status]}>
          <I18n path={`organization.organization-mobile-devices-info.${status.toLowerCase()}`} />
        </Badge>
      );
    }

    return null;
  }

  function renderExplanationForStatus() {
    const { status } = device;

    if (status === 'TRUSTED') {
      return (
        <div data-testid="trusted-description">
          <Text isWithoutMargin>
            <I18n path="organization.organization-mobile-devices-info.text-trusted-part-1" />
          </Text>
          <Text isWithoutMargin>
            <I18n path="organization.organization-mobile-devices-info.text-trusted-part-2" />
          </Text>
          <Text isWithoutMargin>
            <I18n path="organization.organization-mobile-devices-info.text-trusted-part-3" />
          </Text>
          <Text>
            <I18n path="organization.organization-mobile-devices-info.text-trusted-part-4" />
          </Text>
        </div>
      );
    }

    if (status === 'NORMAL') {
      return (
        <div data-testid="normal-description">
          <Text isWithoutMargin>
            <I18n path="organization.organization-mobile-devices-info.text-normal-part-1" />
          </Text>
          <Text isWithoutMargin>
            <I18n path="organization.organization-mobile-devices-info.text-normal-part-2" />
          </Text>
          <Text isWithoutMargin>
            <I18n path="organization.organization-mobile-devices-info.text-normal-part-3" />
          </Text>
          <Text>
            <I18n path="organization.organization-mobile-devices-info.text-normal-part-4" />
          </Text>
        </div>
      );
    }

    return (
      <div data-testid="blocked-description">
        <Text isWithoutMargin>
          <I18n path="organization.organization-mobile-devices-info.text-blocked-part-1" />
        </Text>
        <Text isWithoutMargin>
          <I18n path="organization.organization-mobile-devices-info.text-blocked-part-2" />
        </Text>
        <Text isWithoutMargin>
          <I18n path="organization.organization-mobile-devices-info.text-blocked-part-3" />
        </Text>
        <Text>
          <I18n path="organization.organization-mobile-devices-info.text-blocked-part-4" />
        </Text>
      </div>
    );
  }

  const syncedMembers = device.synced_members;

  return (
    <>
      <div>
        <Text isWithoutMargin data-testid="device-name">
          <b>{TEXT_NAME}</b>
          {device.name}
        </Text>
        <Text isWithoutMargin data-testid="device-model">
          <b>{TEXT_MODEL}</b>
          {device.model}
        </Text>
        <Text isWithoutMargin data-testid="device-os-version">
          <b>{TEXT_OS_VERSION}</b>
          {device.os_version}
        </Text>
        <Text isWithoutMargin data-testid="device-app-version">
          <b>{TEXT_APP_VERSION}</b>
          {device.app_version}
        </Text>
        <Text data-testid="device-org-device-id">
          <b>{TEXT_ORG_DEVICE_ID}</b>
          {device.org_device_id}
        </Text>
        <Text isWithoutMargin data-testid="device-last-access">
          <b>{TEXT_LAST_ACCESS}</b>
          {device.last_access ? getLocalizedDatetimeFromString(device.last_access) : '-'}
        </Text>
        {isTrustedDevice(device.status) ? (
          <>
            <Text isWithoutMargin data-testid="device-expiration">
              <b>{TEXT_EXPIRATION}</b>
              {device.expiration ? getLocalizedDatetimeFromString(device.expiration) : '-'}
            </Text>
            {syncedMembers ? (
              <Text data-testid="device-synced-members">
                <b>{TEXT_PENDING_SYNCS}:</b> {syncedMembers.nb_synced_members} {TEXT_OF} {syncedMembers.nb_members}
              </Text>
            ) : null}
          </>
        ) : null}
      </div>
      <div>
        <Text>
          <b>{TEXT_STATUS}</b>
          {renderStatusBadge()}
        </Text>
      </div>
      <div>{renderExplanationForStatus()}</div>
    </>
  );
}

export default MobileDeviceDetails;
