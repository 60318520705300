import { useEffect, useState } from 'react';
import braincare from 'components/routes/braincare';
import { useDispatch } from 'react-redux';
import { addToast } from 'actions/toasts';
import App from 'components/templates/app';
import Title from 'components/atoms/auth-title';
import Section from 'components/atoms/section';
import Label from 'components/atoms/input-label';
import Tag from 'components/atoms/tag-button';
import InputGroup from 'components/atoms/input-group';
import Button from 'components/atoms/button';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Card from 'components/molecules/member-card';
import InputIcon from 'components/molecules/input-icon';
import Loader from 'components/molecules/section-loader';
import Pagination from 'components/organisms/pagination';
import I18n, { translate } from 'utils/i18n';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate } from 'react-router-dom';
import { fetchAllOrganizations, fetchBuUsers } from 'api/portal';
import Text from 'components/atoms/text';
import styles from './styles.module.css';

const HOME = <I18n path="braincare.breadcrumbs.home" />;
const USERS = <I18n path="braincare.breadcrumbs.users" />;
const TITLE = <I18n path="braincare.users.title" />;

const LOAD_USERS_FAILURE = <I18n path="braincare.users.load-users-failure" />;
const LOAD_ORGANIZATIONS_FAILURE = <I18n path="braincare.users.load-organizations-failure" />;

const BUTTON_CREATE_USER = <I18n path="braincare.users.button-create-user" />;

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/braincare/organizations',
    title: USERS,
    isActive: true,
  },
];

const USER_STATUS = [
  { id: 'ACTIVE', text: translate('utils.status.active') },
  { id: 'SUSPENDED', text: translate('utils.status.suspended') },
];

function AdminUsers() {
  const [nameFilter, setNameFilter] = useState('');
  const [organizationFilter, setOrganizationFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [users, setUsers] = useState([]);
  const [errorUsers, setErrorUsers] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [errorOrganizations, setErrorOrganizations] = useState(false);
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchOrganizations = () => {
    setErrorOrganizations(false);

    fetchAllOrganizations()
      .then((response) => {
        const { data } = response;
        setErrorOrganizations(false);
        setOrganizations(data.results);
      })
      .catch(() => {
        setErrorOrganizations(true);
        dispatch(addToast('error', LOAD_ORGANIZATIONS_FAILURE));
      });
  };

  const fetchUsersHandler = (searchParams) => {
    setIsLoading(true);
    setErrorUsers(false);

    fetchBuUsers(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setErrorUsers(false);
        setUsers(data.results);
        setRows(data.count);
        setCurrentPage(Number(searchParams.get('page')));
      })
      .catch(() => {
        setIsLoading(false);
        setErrorUsers(true);
        dispatch(addToast('error', LOAD_USERS_FAILURE));
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', 8);

    fetchUsersHandler(searchParams);
    fetchOrganizations();
  }, []);

  const handleClearFilterClick = () => {
    setNameFilter('');
    setOrganizationFilter('');
    setStatusFilter('');

    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', 8);

    fetchUsersHandler(searchParams);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const openEditUserPage = (id) => navigate(`/braincare/users/${id}/info`);

  const renderUsers = () => {
    if (errorUsers) {
      return (
        <Text isError data-testid="error-load-users">
          {LOAD_USERS_FAILURE}
        </Text>
      );
    }

    if (users.length === 0) {
      return (
        <div className={styles.noActiveUsers} data-testid="no-users">
          <I18n path="braincare.users.no-users-found" />
        </div>
      );
    }

    return (
      <Section className={styles.usersContainer}>
        {users.map((user) => (
          <Card
            key={`user-${user.id}`}
            status={user.is_active ? 'ACTIVE' : 'SUSPENDED'}
            onClick={() => openEditUserPage(user.id)}
            className={styles.card}
            data-testid={`user-card-${user.id}`}
          >
            <div className={styles.cardHeader}>
              <Card.Header name={`${user.first_name} ${user.last_name}`} image={user.photo} subtitle={user.email} />
            </div>
            {user.organizations ? (
              <Card.Body>
                <div>
                  <p className={styles.cardSubtitle}>
                    <I18n path="braincare.users.card-organizations" />
                  </p>
                  <div className={styles.tag}>
                    {user.organizations.map((organization) => (
                      <Tag key={`tag-${user.id}-${organization.name}`} isSelected>
                        {organization.name}
                      </Tag>
                    ))}
                    {user.organizations.length > 4 && <Tag isSelected>+ {user.organizations.length - 4}</Tag>}
                  </div>
                </div>
              </Card.Body>
            ) : null}
          </Card>
        ))}
      </Section>
    );
  };

  const actionButton = {
    text: BUTTON_CREATE_USER,
    href: '/braincare/users/create',
  };

  return (
    <App breadcrumbs={breadcrumbs}>
      <Section className={styles.title}>
        <Title>{TITLE}</Title>
        <Button onClick={() => navigate(actionButton.href)}>{actionButton.text}</Button>
      </Section>

      <Section className={styles.container}>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
          <div className={styles.filters}>
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="filter">
                <I18n path="braincare.users.input-filter-name" />
              </Label>
              <InputIcon
                id="filter"
                onChange={(event) => setNameFilter(event.target.value)}
                onIconClick={() => {
                  const searchParams = new URLSearchParams();

                  searchParams.set('page', 1);
                  searchParams.set('page_size', 8);
                  searchParams.set('search_name', nameFilter);
                  searchParams.set('organization', organizationFilter);
                  searchParams.set('is_active', statusFilter ? statusFilter === 'ACTIVE' : undefined);

                  fetchUsersHandler(searchParams);
                }}
                value={nameFilter}
                icon="search"
                data-testid="search-input"
              />
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="organization">
                <I18n path="braincare.users.input-filter-organization" />
              </Label>
              <Select
                id="organization"
                onChange={(event) => setOrganizationFilter(event.target.value)}
                value={organizationFilter}
                hasError={errorOrganizations}
                errorMessage={errorOrganizations ? LOAD_ORGANIZATIONS_FAILURE : ''}
                data-testid="organization-select"
              >
                <Option value="">{translate('braincare.users.dropdown-filter-organization')}</Option>
                {organizations.map((organization) => (
                  <Option
                    key={`filter-organization-${organization.id}`}
                    value={organization.id}
                    data-testid={`organization-option-${organization.id}`}
                  >
                    {organization.name}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="status">
                <I18n path="braincare.users.input-filter-status" />
              </Label>
              <Select
                id="status"
                onChange={(event) => setStatusFilter(event.target.value)}
                value={statusFilter}
                data-testid="status-select"
              >
                <Option value="">{translate('braincare.users.dropdown-filter-status')}</Option>
                {USER_STATUS.map((status) => (
                  <Option key={`filter-status-${status.id}`} value={status.id}>
                    {status.text}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <Button className={styles.applyFilter} onClick={handleClearFilterClick} data-testid="clear-filters-button">
              <I18n path="braincare.users.button-clear-filters" />
            </Button>
            <Button
              className={styles.applyFilter}
              onClick={() => {
                const searchParams = new URLSearchParams();

                searchParams.set('page', 1);
                searchParams.set('page_size', 8);
                searchParams.set('search_name', nameFilter);
                searchParams.set('organization', organizationFilter);
                searchParams.set('is_active', statusFilter ? statusFilter === 'ACTIVE' : undefined);

                fetchUsersHandler(searchParams);
              }}
              type="primary"
              data-testid="apply-filters-button"
            >
              <I18n path="braincare.users.button-apply-filters" />
            </Button>
          </div>
        </form>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <SlideFadeInContainer>{renderUsers()}</SlideFadeInContainer>
            <Pagination
              rows={rows}
              rowsPerPage={8}
              currentPage={currentPage}
              onPageSelect={(page) => {
                const searchParams = new URLSearchParams();

                searchParams.set('page', page);
                searchParams.set('page_size', 8);
                searchParams.set('search_name', nameFilter);
                searchParams.set('organization', organizationFilter);
                searchParams.set('is_active', statusFilter ? statusFilter === 'ACTIVE' : undefined);

                fetchUsersHandler(searchParams);
              }}
              maxPagesDisplay={3}
            />
          </>
        )}
      </Section>
    </App>
  );
}

export default braincare(AdminUsers);
