import Avatar from 'components/atoms/avatar';
import TextField from 'components/mui/TextField';
import Button from 'components/mui/Button';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import I18n from 'utils/i18n';
import { SlideRightFadeContainer } from 'utils/transitions';
import App from 'pages/organization/organization-settings/App';
import VisuallyHiddenInput from 'components/mui/VisuallyHiddenInput';
import { useState } from 'react';
import { addToast } from 'actions/toasts';
import { updateMemberOrganization } from 'api/portal';
import { setActiveOrganizationData, setOrganizations } from 'features/userSlice';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import styles from './styles.module.css';

const SAVE_SUCCESS_MESSAGE = <I18n path="organization.organization-settings.save-success-message" />;
const SAVE_FAILURE_MESSAGE = <I18n path="organization.organization-settings.save-failure-message" />;
const FIX_FIELDS = <I18n path="messages.fix-fields" />;
const FILL_REQUIRED = <I18n path="messages.fill-required" />;

function OrganizationInfo() {
  const organization = useSelector((state) => state.user.active_organization);
  const organizations = useSelector((state) => state.user.organizations);

  const [address, setAddress] = useState(organization.address);
  const [photo, setPhoto] = useState(organization.photo);
  const [photoFile, setPhotoFile] = useState(undefined);
  const [errors, setErrors] = useState(new Map());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showImagePreview = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => callback(reader.result);
  };

  const handleOnImageChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    showImagePreview(file, (previewUrl) => {
      setPhoto(previewUrl);
      setPhotoFile(file);
    });
  };

  const onSubmit = () => {
    const errors = new Map();

    if (!address) {
      errors.set('address', FILL_REQUIRED);
    }

    setErrors(errors);

    if (errors.size > 0) {
      dispatch(addToast('error', FIX_FIELDS));
      return;
    }

    const formData = new FormData();

    formData.append('address', address);

    if (photoFile !== undefined) {
      formData.append('photo', photoFile);
    }

    setIsSubmitting(true);

    updateMemberOrganization(formData)
      .then((response) => {
        setIsSubmitting(false);

        const { data } = response;

        dispatch(addToast('success', SAVE_SUCCESS_MESSAGE));
        dispatch(setActiveOrganizationData(data));
        dispatch(
          setOrganizations(
            organizations.map((item) => {
              if (item.id === data.id) {
                return data;
              }

              return item;
            }),
          ),
        );
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(addToast('error', SAVE_FAILURE_MESSAGE));
      });
  };

  return (
    <App>
      <Section className={styles.section} maxWidth="428px">
        <SlideRightFadeContainer>
          <Subtitle className={styles.subtitle}>
            <I18n path="braincare.organizations-edit.step-basic-info.title" />
          </Subtitle>

          <div className={styles.imageWrapper}>
            <Avatar image={photo} description="organization image" className={styles.profileImage} />

            <Button
              component="label"
              sx={{ marginLeft: 5 }}
              variant="outlined"
              data-testid="upload-photo-button"
              startIcon={<CameraAltIcon />}
            >
              <I18n path="braincare.organizations-edit.step-basic-info.button-add-photo" />
              <VisuallyHiddenInput onChange={handleOnImageChange} type="file" />
            </Button>

            <Button
              sx={{ marginLeft: 1 }}
              color="error"
              variant="outlined"
              data-testid="remove-photo-button"
              onClick={() => {
                setPhoto(null);
                setPhotoFile('');
              }}
            >
              <I18n path="braincare.organizations-edit.step-basic-info.button-remove-photo" />
            </Button>
          </div>

          <TextField
            id="name"
            value={organization.name || ''}
            disabled
            label={<I18n path="braincare.organizations-edit.step-basic-info.input-label-name" />}
            variant="outlined"
            data-testid="name-text-field"
            inputProps={{ 'data-testid': 'name-input' }}
            fullWidth
          />

          <TextField
            id="address"
            sx={{ marginTop: 4 }}
            value={address || ''}
            onChange={(event) => setAddress(event.target.value)}
            label={<I18n path="braincare.organizations-edit.step-basic-info.input-label-address" />}
            variant="outlined"
            error={errors.has('address')}
            helperText={errors.get('address')}
            data-testid="address-text-field"
            inputProps={{ 'data-testid': 'address-input' }}
            FormHelperTextProps={{ 'data-testid': 'address-error' }}
            fullWidth
          />
        </SlideRightFadeContainer>
      </Section>

      <Button loading={isSubmitting} variant="outlined" onClick={onSubmit} data-testid="save-button">
        <I18n path="braincare.organizations-edit.buttons.button-save" />
      </Button>
      <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate('/')} data-testid="cancel-button">
        <I18n path="braincare.organizations-edit.buttons.button-cancel" />
      </Button>
    </App>
  );
}

export default OrganizationInfo;
