import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from 'components/molecules/breadcrumbs';
import BreadcrumbItem from 'components/molecules/breadcrumb-item';
import Title from 'components/atoms/title';
import Button from 'components/atoms/button';
import LinkItem from 'components/atoms/link-item';
import Menu from 'components/organisms/Menu';
import { ContentWrapper } from './styles';
import styles from './styles.module.css';

function App({ actionButton, breadcrumbs, children, className, linkItems, secondActionButton, title }) {
  const navigate = useNavigate();

  return (
    <div className={classNames(className, styles.container)}>
      <Menu />

      <ContentWrapper>
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Breadcrumbs className={styles.breadcrumbs}>
            {breadcrumbs.map((item, index) => (
              <BreadcrumbItem
                key={index.toString()}
                path={item.path}
                isActive={item.isActive}
                data-testid={`page-tab-${index}`}
              >
                {item.title}
              </BreadcrumbItem>
            ))}
          </Breadcrumbs>
        )}

        {title && <Title data-testid="page-title">{title}</Title>}
        {((linkItems && linkItems.length) || actionButton || secondActionButton) && (
          <div className={styles.navigation}>
            <div>
              {linkItems &&
                linkItems.length > 0 &&
                linkItems.map((item, index) => (
                  <LinkItem
                    key={index.toString()}
                    href={item.path}
                    isActive={window.location.pathname === item.path}
                    className={styles.linkItem}
                  >
                    {item.title}
                  </LinkItem>
                ))}
              /
            </div>
            <div>
              {secondActionButton && (
                <Button
                  className={styles.secondButton}
                  onClick={() => navigate(secondActionButton.href)}
                  data-testid="second-action-button"
                >
                  {secondActionButton.text}
                </Button>
              )}
              {actionButton && (
                <Button
                  className={styles.actionButton}
                  onClick={() => navigate(actionButton.href)}
                  data-testid="action-button"
                >
                  {actionButton.text}
                </Button>
              )}
            </div>
          </div>
        )}

        <main className={styles.main}>{children}</main>
      </ContentWrapper>
    </div>
  );
}

export default App;
