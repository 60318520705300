import AuthTitle from 'components/atoms/auth-title';
import Section from 'components/atoms/section';
import Text from 'components/atoms/text';
import Subtitle from 'components/atoms/subtitle';
import I18n from 'utils/i18n';
import styles from '../styles.module.css';

export default function TermsAndServicesPortuguese() {
  return (
    <Section className={styles.section}>
      <AuthTitle>
        <I18n path="auth.terms-and-service.title00" />
      </AuthTitle>

      <Subtitle>
        <I18n path="auth.terms-and-service.title01" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text00" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text01" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title02" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text02" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text03" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text04" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text05" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text06" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text07" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text08" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text09" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text10" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title03" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text11" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text12" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text13" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text14" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title04" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text15" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text16" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text17" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title05" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text18" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title06" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text19" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text20" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text21" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text22" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text23" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text24" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title07" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text25" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text26" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text27" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text28" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text29" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text30" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title08" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text31" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title09" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text32" />
        <br />
        <br />
      </Text>

      <Subtitle>
        <I18n path="auth.terms-and-service.title10" />
      </Subtitle>
      <Text size="medium">
        <I18n path="auth.terms-and-service.text33" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text34" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text35" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text36" />
        <br />
        <br />
        <I18n path="auth.terms-and-service.text37" />
        <br />
        <br />
      </Text>
    </Section>
  );
}
