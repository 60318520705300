import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import braincare from 'components/routes/braincare';
import { addToast } from 'actions/toasts';
import App from 'components/templates/app';
import Title from 'components/atoms/auth-title';
import Section from 'components/atoms/section';
import Label from 'components/atoms/input-label';
import InputGroup from 'components/atoms/input-group';
import Button from 'components/atoms/button';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import InputIcon from 'components/molecules/input-icon';
import Loader from 'components/molecules/section-loader';
import DeviceAggregate from 'components/molecules/device-aggregate';
import Card, { Header } from 'components/molecules/organization-card';
import Pagination from 'components/organisms/pagination';
import { round } from 'utils/format-number';
import I18n, { translate } from 'utils/i18n';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate } from 'react-router-dom';
import { fetchOrganizations } from 'api/portal';
import Text from 'components/atoms/text';
import styles from './styles.module.css';

const LOAD_ORGANIZATIONS_FAILURE = <I18n path="braincare.organizations.load-organizations-failure" />;

const HOME = <I18n path="braincare.breadcrumbs.home" />;
const ORGANIZATIONS = <I18n path="braincare.breadcrumbs.organizations" />;
const TITLE = <I18n path="braincare.organizations.title" />;

const CARD_MEMBERS = <I18n path="braincare.organizations.card-members" />;
const CARD_UNITS = <I18n path="braincare.organizations.card-units" />;
const CARD_DEVICES = <I18n path="braincare.organizations.card-devices" />;

const ITEMS = {
  members: { text: CARD_MEMBERS, icon: 'users' },
  units: { text: CARD_UNITS, icon: 'home' },
  devices: { text: CARD_DEVICES, icon: 'feed' },
};

const breadcrumbs = [
  {
    path: '/',
    title: HOME,
  },
  {
    path: '/braincare/organizations',
    title: ORGANIZATIONS,
    isActive: true,
  },
];

const ORGANIZATION_STATUS = [
  { id: 'ACTIVE', text: translate('utils.status.active') },
  { id: 'INACTIVE', text: translate('utils.status.inactive') },
];

const PAGE_SIZE = 8;

function AdminOrganizations() {
  const [filterValue, setFilterValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchOrganizationsHandler = (searchParams) => {
    setIsLoading(true);
    setErrorLoading(false);

    fetchOrganizations(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setErrorLoading(false);
        setOrganizations(data.results);
        setRows(data.count);
        setCurrentPage(Number(searchParams.get('page')));
      })
      .catch(() => {
        setIsLoading(false);
        setErrorLoading(true);
        dispatch(addToast('error', LOAD_ORGANIZATIONS_FAILURE));
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', PAGE_SIZE);

    fetchOrganizationsHandler(searchParams);
  }, []);

  const handleClearFilterClick = () => {
    setFilterValue('');
    setSelectedStatus('');

    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', PAGE_SIZE);

    fetchOrganizationsHandler(searchParams);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const openDetailPage = (id) => navigate(`/braincare/organizations/${id}/edit/info`);

  const renderItems = (items = {}) => (
    <>
      {Object.keys(items).map((key) => (
        <DeviceAggregate
          key={`item-${key}`}
          icon={ITEMS[key].icon}
          value={typeof items[key] === 'number' ? round(items[key]) : items[key]}
          label={ITEMS[key].text}
        />
      ))}
    </>
  );

  const renderOrganizations = () => {
    if (errorLoading) {
      return (
        <Text isError data-testid="error-message">
          {LOAD_ORGANIZATIONS_FAILURE}
        </Text>
      );
    }

    if (organizations.length === 0) {
      return (
        <div className={styles.noActiveOrganizations} data-testid="no-organization">
          <I18n path="braincare.organizations.no-organizations-found" />
        </div>
      );
    }

    return (
      <Section className={styles.organizationsContainer}>
        {organizations.map((organization) => (
          <Card
            key={`organization-card-${organization.id}`}
            onClick={() => openDetailPage(organization.id)}
            isBlocked={!organization.is_active}
            data-testid={`organization-card-${organization.id}`}
          >
            <Header title={organization.name} image={organization.photo} />
            {renderItems({
              members: organization.members_count || 0,
              units: organization.units_count || 0,
              devices: organization.devices_count || 0,
            })}
          </Card>
        ))}
      </Section>
    );
  };

  return (
    <App breadcrumbs={breadcrumbs}>
      <Section className={styles.title}>
        <Title>{TITLE}</Title>
      </Section>

      <Section className={styles.container}>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
          <div className={styles.filters}>
            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="filter">
                <I18n path="braincare.organizations.input-filter-name" />
              </Label>
              <InputIcon
                id="filter"
                onChange={(event) => setFilterValue(event.target.value)}
                onIconClick={() => {
                  const searchParams = new URLSearchParams();

                  searchParams.set('page', 1);
                  searchParams.set('page_size', PAGE_SIZE);
                  searchParams.set('search_name', filterValue);
                  searchParams.set('is_active', selectedStatus ? selectedStatus === 'ACTIVE' : '');

                  fetchOrganizationsHandler(searchParams);
                }}
                value={filterValue}
                icon="search"
                data-testid="name-input"
              />
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="status">
                <I18n path="braincare.organizations.input-filter-status" />
              </Label>
              <Select
                id="status"
                onChange={(event) => setSelectedStatus(event.target.value)}
                value={selectedStatus}
                data-testid="status-select"
              >
                <Option value="">{translate('braincare.organizations.dropdown-filter-status')}</Option>
                {ORGANIZATION_STATUS.map((status) => (
                  <Option key={`filter-${status.id}`} value={status.id}>
                    {status.text}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <Button className={styles.applyFilter} onClick={handleClearFilterClick} data-testid="clear-filters-button">
              <I18n path="braincare.organizations.button-clear-filters" />
            </Button>
            <Button
              className={styles.applyFilter}
              onClick={() => {
                const searchParams = new URLSearchParams();

                searchParams.set('page', 1);
                searchParams.set('page_size', PAGE_SIZE);
                searchParams.set('search_name', filterValue);
                searchParams.set('is_active', selectedStatus ? selectedStatus === 'ACTIVE' : '');

                fetchOrganizationsHandler(searchParams);
              }}
              type="primary"
              data-testid="apply-filters-button"
            >
              <I18n path="braincare.organizations.button-apply-filters" />
            </Button>
          </div>
        </form>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <SlideFadeInContainer>{renderOrganizations()}</SlideFadeInContainer>
            <Pagination
              rows={rows}
              rowsPerPage={8}
              currentPage={currentPage}
              onPageSelect={(page) => {
                const searchParams = new URLSearchParams();

                searchParams.set('page', page);
                searchParams.set('page_size', 8);
                searchParams.set('search_name', filterValue);
                searchParams.set('is_active', selectedStatus ? selectedStatus === 'ACTIVE' : '');

                fetchOrganizationsHandler(searchParams);
              }}
              maxPagesDisplay={3}
            />
          </>
        )}
      </Section>
    </App>
  );
}

export default braincare(AdminOrganizations);
