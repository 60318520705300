import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'utils/tokens';

function UnsignedPage({ children }) {
  const accessToken = getToken('access_token');
  const refreshToken = getToken('refresh_token');

  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken !== undefined && refreshToken !== undefined) {
      navigate('/');
    }
  }, []);

  return children;
}

export default (component) => <UnsignedPage>{component}</UnsignedPage>;
