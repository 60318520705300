import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Section from 'components/atoms/section';
import Subtitle from 'components/atoms/subtitle';
import Tag from 'components/atoms/tag-button';
import Select from 'components/atoms/input-select';
import Option from 'components/atoms/option';
import Label from 'components/atoms/input-label';
import InputGroup from 'components/atoms/input-group';
import Input from 'components/atoms/input';
import Button from 'components/atoms/button';
import Card from 'components/molecules/member-card';
import Loader from 'components/molecules/section-loader';
import Pagination from 'components/organisms/pagination/index';
import I18n, { translate } from 'utils/i18n';
import { MEMBER_STATUS } from 'utils/members';
import { SlideFadeInContainer } from 'utils/transitions';
import { useNavigate, useParams } from 'react-router-dom';
import braincare from 'components/routes/braincare';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationData, setOrganizationRoles, setOrganizationSelectedMember } from 'features/organizationSlice';
import { fetchMembersByOrganization, fetchOrganization, fetchRoles, fetchUnitsByOrganization } from 'api/portal';
import { addToast } from 'actions/toasts';
import styles from './styles.module.css';

const HOME = <I18n path="braincare.breadcrumbs.home" />;
const ORGANIZATIONS = <I18n path="braincare.breadcrumbs.organizations" />;
const ORGANIZATION_MEMBERS = <I18n path="braincare.breadcrumbs.organization-members" />;
const ERROR_LOADING_UNITS = <I18n path="messages.error-units" />;
const ERROR_LOADING_ROLES = <I18n path="messages.error-roles" />;
const LOAD_FAILURE = <I18n path="messages.load-failure" />;

function AdminMembers() {
  const [members, setMembers] = useState([]);
  const [units, setUnits] = useState([]);
  const [rows, setRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [organization, setOrganization] = useState(undefined);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [isLoadingMembers, setIsLoadingMembers] = useState(true);

  const roles = useSelector((state) => state.organization.roles);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { orgId } = params;

  const onClearFilterClick = () => {
    setSelectedUnit('');
    setSelectedStatus('');
    setSelectedRole('');
    setFilterValue('');
  };

  const searchMembers = (page) => {
    setIsLoadingMembers(true);

    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', 8);
    searchParams.set('unit', selectedUnit);
    searchParams.set('status', selectedStatus);
    searchParams.set('role', selectedRole);
    searchParams.set('search_name', filterValue);

    fetchMembersByOrganization(orgId, searchParams)
      .then((response) => {
        const { data } = response;

        if (data) {
          setIsLoadingMembers(false);

          setMembers(data.results);
          setRows(data.count);
          setCurrentPage(page);
        }
      })
      .catch(() => {
        setIsLoadingMembers(false);
        dispatch(addToast('error', LOAD_FAILURE));
      });
  };

  useEffect(() => {
    dispatch(clearOrganizationData());

    fetchOrganization(orgId)
      .then((response) => {
        const { data } = response;
        setOrganization(data);
      })
      .catch(() => {
        navigate('/braincare/organizations');
      });

    searchMembers(1);

    fetchUnitsByOrganization(orgId)
      .then((response) => {
        const { data } = response;
        setUnits(data.results);
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_LOADING_UNITS));
      });

    fetchRoles()
      .then((response) => {
        const { data } = response;
        dispatch(setOrganizationRoles(data));
      })
      .catch(() => {
        dispatch(addToast('error', ERROR_LOADING_ROLES));
      });
  }, []);

  const openEditMemberPage = (id) => {
    const member = members.find((index) => index.id === id);
    dispatch(setOrganizationSelectedMember(member));
    navigate(`/braincare/organizations/${orgId}/members/${id}/edit/info`);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/organizations',
      title: ORGANIZATIONS,
    },
    {
      path: `/braincare/organizations/${orgId}/members`,
      title: ORGANIZATION_MEMBERS,
      isActive: true,
    },
  ];

  const title = organization ? (
    <I18n props={{ organization: organization.name }} path="braincare.organization-members.main-title" />
  ) : (
    <I18n path="braincare.organization-members.default-main-title" />
  );

  return (
    <App title={title} breadcrumbs={breadcrumbs}>
      <br />
      <Section>
        <Subtitle>
          <I18n path="braincare.organization-members.title-filter-members" />
        </Subtitle>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()} onKeyDown={handleKeyDown}>
          <div className={styles.filters}>
            <InputGroup className={styles.filterInputs}>
              <Label htmfor="unit" data-testid="unit-filter-label">
                <I18n path="braincare.organization-members.input-filter-unit" />
              </Label>
              <Select
                id="unit"
                onChange={(event) => setSelectedUnit(event.target.value)}
                value={selectedUnit}
                data-testid="select-unit"
              >
                <Option value="">{translate('braincare.organization-members.dropdown-filter-unit')}</Option>
                {units.map((unit) => (
                  <Option key={`unit-${unit.id}`} value={unit.id.toString()}>
                    {unit.name}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="status" data-testid="status-filter-label">
                <I18n path="braincare.organization-members.input-filter-status" />
              </Label>
              <Select
                id="status"
                onChange={(event) => setSelectedStatus(event.target.value)}
                value={selectedStatus}
                data-testid="select-status"
              >
                <Option value="">{translate('braincare.organization-members.dropdown-filter-protocol')}</Option>
                {MEMBER_STATUS.map((status) => (
                  <Option key={`status-${status.id}`} value={status.id}>
                    {status.text}
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="role" data-testid="role-filter-label">
                <I18n path="braincare.organization-members.input-filter-role" />
              </Label>
              <Select
                id="role"
                onChange={(event) => setSelectedRole(event.target.value)}
                value={selectedRole}
                data-testid="select-role"
              >
                <Option value="">{translate('braincare.organization-members.dropdown-filter-doctor')}</Option>
                {roles.map((role) => (
                  <Option key={`role-${role.id}`} value={role.id.toString()}>
                    <I18n path={`organization.organization-members.card-member-role-${role.id}`} />
                  </Option>
                ))}
              </Select>
            </InputGroup>

            <InputGroup className={styles.filterInputs}>
              <Label htmlFor="filter" data-testid="name-filter-label">
                <I18n path="braincare.organization-members.input-filter-name" />
              </Label>
              <Input
                id="filter"
                placeholder={translate('braincare.organization-members.dropdown-filter-name')}
                onChange={(event) => setFilterValue(event.target.value)}
                value={filterValue}
                data-testid="name-filter-input"
              />
            </InputGroup>
            <Button onClick={onClearFilterClick} data-testid="reset-filters-button">
              <I18n path="braincare.organization-members.button-filter-reset" />
            </Button>
            <Button onClick={() => searchMembers(1)} type="primary" data-testid="apply-filters-button">
              <I18n path="braincare.organization-members.button-filter-apply" />
            </Button>
          </div>
        </form>
        {isLoadingMembers ? (
          <Loader />
        ) : (
          <>
            <SlideFadeInContainer className={styles.memberList}>
              {members.length === 0 ? (
                <div className={styles.notFoundText}>
                  <I18n path="braincare.organization-members.no-results-found" />
                </div>
              ) : null}
              {members.map((member) => (
                <Card
                  key={`member-${member.id}`}
                  status={member.status}
                  onClick={() => openEditMemberPage(member.id)}
                  className={styles.card}
                  data-testid={`member-card-${member.id}`}
                >
                  <div className={styles.cardHeader}>
                    <Card.Header
                      name={`${member.user.first_name} ${member.user.last_name}`}
                      image={member.user.photo}
                      imageDescription={`${member.user.first_name} ${member.user.last_name}`}
                    />
                  </div>
                  <Card.Body>
                    <div>
                      <p className={styles.cardSubtitle}>
                        <I18n path="braincare.organization-members.card-member-roles" />
                      </p>
                      <p className={styles.cardContent}>
                        {member.roles
                          .map((role) => translate(`braincare.organization-members.card-member-role-${role.id}`))
                          .join(', ')}
                      </p>
                    </div>
                    <div>
                      <p className={styles.cardSubtitle}>
                        <I18n path="braincare.organization-members.card-member-units" />
                      </p>
                      <div>
                        {member.units.slice(0, 4).map((unit) => (
                          <Tag key={`tag-${member.id}-${unit.name}`} isSelected>
                            {unit.name}
                          </Tag>
                        ))}
                        {member.units.length > 4 && <Tag isSelected>+ {member.units.length - 4}</Tag>}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </SlideFadeInContainer>
            <Pagination
              rows={rows}
              rowsPerPage={8}
              currentPage={currentPage}
              onPageSelect={searchMembers}
              maxPagesDisplay={3}
            />
          </>
        )}
      </Section>
    </App>
  );
}

export default braincare(AdminMembers);
