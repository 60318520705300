import PropTypes from 'prop-types';
import Input from 'components/atoms/input';
import IconButton from 'components/atoms/icon-button';
import styles from './styles.module.css';

const InputIcon = ({ icon, onIconClick, isDisabled, ...props }) => (
  <div className={styles.inputContainer}>
    <Input isDisabled={isDisabled} className={styles.input} {...props} />
    <IconButton name={icon} onClick={onIconClick} isDisabled={isDisabled} className={styles.icon} />
  </div>
);

InputIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onIconClick: PropTypes.func,
};

InputIcon.defaultProps = {
  isDisabled: false,
  onIconClick: () => {},
};

export default InputIcon;
