import PropTypes from 'prop-types';
import styles from './styles.module.css';

const InputFile = ({ alt, onChange, children, ...props }) => (
  <label htmlFor="file" className={styles.label}>
    <input type="file" id="file" alt={alt} onChange={onChange} className={styles.input} {...props} />
    {children}
  </label>
);

InputFile.propTypes = {
  alt: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

export default InputFile;
