import PropTypes from 'prop-types';
import Text from 'components/atoms/text';
import styles from './styles.module.css';

const Footer = ({ date }) => (
  <div className={styles.footer}>
    <Text size="small" className={styles.footerText}>
      {date}
    </Text>
  </div>
);

Footer.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
};

export default Footer;
