import I18n from 'utils/i18n';
import PasswordValidator from './PasswordValidator';

export default class LengthValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { sizeRange } = passwordPolicy;

    super(
      sizeRange,
      'size_range',
      false,
      <I18n props={sizeRange} path="organization.password-rules.size-constraint" />,
    );
  }

  validate(password) {
    if (this.policy) {
      const { min, max } = this.policy;
      const lengthPassword = password.length;

      if (min > lengthPassword || max < lengthPassword) {
        return false;
      }
    }

    return true;
  }
}
